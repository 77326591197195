import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { FacebookMessengerShareButton, FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import shortUrlApi from '../../../../api/site/shortUrl.api';
import { toAbsoluteUrl } from '../../../../app/helpers';
import DynamicObject from '../../../../models/dynamic-object';
import { TWITTER_SHARE_URL } from '../../../helper/ShareLinks';
import { showSuccess } from '../../../helper/SystemAlert';
import { generateOpenSooqRedirectUrl, getCookies } from '../../../helpers/commonFunctions';
import { useLang } from '../../../i18n/OpenSooqi18n';
import shareLogging from '../../../../api/shareLogging';
import { ShareButtonType } from '../../../helper/ShareLogging';

type Props = {
    listing : DynamicObject
    listingUrl ?: string
    comeFrom : string
    opened?:boolean
    dataComeFrom?: string
}

export function ShareCv({listing,listingUrl,comeFrom,opened, dataComeFrom} : Props) {
    const Intl = useIntl();
    const lang = useLang();
    const [facebook,setFacebook] = useState<string>('')
    const [messenger,setMessenger] = useState<string>('')
    const [copyLink,setCopyLink] = useState<string>('')
    const [twitterT,setTwitterT] = useState<string>('')
    const [whatsappT,setWhatsappT] = useState<string>('')
    const [emailT,setEmailT] = useState<string>('')
    const from = isMobile ? 'from_mobile' : 'from_desktop'
    let link =  listingUrl || generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang) + '/job-seekers/' + listing?.cv_id;
    useEffect(() => {
        let links = {
            links : [
                link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_widget_${from}_myopensooq_to_facebook`,
                link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_widget_${from}_myopensooq_to_twitter`,
                link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_widget_${from}_myopensooq_to_whatsapp`,
                link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_widget_${from}_myopensooq_to_messenger`,
                link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_widget_${from}_myopensooq_to_email`,
                link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_widget_${from}_myopensooq_to_copylink`
            ]
        };
        if(opened ){
            links = {
                links : [
                    link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_popup_${from}_myopensooq_to_facebook`,
                    link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_popup_${from}_myopensooq_to_twitter`,
                    link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_popup_${from}_myopensooq_to_whatsapp`,
                    link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_popup_${from}_myopensooq_to_messenger`,
                    link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_popup_${from}_myopensooq_to_email`,
                    link + `#utm_source=opensooq&utm_medium=share&utm_campaign=manage_cv_share_popup_${from}_myopensooq_to_copylink`
                ]
            }
        }
        shorten(links)
    },[])

    const copy = () => {
        handleShareLogging(ShareButtonType.OTHER)
        navigator.clipboard.writeText(copyLink)
        showSuccess(Intl,lang,Intl.formatMessage({id : "copiedSuccess"}))
    }

    const shorten = (shareLinks : DynamicObject ) => {
        shortUrlApi(shareLinks).then((response) => {
            setFacebook(response.data.links[1]);
            setMessenger(response.data.links[3]);
            setCopyLink(response.data.links[5]);
            setTwitterT(`${Intl.formatMessage({id : "checkMyListing"})} ${response.data.links[0]} \n\n ${Intl.formatMessage({id : 'downloadApp'})}  \n ${TWITTER_SHARE_URL}`)
            setWhatsappT(`${Intl.formatMessage({id : "checkMyListing"})} ${response.data.links[2]} \n\n ${Intl.formatMessage({id : 'downloadApp'})}  \n ${TWITTER_SHARE_URL}`)
            setEmailT(`${Intl.formatMessage({id : "checkMyListing"})} ${response.data.links[4]} \n\n ${Intl.formatMessage({id : 'downloadApp'})}  \n ${TWITTER_SHARE_URL}`)
        })
    }

    const handleShareLogging = (type : string) => {
        if(!dataComeFrom) return;
        shareLogging({
            "Logging": {
                "model": "Post",
                "model_id": listing?.equivalent_post_id,
                "share_to": type,
                "data": dataComeFrom
            }
        })
    }

    return ( <div className="card card-flush">
            <div className="card-header pt-5">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-dark">{Intl.formatMessage({id : "shareListings.title"})}</span>
                    <span className="text-gray-400 mt-1 fw-semibold fs-6">{Intl.formatMessage({id : "shareListings.desc"})}</span>
                </h3>
            </div>
            <div className="card-body pt-5">
                <FacebookShareButton url={facebook} className="w-100 " onClick={()=> handleShareLogging(ShareButtonType.FACEBOOK)}>
                    <div className="d-flex justify-content-between hoverPointer">
                        <div className="">
                            <img src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')} className="w-20px me-3" alt=""/>
                            <span className={`text-primary opacity-75-hover}  fs-6 fw-semibold`}>{Intl.formatMessage({id : "shareListings.facebook"})}</span>
                        </div>
                        <div className="">
                            <span className=" justify-content-end">
                                <span className="svg-icon svg-icon-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"></path> <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"></rect> <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"></path> </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </FacebookShareButton>
                <div className="separator separator-dashed my-3"></div>
                <TwitterShareButton  url={twitterT} className="w-100" onClick={()=> handleShareLogging(ShareButtonType.TWITTER)}>
                    <div className="d-flex justify-content-between hoverPointer">
                        <div className="">
                            <img width="21" height="21" src={toAbsoluteUrl('/media/custom/x_icon_sqaure.svg')} alt="x" className='me-3 w-20px'/>
                            <span className={`text-primary opacity-75-hover} fs-6 fw-semibold`}>{Intl.formatMessage({id : "shareListings.twitter"})}</span>
                        </div>
                        <div className="">
                            <span className="justify-content-end">
                                <span className="svg-icon svg-icon-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"></path> <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"></rect> <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"></path> </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </TwitterShareButton>
                <div className="separator separator-dashed my-3"></div>
                <WhatsappShareButton  url={whatsappT} className="w-100" onClick={()=> handleShareLogging(ShareButtonType.WHATSAPP)}>
                <div className="d-flex justify-content-between hoverPointer">
                    <div className="">
                        <svg className="me-3 w-20px" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.224 3.72825C18.0423 1.53492 15.1373 0.333252 12.0456 0.333252C5.67562 0.333252 0.483959 5.52492 0.483959 11.8949C0.483959 13.9366 1.02063 15.9199 2.02396 17.6699L0.390625 23.6666L6.51563 22.0566C8.20729 22.9783 10.109 23.4683 12.0456 23.4683C18.4156 23.4683 23.6073 18.2766 23.6073 11.9066C23.6073 8.81492 22.4056 5.90992 20.224 3.72825ZM12.0456 21.5083C10.319 21.5083 8.62729 21.0416 7.14563 20.1666L6.79563 19.9566L3.15563 20.9133L4.12396 17.3666L3.89063 17.0049C2.93396 15.4766 2.42063 13.7033 2.42063 11.8949C2.42063 6.59825 6.73729 2.28159 12.034 2.28159C14.6006 2.28159 17.0156 3.28492 18.824 5.10492C20.644 6.92492 21.6356 9.33992 21.6356 11.9066C21.659 17.2033 17.3423 21.5083 12.0456 21.5083ZM17.319 14.3216C17.0273 14.1816 15.604 13.4816 15.3473 13.3766C15.079 13.2833 14.8923 13.2366 14.694 13.5166C14.4956 13.8083 13.9473 14.4616 13.784 14.6483C13.6206 14.8466 13.4456 14.8699 13.154 14.7183C12.8623 14.5783 11.929 14.2633 10.8323 13.2833C9.96896 12.5133 9.39729 11.5683 9.22229 11.2766C9.05896 10.9849 9.19896 10.8333 9.35063 10.6816C9.47896 10.5533 9.64229 10.3433 9.78229 10.1799C9.92229 10.0166 9.98063 9.88825 10.074 9.70159C10.1673 9.50325 10.1206 9.33992 10.0506 9.19992C9.98063 9.05992 9.39729 7.63659 9.16396 7.05325C8.93062 6.49325 8.68563 6.56325 8.51063 6.55159C8.33563 6.55159 8.14896 6.55159 7.95062 6.55159C7.75229 6.55159 7.44896 6.62159 7.18062 6.91325C6.92396 7.20492 6.17729 7.90492 6.17729 9.32825C6.17729 10.7516 7.21563 12.1283 7.35563 12.3149C7.49563 12.5133 9.39729 15.4299 12.2906 16.6783C12.979 16.9816 13.5156 17.1566 13.9356 17.2849C14.624 17.5066 15.254 17.4716 15.7556 17.4016C16.3156 17.3199 17.4706 16.7016 17.704 16.0249C17.949 15.3483 17.949 14.7766 17.8673 14.6483C17.7856 14.5199 17.6106 14.4616 17.319 14.3216Z" fill="#25D366"></path></svg>
                        <span className={`text-primary opacity-75-hover} fs-6 fw-semibold`}>{Intl.formatMessage({id : "shareListings.whatsapp"})}</span>
                    </div>
                    <div className="">
                        <span className="justify-content-end">
                            <span className="svg-icon svg-icon-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"></path> <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"></rect> <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"></path> </svg>
                            </span>
                        </span>
                    </div>
                </div>
                </WhatsappShareButton>
                <div className="separator separator-dashed my-3"></div>
                <FacebookMessengerShareButton appId={process.env.REACT_APP_OPENSOOQ_FB_APPID ?? ''} url={messenger} className="w-100" onClick={()=> handleShareLogging(ShareButtonType.MESSENGER)}>
                <div className="d-flex justify-content-between hoverPointer">
                    <div className="">
                        <i className="w-20px fs-2 me-3 fab fa-facebook-messenger"></i>
                        <span className={`text-primary opacity-75-hover} fs-6 fw-semibold`}>{Intl.formatMessage({id : "shareListings.messenger"})}</span>
                    </div>
                    <div className="">
                        <span className="justify-content-end">
                            <span className="svg-icon svg-icon-2">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"></path> <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"></rect> <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"></path> </svg>
                            </span>
                        </span>
                    </div>
                </div>
                </FacebookMessengerShareButton>
                <div className="separator separator-dashed my-3"></div>
                <a target="_blank" href={`https://mail.google.com/mail/?view=cm&fs=1&su=${Intl.formatMessage({id : 'shareEmailSubject'})}&body=${emailT}}`} className="w-100" onClick={()=> handleShareLogging(ShareButtonType.EMAIL)}>
                    <div className="d-flex justify-content-between hoverPointer">
                        <div className="">
                            <svg className="w-20px me-3" width="24" height="20" viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.3359 0.666748H2.66927C1.38594 0.666748 0.347604 1.71675 0.347604 3.00008L0.335938 17.0001C0.335938 18.2834 1.38594 19.3334 2.66927 19.3334H21.3359C22.6193 19.3334 23.6693 18.2834 23.6693 17.0001V3.00008C23.6693 1.71675 22.6193 0.666748 21.3359 0.666748ZM21.3359 5.33341L12.0026 11.1667L2.66927 5.33341V3.00008L12.0026 8.83341L21.3359 3.00008V5.33341Z" fill="#0179FF"></path></svg>
                            <span  className={`text-primary opacity-75-hover} fs-6 fw-semibold`}>{Intl.formatMessage({id : "shareListings.email"})}</span>
                        </div>
                        <div className="">
                            <span className="justify-content-end">
                                <span className="svg-icon svg-icon-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"></path> <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"></rect> <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"></path> </svg>
                                </span>
                            </span>
                        </div>
                    </div>
                </a>
                <div className="separator separator-dashed my-3"></div>
                <div className="w-100" >
                    <div className="d-flex justify-content-between hoverPointer" onClick={() => {copy()}}>
                        <div className="">
                            <svg className="w-15px me-3" xmlns="http://www.w3.org/2000/svg" width="50" height="30" viewBox="0 0 24 32" fill="#57585A"><path d="M17.666 6.08317L15.6543 8.09484L13.4018 5.84234V21.6665H10.5968V5.84234L8.34435 8.09484L6.33268 6.08317L11.9993 0.416504L17.666 6.08317ZM23.3327 13.1665V28.7498C23.3327 30.3082 22.0577 31.5832 20.4993 31.5832H3.49935C1.92685 31.5832 0.666016 30.3082 0.666016 28.7498V13.1665C0.666016 11.594 1.92685 10.3332 3.49935 10.3332H7.74935V13.1665H3.49935V28.7498H20.4993V13.1665H16.2493V10.3332H20.4993C22.0577 10.3332 23.3327 11.594 23.3327 13.1665Z" fill="#191919"></path></svg>
                            <span className={`text-primary opacity-75-hover} fs-6 fw-semibold`}>{Intl.formatMessage({id : "shareListings.other"})}</span>
                        </div>
                        <div className="">
                            <button type="button"  className="btn btn-icon btn-sm h-auto btn-color-gray-400 btn-active-color-primary justify-content-end">
                                <span className="svg-icon svg-icon-2">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path opacity="0.3" d="M4.7 17.3V7.7C4.7 6.59543 5.59543 5.7 6.7 5.7H9.8C10.2694 5.7 10.65 5.31944 10.65 4.85C10.65 4.38056 10.2694 4 9.8 4H5C3.89543 4 3 4.89543 3 6V19C3 20.1046 3.89543 21 5 21H18C19.1046 21 20 20.1046 20 19V14.2C20 13.7306 19.6194 13.35 19.15 13.35C18.6806 13.35 18.3 13.7306 18.3 14.2V17.3C18.3 18.4046 17.4046 19.3 16.3 19.3H6.7C5.59543 19.3 4.7 18.4046 4.7 17.3Z" fill="currentColor"></path> <rect x="21.9497" y="3.46448" width="13" height="2" rx="1" transform="rotate(135 21.9497 3.46448)" fill="currentColor"></rect> <path d="M19.8284 4.97161L19.8284 9.93937C19.8284 10.5252 20.3033 11 20.8891 11C21.4749 11 21.9497 10.5252 21.9497 9.93937L21.9497 3.05029C21.9497 2.498 21.502 2.05028 20.9497 2.05028L14.0607 2.05027C13.4749 2.05027 13 2.52514 13 3.11094C13 3.69673 13.4749 4.17161 14.0607 4.17161L19.0284 4.17161C19.4702 4.17161 19.8284 4.52978 19.8284 4.97161Z" fill="currentColor"></path> </svg>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}