import React, { createElement, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import CreateCartApi from '../../../../api/CreateCart.api';
import getWalletPackagesApi from '../../../../api/getWalletPackages.api';
import { PointOfSales } from '../../../helper/PointOfSales';
import { KTSVG } from "../../../helpers";
import { getCookies } from '../../../helpers/commonFunctions';
import { useLang } from '../../../i18n/OpenSooqi18n';
import { WalletPackagesInterface } from '../../../interfaces/WalletPackages';

type Props = {
  show: boolean
  handleClose: () => void
}

const AddCreditModal = ({show,handleClose}: Props) => {
    const closeHandler = () => {
        handleClose()
    }
    const [walletPackages, setWalletPackages] = useState<WalletPackagesInterface>(undefined);
    const Intl = useIntl();
    const lang = useLang();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const action = params.get('action') ?? '';

    useEffect(() => {
        getWalletPackagesApi().then((res)=>{
            setWalletPackages(res.data)
        })
    },[show])

    const skeletonRows = useMemo(()=>{
        let rows = []
        for (let i = 0; i < 8; i++) {
            rows.push(<Skeleton className="m-5 h-45px w-400px" key={i}/>)
        }
        return rows;
    }, []);

    const handleCreateCart = (creditKey, productAssignId) => {
        let redirectTo =  window.location.href;
        if(action != '' && action == 'openWalletBundles'){
            redirectTo =  window.location.origin + window.location.pathname;;
        }
        const formData = { 
            Payment: {
                isCredit: 1,
                creditKey: creditKey,
                productAssignId: productAssignId,
                modelId: getCookies()?.userInfo?.id,
                absoluteUrl :redirectTo,
                post : PointOfSales.ACCOUNT_POS_WALLET_TOPUP_ADD_CREDIT
            }
        }
        CreateCartApi(formData).then((res)=>{
            window.location.href = res.data.redirect;
        })
    }

    return  <>
        <Modal id='iframe'tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered' show={show} onHide={closeHandler}  >
                <div className='d-flex flex-column align-items-center justify-content-center gap-5 modal-body'>
                <div className='d-flex justify-content-between mt-2 w-100'>
                    {walletPackages && <span className="h1">{walletPackages.header.title}</span>}
                    {!walletPackages && <Skeleton className="m-5 h-30px w-200px"/>}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeHandler}>
                        <KTSVG className='svg-icon-1 fa-5x' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
               {walletPackages && <p className='align-self-start text-muted fs-5'>{walletPackages.header.subtitle}</p>}
               {!walletPackages && <Skeleton className="m-5 h-30px w-400px"/>}
               <div className='d-flex flex-column w-100'>
                {!walletPackages && skeletonRows}
                {walletPackages && walletPackages.packages.map((item,index)=>{
                    return <div key={index} className='d-flex flex-row hoverPointer justify-content-between align-items-center w-100 shadow m-2 p-3 py-1 rounded-3'  onClick={()=>handleCreateCart(item.walletKey, item.productAssignId)} style={{backgroundImage: `url('/media/custom/addCreditsbackgroung-${lang}.jpeg')`, backgroundSize: "cover"}}>
                        <div className='d-flex gap-2 text-white'>
                            <span className='fw-bold mt-1' style={{fontSize: "36px"}}>{item.countryPrice}</span>
                            <span className='align-self-end mb-3 fs-4'>{item.currency}</span>
                        </div>
                        <button type='button' className='btn bg-white p-2 px-5 text-primary rounded-pill fw-bold'>{Intl.formatMessage({id: "addCredit.select"})}</button>
                    </div>
                })}
                </div>
                </div>
        </Modal>
    </>
}

export default AddCreditModal;
