import { logPermissionLocationAllow, logPermissionLocationDeny } from "../helpers/commonLoggingFunctions";
import { DEFAULT_VALUE, DEFAULT_VALUE_COUNTRY, EMPTY_LOCATION, Location} from "../types/LoggingTypes";


export class GeoDimensions {
    private static instance : GeoDimensions;
    //infra
    private _geoUserCountry : string = DEFAULT_VALUE_COUNTRY;
    private _geoUserCity: string = DEFAULT_VALUE;
    //
    private _geoAppCountry:string = DEFAULT_VALUE_COUNTRY;
    private _geoPostCountry:string = DEFAULT_VALUE_COUNTRY;
    private _geoPostCity : string = DEFAULT_VALUE;
    private _geoUserLocation : Location = EMPTY_LOCATION;
    private _geoPostLocation : Location = EMPTY_LOCATION;
    private _geoPostNeighborhood : string = DEFAULT_VALUE;

    private constructor(){
        this.getGeoUserLocation()
    }

    public static getInstance(){
        if(!GeoDimensions.instance)
        {
            GeoDimensions.instance = new GeoDimensions();
        }
        return GeoDimensions.instance;
    }

    public reset(){
        this._geoPostCountry = DEFAULT_VALUE_COUNTRY;
        this._geoPostCity = DEFAULT_VALUE;
        this._geoPostLocation = EMPTY_LOCATION;
        this._geoPostNeighborhood = DEFAULT_VALUE;
    }

    get geoUserCountry():string {
        return this._geoUserCountry;
    }

    set geoUserCountry(_geoUserCountry:string)
    {
        this._geoUserCountry = _geoUserCountry;
    }

    get geoUserCity():string {
        return this._geoUserCity;
    }

    set geoUserCity(_geoUserCity:string)
    {
        this._geoUserCity = _geoUserCity;
    }

    get geoAppCountry():string {
        return this._geoAppCountry;
    }

    set geoAppCountry(_geoAppCountry:string)
    {
        this._geoAppCountry = _geoAppCountry;
    }

    get geoUserLocation():Location {
        return this._geoUserLocation;
    }

    get geoPostCountry():string {
        return this._geoPostCountry;
    }

    set geoPostCountry(_geoPostCountry:string)
    {
        this._geoPostCountry = _geoPostCountry;
    }

    get geoPostCity():string {
        return this._geoPostCity;
    }

    set geoPostCity(_geoPostCity:string)
    {
        this._geoPostCity = _geoPostCity
    }

    get geoPostLocation():Location {
        return this._geoPostLocation;
    }

    set geoPostLocation(_geoPostLocation:Location)
    {
        this._geoPostLocation = _geoPostLocation
    }

    get geoPostNeighborhood():string {
        return this._geoPostNeighborhood;
    }

    set geoPostNeighborhood(_geoPostNeighborhood:string)
    {
        this._geoPostNeighborhood = _geoPostNeighborhood
    }

    private getGeoUserLocation() : void {
        let isFirstTime : boolean = true; 
        if(typeof navigator !== 'undefined' && navigator.geolocation)
        {   
            if(typeof navigator.permissions !== 'undefined' && typeof navigator.permissions.query !== 'undefined'){
                navigator.permissions.query({name: 'geolocation'}).then((state) => {
                    if(state.state === 'granted' || state.state === 'denied')
                        isFirstTime = false;
                        navigator.geolocation.getCurrentPosition(
                        //success
                        (positon : GeolocationPosition) =>{
                                const lat = positon.coords.latitude;
                                const long = positon.coords.longitude;
                                this._geoUserLocation = {
                                    latitude : lat.toString(),
                                    longitude : long.toString()
                                };
                                if(isFirstTime)
                                    logPermissionLocationAllow();
                        },
                        (error: GeolocationPositionError) => {
                            if(error.code === error.PERMISSION_DENIED && isFirstTime)
                                logPermissionLocationDeny();
                        },
                        ); 
                })
            }
        }
    }
}