import { useEffect, useMemo, useState } from "react";
import { BuyNowServiceInterface } from "../../../interfaces/BuyNowServiceInterface";
import getServicesApi from "../../../../api/buyNow/getServices.api";
import { useLang } from "../../../i18n/OpenSooqi18n";
import Skeleton from "react-loading-skeleton";
import { useIntl } from "react-intl";
import { BundleServiceDetailsCredits } from "./BundleServiceDetailsCredits";
import { deepLinkHandler, deepLinks, generateOpenSooqRedirectUrl, getCookies, getGeneralImage, redirectionInterceptor } from "../../../helpers/commonFunctions";
import { toAbsoluteUrl } from "../../../helpers";
import { BundlePromotionVIPCredits } from "./BundlePromotionVIPCredits";
import { LearnMore } from "./LearnMore";
import { PointOfSales } from "../../../helper/PointOfSales";

interface Iprops {
    flow : number
    changeHeaderHandler: (header:string) => void
    changeSubHeaderHandler: (subHeader:string) => void
    subCatReportingName: string
    selectedService: string
    changeSelectedService: (selectedService: string) => void
    learnMore: boolean
    toggleLearnMore: () => void
    handleClose?: () => void
}
const BundlePromotionCredits = ({flow, changeHeaderHandler, changeSubHeaderHandler, subCatReportingName, selectedService, changeSelectedService, learnMore, toggleLearnMore, handleClose} : Iprops) => {
    const lang = useLang();
    const [service, setService] = useState<BuyNowServiceInterface>();
    const Intl = useIntl();

    const handleTextSplit = (text: string) => {
        const textArray = text.split('*');
        
        return <span className="fs-6">{textArray[0]}<strong>{textArray[1]}</strong></span>;
    }

    useEffect(()=>{
        getServicesApi(flow).then((response) => {            
            setService(response.data);
        })
    }, [flow])

    useEffect(()=>{
        if(!service) return;
        if(!learnMore){
        changeHeaderHandler(service.header);
        changeSubHeaderHandler(service.subHeader);
        }
    }, [service, learnMore, selectedService])

    const skeletonRows = useMemo(()=>{
        let rows = []
        for (let i = 0; i < 3; i++) {
            rows.push(<Skeleton className="m-5 h-80px w-400px" key={i}/>)
        }
        return rows;
    }, []);

    const serviceDetailClickHandler = (service: string)=>{
        switch (service) {
            case "VIP":
                changeSelectedService('vip')
                break;
            case "Turbo":
                changeSelectedService('Turbo');
                break;
            case "Promote":
                changeSelectedService('Premium');
                break;
            default:
                changeSelectedService('');
        }
    }
    const handleRedirection = () => {
        redirectionInterceptor();
        deepLinkHandler(deepLinks.help[lang]);
    }

    return (
        <>  
        {learnMore && <div><LearnMore changeSubHeaderHandler={changeSubHeaderHandler} changeHeaderHandler={changeHeaderHandler}/></div>}
            {!learnMore && selectedService && selectedService === 'vip' && <BundlePromotionVIPCredits handleClose={handleClose} learnMore={learnMore} toggleLearnMore={toggleLearnMore} subCatReportingName={subCatReportingName} changeHeaderHandler={changeHeaderHandler} changeSubHeaderHandler={changeSubHeaderHandler} serviceName={selectedService} />}
            {!learnMore && selectedService && selectedService !== 'vip' && <BundleServiceDetailsCredits pos={PointOfSales.ACCOUNT_POS_WALLET_TOPUP_ADD_PROMOTION_CREDIT} learnMore ={learnMore} toggleLearnMore={toggleLearnMore} subCatReportingName={subCatReportingName} changeHeaderHandler={changeHeaderHandler} changeSubHeaderHandler={changeSubHeaderHandler} serviceName={selectedService} />}
            {!learnMore && !selectedService && <div className="d-flex flex-column justify-content-start align-items-center gap-6 container ">
                <div className="d-flex justify-content-between w-100">
                {!service && <Skeleton className="h-20px w-250px"/>}
                    {service && <p onClick={toggleLearnMore} className="text-primary text-decoration-underline hoverPointer fs-4">{service.blueUnderLineText}</p>}
                    <p className="text-decoration-underline text-primary fs-4 hoverPointer" onClick={handleRedirection} >{Intl.formatMessage({id: "footer.help"})}{lang=="en"?'?':"؟"}</p>
                </div>
                <div className="d-flex flex-column justify-content-start align-items-center gap-6 my-2 w-100">
                {service && service.services && service.services.map((service, index) => {return (
                    <div className="w-100 container shadow rounded-3 p-5 hoverPointer" key={service.text} onClick={()=>serviceDetailClickHandler(service.service)}>
                        <div className="row">
                            <div className="col-2"><img className="w-100" src={getGeneralImage(toAbsoluteUrl(service.iconUrl))} alt={service.text} /></div>
                            <div className="col-9 d-flex flex-column">
                                <span className="fs-3 fw-bold">{service.text}</span>
                                {service.description?.map((desc) => handleTextSplit(desc))}
                            </div>
                            <div className="col-1"><i className={`fa-solid text-white ${lang === "en" ? "fa-chevron-right": "fa-chevron-left"} bg-primary rounded-circle p-2`}></i></div>
                        </div>
                </div>)})}
                    </div>
                {!service && skeletonRows}
            </div>
            }
        </>
    )
}

export {BundlePromotionCredits};