import { useIntl } from "react-intl";
import 'react-tooltip/dist/react-tooltip.css';
import getCategoriesApi from "../../../../api/category/getCategories.api";
import { SearchComponent } from "../../../../app/layout/components/opensooq/SearchComponent";
import { TableLoader } from "../../../../app/layout/components/opensooq/TableLoader";
import { CategoryInterface } from "../../../interfaces/CategoryInterface";
import { ProfileInterface } from "../../../interfaces/ProfileInterface";
import { SubCategoryInterface } from "../../../interfaces/SubCategoryInterface";
import { AccountStatistics } from "../../common/AccountStatistics";
import { useLang } from "../../../../app/i18n/OpenSooqi18n";
import React, { useEffect, useState } from "react";
import 'react-tooltip/dist/react-tooltip.css'
import { AccountSummary} from "./AccountSummary";
import { MemberInfoInterface } from "../../../interfaces/MemberInfoInterface";
import { getProfileFunction } from "../../../helpers/commonFunctions";


export function Summary(props : {selected: boolean, profileData: ProfileInterface}) {
    const {profileData} = props;
    const [accountFeatures, setAccountFeatures] = useState<MemberInfoInterface>(undefined);
    const [categories, setCategories] = useState<CategoryInterface[]>([]);
    const [searchData,setSearchData] = useState <CategoryInterface[]>([]); 
    const [searchString, setSearchString] = useState("")
    const [loader,setLoader] = useState (true)
    const Intl = useIntl();
    const lang = useLang();

    useEffect(() => {
        getProfileFunction().then((response : any) =>{
            setAccountFeatures(response.data);
        }).catch(() => {

        })
        if(categories.length || !props.selected) return;
        getCategoriesApi().then(response => {
            setCategories(response.data.items);
            setSearchData(response.data.items);
            setLoader(false)
        }).catch(() => {
            // setLoader(false)
        })
    },[props.selected]);


    useEffect(() => {
        const newCat : CategoryInterface [] = [];
        categories.map(function (category : CategoryInterface) {
            const literateCat = {...category}
            const cat = (lang == 'ar') ? literateCat.label_ar.toLowerCase().includes(searchString.toLowerCase()) : literateCat.label_en.toLowerCase().includes(searchString.toLowerCase())
            if(cat ){
                newCat.push(category);
            }else{
                const subCats : any = [];
                const subs : any  = literateCat.subCategories ;
                subs.map(function (sub : SubCategoryInterface) {
                    const subCategory = (lang == 'ar') ? sub.label_ar.toLowerCase().includes(searchString.toLowerCase()) : sub.label_en.toLowerCase().includes(searchString.toLowerCase())
                    if(subCategory){
                        subCats.push(sub);
                    }
                });
                if(subCats.length){
                    literateCat.subCategories = subCats;
                    newCat.push(literateCat);
                }
            }
        });
        setSearchData(newCat);
    },[searchString]);

    const setSearch = (e: any) => {
        setSearchString(e.target.value);
    };

    const collapse = (e: any) => {
        if(e.currentTarget.classList.contains('active')){
            e.currentTarget.classList.remove('active');
        }else{
            e.currentTarget.classList.add('active');
        }
    };
    
    const List:React.FC<{searchData:CategoryInterface[]}> = ({searchData}) => {
        return <>{searchData.map((category  : CategoryInterface,index) => {
            return <React.Fragment key={index}><tr className={`hoverPointer opacity-75-hover ${(index%2 === 0) ? " even": "odd"}`} data-kt-table-widget-4="expand_row"  data-bs-toggle="collapse" data-bs-target={`#id-${category.id}`} data-id={`${category.label_en}-${category.id}`}>
                    <td>
                        <div className="d-flex align-items-center gap-3">
                            <span  className="symbol symbol-50px bg-secondary bg-opacity-25 rounded">
                                <img src={category.icon} className="w-30px h-30px" alt={category.label_en} data-kt-table-widget-4="template_image"/>
                            </span>
                            <div className="d-flex flex-column text-muted">
                                <span  className="text-gray-800 text-hover-primary fw-bold" data-kt-table-widget-4="template_name">{(lang == 'ar') ? category.label_ar : category.label_en}</span>
                            </div>
                        </div>
                    </td>
                    <td className="text-end">
                        <span className="text-dark text-hover-primary">
                            {category.posts_limit_to_display} {(category.posts_limit_to_display > 1) ? Intl.formatMessage({id: 'accountLimits.ads'}) : Intl.formatMessage({id: 'accountLimits.ad'})}
                            <button type="button" onClick={(e) => {collapse(e)}} className="ms-3 btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" >
                                <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1"
                                            transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                    </svg>
                                </span>
                                <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                    </svg>
                                </span>
                            </button>
                        </span>
                    </td>
                </tr>
                <tr id={`id-${category.id}`} className="collapse" data-bs-parent="#kt_accordion_tr-1"  >
                    <td colSpan={3} className={'p-5'}>
                        <table className="table align-middle  p-10 table-row-dashed fs-6  dataTable no-footer p-14">
                            <tbody className="fw-bold text-gray-600">
                                {subCategoriesList(category.subCategories)}
                            </tbody>
                        </table>
                    </td>
            </tr></React.Fragment>})}
        </>
    }

    const subCategoriesList = (subCategories : any) => {
        return subCategories.map((sub: SubCategoryInterface,index : number) => {
          return  <tr key={index} className={`ps-4 pe-4 opacity-75-hover ${(index%2 === 0) ? "even": "odd"}`} data-id={`${sub.label_en}-${sub.id}`}>
                <td>
                    <div className="d-flex align-items-center gap-3">
                        <span className="symbol symbol-50px bg-secondary bg-opacity-25 rounded">
                            <img src={sub.icon} alt={sub.label_en} className="w-30px h-30px" data-kt-table-widget-4="template_image"/>
                        </span>
                        <div className="d-flex flex-column text-muted">
                            <span className="text-gray-800 text-hover-primary fw-bold" data-kt-table-widget-4="template_name">{(lang == 'ar') ? sub.label_ar : sub.label_en}</span>
                        </div>
                    </div>
                </td>
                <td className="text-end">
                    <span className="text-dark text-hover-primary">
                        {sub.posts_limit_to_display} {(sub.posts_limit_to_display > 1) ? Intl.formatMessage({id: 'accountLimits.ads'}) : Intl.formatMessage({id: 'accountLimits.ad'})}
                    </span>
                </td>
            </tr>
        });
    }

    return ( <> <div className="row">
        <div className="col-12 col-lg-6 mb-5">
            <AccountSummary accountFeatures={accountFeatures}/>
            <AccountStatistics />
        </div>
        <div className="col-12 col-lg-6">
            <div className="card mb-5 mb-xl-8">
                <div className="card-header p-5">
                    <h3 className="card-title align-items-start flex-column ">
                        <span className="card-label fw-bold text-gray-800">{Intl.formatMessage({id: 'accountLimits.title'})}</span>
                            <span className="text-gray-400 mt-1 fw-semibold fs-6">
                            {Intl.formatMessage({id: 'accountLimits.desc'})}
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <SearchComponent action={setSearch}  placeholder={`${Intl.formatMessage({id: 'searchLabel'})}`} dataId="category-limit-search-box"/>
                    </div>
                </div>
                <div className="card-body pt-2">
                    <div id="kt_table_widget_4_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer">
                        {loader && <TableLoader/>}
                        <div className="table-responsive">
                            <table className="table align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
                                <tbody className="fw-bold text-gray-600">{!loader && <List searchData={searchData} />}</tbody>
                            </table>
                        </div>
                        <div className="row">
                            <div
                                className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                            </div>
                            <div
                                className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>)
}