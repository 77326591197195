import {useIntl} from 'react-intl'
import {isDeviceMobile} from '../../../../helpers/commonFunctions'
import useAccountOwnerStore from './AccountOwnerStore'

export default function AccountOwner() {
  const {accountOwner} = useAccountOwnerStore()
  const intl = useIntl()

  const handleCall = () => {
    if (!isDeviceMobile() || !accountOwner.company_mobile) return
    window.location.href = `tel:${accountOwner?.company_mobile}`
  }

  if (!accountOwner) return null
  return (
    <>
      <div className='dotted-line pt-4 pointerUnset' />
      <div className={`px-3 pt-7 pointerUnset ${isDeviceMobile() ? 'pb-3 mb-3' : ''}`}>
        <p className='fw-bold text-start text-20 pointerUnset'>
          {intl.formatMessage({id: 'sidebar.accountOwner.manager'})}
        </p>
        <div className='d-flex '>
          <img
            className='rounded-1'
            width={40}
            height={40}
            src={accountOwner?.profile_picture}
            alt='account owner'
          />
          <div className='d-flex flex-column align-items-between justify-content-between ms-3'>
            <span className='text-18'>{accountOwner?.account_owner}</span>
            <span
              className={`text-18 ${
                isDeviceMobile() ? 'text-primary text-decoration-underline' : ''
              }`}
              dir="ltr"
              onClick={handleCall}
            >
              {accountOwner?.company_mobile}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
