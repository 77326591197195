import { useIntl } from 'react-intl';
import Skeleton from "react-loading-skeleton";
import { useNavigate } from 'react-router-dom';
import DynamicObject from "../../../models/dynamic-object";
import { PointOfSales } from "../../helper/PointOfSales";
import { WantMoreViews } from "./WantMoreViews";

type Props = {
    stats : DynamicObject
    loading : boolean
    showPromote ?: boolean 
    showStatsButton?: boolean
    comeFrom ? : string
}

export function ListingStatistics(props : Props) {
    const navigate = useNavigate(); 
    const listingId : number | string  = new URLSearchParams(window.location.search).get('id') ?? '';
    const Intl = useIntl();
    const getPos = () => {
        let pos = PointOfSales.MY_LISTING_VIEW_LISTING_STATISTICS_STATISTICS_SUMMARY_WIDGET_PROMOTE_LISTING_BUTTON
        if(props.comeFrom && props.comeFrom == 'myListingView'){
            pos = PointOfSales.MY_LISTING_VIEW_LISTING_STATISTICS_WIDGET_PROMOTE
        }
        return pos;
    }
    return (
        <div className="card card-flush py-4 ">
            <div className="card-header">
                <div className="card-title align-items-start flex-column">
                    <h2>{Intl.formatMessage({id : "myListingView.text41"})}</h2>
                    <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">{Intl.formatMessage({id : "myListingView.text28"})}</div>
                </div>
            </div>
            <div className="card-body pt-0">
                <div className="row p-0 mb-5 px-9">
                    <div className="col " >
                        <div  className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span onClick={() => { navigate(`/listings/my-listings/listing-stats?id=${listingId}`)}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted" >{props.loading && <Skeleton className="w-50px h-20px"/>} {!props.loading && props.stats.views.toLocaleString()}</span>
                            <span id="listingStatisticsView" className="fs-4 fw-semibold text-muted d-block">{Intl.formatMessage({id : "myListingView.text29"})} </span>
                        </div>
                    </div>
                    <div className="col ">
                        <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span onClick={() => { navigate(`/listings/my-listings/listing-stats?id=${listingId}`)}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted" >{props.loading && <Skeleton className="w-50px h-20px"/>} {!props.loading && props.stats.calls.toLocaleString()}</span>
                            <span id="listingStatisticsCalls" className="fs-4 fw-semibold text-muted d-block">{Intl.formatMessage({id : "myListingView.text30"})} </span>
                        </div>
                    </div>
                    <div className="col " >
                        <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span onClick={() => { navigate(`/listings/my-listings/listing-stats?id=${listingId}`)}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted">{props.loading && <Skeleton className="w-50px h-20px"/>} {!props.loading && props.stats.rooms.toLocaleString()}</span>
                            <span id="listingStatisticsChats" className="fs-4 fw-semibold text-muted d-block">{Intl.formatMessage({id : "myListingView.text31"})} </span>
                        </div>
                    </div>
                    <div className="col " >
                        <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                            <span onClick={() => { navigate(`/listings/my-listings/listing-stats?id=${listingId}`)}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted" >{props.loading && <Skeleton className="w-50px h-20px"/>} {!props.loading && props.stats.favorite.toLocaleString()}</span>
                            <span id="listingStatisticsFavorites" className="fs-4 fw-semibold text-muted d-block">{Intl.formatMessage({id : "myListingView.text33"})} </span>
                        </div>
                    </div>
                </div>
                {listingId && <div className="card-footer">
                    <WantMoreViews listingId={parseInt(listingId)} pointOfSale={getPos()} showPromote={props.showPromote ?? false} extraButton={props.showStatsButton ? Intl.formatMessage({id : "myListingView.text37"}) : ''}/>
                </div>}
            </div>
        </div>
    )
}