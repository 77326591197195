import { getCookies } from "../../app/helpers/commonFunctions";
import { DEFAULT_VALUE, UTM_DATA } from "../types/LoggingTypes";

export class SessionDimensions {
    private static instance : SessionDimensions;
    
    private _sessionId : string = DEFAULT_VALUE;
    private _sessionSearchId: string = DEFAULT_VALUE;
    private _sessionMedium: string = 'web_open';
    private _sessionSource: string = 'opensooq';
    private _sessionCampaign: string = 'direct_web_open';

    constructor(){
        this.setUtmDataInStorage();
    }
    
    public static getInstance(){
        if(!SessionDimensions.instance)
        {
            SessionDimensions.instance = new SessionDimensions();
        }
        return SessionDimensions.instance;
    }
    
    get sessionId():string {
        return this._sessionId;
    }
    
    set sessionId(_sessionId:string)
    {
        this._sessionId = _sessionId;
    }
    
    get sessionSearchId():string {
        return this._sessionSearchId;
    }
    
    set sessionSearchId(_sessionSearchId:string)
    {
        this._sessionSearchId = _sessionSearchId;
    }
    
    get sessionLife():string {
        if(getCookies().tgtTimestamp)
        {
            const sessionLifeTime = Math.floor( ( (Date.now()/1000) - getCookies().tgtTimestamp ) );
            if(sessionLifeTime < 0)
            return "1";
        return sessionLifeTime.toString();
        }
        return DEFAULT_VALUE;
    }
    
    get sessionMedium():string {
        return this._sessionMedium    
    }
    
    set sessionMedium(sessionMedium: string)
    {
        this._sessionMedium = sessionMedium;
    }
    
    get sessionSource():string {
        return this._sessionSource 
    }
    
    set sessionSource(sessionSource: string)
    {
        this._sessionSource = sessionSource;
    }
    
    get sessionCampaign():string {
        return this._sessionCampaign    
    }
    
    set sessionCampaign(sessionCampaign: string)
    {
        this._sessionCampaign = sessionCampaign;
    }

    public setUtmDataInStorage(){
        const utmData : UTM_DATA = {
            cCampaign: this.sessionCampaign,
            cSource: this.sessionSource,
            cMedium: this.sessionMedium
        }
        sessionStorage.setItem('utmData',JSON.stringify(utmData));
    }
}