import { useEffect, useState } from "react"
import getDocTypesApi from "../../../../api/account/getDocTypes.api"
import { DocTabInterface, DocTypeInterface } from "../../../interfaces/DocsInterface"
import Header from "../profile/Header"
import { DocumentCard } from "../profile/commercial/DocumentCard"
import DocumentNotice from "../profile/DocumentNotice"
import commercialApi from "../../../../api/commercial.api"

type Props = {
    showFileModal?: () => any
    docsImagesList?: () => any
    docs ?: any
}

const Documents = ({docs} : Props) => {
    const LIMIT_DOCUMENTS : number = 5;
    const DocumentCards : JSX.Element[] = [];
    const [docTypes, setDocTypes] = useState<DocTypeInterface[]>([]);
    const [documents, setDocuments] = useState<DocTabInterface []>([]);

    useEffect(()=>{
        try {
            getDocTypesApi().then((response) => {
                if(response && response.data && Array.isArray(response.data))
                {
                    setDocTypes(response.data);
                }
            })
            commercialApi().then((response) => {                
                if(response && response.data && response.data.items && Array.isArray(response.data.items))
                    setDocuments(response.data.items.reverse());  
            })
        } catch (error) {
            console.error(error)
        }
    }, [])
    
    for (let i = 0; i < LIMIT_DOCUMENTS; i++) {
        DocumentCards.push(<DocumentCard id={i+1} docTypes={docTypes} uploadedDoc={i < documents.length ? documents[i] : undefined}/>)        
    } 
    return (
    <>
        <div className='card mb-5 mb-xl-10'>
            <div className='card-header'>
                <Header/>
            </div>
            <div className='card-body border-top p-9'>
                <DocumentNotice docs={docs}/>
            </div>
        </div>
        {DocumentCards}
    </>);
}

export default Documents