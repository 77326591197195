import React, { useState, useEffect } from 'react';
import { CategoryFieldMobileI, CategoryFieldItemMobileI, SubCategoryFieldItemMobileI } from '../../../../interfaces/MyListingsMobile';
import { AddFieldType, RemoveFieldType, SelectedFieldsType, ValueType } from '../../../../interfaces/MyListings';

type CategoryFilterMobileProps = {
  category: CategoryFieldMobileI;
  addField: AddFieldType;
  removeField: RemoveFieldType;
  fetchDynamicFields: (reportingName: string) => void
  resetDynamicFields: () => void
  selectedFields: SelectedFieldsType
};

const CategoryFilterMobile: React.FC<CategoryFilterMobileProps> = ({ category, addField, removeField, fetchDynamicFields, resetDynamicFields, selectedFields}: CategoryFilterMobileProps) => {
  const [selectedCategory, setSelectedCategory] = useState<CategoryFieldItemMobileI | null>(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState<SubCategoryFieldItemMobileI | null>(selectedFields[category?.items[0]?.items[0]?.fieldId] ?? null);
  
  useEffect(()=>{
    const selectedCatFromFields = category?.items?.find((item) => {
      const selectedCat = selectedFields[category?.items[0].fieldId] as ValueType;
      return item?.value === selectedCat?.value;
    })
    setSelectedCategory(selectedCatFromFields ?? null);

    const selectedSubCatFromFields = selectedCatFromFields?.items?.find((item) => {
      const selectedSubCat = selectedFields[selectedCatFromFields?.items[0].fieldId] as ValueType;
      return item?.value === selectedSubCat?.value;
    })
    setSelectedSubCategory(selectedSubCatFromFields ?? null);
    if((selectedSubCategory?.value !== selectedSubCatFromFields?.value) && selectedSubCatFromFields && selectedSubCatFromFields.reporting)
      fetchDynamicFields(selectedSubCatFromFields.reporting);
  }, [selectedFields])

  const toggleCategory = (item: CategoryFieldItemMobileI) => {
    if (selectedCategory && selectedCategory.value === item.value) {
      removeField(item.fieldId, { value: item.value }, 'SINGLE');
      setSelectedCategory(null);
    } else {
      addField(item.fieldId, {value: item.value, label: item.label}, 'SINGLE')
      setSelectedCategory(item);
    }
  };

  const toggleSubCategory = (item: SubCategoryFieldItemMobileI) => {
    resetDynamicFields();
    if (selectedCategory && selectedCategory.value === item.value) {
      removeField(item.fieldId, { value: item.value }, 'SINGLE');
      setSelectedSubCategory(null);
    } else {
      addField(item.fieldId, {value: item.value, label: item.label, parentid: item.parentId.toString()}, 'SINGLE')
      setSelectedSubCategory(item);
      fetchDynamicFields(item.reporting);
    }
  };

  const getDisplayText = ()=>{
    if(selectedSubCategory)
      return selectedSubCategory.label;
    if(selectedCategory && !selectedSubCategory)
      return selectedCategory.label;
    return category.label;
  }

  useEffect(()=>{
    if(!selectedFields[category?.items[0]?.fieldId])
    {
      setSelectedCategory(null);
    }
    if(!selectedFields[category?.items[0].items[0].fieldId])
    {
      setSelectedSubCategory(null);
    }
  }, [selectedFields])

  return (
    <div className="container-fluid m-0 p-0" hidden={selectedCategory && selectedSubCategory ? true : false}>
      <div className="card w-100 p-5 rounded-3">
        <h5 className="card-title my-3 fs-3 fw-bold">{getDisplayText()}</h5>
        <div className="d-flex flex-wrap gap-2">
          {!selectedCategory && category.items?.map((item, index) => (
            <span
            key={index}
            className={`px-4 fs-4 py-3 badge hoverPointer user-select-none bg-light fw-normal ${(selectedCategory?.value === item.value) ? 'bg-primary text-white' : 'text-dark'}`}
            style={{
              borderRadius: '1rem',
              cursor: 'pointer',
            }}
              onClick={() => toggleCategory(item)}
            >
              {item.label}
            </span>
          ))}
          {selectedCategory && selectedCategory.items?.map((subItem, index) => (
            <span
            key={index}
            className={`px-4 fs-4 py-3 badge hoverPointer user-select-none bg-light fw-normal ${(selectedSubCategory?.value === subItem.value) ? 'bg-primary text-white' : 'text-dark'}`}
            style={{
              borderRadius: '1rem',
              cursor: 'pointer',
            }}
              onClick={() => toggleSubCategory(subItem)}
            >
              {subItem.label}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryFilterMobile;
