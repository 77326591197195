import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChatFactoryTypes } from "./listing/my-listings/helper";

const ChatRedirect = () => {
    const listingId : any = new URLSearchParams(window.location.search).get('id') ?? 0;
    const navigate = useNavigate();
    useEffect(() => {
        navigate('/chats',{state : {
            type : ChatFactoryTypes.LISTING_CHATS,
            data : {
                listing_id : listingId,
            }
        }});
    },[])
    return <></>
}
export default ChatRedirect