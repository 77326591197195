import React from 'react';
import { Pagination } from 'react-bootstrap';
import { isDeviceMobile } from '../../../../helpers/commonFunctions';


const MyListingsPagination = ({ currentPage, pagesCount, handlePageChange }) => {
  let startPage, endPage;
  const maxVisiblePages = isDeviceMobile() ? 4 : 10;

  const renderPaginationItems = () => {
    let items = [];

    if (pagesCount <= maxVisiblePages) {
      // Show all pages if total pages are less than or equal to maxVisiblePages
      startPage = 1;
      endPage = pagesCount;
    } else {
      // Calculate start and end pages for pagination
      if (currentPage <= Math.ceil(maxVisiblePages / 2)) {
        startPage = 1;
        endPage = maxVisiblePages - 1;
      } else if (currentPage + Math.floor(maxVisiblePages / 2) >= pagesCount) {
        startPage = pagesCount - (maxVisiblePages - 2);
        endPage = pagesCount;
      } else {
        startPage = currentPage - Math.floor(maxVisiblePages / 2);
        endPage = currentPage + Math.floor(maxVisiblePages / 2) - 1;
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <Pagination.Item
          key={number}
          active={number === currentPage}
          onClick={() => handlePageChange(number)}
          className='border rounded-3 bg-white pagination-item-large-text'
        >
          {number}
        </Pagination.Item>
      );
    }

    return items;
  };

  const items = renderPaginationItems();

  if (items.length < 1) return null;

  return (
    <Pagination className='my-3'>
      <Pagination.Prev
        className='border rounded-3 bg-white pagination-item-large-text'
        onClick={() => handlePageChange(currentPage > 1 ? currentPage - 1 : 1)}
      />
      {startPage > 1 && (
        <>
          <Pagination.Item
            onClick={() => handlePageChange(1)}
            className='border rounded-3 bg-white pagination-item-large-text'
          >
            1
          </Pagination.Item>
          {startPage > 2 && <Pagination.Ellipsis />}
        </>
      )}
      {items}
      {endPage < pagesCount && (
        <>
          {endPage < pagesCount - 1 && <Pagination.Ellipsis />}
          <Pagination.Item
            onClick={() => handlePageChange(pagesCount)}
            className='border rounded-3 bg-white pagination-item-large-text'
          >
            {pagesCount}
          </Pagination.Item>
        </>
      )}
      <Pagination.Next
        className='border rounded-3 bg-white pagination-item-large-text'
        onClick={() => handlePageChange(currentPage < pagesCount ? currentPage + 1 : pagesCount)}
      />
    </Pagination>
  );
};

export default MyListingsPagination;
