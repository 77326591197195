
import { useCallback, useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css';
import { logOrderStartClick } from "../../../logging/helpers/commonLoggingFunctions";
import { VAS_BUMPUP, VAS_PREMIUM, VAS_TURBO } from "../../helper/VasServicrs";
import { MemberShipModal } from "../payment/MemberShipModal";

interface Props{
    extraButton ?: string | ''
    listingId : number
    showPromote? : boolean
    pointOfSale? : string
}


export function WantMoreViews({extraButton,listingId,showPromote, pointOfSale} : Props) {
    const [showBundle,setShowBundle] = useState (false);
    const navigate = useNavigate(); 
    const Intl = useIntl();
    const [pos,setPos] = useState ('');

    useEffect(() => {
        setPos(pointOfSale);
    },[])
    
    const showBundleModalHandler = useCallback(()=>{
        //logging
        logOrderStartClick('promote_listing')
        //
        setShowBundle(true)
    }, [])    

    return (
        <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">
            <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                <svg width="125" height="125" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="75" height="75" fill="white"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M14.2708 14.375H25.7292V17.75L30.3125 13.25L25.7292 8.75V12.125H11.9792V18.875H14.2708V14.375ZM25.7292 25.625H14.2708V22.25L9.6875 26.75L14.2708 31.25V27.875H28.0208V21.125H25.7292V25.625Z" fill="#0E91F9"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M63.5861 17.2539C63.5861 22.1231 59.745 26.0693 55 26.0693C50.2551 26.0693 46.414 22.1231 46.414 17.2539C46.414 12.3847 50.2551 8.46158 55 8.46158C59.745 8.46158 63.5861 12.3847 63.5861 17.2539ZM46.3462 28.7462L50.0518 27.8L51.2493 31.5385L53.7347 26.9231C51.769 26.6462 50.0066 25.7693 48.6283 24.477L46.3462 28.7462ZM61.3718 24.477C59.9709 25.7693 58.2085 26.6462 56.2654 26.9231L58.7508 31.5385L59.9483 27.8L63.6539 28.7462L61.3718 24.477Z" fill="#F5A623"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M21.7612 47.7035C26.2819 43.2109 31.2263 43.7725 31.2263 43.7725C31.2263 43.7725 31.7914 48.8266 27.412 53.3193C25.293 55.4252 22.3263 57.2503 19.9247 58.6542L20.6311 62.1641L18.3708 64.4104L16.393 60.4794C15.5454 60.7601 14.9803 61.0409 14.9803 61.0409L13.8502 59.9178C13.8502 59.9178 13.9914 59.3562 14.4152 58.5138L10.4597 56.5483L12.72 54.302L16.2517 55.1444C17.6644 52.6173 19.5009 49.8094 21.7612 47.7035ZM24.4454 50.5114C25.1517 51.073 26.1406 51.073 26.7057 50.5114C27.2708 49.8094 27.2708 48.8266 26.7057 48.2651C25.9993 47.7035 25.0104 47.7035 24.4454 48.2651C23.8803 48.967 23.8803 49.9498 24.4454 50.5114ZM10.6009 64.2705C10.6009 64.2705 13.1438 64.832 14.2739 63.7089C15.4041 62.5858 15.6866 61.603 15.6866 61.603C15.6866 61.603 16.1104 63.4281 14.839 64.6916C12.5787 66.9379 8.90568 66.0955 8.90568 66.0955C8.90568 66.0955 8.05807 62.4454 10.3184 60.1991C11.4485 58.9356 13.285 59.3568 13.285 59.3568C13.285 59.3568 12.2961 59.4972 11.166 60.6203C10.0358 61.7434 10.6009 64.2705 10.6009 64.2705Z" fill="#F93852"/>
                    <path d="M44.8737 49L47.4578 57.4378H47.4895L50.1053 49H52.7369L48.8846 61H46.0152L42.25 49H44.8737Z" fill="#F5A623"/>
                    <path d="M56.2722 49V61H53.7198V49H56.2722Z" fill="#F5A623"/>
                    <path d="M63.8184 49C65.0232 49 65.9797 49.3529 66.6878 50.0588C67.3959 50.7592 67.75 51.6895 67.75 52.8495C67.75 54.0041 67.3959 54.9343 66.6878 55.6402C65.9797 56.3461 65.0232 56.699 63.8184 56.699H61.0599V61H58.5075V49H63.8184ZM63.1129 54.6471C63.8475 54.6471 64.3917 54.5048 64.7458 54.2202C65.0999 53.9357 65.2769 53.4788 65.2769 52.8495C65.2769 52.2367 65.1051 51.7852 64.7617 51.4952C64.4182 51.1997 63.8712 51.052 63.1208 51.052H61.0599V54.6471H63.1129Z" fill="#F5A623"/>
                </svg>
            </span>
            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                <div className="mb-3 mb-md-0 fw-semibold">
                    <h4 className="text-gray-900 fw-bold">{Intl.formatMessage({id : "myListingView.text35"})} </h4>
                    <div className="fs-8 text-gray-700 pe-7 mb-3">{Intl.formatMessage({id : "myListingView.text36"})}</div>
                    {showPromote && <button onClick={showBundleModalHandler} className="me-1 btn mt-1 btn-primary px-6 align-self-center text-nowrap">{Intl.formatMessage({id : "myListingView.text38"})}</button>}
                    {extraButton && <button onClick={() => { navigate(`/listings/my-listings/listing-stats?id=${listingId}`)}} className="btn btn-primary px-6 mt-1 align-self-center text-nowrap">{extraButton}</button>}
                </div>
            </div>
            {showBundle && <MemberShipModal  show={showBundle}  type={[VAS_TURBO,VAS_PREMIUM,VAS_BUMPUP]} handleClose={() => {setShowBundle(false)}} id={listingId} pos={pos}/>}
        </div>
    )
}