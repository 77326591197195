import Moment from 'moment';
import { useEffect, useRef, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useIntl } from "react-intl";
import Swal from "sweetalert2";
import getListingPhoneApi from "../../../../api/listing/getListingPhone.api";
import logApi from "../../../../api/logging/log.api";
import { ListingInterface } from "../../../../app/interfaces/ListingInterface";
import { logCallListingClick, logCallListingSuccess, logFavoriteAddClick, logFavoriteRemoveClick, logSendMessage, logStatsListingPrint, logViewCvClickAsListing, logViewListingClick } from "../../../../logging/helpers/commonLoggingFunctions";
import DynamicObject from "../../../../models/dynamic-object";
import { toAbsoluteUrl } from "../../../helpers";
import { checkForListingIdInCookiesIfExist, modifyFormat } from "../../../helpers/LastContacted";
import { defaultOptions, generateOpenSooqRedirectUrl, getCookies, getNoImageLogo, isDeviceMobile, redirectionInterceptor, setCookie, showChatInCountry } from "../../../helpers/commonFunctions";
import { useLang } from "../../../i18n/OpenSooqi18n";
import ChatPopup from "../../../modules/ChatPopup";
import IconVerified from "../../../modules/common/IconVerified";
import { JOB_POSTER_TYPE, JOB_SEEKER_TYPE } from "../../../modules/listing/my-listings/helper";
import LastContacted from "./LastContacted";
import { EnumElementName } from '../../../../logging/types/LoggingTypes';
import getListingInfoV3Api from '../../../../api/listing/get-listing-info-v3.api';
import getListingPhoneV3Api from '../../../../api/listing/getListingPhoneV3.api';


interface cellProps {
    ad: ListingInterface
    action: (params: any) => any
    showButton: boolean
    hideNumber?: boolean | false
    hideChat?: boolean | false
    screen?: string | ''
    comeFrom?: string | 'listing'
    dataIdSource: string
    index: number
}

const Cell = (props: cellProps) => {
    const memberInfo = getCookies().userInfo
    const [phone, setPhone] = useState(props.ad.masked_local_phone);
    const [showButton, setShowButton] = useState(false);
    const [price, setPrice] = useState('');
    const Intl = useIntl();
    const lang = useLang();
    const cellRef = useRef();
    const [whatsPhone, setWhatsPhone] = useState(null);
    const [smsPhone, setSmsPhone] = useState(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if(entry.isIntersecting)
                {   
                    let timeout = props.index * 100 + 200;
                    setTimeout(()=>{
                        logStatsListingPrint(
                            {
                                id: props.ad.id,
                                category: {label : props.ad.category_reporting_name},
                                subCategory: props.ad.subcategory_reporting_name,
                                country: props.ad.country_abbr,
                                service: props.ad.services && props.ad.services.length > 0 ? props.ad.services[props.ad.services.length-1] : '',
                                city: {
                                    name: (props.ad.city.name_en) ? props.ad.city.name_en : props.ad.city.name_english, 
                                    lat: props.ad.city ? props.ad.city.lat : undefined, 
                                    lng: props.ad.city ? props.ad.city.lng : undefined
                                },
                                neighborhoodName: (props.ad.neighborhood && props.ad.neighborhood.name_en) ? props.ad.neighborhood.name_en : '',
                                listingStatus: props.ad.listing_status,
                                memberId: (props.ad.members_id) ? props.ad.members_id : undefined
                            },
                        )
                        observer.disconnect();
                    }, timeout)
                }   
            },
            {threshold: 0.1}
        );
        if(cellRef.current)
        {
            observer.observe(cellRef.current);
        }
        Moment.locale('en');
        // const price = props.ad.price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
        const price = props.ad.price_list[props.ad.price_currency]?.price ?? Intl.formatMessage({id: 'noPrice'})
        setPrice(price);
        setShowButton(props.showButton)

        return ()=>{
            if(cellRef.current)
                observer.unobserve(cellRef.current);
        }
    }, []);

    const showPhone = (listingId: number) => {        
        if(props.ad && props.ad.hide_user_contact_details == 0 && phone != 'XX' && phone){
            logApi('call', 'post', props.ad.id, props.screen).then(response => {
                getListingInfoV3Api(listingId).then(listingInfo => {
                    const payload = listingInfo.data
                    if(payload && payload.listing.masked_local_phone && (getCookies().userInfo?.id?.toString() !== payload.listing?.member_id?.toString())) {
                        getListingPhoneV3Api({"reveal_key" : payload.listing.listing_reveal_phone_key, "type" : "post"}).then(phoneData => {
                            setPhone(phoneData.data.ui_phone_number);
                            setWhatsPhone(phoneData.data.whatsapp_phone_number);
                            setSmsPhone(phoneData.data.sms_phone_number);
                            logCallListingClick(
                                {
                                    id: props.ad.id,
                                    category: {label : props.ad.category_reporting_name},
                                    subCategory: props.ad.subcategory_reporting_name,
                                    country: props.ad.country_abbr,
                                    service: props.ad.services && props.ad.services.length > 0 ? props.ad.services[props.ad.services.length-1] : '',
                                    city: {
                                        name: (props.ad.city.name_en) ? props.ad.city.name_en : props.ad.city.name_english, 
                                        lat: props.ad.city ? props.ad.city.lat : undefined, 
                                        lng: props.ad.city ? props.ad.city.lng : undefined
                                    },
                                    neighborhoodName: (props.ad.neighborhood && props.ad.neighborhood.name_en) ? props.ad.neighborhood.name_en : '',
                                    listingStatus: props.ad.listing_status,
                                    memberId: (props.ad.members_id) ? props.ad.members_id : undefined
                                },
                                'Yes'
                            );
                            window.location.href = `tel:${phoneData.data.call_phone_number}`;
                            logCallListingSuccess({
                                id: props.ad.id,
                                category: {label : props.ad.category_reporting_name},
                                subCategory: props.ad.subcategory_reporting_name,
                                country: props.ad.country_abbr,
                                service: props.ad.services && props.ad.services.length > 0 ? props.ad.services[props.ad.services.length-1] : '',
                                city: {
                                    name: (props.ad.city.name_en) ? props.ad.city.name_en : props.ad.city.name_english, 
                                    lat: props.ad.city ? props.ad.city.lat : undefined, 
                                    lng: props.ad.city ? props.ad.city.lng : undefined
                                },
                                neighborhoodName: (props.ad.neighborhood && props.ad.neighborhood.name_en) ? props.ad.neighborhood.name_en : '',
                                listingStatus: props.ad.listing_status,
                                memberId: props.ad.members_id
                            }, 'Yes',  200);
                            logSendMessage({
                                id: props.ad.id,
                                category: {label : props.ad.category_reporting_name},
                                subCategory: props.ad.subcategory_reporting_name,
                                country: props.ad.country_abbr,
                                service: props.ad.services && props.ad.services.length > 0 ? props.ad.services[props.ad.services.length-1] : '',
                                city: {
                                    name: (props.ad.city.name_en) ? props.ad.city.name_en : props.ad.city.name_english, 
                                    lat: props.ad.city ? props.ad.city.lat : undefined, 
                                    lng: props.ad.city ? props.ad.city.lng : undefined
                                },
                                neighborhoodName: (props.ad.neighborhood && props.ad.neighborhood.name_en) ? props.ad.neighborhood.name_en : '',
                                listingStatus: props.ad.listing_status,
                                memberId: props.ad.members_id,
                            }, memberInfo.id === props.ad.members_id, 500 );
                        })
                    }
                })
            })
        }else{
            Swal.fire({
                icon: 'warning',
                html: `<div class="fw-bolder">${Intl.formatMessage({id : "memberHidesHisPhone"})} </div>`,
                buttonsStyling: false,
                showCancelButton: false,
                showLoaderOnConfirm: false,
                cancelButtonText: Intl.formatMessage({id: 'no'}),
                confirmButtonText: Intl.formatMessage({id: 'ok'}),
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-light-danger',
                },
                allowOutsideClick: () => !Swal.isLoading(),
            })
        }
        
    }

    const customParamsList = () => {
        return props.ad.custom_param_array.map((customParam: string, index: number) => {
            const arrayCounter = props.ad.custom_param_array.length;
            const counterTocCheck = (arrayCounter < 3) ? arrayCounter : 3;
            return index < counterTocCheck && <span key={index} className="bigContainer">{customParam}{(index < counterTocCheck - 1) ? ' , ' : ''}</span>
        });
    }

    const cookies = getCookies();
    const redirectToPost = (e: any, post: ListingInterface) => {
        redirectionInterceptor();
        if (e.target.classList.contains('bigContainer')) {
            let link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',cookies.ecountry ?? '',lang) + post.postUrl
            if(post.view_type == JOB_POSTER_TYPE){
                link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',cookies.ecountry ?? '',lang) + '/job-posters/' + post.id;
            }else if(post.view_type == JOB_SEEKER_TYPE){
                link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',cookies.ecountry ?? '',lang) + '/job-seekers/' + post.cv_id;
            }
            const postLogData = {
                id: post.id,
                category: {label : post.category_reporting_name},
                subCategory: post.subcategory_reporting_name,
                country: post.country_abbr,
                service: post.services && post.services.length > 0 ? post.services[post.services.length-1] : '',
                city: {
                    name: (post.city.name_en) ? post.city.name_en : post.city.name_english, 
                    lat: post.city ? post.city.lat : undefined, 
                    lng: post.city ? post.city.lng : undefined
                },
                neighborhoodName: (post.neighborhood && post.neighborhood.name_en) ? post.neighborhood.name_en : '',
                listingStatus: props.ad.listing_status,
                memberId: post.members_id
            }
            if(post.subcategory_reporting_name === 'JobSeeker'){
                logViewCvClickAsListing(postLogData);
            }else{
                logViewListingClick(postLogData, EnumElementName.CARD_CELL_RESULT);
            }
            window.open(link.replace("/ar/ar/","/ar/").replace("/en/en/","/en/"), "_blank");
        }
    }

    const [showLastContacted, setShowLastContacted] = useState<boolean>(false);
    const [lastContacted, setLastContacted] = useState<any>(null);

    useEffect(() => {
        if(getCookies().lastContacted && getCookies()?.lastContacted.filter((item) => +item.post_id == listing.id).length > 0) {
            let thirtyDaysFromNow = new Date();
            thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
            if(new Date(getCookies()?.lastContacted?.filter((item) => +item.post_id == listing.id)[0].last_contacted) > thirtyDaysFromNow) {
                defaultOptions.expires = thirtyDaysFromNow.toString()
                setCookie(
                    'lastContacted',
                    JSON.stringify(getCookies().lastContacted?.filter((item) => +item.post_id != listing.id)),
                    defaultOptions
                );
            } else {
                setShowLastContacted(true);
                setLastContacted(modifyFormat(getCookies().lastContacted , listing.id, lang))
            }
        }
    }, [getCookies()?.lastContacted]);

    const listing = props.ad
    const roomData = {
        postId: listing.id,
        ownerId: listing.members_id,
        userId: memberInfo.id,
        post_country: listing.country_abbr,
        post_img: listing.first_image_thumb,
        post_title: listing.title,
        room: {
            avatar: listing.searchMember.profile_picture,
        name: listing.searchMember.full_name
        }
    }

    return (
        <div key="postCell" ref={cellRef}
             className={`mb-32  position-relative  ${(props.comeFrom == 'postView') ? '' : 'customShadow grayHoverBg hoverPointer'} bigContainer  mt-1`}
             onClick={(e) => {
                redirectToPost(e, props.ad)
             }} data-id={`${props.dataIdSource}-listings-list-${props.ad.id}`}>
            <div className="flex flexNoWrap ps-4 pe-4 pt-4 pb-2 blackColor radius-8 bigContainer  ripple boxShadow2 relative">
                <div className={`sc-265a2526-1 jRUVEn postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ixKSmm`}>
                    <img src={props.ad.imagesCount > 0 ? props.ad.first_image_thumb : getNoImageLogo(lang)} width="272" height="204"  className="width-100 bigContainer customImg height-100" alt={props.ad.title} loading="eager"/>
                </div>
                <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                    <div className="flex flexSpaceBetween">
                        <span  className={` ${isMobile ? 'fs-5' : 'fs-2'} fw-bold  breakWord bigContainer overflowHidden  `} data-id={`${props.dataIdSource}-listings-list-postTitle-${props.ad.id}`}>{props.ad.title}</span>
                    </div>
                    <div className={`${isMobile ? 'fs-5' : 'fs-3'}  bigContainer`}>{customParamsList()}</div>
                    <div className="flex alignItems bigContainer ">
                        <div className={`${isMobile ? 'fs-5' : 'fs-3'}  category`}>{(props.ad.category) ? props.ad.category.label + ` ${Intl.formatMessage({id: 'in'})}` : ''} {props.ad.neighborhood && <> <span className="text-dark fw-bold"><b>{props.ad.neighborhood.name}</b></span></>}</div>
                    </div>
                </div>
            </div>
            <div className="flex alignItems ps-4 pe-4 pt-0 pb-1 bigContainer">
                <div className="pe-1  bd-highlight bigContainer">
                    <div  className="sc-265a2526-2 iRqeKu numOfCont flex bigContainer  alignItems radius-8 whiteColor fs-13 gap-5 ">
                        <span className="flex alignItems radius-8 bigContainer">
                            <svg viewBox="0 0 12 12" width="12" height="12" className="me-2 bigContainer" data-name="iconCamera2">
                                <path fill="#fff"  d="M12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667ZM3.66667 7L5.33333 9.00667L7.66667 6L10.6667 10H1.33333L3.66667 7Z"></path>
                            </svg>
                            {props.ad.imagesCount}
                        </span>
                    </div>
                </div>
                <div className="ms-1 bd-highlight bigContainer">
                    <div className="d-flex justify-content-start bigContainer">
                        {props.ad?.is_delivery_service && <img className="bigContainer me-1" width="25" height="25" src={toAbsoluteUrl(`/media/custom/delivery.svg`)}/>}
                        {props.ad?.services.map((service, index) => {
                            return <img key={index} className="bigContainer me-1" width="25" height="25" src={toAbsoluteUrl(`/media/custom/${service}-service.svg`)}/>
                        })}
                    </div>
                </div>
                <div className="postPrice ms-auto bold alignSelfCenter fs-3">{price != '' && <><span
                    className="fw-bolder"> {price} </span></>}</div>
            </div>
            <div className="flex alignItems ps-4 pe-4 pt-0 pb-2 bigContainer" style={{paddingTop: "5px"}} data-id={`${props.dataIdSource}-listings-list-CTAsBlock-${props.ad.id}`}>
                {!props.hideNumber && props.ad && (props.ad.view_type != JOB_POSTER_TYPE && props.ad.view_type != JOB_SEEKER_TYPE) &&
                    <div className="pe-1  bd-highlight">
                        <button data-id={`${props.dataIdSource}-listings-list-callBtn-${props.ad.id}`} onClick={() => {
                            showPhone(props.ad.id)
                            checkForListingIdInCookiesIfExist(listing,null)
                            listing.rerender = true
                        }} style={{minWidth: "145px"}}
                                className={`btn blueBtn fs-6 text-hover-white ${isMobile ? 'flex-1' : ''}`}><i
                            className="fas fa-phone text-white"></i> {(phone != 'XX' && phone != '') ? phone : ''}
                        </button>
                    </div>}
                {!props.hideChat && showChatInCountry() && props.ad.members_id != memberInfo.id && (props.ad.view_type != JOB_POSTER_TYPE && props.ad.view_type != JOB_SEEKER_TYPE) &&
                    <div className="pe-1 bd-highlight" data-id={`${props.dataIdSource}-listings-list-chatBtn-${props.ad.id}`} >
                        <ChatPopup setShowLastContacted={setShowLastContacted} post_url={props.ad.post_url} roomData={roomData} contactAddon={props.ad.contactAddon} listing={listing}  whatsPhone={whatsPhone} smsPhone={smsPhone}/>
                    </div>}
                {showButton && <div className="  bd-highlight customShadow ms-2 fs-6 ">
                    <button disabled={false} data-id={`${props.dataIdSource}-listings-list-favoriteBtn-${props.ad.id}`} className={'btn btn-bg-white btn-sm border-1'} onClick={(e) => {
                        e.stopPropagation()
                        if(props.ad.bookmarkStatus)
                        {
                            logFavoriteRemoveClick(
                                {
                                    id: props.ad.id,
                                    category: {label : props.ad.category_reporting_name},
                                    subCategory : props.ad.subcategory_reporting_name,
                                    country: props.ad.country_abbr,
                                    service: props.ad.services && props.ad.services.length > 0 ? props.ad.services[props.ad.services.length-1] : '',
                                    city: {
                                        name: (props.ad.city.name_en) ? props.ad.city.name_en : props.ad.city.name_english, 
                                        lat: props.ad.city ? props.ad.city.lat : undefined, 
                                        lng: props.ad.city ? props.ad.city.lng : undefined
                                    },
                                    neighborhoodName: (props.ad.neighborhood && props.ad.neighborhood.name_en) ? props.ad.neighborhood.name_en : '',
                                    listingStatus: props.ad.listing_status,
                                    memberId: props.ad?.members_id
                                }, 'Yes'
                            );
                        }else {
                            logFavoriteAddClick(
                                {
                                    id: props.ad.id,
                                    category: { label: props.ad.category_reporting_name},
                                    subCategory: props.ad.subcategory_reporting_name,
                                    country: props.ad.country_abbr,
                                    service: props.ad.services && props.ad.services.length > 0 ? props.ad.services[props.ad.services.length-1] : '',
                                    city: {
                                        name: (props.ad.city.name_en) ? props.ad.city.name_en : props.ad.city.name_english, 
                                        lat: props.ad.city ? props.ad.city.lat : undefined, 
                                        lng: props.ad.city ? props.ad.city.lng : undefined
                                    },
                                    neighborhoodName: (props.ad.neighborhood && props.ad.neighborhood.name_en) ? props.ad.neighborhood.name_en : '',
                                    listingStatus: props.ad.listing_status,
                                    memberId: props.ad?.members_id
                                }, 'Yes' ,0);
                        }
                        props.action(props.ad.id)
                    }}>
                        {(props.ad.bookmarkStatus) ?
                            <svg viewBox="0 0 32 32" width="25" height="25"
                                 data-name="iconFavouriteFill" className="bigContainer" >
                                <path fill="#ee5555" stroke="#ee5555" strokeWidth="3"
                                      d="M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.333-11.4-15.373 0-4.107 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.787 1.453-1.707 3.68-2.787 6-2.787 4.107 0 7.333 3.227 7.333 7.333 0 5.040-4.533 9.147-11.4 15.387l-1.633 1.747z"></path>
                            </svg> : <svg viewBox="0 0 32 32" width="25" height="25" data-name="iconFavouriteFill" className="bigContainer">
                                <path fill="#fff" stroke="#ee5555" stroke-width="3"
                                      d="M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.333-11.4-15.373 0-4.107 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.787 1.453-1.707 3.68-2.787 6-2.787 4.107 0 7.333 3.227 7.333 7.333 0 5.040-4.533 9.147-11.4 15.387l-1.633 1.747z"></path>
                            </svg>}
                    </button>
                </div>}
            </div>
            {props.ad && props.ad.branding && <div className="ps-5 pe-5 bigContainer">
                <div className="separator  separator-dotted border-gray mb-4 mt-4 bigContainer"></div>
            </div>}
            {props.ad && props.ad.branding && <div className="d-flex align-items-center ps-4 pe-4 pt-0 pb-2 bigContainer"  >
                <div className="d-flex align-items-center flex-grow-1 bigContainer">
                    <div className="symbol symbol-45px me-5 bigContainer">
                        <img className="bigContainer" src={props.ad.branding.avatar} alt=""/>
                    </div>
                    <div className="d-flex flex-column bigContainer">
                        <span className={`text-gray-800 fs-6 fw-bold text-truncate ${(isDeviceMobile()) ? 'w-200px' : 'w-600px'} bigContainer`}>{props.ad.branding.name}</span>
                    </div>
                </div>
                {props.ad.is_authorized_member && <div className="my-0 border rounded-1 border-1 verifiedBorderColor bigContainer">
                    <div className="d-flex align-items-center flex-grow-1 p-1 bigContainer">
                        <div className="symbol symbol-25px pe-1 bigContainer" >
                            <IconVerified  className="bigContainer" />
                        </div>
                        <div className="d-flex flex-column bigContainer">
                            <span className="text-gray-900 fs-7 fw-bolder verifiedTextColor bigContainer">{Intl.formatMessage({id : "verified"})}</span>
                        </div>
                    </div>
                </div>}
            </div>}
            {((getCookies().lastContacted && getCookies().lastContacted.length > 0 && getCookies().lastContacted.filter((item) => +item.post_id == listing.id).length > 0 ) || showLastContacted) && (
                <LastContacted listingId={listing.id} lastContacted={lastContacted}/>
            )}
        </div>
    );
};

export { Cell };
