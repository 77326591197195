import { isMobileDevice } from '../assets/ts/_utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl'
import { FacebookShareButton, TwitterShareButton} from "react-share";
import { getProfileFunction } from '../helpers/commonFunctions';
import shareLogging from '../../api/shareLogging';
import { ShareButtonType, ShareLoggingScreens } from '../helper/ShareLogging';
import shortUrlApi from '../../api/site/shortUrl.api';
import { logScreenLoad, logShareApp } from '../../logging/helpers/commonLoggingFunctions';
import DynamicObject from '../../models/dynamic-object';
import { EMAIL_SHARE_URL, TWITTER_SHARE_URL } from '../helper/ShareLinks';
import { generateOpenSooqRedirectUrl, getCookies } from '../helpers/commonFunctions';
import { toAbsoluteUrl } from '../helpers';
import { AuthContext } from '../i18n/AuthProvider';

export function InviteFriends() {
    
    const [shortUrl,setShortUrl] = useState<string>('')
    const [twitterShareTemplate,setTwitterShareTemplate] = useState<string>('')
    const [emailTemplate,setEmailTemplate] = useState<string>('')
    const memberId = getCookies().userInfo.id;
    const [qrCode,setQrCode] = useState<string>('')
    const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
    const [imageError, setImageError] = useState<boolean>(false);
    const Intl = useIntl();
    const {userIdentity} = useContext(AuthContext)

    useEffect(() => {
        if(userIdentity){
            var baseUrl = process.env.REACT_APP_OPENSOOQ_BASE.replace("/{lang}","");
            const url = generateOpenSooqRedirectUrl(baseUrl ?? '',getCookies().ecountry ?? '') + userIdentity?.memberLink + `?utm_source=opensooq&utm_medium=share&utm_campaign=account_invitefriend_download_app_from_myopensooq`
            const data = {
                links : {
                    url
                }
            }
            shorten(data);
            getProfileFunction().then(response => {
                const results = response.data;
                if(results && results.member_basic && results.member_basic.qr_link){
                    setQrCode(results.member_basic.qr_link);
                }
            })
        }
        
    },[memberId,userIdentity]);

    const shorten = (data : DynamicObject) => {
        shortUrlApi(data).then((response) => {
            setShortUrl(response.data.links.url);
        })
    }

    useEffect(() => {
        const emailShareUrl     = EMAIL_SHARE_URL;
        const twitterShareUrl   = TWITTER_SHARE_URL;
        setTwitterShareTemplate(`${Intl.formatMessage({id : "checkMyListings"})} \n ${shortUrl} \n\n ${Intl.formatMessage({id : 'downloadApp'})} ${twitterShareUrl}`)
        setEmailTemplate(`${Intl.formatMessage({id : "checkMyListings"})} \n ${shortUrl} \n\n ${Intl.formatMessage({id : 'downloadApp'})} ${emailShareUrl}`)
    },[shortUrl]);

    useEffect(()=>{
        logScreenLoad()
    }, [])

    const handleImageLoad = useCallback(()=>{
        setIsImageLoaded(true);
    }, [])

    const handleImageError = useCallback(()=>{
        setImageError(true);
    }, [])

    const handleShareLogging = (type : string) => {
        shareLogging({
            "Logging": {
                "model": "Member",
                "model_id": memberId,
                "share_to": type,
                "data": ShareLoggingScreens.Invite_Friend
            }
        })
    }

    const handleInviteFriend = (type: string) => {
        handleShareLogging(type)
        logShareApp({id: memberId})
    }

    return (
        <div className="card mb-5">
            <div className="card-body py-10 d-flex flex-column">
                <h2 className="mb-7 text-center">{Intl.formatMessage({id: 'inviteFriend'})}</h2>
                <div className="row mb-5 justify-content-center">
                    <div className="col-xxl-6 col-xl-8 col-lg-8 col-md-8 mt-2">
                        <p className="fs-6 fw-semibold text-gray-600 pb-2 m-0">{Intl.formatMessage({id: 'inviteFriendText1'})} </p>
                        <div className="d-flex">
                            <input type="text"  className="form-control form-control-solid me-3 flex-grow-1" name="search" defaultValue={shortUrl} data-id="invite-friends-linkField" />
                            <button onClick={() => {navigator.clipboard.writeText(shortUrl); handleInviteFriend(ShareButtonType.OTHER)}} className="btn btn-light btn-active-light-primary fw-bold flex-shrink-0" data-id="invite-friends-copyLinkBtn" >{Intl.formatMessage({id: 'inviteFriendText2'})}</button>
                        </div>
                    </div>
                </div>
                <div className="row mt-5 justify-content-center">
                    <div className="col-xxl-6 col-xl-8 col-lg-8 col-md-8 mt-2">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-12 mt-2">
                                <a target="_blank" href={`https://mail.google.com/mail/?view=cm&fs=1&su=${Intl.formatMessage({id : 'shareEmailSubject'})}&body=${emailTemplate}}`} className="btn w-100 btn-light btn-active-light-primary fw-bold flex-shrink-0" onClick={()=>{handleInviteFriend(ShareButtonType.EMAIL)}} data-id="invite-friends-emailBtn">
                                    <i className="fs-1 me-1 fa-solid fa-envelope"></i>
                                    {Intl.formatMessage({id: 'inviteFriendText5'})}
                                </a>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-2">
                                <FacebookShareButton data-id="invite-friends-facebookBtn" url={shortUrl} className="w-100" onClick={()=>{handleInviteFriend(ShareButtonType.FACEBOOK)}}>
                                    <span id="" className="btn w-100 btn-light btn-active-light-primary fw-bold flex-shrink-0">
                                        <i className="fab fa-facebook-square fs-1 me-1" style={{color : "#385898"}}></i>
                                        {Intl.formatMessage({id: 'inviteFriendText4'})}
                                    </span>
                                </FacebookShareButton>
                            </div>
                            <div className="col-lg-4 col-md-4 col-12 mt-2">
                                <TwitterShareButton data-id="invite-friends-twitterXBtn" url={twitterShareTemplate} className="w-100" onClick={()=>{handleInviteFriend(ShareButtonType.TWITTER)}}>
                                    <span className="btn w-100 btn-light btn-active-light-primary fw-bold flex-shrink-0">
                                        <img width="21" height="21" src={toAbsoluteUrl('/media/custom/x_icon_sqaure.svg')} alt="x" className='me-1'/>
                                        {Intl.formatMessage({id: 'inviteFriendText3'})}
                                    </span>
                                </TwitterShareButton>
                            </div>
                        </div>
                    </div>
                </div>
               { 
                <div className={isImageLoaded ? `col-lg-6 col-md-6 col-12 m-10 align-self-center border border-2 rounded-3 p-5 d-flex flex-column justify-content-center align-items-center` : ''}>
                    { (isImageLoaded || (qrCode && !imageError)) && <img height={'33%'} width={'33%'}  src={qrCode} alt='QR-CODE' onLoad={handleImageLoad} onError={handleImageError} />}
                    {isImageLoaded && <h1 className='fs-1 text-primary' style={{marginTop: '-10px'}}>{Intl.formatMessage({id: 'inviteFriendText.scanMe'})}</h1>}
                </div>
                }
            </div>
        </div>
    )
}