import Cookies from "js-cookie";
import { ActionDimensions } from "../dimensions/ActionDimensions";
import { DEFAULT_VALUE } from "../types/LoggingTypes";
import { defaultOptions, setCookie } from "../../app/helpers/commonFunctions";


const updateWidgetHistory = (widgetName : string)=>{
  let prevWidget = Cookies.get('prevWidget');
  if(!prevWidget || prevWidget === '')
    prevWidget = DEFAULT_VALUE;
  if(widgetName !== '')
    ActionDimensions.getInstance().currWidgetName = widgetName;
  ActionDimensions.getInstance().prevWidgetName = prevWidget;
  setCookie('prevWidget', widgetName, {...defaultOptions, expires: 10})
}

export {updateWidgetHistory}