import Skeleton from "react-loading-skeleton";

export function IframeLoader() {
    return (
        <div className='text-center' >
            <div className="row justify-content-center" style={{height : "60vh"}}>
                <div className="col-5">
                    <div className="row">
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                        <div className="col-12 mb-5">
                            <Skeleton className="w-100 h-25px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
  }