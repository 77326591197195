import { Modal } from 'react-bootstrap'
import ImageUploading from 'react-images-uploading'
import { useIntl } from 'react-intl'
import { Loader } from '../opensooq/Loader'

type Props = {
  show: boolean
  handleClose: () => void
  uploadFile: (file: any) => void
  loader: boolean
}

const UploadShopMedia = ({show, handleClose, uploadFile, loader}: Props) => {
  const Intl = useIntl()
  const onChange = (imageList : any, addUpdateIndex : any) => {
      uploadFile(imageList);
  };
  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered '
        show={show}
      >
        <div className='card'>
          <div className='card-body'>
            <div className='d-flex justify-content-between'>
              <span>
                <h1>{Intl.formatMessage({id: 'myProfile.shop.text27'})}</h1>
              </span>
              <span onClick={handleClose}>
                <i className='fa-solid fa-xmark fs-1'></i>
              </span>
            </div>
            <div className='mt-10 '>
              {                
              loader ? Loader() :
              <ImageUploading  multiple  value={[]}  onChange={onChange}  maxNumber={1} dataURLKey="data_url" >
                    {({
                        imageList,
                        onImageUpload,
                        isDragging,
                        dragProps,
                    }) => (
                    <div className="upload__image-wrapper">
                        <button style={isDragging ? { color: 'red' } : undefined} className="btn btn-primary" onClick={onImageUpload} {...dragProps} >
                          {Intl.formatMessage({id: 'myProfile.shop.text30'})}
                        </button>
                    </div>
                    )}
                </ImageUploading>
              }
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export { UploadShopMedia }

