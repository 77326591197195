import DynamicObject from "opensooq-notification/dist/esm/dynamic-object"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import settingsApi from "../../../api/settings/settings.api"
import updateSettingsApi from "../../../api/settings/updateSettings.api"
import { showError, showSuccess } from "../../helper/SystemAlert"
import { useLang } from "../../i18n/OpenSooqi18n"
import { SwitchInformationI } from "../../interfaces/SwitchInformation"



const NotificationSettings = ()=>{
    const [loader,setLoader] = useState('off')
    const [switchList, setSwitchList] = useState<SwitchInformationI[]>([])
    const Intl = useIntl()
    const lang = useLang();


    useEffect(()=>{
        settingsApi().then((response) => {
            if(response && response.data && response.data.notificationGroups){
                setSwitchList(response.data.notificationGroups);
            }
        }).catch(err => {
            showError(Intl,lang)
        })
    },[]);

    const onEdit = () => {
        setLoader('on');
        const settings : DynamicObject = {
            'DynamicModel' : {}
        }
        switchList.forEach(switchInfo => {
            settings['DynamicModel'][switchInfo.id] = switchInfo.status;
        })
        updateSettingsApi(settings).then((response) => {
            showSuccess(Intl,lang);
            setLoader('off');
        }).catch(() => {
            showError(Intl,lang);
            setLoader('off');
        });
    }

    return <>
            <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
                <div className='card-header cursor-pointer'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>{Intl.formatMessage({id: 'notifications.text1'})}</h3>
                    </div>
                </div>
            <div className='card-body p-9'>
                {switchList.map( (switchInfo:SwitchInformationI, index:number)=>{
                    return (
                    <div className='row mb-6 border-bottom-1' key={index} data-id={`notification-settings-${switchInfo.id}`}>
                        <label className='col-lg-10 col-10 col-form-label fw-bold fs-6'>{switchInfo.label}</label>
                        <div className='col-lg-2 col-2 fv-row'>
                            <div className='d-flex align-items-center mt-3 justify-content-end'>
                                <div className="form-check form-switch form-check-custom form-check-solid">
                                    <input onChange={() => {
                                        setSwitchList((old:SwitchInformationI[])=>{
                                            old[index].status = old[index].status === 0 ? 1 : 0
                                            return [...old];
                                        }
                                    )}} className={`form-check-input ${(switchInfo.status === 1) ? 'checked' : ''}`} type="checkbox" checked={switchInfo.status === 1}/>
                                    <label className="form-check-label" htmlFor={'flexSwitchDefault'}> </label>
                                </div>
                            </div>
                        </div> 
                    </div>
                    )})}
                </div>
                <div className='card-footer '>
                    <div className='d-flex justify-content-end '>
                        <button type="button" className="btn btn-primary" data-kt-indicator={loader} onClick={onEdit} disabled={(loader == 'on') ? true : false} data-id="notification-settings-saveBtn">
                            <span className="indicator-label">
                                {Intl.formatMessage({id: 'save'})}
                            </span>
                            <span className="indicator-progress">
                                {Intl.formatMessage({id : "myProfile.profileDetails.text31"})}
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </>
}

export { NotificationSettings }
