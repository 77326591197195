import { useIntl } from "react-intl"
import { logViewMemberClick } from "../../../../logging/helpers/commonLoggingFunctions"
import { getCookies, redirectionInterceptor } from "../../../helpers/commonFunctions"
import { useLang } from "../../../i18n/OpenSooqi18n"
import { MemberInterface } from "../../../interfaces/MemberInterface"

interface cardProps{
    member      :   MemberInterface
    action      ?:  (params: any,param2 ?: any) => any
    name        :   string
    avatar      :   string
    isFollowed  :   boolean
    dataIdSource:   string
}

const MemberCard = (props : cardProps) => {
    const Intl = useIntl()
    const lang = useLang()
    return (
        <>
            <div className={`card  h-100 customShadow  id_${props.member.member_id}`} >
                <div className="card-body d-flex flex-center flex-column py-9 px-5">
                    <div className="symbol symbol-65px symbol-circle mb-5">
                        <img src={props.avatar} alt="followers"/>
                    </div>
                    <span className="fs-5 text-gray-800 text-hover-primary fw-bold mb-5 text-center" data-id={`${props.dataIdSource}-name-${props.member.member_id}`}>{props.name}</span>
                    <div className='d-flex justify-content-center'>
                        <button className="btn btn-sm btn-light-primary me-2" data-name={props.name} onClick={props.action} data-id={`${props.dataIdSource}-followBtn-${props.member.member_id}`}>
                            {props.isFollowed && <>
                                <span className="svg-icon following svg-icon-3" data-id={props.member.member_id} data-name={props.name}>
                                    <svg data-id={props.member.member_id} data-name={props.name} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path data-id={props.member.member_id} opacity="0.3" d="M10 18C9.7 18 9.5 17.9 9.3 17.7L2.3 10.7C1.9 10.3 1.9 9.7 2.3 9.3C2.7 8.9 3.29999 8.9 3.69999 9.3L10.7 16.3C11.1 16.7 11.1 17.3 10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                                        <path data-id={props.member.member_id} d="M10 18C9.7 18 9.5 17.9 9.3 17.7C8.9 17.3 8.9 16.7 9.3 16.3L20.3 5.3C20.7 4.9 21.3 4.9 21.7 5.3C22.1 5.7 22.1 6.30002 21.7 6.70002L10.7 17.7C10.5 17.9 10.3 18 10 18Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                <span className="indicator-label" data-id={props.member.member_id} data-name={props.name} >{Intl.formatMessage({id: 'following'})}</span>
                            </>}
                            {!props.isFollowed && <>
                                <span className="indicator-label" data-id={props.member.member_id} data-name={props.name} >{Intl.formatMessage({id: 'follow'})}</span>
                            </>}
                        </button>
                        <a target="_blank" href={`${process.env.REACT_APP_OPENSOOQ_BASE?.replace('{country}',getCookies().ecountry).replace('{lang}',lang)}/mid/${props.member.M_user_name}`} className='btn btn-sm btn-light-primary' onClick={()=>{redirectionInterceptor();logViewMemberClick({id:props.member.member_id})}} rel="noreferrer" data-id={`${props.dataIdSource}-viewBtn-${props.member.member_id}`}>
                            <span className="svg-icon follow svg-icon-3 d-none">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                                    <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                                </svg>
                            </span>
                            <span className="indicator-label">{Intl.formatMessage({id: 'viewMember'})}</span>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export { MemberCard }
