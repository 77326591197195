import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton"
import { useNavigate } from "react-router-dom"
import DynamicObject from "../../../../models/dynamic-object"
import { getCvImage, isDeviceMobile } from "../../../helpers/commonFunctions"

type Props = {
    cv : DynamicObject
}

const CvBody = ({cv} : Props) => {
    const navigate = useNavigate();
    const Intl = useIntl();
    const editCvCtaName = cv.completion_score < 50 ? Intl.formatMessage({id : "cv.action.complete.cv"}) : Intl.formatMessage({id : "cv.action.edit.cv"})
    return <div className="d-flex w-100" key={"cv-body"}>
        {!cv && <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
            <Skeleton className="symbol-label fs-2 fw-bold text-success " />
        </div>}
        {cv && <div className="symbol symbol-100px me-5">
            <img src={`${getCvImage(cv.picture_uri)}`} alt=""/>
        </div>}
        <div className="d-flex justify-content-center flex-column gap-4">
            <h1 className="fw-bolder">{cv && cv.user_name ? cv.user_name : <Skeleton className="w-150px"/>}</h1>
            <span className="fw-semibold text-muted d-block fs-7">{cv && cv.job_role ? cv.job_role : <Skeleton className="w-150px"/>}</span>
        </div>
        {cv && !isDeviceMobile() && <div className="d-flex justify-content-center flex-column ms-auto">
            <button className="btn btn-primary ltrDir" onClick={() => {navigate(`/account/cv/edit`)}}>{editCvCtaName}</button>
        </div>}
    </div>
}

export default CvBody