import { defaultOptions, setCookie } from "../../app/helpers/commonFunctions";
import { ActionName, DEFAULT_VALUE, EMPTY_ACTION_NAME } from "../types/LoggingTypes";


export class ActionDimensions {
    private static instance : ActionDimensions;
    private _currScreen : string = DEFAULT_VALUE;
    private _prevScreen : string = DEFAULT_VALUE;
    private _currWidgetName : string = DEFAULT_VALUE;
    private _prevWidgetName : string = DEFAULT_VALUE;
    private _uxType : string = DEFAULT_VALUE;
    private _actionName : ActionName = EMPTY_ACTION_NAME;
    private _prevActionName : ActionName = EMPTY_ACTION_NAME;
    private _actionListingId : string = DEFAULT_VALUE;
    private _actionListingStatus : string = DEFAULT_VALUE;
    private _actionListingCategory : string = DEFAULT_VALUE;
    private _actionListingSubCategory : string = DEFAULT_VALUE;
    private _actionListingPaidFeatures : string = DEFAULT_VALUE;
    private _actionListingCellType : string = DEFAULT_VALUE;
    private _actionListingOriginSlotType : string = DEFAULT_VALUE;
    private _actionReferenceId : string = DEFAULT_VALUE;
    private _actionGeneratedBy : string = DEFAULT_VALUE;
    private _actionReferenceEntity : string = DEFAULT_VALUE;
    private _sessionFunnelSource : string = DEFAULT_VALUE;
    private _sessionFunnelPointOfEntry : string = DEFAULT_VALUE;
    private _actionElementName: string = DEFAULT_VALUE;
    private _actionListingVasLevel : string = DEFAULT_VALUE;
    private _filterCategory : 'Yes' | 'No' | 'NA' = DEFAULT_VALUE;
    private _filterSubcategory : 'Yes' | 'No' | 'NA' = DEFAULT_VALUE;
    private _filterListing : 'Yes' | 'No' | 'NA' = DEFAULT_VALUE;
    private _filterMember : 'Yes' | 'No' | 'NA' = DEFAULT_VALUE;
    private _filterKeyword : 'Yes' | 'No' | 'NA' = DEFAULT_VALUE;
    private _filterCP : 'Yes' | 'No' | 'NA' = DEFAULT_VALUE;

    public static getInstance(){
        if(!ActionDimensions.instance)
        {
            ActionDimensions.instance = new ActionDimensions();
        }
        return ActionDimensions.instance;
    }

    get currScreen(){
        return this._currScreen;
    }

    set currScreen(_currScreen:string)
    {
        this._currScreen = _currScreen;
    }

    get prevScreen(){
        return this._prevScreen;
    }

    set prevScreen(_prevScreen:string)
    {
        this._prevScreen = _prevScreen;
    }

    get currWidgetName(){
        return this._currWidgetName;
    }

    set currWidgetName(_currWidgetName:string){
        this._currWidgetName = _currWidgetName;
    }

    get prevWidgetName(){
        return this._prevWidgetName;
    }

    set prevWidgetName(_prevWidgetName:string){
        this._prevWidgetName = _prevWidgetName;
    }

    get uxType(){
        return this._uxType;
    }

    set uxType(_uxType:string){
        this._uxType = _uxType;
    }

    set actionName(_actionName : ActionName)
    {
        this._actionName = _actionName;
    }

    get actionName()
    {
        return this._actionName;
    }

    get prevActionName(){
        return this._prevActionName;
    }

    set prevActionName(_prevActionName:ActionName){
        this._prevActionName = _prevActionName;
    }

    get actionGeneratedBy(){
        return this._actionGeneratedBy;
    }

    set actionGeneratedBy(_actionGeneratedBy:string)
    {
        this._actionGeneratedBy = _actionGeneratedBy;
    }

    get actionListingCategory(){
        return this._actionListingCategory;
    }

    set actionListingCategory(_actionListingCategory:string)
    {
        this._actionListingCategory = _actionListingCategory;
    }

    get actionListingCellType(){
        return this._actionListingCellType;
    }

    set actionListingCellType(_actionListingCellType:string)
    {
        this._actionListingCellType = _actionListingCellType;
    }

    set actionListingStatus(status : string){
        this._actionListingStatus = status;
    }

    get actionListingStatus(){
        return this._actionListingStatus;
    }

    get actionListingId(){
        return this._actionListingId;
    }

    set actionListingId(_actionListingId:string)
    {
        this._actionListingId = _actionListingId;
    }

    get actionListingSubCategory(){
        return this._actionListingSubCategory;
    }

    set actionListingSubCategory(_actionListingSubCategory:string)
    {
        this._actionListingSubCategory = _actionListingSubCategory;
    }

    get actionListingPaidFeatures(){
        return this._actionListingPaidFeatures;
    }

    set actionListingPaidFeatures(_actionListingPaidFeatures:string)
    {
        this._actionListingPaidFeatures = _actionListingPaidFeatures;
    }

    get actionReferenceId(){
        return this._actionReferenceId;
    }

    set actionReferenceId(_actionReferenceId:string)
    {
        this._actionReferenceId = _actionReferenceId;
    }

    get actionListingOriginSlotType(){
        return this._actionListingOriginSlotType;
    }

    set actionReferenceEntity(_actionReferenceEntity:string)
    {
        this._actionReferenceEntity = _actionReferenceEntity;
    }

    get actionReferenceEntity(){
        return this._actionReferenceEntity;
    }

    set sessionFunnelSource(sessionFunnelSource:string)
    {
        this._sessionFunnelSource = sessionFunnelSource;
    }

    set sessionFunnelPointOfEntry(sessionFunnelPointOfEntry:string){
        this._sessionFunnelPointOfEntry = sessionFunnelPointOfEntry;
        setCookie('sessionFunnelPointOfEntry', sessionFunnelPointOfEntry, defaultOptions);
    }
    
    get sessionFunnelSource(){
        return this._sessionFunnelSource;
    }

    get sessionFunnelPointOfEntry(){
        return this._sessionFunnelPointOfEntry
    }

    get actionElementName(){
        return this._actionElementName;
    }

    get actionListingVasLevel(){
        return this._actionListingVasLevel;
    }
    
    get filterCategory(){
        return this._filterCategory;
    }

    get filterSubcategory(){
        return this._filterSubcategory;
    }

    get filterMember(){
        return this._filterMember;
    }

    get filterKeyword(){
        return this._filterKeyword;
    }

    get filterCP(){
        return this._filterCP;
    }

    get filterListing(){
        return this._filterListing
    }

    set filterCategory(filterCategory: 'Yes' | 'No' | 'NA'){
        this._filterCategory = filterCategory;
    }

    set filterSubcategory(filterSubcategory: 'Yes' | 'No' | 'NA'){
        this._filterSubcategory = filterSubcategory;
    }

    set filterMember(filterMember: 'Yes' | 'No' | 'NA'){
        this._filterMember = filterMember;
        this._filterCP = filterMember == 'Yes' ? 'No' : 'NA';
        this._filterCategory = filterMember == 'Yes' ? 'No' : 'NA';
        this._filterKeyword = filterMember == 'Yes' ? 'No' : 'NA';
        this._filterListing = filterMember == 'Yes' ? 'No' : 'NA';
        this._filterSubcategory = filterMember == 'Yes' ? 'No' : 'NA';
    }

    set filterKeyword(filterKeyword: 'Yes' | 'No' | 'NA'){
        this._filterKeyword = filterKeyword;
    }

    set filterCP(filterCP: 'Yes' | 'No' | 'NA'){
        this._filterCP = filterCP;
    }

    set filterListing(filterListing: 'Yes' | 'No' | 'NA')
    {
        this._filterListing = filterListing;
    }

    set actionElementName(actionElementName: string){
        this._actionElementName = actionElementName;
    }

    set actionListingVasLevel(actionListingVasLevel: string){
        this._actionListingVasLevel = actionListingVasLevel;
    }

    public reset(){
        this._uxType = DEFAULT_VALUE;
        this._actionListingStatus = DEFAULT_VALUE;
        this._actionListingCategory = DEFAULT_VALUE;
        this._actionListingCellType = DEFAULT_VALUE;
        this._actionListingId = DEFAULT_VALUE;
        this._actionListingOriginSlotType = DEFAULT_VALUE
        this._actionListingPaidFeatures = DEFAULT_VALUE;
        this._actionListingSubCategory = DEFAULT_VALUE;
        this._actionReferenceId = DEFAULT_VALUE;
        this._actionReferenceEntity = DEFAULT_VALUE;
        this._currWidgetName = DEFAULT_VALUE;
        this._prevWidgetName = DEFAULT_VALUE;
        this._actionGeneratedBy = DEFAULT_VALUE;
        this._sessionFunnelSource = DEFAULT_VALUE;
        this._sessionFunnelPointOfEntry = DEFAULT_VALUE;
    }

}