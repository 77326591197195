import React, {useMemo} from 'react'
import {FinalizeButtonDataType} from '../types/FinalizeButtonType'
import {isDeviceMobile} from '../../../../../helpers/commonFunctions'

const FinalizeButton = ({
  data,
  contFunction,
  isLoading,
}: {
  data: FinalizeButtonDataType
  contFunction: (payload: any) => void
  isLoading: boolean
}) => {
  const clickHandler = () => {
    if (!data || !data.payload) return
    contFunction(data.payload)
  }
  const Loader = useMemo(() => {
    return <i className='spinner-border text-white spinner-border-sm fs-2 p-2' />
  }, [])

  const ReactButton = (
    <button
      className={`btn px-2 btn-lg w-100 d-flex justify-content-between align-items-center fs-3 ${
        isDeviceMobile() ? 'py-6' : ''
      }`}
      style={{backgroundColor: data.backgroundColor}}
      disabled={isLoading || !data?.status}
      onClick={clickHandler}
    >
      <span className='d-block d-flex gap-1 align-items-center'>
        <span
          className={`border-radius rounded pt-2 px-2 mx-1 ${
            data?.countBox?.style?.bold ? 'fw-bold' : ''
          } ${data?.countBox?.style?.underline ? 'text-decoration-underline' : ''}`}
          style={{
            backgroundColor: data?.countBox.backgroundColor,
            color: data?.countBox?.textColor,
            width: '40px',
          }}
        >
          {!isLoading ? data?.countBox?.text : Loader}
        </span>
        <span
          className={`mt-1 ${data?.contentBox?.style?.bold ? 'fw-bold' : ''} ${
            data?.contentBox?.style?.underline ? 'text-decoration-underline' : ''
          }`}
          style={{color: data?.contentBox?.textColor}}
        >
          {data?.contentBox?.text ?? ''}
        </span>
      </span>
      <span
        className={`fw-bold mt-1 ${data?.totalBox?.style?.bold ? 'fw-bold' : ''} ${
          data?.totalBox?.style?.underline ? 'text-decoration-underline' : ''
        }`}
        style={{color: data?.totalBox?.textColor}}
      >
        {data?.totalBox?.text ?? ''}
      </span>
    </button>
  )

  return (
    <>
      {isDeviceMobile() ? (
        <div className='mt-auto shadow shadow-lg'>{ReactButton}</div>
      ) : (
        <>{ReactButton}</>
      )}
    </>
  )
}

export default FinalizeButton
