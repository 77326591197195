import React, {useState, useEffect} from 'react'
import Datetime from 'react-datetime'
import moment from 'moment'
import {useIntl} from 'react-intl'
import Sheet from 'react-modal-sheet'
import {AddFieldType, RemoveFieldType} from '../../../../interfaces/MyListings'
import {KTSVG} from '../../../../helpers'

const RangeDateFilterMobile = ({
  rangeField,
  addField,
  removeField,
  selectedFields,
}: // reset,
{
  rangeField: any
  addField: AddFieldType
  removeField: RemoveFieldType
  selectedFields: any
  // reset: boolean
}) => {
  const intl = useIntl()
  const [showSheet, setShowSheet] = useState(false)
  const [activeField, setActiveField] = useState<'from' | 'to' | null>(null)
  const [fromValue, setFromValue] = useState<string | null>(null)
  const [toValue, setToValue] = useState<string | null>(null)

  // useEffect(() => {
  //   if (reset) {
  //     setFromValue(null)
  //     setToValue(null)
  //   }
  // }, [reset])

  const handleOpenSheet = (field: 'from' | 'to') => {
    setActiveField(field)
    setShowSheet(true)
  }

  const handleCloseSheet = () => {
    setShowSheet(false)
    setActiveField(null)
  }

  useEffect(() => {
    if (selectedFields[rangeField?.fieldId]) {
      const from = selectedFields[rangeField.fieldId]?.from || null
      const to = selectedFields[rangeField.fieldId]?.to || null

      if (from) {
        setFromValue(moment(from * 1000).format('DD-MM-YYYY'))
      }

      if (to) {
        setToValue(moment(to * 1000).format('DD-MM-YYYY'))
      }
    } else {
      setFromValue(null)
      setToValue(null)
    }
  }, [selectedFields, rangeField.fieldId])

  const handleDateChange = (date: moment.Moment) => {
    const formattedDate = date.format('DD-MM-YYYY')
    const dateTimestamp = Math.floor(date.valueOf() / 1000)

    if (activeField === 'from') {
      if (formattedDate === fromValue) {
        // If the clicked date is already selected, reset it
        setFromValue(null)
        removeField(rangeField.fieldId, {value: dateTimestamp, keyName: 'from'}, 'RANGE')
      } else {
        // Set the new value
        const startOfDayTimestamp = Math.floor(date.startOf('day').valueOf() / 1000)
        setFromValue(formattedDate)
        addField(rangeField.fieldId, {value: startOfDayTimestamp, keyName: 'from'}, 'RANGE')
      }
    } else if (activeField === 'to') {
      if (formattedDate === toValue) {
        // If the clicked date is already selected, reset it
        setToValue(null)
        removeField(rangeField.fieldId, {value: dateTimestamp, keyName: 'to'}, 'RANGE')
      } else {
        // Set the new value
        const endOfDayTimestamp = Math.floor(date.endOf('day').valueOf() / 1000)
        setToValue(formattedDate)
        addField(rangeField.fieldId, {value: endOfDayTimestamp, keyName: 'to'}, 'RANGE')
      }
    }

    // Close the sheet after selecting/deselecting a date
    handleCloseSheet()
  }

  const isValidDateFrom = (current: moment.Moment) => {
    const threeYearsAgo = moment().subtract(3, 'years')
    const toDate = toValue ? moment(toValue, 'DD-MM-YYYY') : moment().endOf('day')
    return current.isBetween(threeYearsAgo, toDate, undefined, '[]')
  }

  const isValidDateTo = (current: moment.Moment) => {
    const fromDate = fromValue ? moment(fromValue, 'DD-MM-YYYY') : moment().subtract(3, 'years')
    return current.isBetween(fromDate, moment().endOf('day'), undefined, '[]')
  }

  return (
    <div className='card shadow p-5'>
      <div className='fw-bold fs-3 mb-3'>{rangeField.label}</div>
      <div className='d-flex justify-content-between'>
        <div className='w-50 p-2' onClick={() => handleOpenSheet('from')}>
          <div className='fw-bold fs-5'>{rangeField?.minLabel}</div>
          <div className='form-control bg-white'>
            {fromValue || intl.formatMessage({id: 'mylistings.date.placeholder'})}
          </div>
        </div>
        <div className='w-50 p-2' onClick={() => handleOpenSheet('to')}>
          <div className='fw-bold fs-5'>{rangeField?.maxLabel}</div>
          <div className='form-control bg-white'>
            {toValue || intl.formatMessage({id: 'mylistings.date.placeholder'})}
          </div>
        </div>
      </div>

      <Sheet
        detent='content-height'
        isOpen={showSheet}
        onClose={handleCloseSheet}
        disableScrollLocking
      >
        <Sheet.Container>
          <Sheet.Content>
            <div className='p-3'>
              <div className='d-flex justify-content-between'>
                <div className='fw-bold fs-4 mb-4'>
                  {activeField === 'from' ? rangeField?.minLabel : rangeField?.maxLabel}
                </div>
                <div onClick={handleCloseSheet} className='mb-5'>
                  <KTSVG
                    className='svg-icon-1 border rounded-circle shadow'
                    path='/media/icons/duotune/arrows/arr061.svg'
                  />
                </div>
              </div>
              <Datetime
                onChange={handleDateChange}
                closeOnSelect={true}
                dateFormat={'DD-MM-YYYY'}
                timeFormat={false}
                input={false}
                isValidDate={activeField === 'from' ? isValidDateFrom : isValidDateTo}
              />
            </div>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop onTap={handleCloseSheet} />
      </Sheet>
    </div>
  )
}

export default RangeDateFilterMobile
