import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import getCarReportDetailsApi from "../../../api/servicrs/get.carReportDetails.api";
import { useLang } from "../../i18n/OpenSooqi18n";
import { CarReportInterface } from "../../interfaces/CarReportInterface";
import { handleError } from "../common/HandleError";
import { IframeLoader } from "../common/IframeLoader";

export function CarReportDetails() {

    const {id, type} = useParams();
    const lang = useLang();
    const [report, setReport] = useState<CarReportInterface>();
    const intl = useIntl();

    useEffect(()=>{
        getCarReportDetailsApi(id, type).then((response) => {
            if(response && response.data && response.data.id)
            {
                setReport(response.data);
            }
        }).catch((err)=>{
            handleError(err, lang,intl);
        })
    }, [id, type]);
    
    return <>{!report ? <IframeLoader /> : <iframe
            srcDoc={report.report.replace("720px","100%")}
            style={{height: "70vh", width:"100%"}}>
        </iframe>
    }</>;
}