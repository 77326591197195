import { useEffect, useMemo, useState } from "react";
import { useLang } from "../../../i18n/OpenSooqi18n";
import getServiceDetailsApi from "../../../../api/buyNow/getServiceDetails.api";
import { DefaultTotal, Duration, Qty, ServiceDetailsInterface } from "../../../interfaces/ServiceDetailsInterface";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import calculateTotalApi from "../../../../api/buyNow/calculateTotal.api";
import { Button } from "react-bootstrap";
import CreateCartApi from "../../../../api/CreateCart.api";
import { LearnMore } from "./LearnMore";
import { deepLinkHandler, deepLinks } from "../../../helpers/commonFunctions";

interface Iprops {
    serviceName: string
    subCatReportingName: string
    changeHeaderHandler: (header:string) => void
    changeSubHeaderHandler: (subHeader:string) => void
    learnMore: boolean
    toggleLearnMore: () => void
    pos ?: string
    redirectTo ?: string
}

const BundleServiceDetailsCredits = ({subCatReportingName, changeHeaderHandler, changeSubHeaderHandler, serviceName, learnMore, toggleLearnMore,pos,redirectTo} : Iprops) => {
    const lang = useLang();
    const Intl = useIntl();
    const [serviceDetails, setServiceDetails] = useState<ServiceDetailsInterface>();
    const [qty, setQty] = useState<Qty>(undefined);
    const [productAssignId, setProductAssignId] = useState<number>(undefined);
    const [isCalculating, setIsCalculating] = useState(true);
    const [calculatedTotal, setCalculatedTotal] = useState<DefaultTotal>(undefined);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const action = params.get('action') ?? '';

    useEffect(()=>{
        getServiceDetailsApi(serviceName, subCatReportingName).then((response) => {
            setServiceDetails(response.data);
        })
    }, [subCatReportingName])

    useEffect(()=>{
        if(!serviceDetails) return;
        changeHeaderHandler(serviceDetails.header);
        changeSubHeaderHandler('');
        setQty(serviceDetails.qty.find((q) => q.isDefault === true))
        setProductAssignId( serviceDetails.durations[serviceDetails.durations.length - 1].productAssignId )
    }, [serviceDetails, learnMore])

    const qtyHandler = (qt: Qty)=>{
        setQty(qt)
    }

    const durationHandler = (duration: Duration) => {
        setProductAssignId(duration.productAssignId);
    }

    useEffect(()=>{
        if(!qty || !productAssignId)
            return;
        setIsCalculating(true);
        calculateTotalApi(productAssignId, qty.value).then(res=>{
            setCalculatedTotal(res.data);
            setIsCalculating(false);
        })
    }, [qty, productAssignId]);

    const skeletonRows = useMemo(()=>{
        let rows = []
        for (let i = 0; i < 1; i++) {
            rows.push(<Skeleton className="m-2 h-80px w-400px" key={i}/>)
        }
        return rows;
    }, []);

    const checkoutHandler = ()=>{
        let redirectToUrl =  window.location.href;
        if(action != '' && action == 'openWalletBundles'){
            redirectToUrl =  window.location.origin + window.location.pathname;;
        }
        const formData = { 
            Payment: {
                productAssignId: productAssignId,
                qty: qty.value,
                isBundle: true,
                absoluteUrl : redirectTo ? redirectTo : redirectToUrl,
                posSource : pos
            }
        } 
        CreateCartApi(formData).then((res) => {
            window.location.href = res.data.redirect;
        })
    }

    const handleRedirection = () => {
        deepLinkHandler(deepLinks.help[lang]);
    }

    return ( <>
        {learnMore && <div className="d-flex flex-column justify-content-start align-items-center gap-6 "><LearnMore changeSubHeaderHandler={changeSubHeaderHandler} changeHeaderHandler={changeHeaderHandler}/></div>}
        {!learnMore && <div className="d-flex flex-column justify-content-start align-items-center gap-6 ">
            <div className="d-flex justify-content-between w-100">
            {!serviceDetails && <Skeleton className="h-10px w-250px"/>}
                {serviceDetails && <p onClick={toggleLearnMore} className="text-primary text-decoration-underline fs-4 hoverPointer">{serviceDetails.blueUnderLineText}</p>}
                <p className="text-decoration-underline text-primary fs-4 hoverPointer"  onClick={handleRedirection}>{Intl.formatMessage({id: "footer.help"})}{lang=="en"?'?':"؟"}</p>
            </div>
            <div className="col-12">
                <div className="row mt-5 mb-15">
                    {serviceDetails && <div className="h3">{serviceDetails.durationDescription}</div>}
                    { serviceDetails && 
                   <div className="d-flex text-center align-items-center">
                        {serviceDetails.durations.map((duration : Duration, _index:number) => {
                            return <div style={{width: "17.5%"}} className={`radius-8 shadow m-2 fs-1 px-3 py-2  ${ (duration.productAssignId === productAssignId || ( productAssignId === undefined && _index+1 === serviceDetails.durations.length) ) ? 'border border-2 border-primary':'' }`} role="presentation" onClick={()=>{ durationHandler(duration)}} key={duration.value}>
                            <span className={`btn btn-sm fw-bold fs-1 px-4 py-3 `}>
                                {duration.value}
                            </span>
                        </div>})}
                    </div> }
                    {!serviceDetails && skeletonRows}
                </div>
                <div className="row mt-5">
                    {serviceDetails && <div className="h3">{serviceDetails.postCountDescription}</div>}
                    {serviceDetails && <div className="d-flex text-center align-items-center">
                        {serviceDetails.qty.map((qt : Qty )=>  {
                            return <div style={{width: "17.5%"}} className={`radius-8 shadow m-2 fs-1 px-3 py-2 ${((qty && qty.value === qt.value) || (qty === undefined && qt.isDefault))? 'border border-2 border-primary':'' }`} role="presentation" onClick={()=>{ qtyHandler(qt) }} key={qt.value}>
                            <span className={`btn btn-sm fw-bold fs-1 px-4 py-3 `}>
                                {qt.value}
                            </span>
                        </div>})}
                    </div>
                    }
                    {!serviceDetails && skeletonRows}
                </div>
               {serviceDetails && <div className="d-flex flex-column gap-3 my-3">
                    <h2 className="mt-5">{Intl.formatMessage({id: "repost.summary"})}</h2>
                    <div>
                    {calculatedTotal && calculatedTotal.discount && <div className="d-flex justify-content-between">
                        <span>{Intl.formatMessage({id: "repost.discount"})}</span>
                        <span><strong>{calculatedTotal.discount }</strong>{serviceDetails.summary?.currency}</span>
                    </div>}
                    {calculatedTotal && calculatedTotal.subTotal && <div className="d-flex justify-content-between">
                        <span>{Intl.formatMessage({id: "repost.subTotal"})}</span>
                        <span>
                            {!isCalculating ? <strong>{calculatedTotal.subTotal} </strong> : <Skeleton className="m-2 h-10px w-60px"/>}
                            {!isCalculating && calculatedTotal.currency}
                            </span>
                    </div>}

                    {calculatedTotal && calculatedTotal.tax && <div className="d-flex justify-content-between">
                        <span>{Intl.formatMessage({id: "repost.tax"})}</span>
                        <span>
                            {!isCalculating ? <strong>{calculatedTotal.tax}</strong> : <Skeleton className="m-2 h-10px w-60px"/>}
                            {!isCalculating && calculatedTotal.currency}
                        </span>
                    </div>}
                    </div>
                    <hr style={{borderTop: "1px dotted"}} />
                    {serviceDetails.total && <div className="fs-3 fw-bold d-flex justify-content-between">
                        <span className="fw-bolder">{serviceDetails.total}</span>
                        <span>
                            {!isCalculating ? <strong className="">{calculatedTotal.total} {calculatedTotal.currency}</strong> : <Skeleton className="m-2 h-10px w-60px"/>}
                        </span>
                    </div>}
                </div>}
                <Button disabled={isCalculating} onClick={checkoutHandler} className='w-100 fw-bold'>{Intl.formatMessage({id: "repost.checkout"})}</Button>
            </div>
        </div>}
        </>
    )
}

export {BundleServiceDetailsCredits};