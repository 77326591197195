import { Modal } from 'react-bootstrap'
import ImageUploading, {ImageType} from 'react-images-uploading'
import { useIntl } from 'react-intl'
import { Loader } from '../opensooq/Loader'

type Props = {
  show: boolean
  handleClose: () => void
  uploadFiles: (file: any) => void
  loader: boolean
}

const UploadShopImages = ({show, handleClose, uploadFiles, loader}: Props) => {
  const Intl = useIntl()
  const onChange = (imageList : ImageType[], addUpdateIndex : any) => {
    uploadFiles(imageList);
  };
  return (
    <>
      <Modal
        id='kt_modal_create_app'
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName='modal-dialog modal-dialog-centered '
        show={show}
      >
        <div className='card'>
          <div className='card-body'>
            <div className='d-flex justify-content-between'>
              <span>
                <h1>{Intl.formatMessage({id: 'myProfile.shop.text51'})}</h1>
              </span>
              <span onClick={handleClose}>
                <i className='fa-solid fa-xmark fs-1'></i>
              </span>
            </div>
            <div className='mt-10 '>
              {
                loader ? Loader() :
                <ImageUploading  multiple  value={[]}  onChange={onChange} maxNumber={8} dataURLKey="data_url">
                  {({
                      imageList,
                      onImageUpload,
                      isDragging,
                      dragProps,
                  }) => (
                  <div className="upload__image-wrapper">
                      <button style={isDragging ? { color: 'red' } : undefined} className="btn btn-primary" onClick={onImageUpload} {...dragProps} >
                        {Intl.formatMessage({id: 'myProfile.shop.text30'})}
                      </button>
                  </div>
                  )}
              </ImageUploading>
            }
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export { UploadShopImages }
