import { createContext, useCallback, useEffect, useState } from "react"
import { WithChildren } from '../helpers'
import {FC} from 'react'
import { generateToken } from "../helpers/commonFunctions"
import Auth from "opensooq-sso";  
import { ProfileInterface } from "../interfaces/ProfileInterface"

const authInitState: {isLoggedIn: boolean, regenerateToken: ()=> Promise<string>, userIdentity: ProfileInterface} = 
{
    isLoggedIn : false,
    regenerateToken : () => new Promise<string>(()=>{
        return ''
    }), 
    userIdentity: undefined
}
export const AuthContext = createContext(authInitState)
const AuthProvider: FC<WithChildren> = ({children}) => {
    const [isLoggedIn,setIsLoggedIn] = useState(false)
    const [userIdentity, setUserIdentity] = useState<ProfileInterface>(undefined);

    const getUserIdentity = async() => {
        const userInfo = await Auth.getInstance().getIdentity();
        setUserIdentity(userInfo);
    }

    useEffect(()=>{
        getUserIdentity();
    }, [])

    const regenerateToken = useCallback(()=>{
        return generateToken().then((token)=>{
            if(token){
                setIsLoggedIn(true)
            }
            return token;
        });
    }, [])
    return <AuthContext.Provider value={{isLoggedIn,regenerateToken, userIdentity}}>{children}</AuthContext.Provider>
}
export default AuthProvider