import React, {useState, useRef, useEffect} from 'react'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import {
  AddFieldType,
  CategoryFieldI,
  CategoryItemI,
  RemoveFieldType,
  SelectedFieldsType,
  SubCategoryItemI,
} from '../../../../interfaces/MyListings'
import {useLocation} from 'react-router-dom'
import useFiltersStore from '../../my-listings/FilterStore'

type CategorySelectorProps = {
  categoryField: CategoryFieldI
  addField: AddFieldType
  removeField: RemoveFieldType
  fetchDynamicFields: (reportingName: string) => void
  resetDynamicFields: () => void
  catReportingNameHandler: (name: string) => void
  subCategoryReportingNameHandler: (name: string) => void
  handleSetIsNhoodDisabled: (isDisabled: boolean) => void
  reset: boolean
  selectedFields: SelectedFieldsType
}

const CategorySelector = ({
  categoryField,
  addField,
  removeField,
  fetchDynamicFields,
  resetDynamicFields,
  catReportingNameHandler,
  subCategoryReportingNameHandler,
  handleSetIsNhoodDisabled,
  reset,
  selectedFields,
}: CategorySelectorProps) => {
  const {icon, items, label, type} = categoryField
  const [showOptions, setShowOptions] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<CategoryItemI | null>(null)
  const [selectedSubcategory, setSelectedSubcategory] = useState<SubCategoryItemI | null>(null)
  const [hoveredCategory, setHoveredCategory] = useState<CategoryItemI | null>(null)
  const [hoveredSubCategory, setHoveredSubCategory] = useState<SubCategoryItemI | null>(null)
  const [menuWidth, setMenuWidth] = useState(300)
  const [isRendered, setIsRendered] = useState(false)
  const containerRef = useRef(null)
  const location = useLocation()
  const lang = useLang()
  const isRtl = lang === 'ar'

  const {globalFiltersDisable} = useFiltersStore()

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(location.search)
  //   const categoryFieldUrl = searchParams.get(categoryField.items[0]?.fieldId)
  //   let selectedCategoryFromUrl: null | CategoryItemI = null
  //   if (categoryFieldUrl) {
  //     selectedCategoryFromUrl = categoryField?.items.find(
  //       (field) => field.value.toString() === categoryFieldUrl
  //     )
  //     handleCategorySelect(null, selectedCategoryFromUrl)
  //   }
  // }, [categoryField])

  // useEffect(() => {
  //   if (isRendered || !selectedCategory) return
  //   setIsRendered(true)
  //   const searchParams = new URLSearchParams(location.search)
  //   const subCategoryFieldUrl = searchParams.get(selectedCategory?.items[0]?.fieldId)
  //   if (selectedCategory && subCategoryFieldUrl) {
  //     const selectedSubCategoryFromUrl = selectedCategory.items?.find(
  //       (item) => item.value.toString() === subCategoryFieldUrl
  //     )
  //     handleSubcategorySelect(null, selectedSubCategoryFromUrl)
  //   }
  // }, [selectedCategory])

  //reset
  useEffect(() => {
    if (reset) {
      setSelectedSubcategory(null)
      setSelectedCategory(null)
      setMenuWidth(300)
    }
  }, [reset])

  const toggleOptions = (e: React.MouseEvent) => {
    e.stopPropagation()
    setShowOptions((prev) => {
      return !prev
    })
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowOptions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (!selectedSubcategory) return
    fetchDynamicFields(selectedSubcategory.reporting)
  }, [selectedSubcategory])

  const handleCategorySelect = (e: React.MouseEvent, category: CategoryItemI) => {
    resetDynamicFields()
    subCategoryReportingNameHandler('')
    e?.stopPropagation()
    if (selectedCategory?.value === category?.value) {
      setSelectedCategory(null)
      setSelectedSubcategory(null)
      catReportingNameHandler('')
      if(selectedSubcategory)
        removeField(selectedSubcategory.fieldId, {value: selectedSubcategory?.value}, 'SINGLE');
      removeField(category.fieldId, {value: category?.value}, 'SINGLE')
      handleSetIsNhoodDisabled(false)
      setShowOptions(false);
      setMenuWidth(300)
    } else {
      setMenuWidth(600)
      setSelectedCategory((oldCategory) => {
        if (oldCategory) removeField(oldCategory.fieldId, {value: oldCategory.value}, 'SINGLE')
        addField(
          category.fieldId,
          {label: category.label, value: category.value, parentid: '0'},
          'SINGLE'
        )
        catReportingNameHandler(category.reporting)
        handleSetIsNhoodDisabled(category?.isNhoodDisabled)
        return category
      })
    }
    setSelectedSubcategory((old) => {
      if(old)
        removeField(old?.fieldId, {value: old.value}, 'SINGLE')
      return null;
    });
  }

  const handleSubcategorySelect = (e: React.MouseEvent, subcategory: SubCategoryItemI) => {
    resetDynamicFields()
    e?.stopPropagation()
    if (selectedSubcategory?.value === subcategory.value) {
      setSelectedSubcategory(null)
      removeField(subcategory?.fieldId, {value: subcategory.value}, 'SINGLE')
      subCategoryReportingNameHandler('')
    } else {
      if(!selectedCategory || (hoveredCategory?.value !== selectedCategory?.value)) 
          handleCategorySelect(e, hoveredCategory);
      setSelectedSubcategory(subcategory)
      subCategoryReportingNameHandler(subcategory.reporting)
      handleSetIsNhoodDisabled(selectedCategory?.isNhoodDisabled)
      setTimeout(()=>{
      addField(
        subcategory?.fieldId,
        {label: subcategory.label, value: subcategory.value, parentid: hoveredCategory?.fieldId ?? selectedCategory?.fieldId},
        'SINGLE'
      )}, 0)
      setShowOptions(false)
    }
  }

  const handleCategoryHover = (e: React.MouseEvent, category: CategoryItemI) => {
    e.stopPropagation()
    setMenuWidth(600)
    setHoveredCategory(category)
    setHoveredSubCategory(null)
  }

  const handleSubcategoryHover = (e: React.MouseEvent, subcategory: SubCategoryItemI) => {
    e.stopPropagation()
    setHoveredSubCategory(subcategory)
  }

  const handleCategoryLeaveHover = () => {
    setHoveredCategory(null)
    if (!selectedCategory) {
      setMenuWidth(300)
    }
  }

  const handleSubcategoryLeaveHover = () => {
    setHoveredSubCategory(null)
  }

  const getDisplayText = () => {
    if (selectedCategory && selectedSubcategory) {
      return `${selectedCategory.label} / ${selectedSubcategory.label}`
    } else if (selectedCategory && !hoveredCategory) {
      return selectedCategory.label
    } else {
      return categoryField.label
    }
  }

  const disabled = !items || items.length < 1

  return (
    <div
      ref={containerRef}
      data-id={categoryField?.items[0]?.fieldId}
      onMouseLeave={handleCategoryLeaveHover}
      className={`dropdown user-select-none desktop-filter-field ${isRtl ? 'rtl' : 'ltr'} ${
        !showOptions && !disabled && 'hoverPointer'
      } ${globalFiltersDisable ? 'hoverNotAllowed' : ''}`}
      style={{
        borderRadius: '10px',
      }}
    >
      <div
        className={`form-control fs-5 d-flex justify-content-between align-items-center  ${
          disabled ? 'bg-light' : 'bg-transparent'
        }`}
        style={{
          color: 'black',
          height: '50px',
        }}
        onClick={(e) => {
          if (items && items.length > 0 && !globalFiltersDisable) toggleOptions(e)
        }}
      >
        <div className='d-flex flex-column' style={{width: '85%'}}>
          {selectedCategory && (
            <span className='text-primary ' style={{fontSize: '0.8em'}}>
              {label}
            </span>
          )}
          <span
            className={`${selectedCategory ?'text-dark fs-6 fw-bold ' : '' } ${globalFiltersDisable ? 'text-muted' : ''}`}
            style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}
          >
            {getDisplayText()}
          </span>
        </div>
        <span className={`fa-solid fa-bars`}></span>
      </div>
      <div
        className={`dropdown-menu ${showOptions ? 'show' : ''}`}
        style={{
          position: 'absolute',
          top: '100%',
          left: isRtl ? 'auto' : '0',
          right: isRtl ? '0' : 'auto',
          zIndex: '1000',
          width: menuWidth,
          userSelect: 'none',
        }}
      >
        <div className='row'>
          <div className='col d-flex flex-column hoverPointer fs-3 p-5'>
            {items &&
              items.map((category: CategoryItemI, index: number) => {
                const isSelected = !hoveredCategory && selectedCategory?.value === category.value
                const isHovered = hoveredCategory?.value === category.value
                return (
                  <div
                    key={category.value}
                    data-id={category.value}
                    className={`p-3 my-1 gap-2 d-flex flex-row align-items-center justify-content-between`}
                    style={{
                      borderRadius: '10px',
                      backgroundColor: isHovered || isSelected ? '#0179FF12' : 'transparent',
                    }}
                    onMouseEnter={(e) => {
                      handleCategoryHover(e, category)
                    }}
                    onClick={(e) => handleCategorySelect(e, category)}
                  >
                    <span className='d-flex gap-3 align-items-center'>
                      <span style={{maxWidth: '35px', maxHeight: '35px'}}>
                        <img
                          src={`${process.env.REACT_APP_OPENSOOQ_CUSTOM_ICON_URL}/new_addpost/${category.icon}`}
                          className='h-100 w-100'
                          alt=''
                        />
                      </span>
                      <span className='fw-bold'>{category.label}</span>
                    </span>
                    {(isSelected || isHovered) && (
                      <span className={`fa-solid fa-chevron-${isRtl ? 'left' : 'right'}`}></span>
                    )}
                  </div>
                )
              })}
          </div>
          {(selectedCategory || hoveredCategory) && (
            <div className='col border-start border-muted d-flex flex-column gap-3 hoverPointer fs-3 fw-bold p-5'>
              {items &&
                items
                  .find((cat) => {
                    return (
                      cat.value === hoveredCategory?.value ||
                      (!hoveredCategory && cat.value === selectedCategory?.value)
                    )
                  })
                  ?.items?.map((subcategory, index) => {
                    const isHovered = hoveredSubCategory?.value === subcategory.value
                    const isSelected =
                      !hoveredSubCategory && selectedSubcategory?.value === subcategory.value
                    return (
                      <div
                        key={index}
                        data-id={subcategory.value}
                        className={`p-2 d-flex flex-row align-items-center justify-content-between`}
                        style={{
                          borderRadius: '10px',
                          backgroundColor: isHovered || isSelected ? '#0179FF12' : 'transparent',
                        }}
                        onMouseEnter={(e) => {
                          handleSubcategoryHover(e, subcategory)
                        }}
                        onMouseLeave={handleSubcategoryLeaveHover}
                        onClick={(e) => handleSubcategorySelect(e, subcategory)}
                      >
                        <div
                          className=''
                          style={{color: isHovered || isSelected ? '#0179FF' : 'black'}}
                        >
                          {subcategory.label}
                        </div>
                      </div>
                    )
                  })}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CategorySelector
