import {useCallback, useRef} from 'react'
import Modal from 'react-bootstrap/Modal'
import styles from './LearnMoreModal.module.css'
import CloseButton from './CloseButton'
const LearnMoreModal = ({open, handleCloseLearnMore, link}) => {
  const handleClose = () => {
    handleCloseLearnMore()
  }
  const iframeContent = useRef<null | HTMLIFrameElement>(null)
  const randomNumberInRange = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  return (
    <Modal centered={true} show={open} size='lg' onHide={handleClose} radius='40px'>
      <div className={`${styles.modal_background} d-flex flex-column gap-4 rounded-3`}>
        <div className='w-100' style={{height: '5vh'}}>
          <CloseButton handleClose={handleClose} />
        </div>
        <iframe
          title='learn-more-iframe'
          key={randomNumberInRange(10000, 999)}
          src={link}
          className='vas w-100'
          style={{height: '80vh', overflowY: 'hidden'}}
          ref={iframeContent}
        />
      </div>
    </Modal>
  )
}

export default LearnMoreModal
