import {AxiosResponse} from 'axios'
import Api from '../api'
export default (
  lead: string,
  source: string,
  modelType: string,
  postId: number,
  extra?: string
): Promise<AxiosResponse> => {
  const path = 'logging'
  return Api.getInstance().request.post(
    `${path}/${lead}/${source}/${modelType}`,
    {
      Logging: {
        post_id: postId,
        data: extra,
      },
    },
    path
  )
}
