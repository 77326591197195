import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Swal from 'sweetalert2'
import deleteSavedSearchApi from '../../../api/searches/delete.savedSearch.api'
import savedSearchApi from '../../../api/searches/savedSearch.api'
import { useLang } from '../../../app/i18n/OpenSooqi18n'
import { EmptySavedSearch } from '../../../app/layout/components/empty/EmptySavedSearch'
import { CellLoader } from '../../../app/layout/components/opensooq/CellLoader'
import { LazyLoader } from '../../../app/layout/components/opensooq/LazyLoader'
import { SearchComponent } from '../../../app/layout/components/opensooq/SearchComponent'
import { logSavedSearchRemoveClick, logSavedSearchRemoveSuccess, logScreenLoad } from '../../../logging/helpers/commonLoggingFunctions'
import { showError } from '../../helper/SystemAlert'
import { isDeviceMobile } from '../../helpers/commonFunctions'
import { SavedSearchInterface } from '../../interfaces/SavedSearchInterface'
import { SearchesList } from './SearchesList'

export function SavedSearch() {
    const [searchData,setSearchData] = useState <SavedSearchInterface[]>([]); 
    const [list,setList] = useState <SavedSearchInterface[]>([]); 
    const [searchString, setSearchString] = useState("")
    const [loader,serLoader] = useState <boolean>(true)
    const [isLastPage,setIsLastPage] = useState(false)
    const [nextPage,setNextPage] = useState('')
    const Intl = useIntl();
    const lang = useLang();

    useEffect(() => {
        getSavedSearch();
    },[]);

    useEffect(()=>{
        logScreenLoad()
    }, [])

    const getSavedSearch = () => {
        savedSearchApi(nextPage).then(response => {
            const results = response.data.items;
            const links = response.data._links;
            if(isLastPage && nextPage == ''){
                setSearchData(results);
                setList(results);
            }else{
                setSearchData(prevAds=> {
                    return [...prevAds, ...results];
                })
                setList(prevAds=> {
                    return [...prevAds, ...results];
                })
            }
            
            setNextPage(links.next?.href ?? '')
            if(links.next?.href == undefined){
                setNextPage('');
                setIsLastPage(true)
            }

            serLoader(false);
        }).catch(() => {
            serLoader(false);
            const results : any = [];
            setSearchData(prevAds=> {
                return [...prevAds, ...results];
            })
            setList(prevAds=> {
                return [...prevAds, ...results];
            })
            setIsLastPage(true)
            showError(Intl,lang);
        });
    }

    const setSearch = (e: any) => {
        if(e.target.value && e.target.value.length > 0){
            setIsLastPage(true);
        }else{
            setIsLastPage(false);
        }
        setSearchString(e.target.value);
    };

    useEffect(() => {
        const newList = list.filter(item => {
            if(item.term.toLowerCase().includes(searchString.toLowerCase())){
                return true
            }else{
                if(item.tags.toString().toLowerCase().includes(searchString.toLowerCase())){
                    return true;
                }
            }
        })
        setSearchData(newList);
    },[searchString]);
    
    const deleteSavedSearch = (savedSearchMd5 : string, savedSearchId: number, index) => {
        //logging
        logSavedSearchRemoveClick(savedSearchId);
        //
        const data = {
            md5 : savedSearchMd5,
            status : '0'
        }  

        Swal.fire({
            icon: "warning",
            html:
            `<div class="pe-10 ps-10 fw-bolder">${Intl.formatMessage({id : "alerts.savedSearch"})}</div>`,
            buttonsStyling: false,
            showCancelButton: true,
            cancelButtonText : Intl.formatMessage({id : 'no'}),
            showLoaderOnConfirm: true,
            confirmButtonText: Intl.formatMessage({id : 'yes'}),
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-light-danger"
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                //logging
                logSavedSearchRemoveSuccess(savedSearchId);
                //
                deleteSavedSearchApi(data);
                setList(prevMemberList =>{
                    return prevMemberList.filter(it => it.md5 != savedSearchMd5);
                });
                setSearchData(prevMemberList =>{
                    return prevMemberList.filter(it => it.md5 != savedSearchMd5);
                });
            }
        })

    };

    const paginate = () => {
        getSavedSearch();
    };

    return (
        <>
        {!isDeviceMobile() ? <div className="card">
            <div className="card-header">
                <SearchComponent dataId="saved-searches-search-box" action={setSearch}  placeholder={`${Intl.formatMessage({id: 'searchIn'})} ${Intl.formatMessage({id : "menu.listing.savedSearch"})}`} />
            </div>
            <div className="card-body">
                {loader && <>
                    <CellLoader />
                </>}
                {!loader && <div className="row g-5">
                    <LazyLoader list={<SearchesList dataId="saved-searches" handleUpdateSavedSearch={deleteSavedSearch} savedSearch={true} searchData={searchData}/>} data={searchData} endMessage={<EmptySavedSearch/>} loader={<CellLoader/>} paginate={paginate} isLastPage={isLastPage}/>
                </div>}
            </div>
        </div>: <div>
            <div>
            <SearchComponent dataId="saved-searches-search-box" action={setSearch} totalNumber={`${searchData.length}`}   placeholder={`${Intl.formatMessage({id: 'searchIn'})} ${Intl.formatMessage({id : "menu.listing.savedSearch"})}`} inputClassName="bg-white"/>
        </div>
        <div>
            {loader && <>
                <CellLoader />
            </>}
            {!loader && <div className="row g-5">
                <LazyLoader list={<SearchesList dataId="saved-searches" handleUpdateSavedSearch={deleteSavedSearch} savedSearch={true} searchData={searchData}/>} data={searchData} endMessage={<EmptySavedSearch/>} loader={<CellLoader/>} paginate={paginate} isLastPage={isLastPage}/>
            </div>}
        </div>
        </div>}
    </>
  )
}
