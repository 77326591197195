import { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import learnMoreApi from "../../../../api/learnMore.api";
import { getGeneralImage } from "../../../helpers/commonFunctions";
import { LearnMoreInterface } from "../../../interfaces/LearnMoreInterface";

interface Iprops {
    changeHeaderHandler: (header:string) => void
    changeSubHeaderHandler: (subHeader:string) => void
}

const LearnMore = ({changeHeaderHandler, changeSubHeaderHandler} : Iprops) => {
    const [learnMore, setLearnMore] = useState<LearnMoreInterface>(null);

    useEffect(()=>{
        learnMoreApi().then((response) => {
            setLearnMore(response.data);
        })
    }, [])

    useEffect(()=>{
        if(!learnMore){
            return;
        }
        changeHeaderHandler(learnMore.header)
        changeSubHeaderHandler('');
    }, [learnMore])

    const skeletonRows = useMemo(()=>{
        let rows = []
        for (let i = 0; i < 5; i++) {
            rows.push(<Skeleton className="mt-5 mb-5 h-100px w-400px" key={i}/>)
        }
        return rows;
    }, []);

    return (<div className="d-flex flex-column p-4 overflow-scroll h-800px ">
        {learnMore && learnMore.services.map(item => {
            return <div className="d-flex flex-column shadow m-2 radius-8 p-5">
                <div className="d-flex justify-content-start align-items-center">
                    <img className="w-50px" src={getGeneralImage(process.env.REACT_APP_OPENSOOQ_CUSTOM_ICON_URL + item.icon)}></img>
                    <div className="fs-1 ms-3 fw-bold">{item.title}</div>
                </div>
                <div className="fs-6 text-muted m-2">
                    {item.description}
                </div>
            </div>
        })}
        {!learnMore && skeletonRows}
    </div> )
}

export { LearnMore };
