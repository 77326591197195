import Skeleton from "react-loading-skeleton";
export function CvLoader() {
    return <><div className="row justify-content-center">
        <div className="col-lg-12">
            <div className="card">
                <div className="card-body w-100 ">
                    <div className="row">
                        <div className="col-lg-2 col-2">
                            <Skeleton className="h-25px"/>
                        </div>
                        <div className="col-lg-2 col-2">
                            <Skeleton className="h-25px"/>
                        </div>
                        <div className="col-lg-8 col-8">
                            <Skeleton className="h-25px"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div className="row mt-3">
        <div className="col-lg-12  pt-2 pb-6">
            <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                <div className="d-flex flex-l-row flex-column gap-7  w-lg-350px">
                    <div className="card card-flush py-4 ">
                        <div className="card-body">
                            <Skeleton count={14} className="h-25px mt-5"/>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-column w-100 gap-7 gap-lg-10">
                    <div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
                        <div className="card card-flush py-4 flex-row-fluid overflow-hidden">
                            <div className="card-header">
                                <div className="card-title align-items-start flex-column">
                                    <h2><Skeleton className="w-150px"/></h2>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                <Skeleton className="w-100"/>
                                <Skeleton className="w-75"/>
                                <Skeleton className="w-50"/>
                                <Skeleton className="w-25"/>
                            </div>
                        </div>
                    </div>
                    <div className="card card-flush py-4 ">
                        <div className="card-body pt-0">
                            <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-auto">
                                <tbody className="fw-semibold text-gray-600">
                                    <tr>
                                        <td className="text-muted">
                                            <Skeleton className="w-100"/>
                                        </td>
                                        <td className="fw-bold text-end ltrDir">
                                            <Skeleton className="w-20px"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted">
                                            <Skeleton className="w-100"/>
                                        </td>
                                        <td className="fw-bold text-end ltrDir">
                                            <Skeleton className="w-20px"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted">
                                            <Skeleton className="w-100"/>
                                        </td>
                                        <td className="fw-bold text-end ltrDir">
                                            <Skeleton className="w-20px"/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="text-muted">
                                            <Skeleton className="w-100"/>
                                        </td>
                                        <td className="fw-bold text-end ltrDir">
                                            <Skeleton className="w-20px"/>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="card card-flush ">
                        <div className="card-body">
                            <div className="row">
                                <div className="col" >
                                    <div  className="border border-dashed border-gray-300 text-center  rounded p-5">
                                        <Skeleton className="w-50 h-25px"/>
                                    </div>
                                </div>
                                <div className="col" >
                                    <div  className="border border-dashed border-gray-300 text-center  rounded p-5">
                                        <Skeleton className="w-50 h-25px"/>
                                    </div>
                                </div>
                                <div className="col" >
                                    <div  className="border border-dashed border-gray-300 text-center  rounded p-5">
                                        <Skeleton className="w-50 h-25px"/>
                                    </div>
                                </div>
                                <div className="col" >
                                    <div  className="border border-dashed border-gray-300 text-center  rounded p-5">
                                        <Skeleton className="w-50 h-25px"/>
                                    </div>
                                </div>
                                <div className="col" >
                                    <div  className="border border-dashed border-gray-300 text-center  rounded p-5">
                                        <Skeleton className="w-50 h-25px"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div></>
}