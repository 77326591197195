import React, {useEffect, useState} from 'react'

import {useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import Skeleton from 'react-loading-skeleton'
import {AddFieldType, RemoveFieldType, SelectedFieldsType} from '../../../interfaces/MyListings'
import {useLang} from '../../../i18n/OpenSooqi18n'
import useFiltersStore from '../my-listings/FilterStore'

const CategorySingleSelect = ({
  field,
  addField,
  removeField,
  selectedFields,
}: {
  field: any
  removeField: RemoveFieldType
  addField: AddFieldType
  selectedFields: SelectedFieldsType
}) => {
  const {fieldId, label, items} = field
  const [selectedOption, setSelectedOption] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [options, setOptions] = useState(field?.items ? [...field?.items] : [])
  const [isLoading, setIsLoading] = useState(items ? false : true)
  const location = useLocation()
  const intl = useIntl()
  const {globalFiltersDisable} = useFiltersStore()

  const selectOption = (option) => {
    if (selectedOption) removeField(fieldId, {value: selectedOption?.value}, 'SINGLE')
    if (selectedOption?.value?.toString() === option.value.toString()) {
      setSelectedOption(null)
    } else {
      setSelectedOption(option)
      addField(fieldId, {value: option.value, label: label, parentid: '0'}, 'SINGLE')
    }
    setIsModalOpen(false)
  }

  const lang = useLang()

  const handleSelectOption = (option) => {
    selectOption(option)
    setIsModalOpen(false)
  }

  const filteredOptions = options.filter((option) => {
    return option.label.includes(searchTerm)
  })

  const optionsLoadingCells = () => {
    const loaders = []
    for (let index = 0; index < 10; index++) {
      const element = <Skeleton className='my-5' width={325} height={50} />
      loaders.push(element)
    }
    return loaders
  }

  useEffect(() => {
    if (selectedFields[fieldId]) {
      const fetchOption = items.find(
        (item) => item.value == (selectedFields[fieldId] as any)?.value
      )
      setSelectedOption(fetchOption || null)
    } else {
      setSelectedOption(null)
    }
  }, [selectedFields[fieldId]])

  return (
    <div style={{direction: lang === 'ar' ? 'rtl' : 'ltr'}} className='col-4 ' key={fieldId}>
      <div
        onClick={() => {
          if (!globalFiltersDisable) setIsModalOpen(true)
        }}
        className='card w-100 d-flex align-items-center justify-content-between flex-row p-2 px-3'
      >
        <div className='d-flex flex-column py-3 w-75'>
          <div
            className={`text-16 text-truncate w-100 ${globalFiltersDisable ? 'text-muted' : ''}`}
          >
            {selectedOption ? selectedOption.label : label}
          </div>
        </div>
        <i className='fa-solid fa-chevron-down w-25'></i>{' '}
      </div>

      {isModalOpen && (
        <div className='modal show d-block' style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
          <div className='modal-dialog modal-fullscreen'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title fs-1'>{label}</h5>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => setIsModalOpen(false)}
                ></button>
              </div>
              <div className='modal-body d-flex flex-column gap-2'>
                {!isLoading ? (
                  filteredOptions.map((option, index) => (
                    <div
                      key={index}
                      className='card p-5 py-7 fs-2 fw-bold mb-3 shadow-sm d-flex flex-row justify-content-between align-items-center'
                      style={{borderRadius: '0.5rem', cursor: 'pointer'}}
                      onClick={() => handleSelectOption(option)}
                    >
                      <span>{option?.label}</span>
                      <i
                        className={`fa text-dark fw-bold fa-chevron-${
                          lang === 'ar' ? 'left' : 'right'
                        }`}
                      ></i>
                    </div>
                  ))
                ) : (
                  <div>{optionsLoadingCells()}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CategorySingleSelect
