import { useCallback, useState } from "react";
import { isMobile } from 'react-device-detect';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { CellLoader } from '../../layout/components/opensooq/CellLoader';
import { logOrderStartClick } from '../../../logging/helpers/commonLoggingFunctions';
import { EnumUxTypes } from '../../../logging/types/LoggingTypes';
import DynamicObject from '../../../models/dynamic-object';
import { BUMPUP, EDIT, PROMOTE, VIEW_CHATS } from '../../helper/Ctas';
import { PointOfSales } from '../../helper/PointOfSales';
import { editListing, isDeviceMobile, showChatInCountry } from "../../helpers/commonFunctions";
import { IframeModal } from "../../layout/components/modals/IframeModal";
import { CheckCtasIcon } from '../CheckCtasIcon';
import { ListingViewCell } from "../listing/ListingViewCell";
import { ChatFactoryTypes } from '../listing/my-listings/helper';
import { MemberShipModal } from '../payment/MemberShipModal';
import Header from './listingInfoComponents/Header';

type Props = {
    listing ?: DynamicObject
    refresh :  () => any
    count ?: number
    comeFrom ? : string
    applicantsView : boolean
}

export function ListingInfo({listing,refresh,count,applicantsView,comeFrom} : Props) {
    const [showEditListingModal, setShowEditListingModal] = useState(false)
    const [showBundle,setShowBundle] = useState (false);
    const [pos, setPos] = useState('')
    const [cta, setCta] = useState ('');
    const Intl = useIntl();
    const navigate = useNavigate();
    const viewChat = (listing : DynamicObject) => {
        navigate('/chats',{state : {
            type : ChatFactoryTypes.LISTING_CHATS,
            data : {
                listing_id : listing.listing_id
            }
        }});
    }

    const handleClose = () => {
        setShowEditListingModal(false)
        refresh()
    }

    const showBundleModalHandlerPromote = useCallback(()=>{
        logOrderStartClick('listing_cell', EnumUxTypes.LIST_CELL)
        let pos = PointOfSales.MY_LISTING_VIEW_POS_TOP_WIDGET_PROMOTE;
        if(comeFrom && comeFrom == 'stats'){
            pos = PointOfSales.MY_LISTING_VIEW_POS_LISTING_STATISTICS_WIDGET_PROMOTE;
        }
        setPos(pos);
        setCta('premium');
        setShowBundle(true)
    }, [])

    const showBundleModalHandlerRepost = useCallback(()=>{
        logOrderStartClick('listing_cell', EnumUxTypes.LIST_CELL)
        let pos = PointOfSales.MY_LISTING_VIEW_POS_TOP_WIDGET_REPOST;
        if(comeFrom && comeFrom == 'stats'){
            pos = PointOfSales.MY_LISTING_VIEW_POS_LISTING_STATISTICS_WIDGET_REPOST;
        }
        setPos(pos)
        setCta('bumpup');
        setShowBundle(true)
    }, [])

    const editListingClickHandler = (listing: DynamicObject)=>{
        if(listing && listing.listing_id){
            editListing(listing,'edit');
        }
    }

    return (
        <div className="card">
            <div className="card-header ">
                {listing && <Header listing={listing}/>}
            </div>
            <div className="card-body p-0 ">
                {!listing && <CellLoader counter={1}/>}
                <div className={`flex ${isDeviceMobile() ? 'flex-column' : 'flex-row'} justify-content-between`}>
                    {listing && <ListingViewCell listing={listing}/>}
                    {!applicantsView && listing && listing.job_applicant_count > 0 && <div className="border h-100px w-300px border-dashed border-gray-300 grayHoverBg rounded my-5 p-5 me-5 mx-2 hoverPointer">
                        <div className="d-flex align-items-center justify-content-between h-30px hoverPointer mb-2 ">
                            <div className="form-check form-check-custom form-check-solid fs-6">{Intl.formatMessage({id: "applicants"})}</div>
                            <span className="badge badge-light fs-12 fw-bold">{listing.job_applicant_count}</span>
                        </div>
                        <div className="h-5px w-80 bg-light mb-3 rounded">
                            <div className="bg-success rounded h-5px" style={{width: `${listing && listing?.job_applicant_count && listing?.job_applicant_count}%` }}></div>
                        </div>
                        <div className='fs-6 fw-bold text-primary text-decoration-underline' onClick={()=>navigate(`/listings/my-listings/job-applicants?id=${listing?.listing_id}`)}>{Intl.formatMessage({id: 'view.applicants'})}</div>
                    </div>}
                </div>
            </div>
            <div className={`card-footer  ${isMobile ? 'd-flex p-2 ' : 'p-3' }`}>
                {listing && listing?.ctas?.map((cta : DynamicObject,index : number) => {
                    if(cta?.cta == PROMOTE ){
                        return <button key={index} className={`btn mt-1 btn-primary btn-sm ${isMobile ? 'flex-1 p-2' : '' } me-3`} onClick={showBundleModalHandlerPromote}>
                            <CheckCtasIcon width='25' height='18' color="white" cta={cta.cta}/>
                            {cta.label}
                        </button>
                    }
                    if(cta?.cta == BUMPUP){
                        return <button key={index} className={`btn mt-1 btn-primary btn-sm ${isMobile ? 'flex-1 p-2' : '' } me-3`} onClick={showBundleModalHandlerRepost}>
                            <CheckCtasIcon width='25' height='18' color="white" cta={cta.cta}/>
                            {cta.label}
                        </button>
                    }
                    if(cta?.cta == VIEW_CHATS && showChatInCountry()){
                        return  <button key={index} className={`btn mt-1 btn-primary btn-sm ${isMobile ? 'flex-1 p-2' : '' } me-3`} onClick={() => {viewChat(listing)}}>
                            <CheckCtasIcon width='25' height='18' color="white" cta={cta.cta}/>
                            <span  className='ltrDir'>{cta.label} ({count? count : "0"})</span>
                        </button>
                    }
                    if(cta?.cta == EDIT){
                        return  <button key={index} className={`btn mt-1 btn-primary btn-sm ${isMobile ? 'flex-1 p-2' : '' } me-3`} disabled={cta.cta.enabled} onClick={()=>{editListingClickHandler(listing)}}>
                            <CheckCtasIcon width='25' height='18' color="white" cta={cta?.cta}/>
                            <span className='ltrDir'>{cta.label}</span>
                        </button>
                    }
                })}
            </div>
            <MemberShipModal  show={showBundle}  type={['Turbo','Premium','Bumpup']} handleClose={() => {setShowBundle(false)}} id={listing?.listing_id} pos={pos} cta={cta}/>
            <IframeModal show={showEditListingModal} id={listing?.listing_id ?? 0} type="edit" handleClose={() => {handleClose()}}/>
        </div>
    )
}