import Cookies from 'js-cookie'
import React, {FC, createContext, useContext, useEffect} from 'react'
import {WithChildren} from '../helpers'
import { defaultOptions, deleteCookie, setCookie } from '../helpers/commonFunctions'

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: 'de' | 'en' | 'es' | 'fr' | 'ja' | 'zh' | 'ar'
}
const initialState: Props = {
  selectedLang: 'ar',
}


function getConfig(): Props {
  // const queryString = window.location.search;
  // const urlParams = new URLSearchParams(queryString);
  // let lang = urlParams.get('lang') ?? ''
  // if(lang != ''){
  //   localStorage.setItem("i18nConfig", "{selectedLang : '"+lang+"'}");
  //   document.cookie = "i18nConfig={selectedLang : '"+lang+"'}";
  //   const data  = {
  //     selectedLang : lang
  //   } as Props
  //   return data;
  // }
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return JSON.parse(ls) as Props
    } catch (er) {
      console.error(er)
    }
  }
  return initialState
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  Cookies.remove('NEXT_LOCALE',{domain : (window.location.hostname == 'localhost') ? window.location.hostname : '.' + process.env.REACT_APP_DOMAIN_BASE})
  Cookies.set('NEXT_LOCALE',lang,{domain : (window.location.hostname == 'localhost') ? window.location.hostname : '.' + process.env.REACT_APP_DOMAIN_BASE})
  window.location.reload()
}

const I18nContext = createContext<Props>(initialState)

const useLang = () => {
  return useContext(I18nContext).selectedLang
}

const OpenSooqI18nProvider: FC<WithChildren> = ({children}) => {
  const lang = getConfig();
  useEffect(()=>{
    const newDir = lang.selectedLang === 'en' ? 'ltr' : 'rtl';
    document.getElementsByTagName('html')[0].setAttribute('dir',newDir);
    document.getElementsByTagName('html')[0].setAttribute('direction',newDir);
    document.getElementsByTagName('html')[0].style.direction = newDir;
  },[lang.selectedLang])
  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>
}

export {OpenSooqI18nProvider, useLang}
