// Account Point Of Sales
export enum PointOfSales {
    ACCOUNT_POS_WALLET_ADD_CREDIT = 'wallet_walletsummary_buycredits_addcredit_button',
    ACCOUNT_POS_WALLET_BUY_PROMOTIONAL_CREDIT = 'wallet_walletsummary_buypromotioncredits_widget_button',
    ACCOUNT_POS_WALLET_RECHARGE_WALLET = 'wallet_walletsummary_buycredits_addvoucher_button',
    ACCOUNT_POS_WALLET_TOPUP_REPOST_CREDIT = 'wallet_topup_widget_add_repost_credit_button',
    ACCOUNT_POS_WALLET_TOPUP_ADD_PROMOTION_CREDIT = 'my_wallet_topup_widget_add_promotion_credit_button',
    ACCOUNT_POS_WALLET_TOPUP_ADD_REPOST_CREDIT = 'my_wallet_topup_widget_add_repost_credit_button',
    ACCOUNT_POS_WALLET_TOPUP_ADD_CREDIT = 'my_wallet_topup_widget_add_credit_button',
    ACCOUNT_POS_WALLET_TOPUP_ADD_LISTING_CREDITS = 'my_wallet_topup_widget_add_listing_credits_button',
    MY_LISTINGS_POS_PROMOTE_LISTING = 'mylistings_listingcell_promote_button',
    MY_LISTINGS_POS_REPOST_LISTING = 'mylistings_listingcell_repost_button',
    MY_LISTINGS_POS_DELETE_WIDGET_PROMOTE_LISTING = 'mylistings_listingcell_delete_widget_promote_button',
    MY_LISTING_VIEW_POS_STATISTICS_TOP_PROMOTE = 'mylistingview_listingstatistics_wantmoreviews_widget_promotenow_button',
    MY_LISTING_VIEW_POS_STATISTICS_BOTTOM_PROMOTE = 'mylistingview_listingstatistics_bottom_promotewidget',
    MY_LISTING_VIEW_POS_TOP_WIDGET_PROMOTE = 'mylistingview_mylistingcard_promotelisting_button',
    MY_LISTING_VIEW_POS_TOP_WIDGET_REPOST = 'mylistingview_mylistingcard_repostlisting_button',
    MY_LISTING_VIEW_POS_MANAGE_LISTING_PROMOTE = 'mylistingview_managelisting_promotelisting_link',
    MY_LISTING_VIEW_POS_MANAGE_LISTING_REPOST = 'mylistingview_managelisting_repostlisting_link',
    MY_LISTING_ViEW_POS_DELETE_WIDGET_PROMOTE_LISTING = 'mylistingview_managelisting_delete_widget_promote_button',
    MY_LISTING_VIEW_POS_MANAGE_WANT_MORE_VIEW_PROMOTE = 'mylistingview_managelisting_want_more_view_promotelisting',
    MY_LISTING_VIEW_POS_WANT_MORE_VIEW = 'mylistingview_want_more_view_promote',
    MY_LISTING_VIEW_LISTING_STATISTICS_WIDGET_PROMOTE = 'mylistingview_listingstatistics_widget_promotenow_button',
    MY_LISTING_VIEW_POS_LISTING_STATISTICS_WIDGET_PROMOTE = 'mylistingview_listingstatistics_mylistingcard_promotelisting_button',
    MY_LISTING_VIEW_POS_LISTING_STATISTICS_WIDGET_REPOST = 'mylistingview_listingstatistics_mylistingcard_repostlisting_button',
    UPGRADE_MEMBERSHIP_PROFILE_HEADER_WIDGET = 'myhomepage_accountsummary_widget_upgrade_membership_top_link',
    UPGRADE_MEMBERSHIP_BOTTOM_WIDGET = 'myhomepage_upgrade_membership_bottom_widget_button',
    ACCOUNT_PROFILE_HEADER = 'myaccount_accountsummary_widget_upgrade_membership_top_link',
    PROMOTE_CV_STATISTICS_CV_WIDGET_1 = 'myaccount_manage_cv_widget_promote_button',
    PROMOTE_CV_STATISTICS_CV_WIDGET_2 = 'myaccount_manage_cv_widget_repost_button',
    PROMOTE_CV_STATISTICS_CV_WIDGET_3 = 'myaccount_cv_statistics_widget_promote_button',
    PROMOTE_CV_STATISTICS_CV_WIDGET_4 = 'myaccount_cv_manage_cv_see_more_view_widget_promote_button',
    MY_LISTING_VIEW_MANAGE_LISTING_WANT_MORE_VIEWS= "mylistingview_managelisting_widget_want_more_views_promote",
    MYACCOUNT_ACCOUNTSUMMARY_WIDGET_UPGRADE_MEMBERSHIP_BUTTON = 'myaccount_accountsummary_widget_upgrade_membership_button',
    MYACCOUNT_SHOP_TAB_WIDGET_UPGRADE_MEMBERSHIP_BUTTON = 'myaccount_shop_tab_widget_upgrade_membership_button',
    MY_LISTING_VIEW_LISTING_STATISTICS_STATISTICS_SUMMARY_WIDGET_PROMOTE_LISTING_BUTTON = 'mylistingview_listingstatistics_statisticssummary_widget_promotelisting_button',
    MY_WALLET_AMOUNT_DUE = "mywallet"
}