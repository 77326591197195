import 'react-toastify/dist/ReactToastify.css';
import DynamicObject from '../../models/dynamic-object';
import { toastify } from '../../tostify/snackbarAlert';


const showSuccess = (Intl : any,lang : string, customBody ?: string, customTitle?: string) => {
    const description : string= customBody ? customBody : Intl.formatMessage({id : 'successMsg'})
    const title : string = customTitle ? customTitle : Intl.formatMessage({id : 'success'})
    toastify({severity : 'success', title :title, description : description, locale : lang})
}

const showError = (Intl : any,lang : string, errorMessages? : DynamicObject[] | DynamicObject) => {
    if(errorMessages)
    {   
        let messages:any[] = [];
        if(Array.isArray(errorMessages))
        {
            errorMessages?.map((e : any) => messages.push(e.message));    
        }
        else {
            messages.push(errorMessages.message);
        }
        toastify({severity: 'error' ,title : Intl.formatMessage({id: 'error'}), description: messages, locale: lang});
    }else {

        toastify({severity: 'error' ,title : Intl.formatMessage({id: 'error'}), description: "", locale: lang});
    }}


export { showError, showSuccess };
