import React, {useCallback, useEffect, useState, useRef, memo} from 'react'
import PropTypes from 'prop-types'
import {useLang} from '../../../../i18n/OpenSooqi18n'

const MultiRangeSlider = ({
  min,
  max,
  onChange,
  minVal,
  maxVal,
  hideMinRange,
  hideMaxRange,
  step,
}) => {
  const [internalMinVal, setInternalMinVal] = useState(minVal)
  const [internalMaxVal, setInternalMaxVal] = useState(maxVal)
  const minValRef = useRef(minVal)
  const maxValRef = useRef(maxVal)
  const range = useRef(null)
  const lang = useLang()

  useEffect(() => {
    setInternalMinVal(minVal)
    setInternalMaxVal(maxVal)
    minValRef.current = minVal
    maxValRef.current = maxVal
  }, [minVal, maxVal])

  // Convert to percentage
  const getPercent = useCallback(
    (value) => Math.round(((value - min) / (max - min)) * 100),
    [min, max]
  )

  // Set width of the range to decrease from the left side
  useEffect(() => {
    const minPercent = getPercent(internalMinVal)
    const maxPercent = getPercent(maxValRef.current)

    if (range.current) {
      const coveredRange = maxPercent - minPercent >= 100 ? 99 : maxPercent - minPercent
      if (lang === 'ar') {
        range.current.style.right = `${minPercent}%`
      } else {
        range.current.style.left = `${minPercent}%`
      }
      range.current.style.width = `${coveredRange}%`
    }
  }, [internalMinVal, getPercent])

  // Set width of the range to decrease from the right side
  useEffect(() => {
    const minPercent = getPercent(minValRef.current)
    const maxPercent = getPercent(internalMaxVal)

    if (range.current) {
      const coveredRange = maxPercent - minPercent >= 100 ? 99 : maxPercent - minPercent

      range.current.style.width = `${coveredRange}%`
    }
  }, [internalMaxVal, getPercent])

  // Get min and max values when their state changes
  useEffect(() => {
    onChange({min: internalMinVal, max: internalMaxVal})
  }, [internalMinVal, internalMaxVal])

  return (
    <div className='d-flex justify-content-center align-items-center mb-6' style={{scale: '0.99'}}>
      {!hideMinRange && (
        <input
          type='range'
          min={min}
          max={max}
          step={step}
          value={internalMinVal}
          onChange={(event) => {
            const value = Math.min(Number(event.target.value), internalMaxVal)
            setInternalMinVal(value)
            minValRef.current = value
          }}
          className='thumb thumb--left'
          // style={{zIndex: internalMinVal > max - 100 ? '5' : '5'}}
        />
      )}
      {!hideMaxRange && (
        <input
          type='range'
          min={min}
          max={max}
          step={step}
          value={internalMaxVal}
          onChange={(event) => {
            const value = Math.max(Number(event.target.value), internalMinVal)
            setInternalMaxVal(value)
            maxValRef.current = value
          }}
          className='thumb thumb--right'
        />
      )}

      <div className='slider'>
        <div className='slider__track' />
        <div ref={range} className='slider__range' />
      </div>
    </div>
  )
}

MultiRangeSlider.propTypes = {
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  minVal: PropTypes.number.isRequired,
  maxVal: PropTypes.number.isRequired,
}

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.min === nextProps.min &&
    prevProps.max === nextProps.max &&
    prevProps.minVal === nextProps.minVal &&
    prevProps.maxVal === nextProps.maxVal
  )
}

export default memo(MultiRangeSlider, areEqual)
