import { HeaderDataType } from "../types/HeaderType"

const Header = ({data, handleClose} : {data: HeaderDataType, handleClose: ()=>void}) => {
  return (
    <div id='header' className='w-100 d-flex justify-content-between'>
        <div className={`fs-1 mt-1 ${data?.style?.bold && 'fw-bold'}`} style={{color: data?.textColor}}>
            {data?.text ?? ""}
        </div>
        <span className='btn btn-sm btn-icon btn-active-color-primary text-end' onClick={handleClose}>
            <span className='fa fa-close fs-1'></span>
        </span>
    </div>
    )
}

export default Header