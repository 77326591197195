import { useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import repostListingApi from "../../../../api/listing/repost.listing.api";
import { logBoostListingFreeBumpUp } from "../../../../logging/helpers/commonLoggingFunctions";
import { EnumUxTypes } from "../../../../logging/types/LoggingTypes";
import DynamicObject from "../../../../models/dynamic-object";
import { ACTIVATE, BUMPUP, BUMPUP_FREE, COPY, EDIT, LISTING_STATISTICS, PROMOTE, SHARE, VIEW_CHATS, VIEW_ON_OPENSOOQ } from "../../../helper/Ctas";
import { PointOfSales } from "../../../helper/PointOfSales";
import { ShareLoggingScreens } from "../../../helper/ShareLogging";
import shareLogging from "../../../../api/shareLogging";
import { TWITTER_SHARE_URL } from '../../../helper/ShareLinks';
import { showError, showSuccess } from "../../../helper/SystemAlert";
import { editListing, generateOpenSooqRedirectUrl, getCookies, getSiteCustomContent, isDeviceMobile, redirectionInterceptor, shorten, showChatInCountry } from "../../../helpers/commonFunctions";
import { useLang } from "../../../i18n/OpenSooqi18n";
import { DeactivateListModal } from "../../../layout/components/modals/DeactivateListModal";
import { IframeModal } from "../../../layout/components/modals/IframeModal";
import { CustomModal } from "../../../modales/CustomModal";
import { CheckCtasIcon } from "../../CheckCtasIcon";
import { WantMoreViews } from "../../common/WantMoreViews";
import { TrLoader } from "../../listing/TrLoader";
import { ChatFactoryTypes } from "../../listing/my-listings/helper";
import { MemberShipModal } from "../../payment/MemberShipModal";
import { ShareCv } from "./ShareCv";

type Props = {
    listing ?: DynamicObject
    count ?: number
}

export function ManageCv({listing} : Props) {
    const [listingIntegrationActionType, setListingIntegrationActionType] = useState('')
    const [showEditListingModal, setShowEditListingModal] = useState(false)
    const [showDeactivateModal, setShowDeactivateModal] = useState(false)
    const [showCustomModal,setShowCustomModal] = useState<boolean>(false)
    const [pickedListing,setPickedListing] = useState <DynamicObject>();
    const [showBundle,setShowBundle] = useState (false);
    const [type,setType] = useState<string[]> ([]);
    const [cta, setCta] = useState<string>('');
    const [pos, setPos] = useState('');
    const navigate = useNavigate();
    const Intl = useIntl();
    const lang = useLang();

    const takeAction = (cta : string) => {
        setPickedListing(listing);
        let link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang) + '/job-seekers/' + listing?.cv_id;
        switch (cta) {
            case PROMOTE:
                setPos(PointOfSales.PROMOTE_CV_STATISTICS_CV_WIDGET_1)
                setShowBundle(true)
                setType(['Turbo','Premium','Bumpup'])
                setCta('premium');
                break;
            case BUMPUP:
                setPos(PointOfSales.PROMOTE_CV_STATISTICS_CV_WIDGET_2)
                setShowBundle(true)
                setType(['Bumpup'])
                setCta('bumpup');
                break;
            case BUMPUP_FREE:
                repost(listing?.equivalent_post_id ?? 0);
                logBoostListingFreeBumpUp(
                    {
                        id: listing.listing_id, 
                        service: listing.service_name && listing.service_name.length > 0 ? listing.service_name[listing.service_name.length-1]?.icon : '', 
                        category: { label: listing.category_reporting_name },
                        city:{name: listing.city_label_en}, 
                        country: listing.country_abbreviation, 
                        neighborhoodName: listing.neighborhood_name_en ?? '',
                        subCategory: listing.subcategory_reporting_name              
                    },
                    EnumUxTypes.LIST_CELL,
                    'export_listing_options'
                );
                break;
            case VIEW_ON_OPENSOOQ:
                redirectionInterceptor();
                window.open(link, "_blank");
                break;
            case VIEW_CHATS:
                viewChat(listing?.equivalent_post_id ?? 0);
                break;
            case LISTING_STATISTICS:
                navigate(`/account/cv/cv-stats?id=${listing?.equivalent_post_id}`)
                break;
            case EDIT:
                navigate(`/account/cv/edit`)
                break;
            case SHARE:
                if(isDeviceMobile()){
                    handleSharing(link + '#utm_source=opensooq&utm_medium=share&utm_campaign=listing_my_listing_view_standard_from_myopensooq')
                }else{
                    setShowCustomModal(true);
                }
                break;
            case COPY:
                if(listing && listing.equivalent_post_id){
                    editListing(listing,'republish');
                }else{
                    showError(Intl,lang)
                }
                break;
            case ACTIVATE:
                setListingIntegrationActionType('activate')
                setShowEditListingModal(true)
                break;
            default:
                break;
        }
    } 

    const viewChat = (listingId : number) => {
        navigate('/chats',{state : {
            type : ChatFactoryTypes.LISTING_CHATS,
            data : {
                listing_id : listingId
            }
        }});
    }

    const repost = (listingId : number) => {
        repostListingApi(listingId).then((response) => {
            showSuccess(Intl,lang);
            setTimeout(() => {
                window.location.reload();
            }, 3000);
        }).catch(() => {
            showError(Intl,lang);
        })
    }

    const handleSharing = (link : string) => {
        if (navigator.share) {
            shareLogging({
                "Logging": {
                    "model": "Post",
                    "model_id": listing?.equivalent_post_id,
                    "share_to": "social",
                    "data": ShareLoggingScreens.MY_CV_Standard_Share_Standard
                }
            })
            const data = {links : {link}}
            shorten(data).then(response => {
                if(response.data && response.data.links && response.data.links.link){
                    const downloadApp   = TWITTER_SHARE_URL;
                    const text = (`${Intl.formatMessage({id : "checkMyListing"})} \n ${response.data.links.link} \n\n ${Intl.formatMessage({id : 'downloadApp'})} ${downloadApp}`)
                    navigator.share({
                        title: listing?.title,
                        text: text,
                        url: response.data.links.link,
                    })
                    .then(() => {
                    })
                    .catch((error) => {
                        console.error("Something went wrong", error);
                    });
                }else{
                    showError(Intl,lang);
                }
            }).catch(err => {
                showError(Intl,lang);
            })
        }
    };

    const handleClose = () => {
        setShowEditListingModal(false)
    }
    
    const getLabel = (cta : DynamicObject) => {
        let label = cta.label.replace("{{unread_messages}}",` (${10}) `)
        if(cta.cta == VIEW_ON_OPENSOOQ){
            label = getSiteCustomContent(Intl).viewOnSite
        }
        return label
    }

    const HtmlComponent = ({cta} : {cta : DynamicObject}) => {
        return <tr key={cta.cta} className="hoverPointer grayHoverBg" onClick={(e) => {takeAction(cta.cta)}}>
            <td className="label" >
                <div className="d-flex align-items-center">
                    <span className="svg-icon svg-icon-2 me-2">
                        <CheckCtasIcon cta={cta.cta}/>
                    </span>
                    <span className="ltrDir">{getLabel(cta)}</span>
                </div>
            </td>
        </tr>
    }
    
    return  <>
        <div className="card card-flush">
            {listing && <div className="card-header">
                <div className="card-title align-items-start flex-column">
                    <h2>{Intl.formatMessage({id : "cv.manageCvWidget.text1"})}</h2>
                </div>
            </div>}
            <div className="card-body pt-0">
                <div className="table-responsive">
                    <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5">
                        <tbody className="fw-semibold ">
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {!listing && <TrLoader/>}
                            {listing && listing.ctas && listing.ctas.map((cta : DynamicObject,index : number) => {
                                return (cta.cta == VIEW_CHATS && showChatInCountry()) ? <HtmlComponent key={index} cta={cta}/> : cta.cta != VIEW_CHATS ? <HtmlComponent key={index} cta={cta}/> : ''
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            {listing && listing.ctas && listing.ctas.find((cta :DynamicObject) => {return cta.cta == PROMOTE}) && <div className="card-footer py-6 px-9">
                <WantMoreViews pointOfSale={PointOfSales.PROMOTE_CV_STATISTICS_CV_WIDGET_4} showPromote={listing && listing.ctas.find((cta :DynamicObject) => {return cta.cta == PROMOTE})} listingId={listing.equivalent_post_id}/>
            </div>}
        </div>
        <MemberShipModal  show={showBundle}  type={type} handleClose={() => {setShowBundle(false)}} id={listing?.equivalent_post_id} pos={pos} cta={cta}/>
        <IframeModal show={showEditListingModal} id={listing?.equivalent_post_id} type={listingIntegrationActionType} handleClose={() => {handleClose()}} />
        {pickedListing && <CustomModal show={showCustomModal} handleClose={() => {setShowCustomModal(false)}} children={<><ShareCv dataComeFrom={ShareLoggingScreens.MY_CV_View_Share_Button} opened={showCustomModal} comeFrom={'myListingView'} listingUrl='' listing={pickedListing}/></>}/>}
        {listing && <DeactivateListModal reasons={{}} show={showDeactivateModal} id={listing?.equivalent_post_id} type="edit" handleClose={() => {setShowDeactivateModal(false)}}/>}
    </>
}