import React, {useCallback, useContext, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import initDraft from '../../../../../api/wallet/initDraft'
import {Widget} from './types/ResType'
import {AuthContext} from '../../../../i18n/AuthProvider'
import {toastError} from '../../../../../tostify/snackbarAlert'
import AmountDueMapper from './AmountDueMapper'
import continueDraft from '../../../../../api/wallet/continueDraft'
import AmountDueSkeleton from './components/AmountDueSkeleton'
import {isDeviceMobile} from '../../../../helpers/commonFunctions'
import {PointOfSales} from '../../../../helper/PointOfSales'

type Props = {
  show: boolean
  handleClose: () => void
}

const DEFAULT_SELECTED_LISTINGS: React.SetStateAction<number[]> = []

const AmountDueModal = ({show, handleClose}: Props) => {
  const lang = useLang()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [widgets, setWidgets] = useState<Widget[]>([])
  const {userIdentity} = useContext(AuthContext)

  const initiateDraft = async () => {
    try {
      setIsLoading(true)
      const body = {
        Payment: {modelId: userIdentity?.id, posSource: PointOfSales.MY_WALLET_AMOUNT_DUE},
        form: 'Overdue',
      }
      const res = await initDraft(body)
      if (res && res.data && res.data.widgets) setWidgets(res?.data?.widgets)
    } catch (error) {
      toastError('', lang)
      handleClose()
    } finally {
      setIsLoading(false)
    }
  }

  const contDraft = async (payload: any) => {
    if (isLoading) return
    try {
      setIsLoading(true)
      const body = {
        ...payload,
        Payment: {...payload?.Payment, posSource: PointOfSales.MY_WALLET_AMOUNT_DUE},
      }
      const res = await continueDraft(body)
      if (res && res.data && res.data.outcome === 'checkout' && res.data.payload?.redirect)
        window.location.href = res.data.payload.redirect
      else if (res && res.data && res.data.widgets) setWidgets(res?.data?.widgets ?? [])
      else handleClose()
    } catch (error) {
      toastError('', lang)
      handleClose()
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!show) return
    initiateDraft()
    return () => {
      setWidgets([])
    }
  }, [show])

  return (
    <>
      <Modal
        tabIndex={-1}
        aria-hidden='true'
        dialogClassName={`modal-dialog modal-dialog-centered modal-dialog-scrollable modal-l ${
          isDeviceMobile() ? 'modal-fullscreen' : ''
        }`}
        show={show}
        onHide={handleClose}
      >
        <div
          className={`modal-body w-100 d-flex flex-column ${
            isDeviceMobile() ? 'p-5 gap-10' : 'p-10 gap-5'
          }`}
        >
          {widgets.length === 0 && <AmountDueSkeleton handleClose={handleClose} />}
          {widgets.map((widget) => {
            return (
              <>
                <AmountDueMapper
                  widget={widget}
                  functions={{handleClose, contFunction: contDraft}}
                  isLoading={isLoading}
                />
              </>
            )
          })}
        </div>
      </Modal>
    </>
  )
}

export {AmountDueModal}
