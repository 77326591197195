import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import savedSearchSearchApi from '../../../api/searches/delete.savedSearch.api';
import recentSearchApi from '../../../api/searches/recentSearch.api';
import { useLang } from '../../../app/i18n/OpenSooqi18n';
import { EmptyRecentSearch } from '../../../app/layout/components/empty/EmptyRecentSearch';
import { CellLoader } from '../../../app/layout/components/opensooq/CellLoader';
import { LazyLoader } from '../../../app/layout/components/opensooq/LazyLoader';
import { SearchComponent } from '../../../app/layout/components/opensooq/SearchComponent';
import { logScreenLoad } from '../../../logging/helpers/commonLoggingFunctions';
import { showError } from '../../helper/SystemAlert';
import { isDeviceMobile } from '../../helpers/commonFunctions';
import { SearchesList } from './SearchesList';

export interface RecentSearchInterface {
    id                  :   number
    deeplink            :   string
    search_result_num   :   number
    category_image      :   string
    tags                :   string[]
    date                :   string
    term                :   string
    md5?                :   string
    enable_notification?:   number
}

export function RecentSearch() {
    const [searchData,setSearchData] = useState <RecentSearchInterface[]>([]); 
    const [list,setList] = useState <RecentSearchInterface[]>([]); 
    const [loader,serLoader] = useState <boolean>(true)
    const [searchString, setSearchString] = useState("")
    const [isLastPage,setIsLastPage] = useState(false)
    const [nextPage,setNextPage] = useState('')
    const Intl = useIntl();
    const lang = useLang();
    
    useEffect(() => {
        getSavedSearch()
    },[]);

    useEffect(()=>{
        logScreenLoad()
    }, [])

    const getSavedSearch = () => {
        recentSearchApi(nextPage).then(response => {
            const results = response.data.items;
            const links = response.data._links;
            setSearchData(prevAds=> {
                return [...prevAds, ...results];
            })
            setList(prevAds=> {
                return [...prevAds, ...results];
            })

            setNextPage(links.next?.href ?? '')

            if(links.next?.href == undefined){
                setNextPage('');
                setIsLastPage(true)
            }
            serLoader(false);
        }).catch(() => {
            showError(Intl,lang);
            serLoader(false);
            const results : any = [];
            setSearchData(prevAds=> {
                return [...prevAds, ...results];
            })
            setList(prevAds=> {
                return [...prevAds, ...results];
            })
            setIsLastPage(true)
        });
    }


    const setSearch = (e: any) => {
        if(e.target.value && e.target.value.length > 0){
            setIsLastPage(true);
        }else{
            setIsLastPage(false);
        }
        setSearchString(e.target.value)
    }

    useEffect(() => {
        const newList = list.filter(item => {
            if(item.term.toLowerCase().includes(searchString.toLowerCase())){
                return true
            }else{
                if(item.tags.toString().toLowerCase().includes(searchString.toLowerCase())){
                    return true;
                }
            }
        })
        setSearchData(newList)
    },[searchString])

    const paginate = () => {
        getSavedSearch();
    };

    const addToSavedSearch = (savedSearchMd5 : string, savedSearchId: number, index) => {   
        const data = {
            md5 : savedSearchMd5,
            status : searchData[index].enable_notification == 1 ? '2' : '0'
        }  
        savedSearchSearchApi(data).then(() => {
            let newSearchData = [...searchData];
            if(newSearchData[index].enable_notification == 1){
                newSearchData[index].enable_notification = 2;
            }else{
               newSearchData[index].enable_notification = 1;
            }
            setSearchData(newSearchData);
        }).catch(() => {
            showError(Intl,lang)
        });
    }
    return (<>{!isDeviceMobile()?  <div className="card">
            <div className="card-header">
            <SearchComponent dataId="recent-searches-search-box" action={setSearch}   placeholder={`${Intl.formatMessage({id: 'searchIn'})} ${Intl.formatMessage({id : "menu.listing.recentSearch"})}`} />
            </div>
            <div className="card-body">
                <div className="row g-5">
                    {loader && <>
                    <CellLoader />
                    </>}
                    {!loader && <div className="row g-5">
                        <LazyLoader list={<SearchesList dataId="recent-searches" handleUpdateSavedSearch={addToSavedSearch} recentSearch={true} searchData={searchData}/>} data={searchData} endMessage={<EmptyRecentSearch/>} loader={<CellLoader/>} paginate={paginate} isLastPage={isLastPage}/>
                    </div>}
                </div>
            </div>
        </div>: <div>
            <div>
                <SearchComponent dataId="recent-searches-search-box" action={setSearch} totalNumber={`${searchData.length}`}   placeholder={`${Intl.formatMessage({id: 'searchIn'})} ${Intl.formatMessage({id : "menu.listing.recentSearch"})}`} inputClassName="bg-white" />
            </div>
            <div>
                <div>
                    {loader && <><CellLoader/></>}
                    {!loader && <div className="row g-5">
                        <LazyLoader list={<SearchesList dataId="recent-searches" handleUpdateSavedSearch={addToSavedSearch} recentSearch={true} searchData={searchData}/>} data={searchData} endMessage={<EmptyRecentSearch/>} loader={<CellLoader/>} paginate={paginate} isLastPage={isLastPage}/>
                    </div>}
                </div>
            </div>
        </div>}
    </>)
}
