/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { toAbsoluteUrl } from '../../../helpers'
import { getContactUsByCountry, getSocialUrlsByCountry ,getCookies, getSiteCustomContent, isShobiddak, redirectionInterceptor, footerLinksMapper } from '../../../helpers/commonFunctions'
import { useLang } from '../../../i18n/OpenSooqi18n'
import {ILayout} from '../../core'
import { isMobileDevice } from '../../../assets/ts/_utils'

const Footer = () => {
  const lang = useLang();
  const Intl = useIntl();
  const country = getCookies().ecountry;
  const footerCountry = getContactUsByCountry(country || '');
  const footerSocialUrls = getSocialUrlsByCountry(country || '')
  const openSooqUrl =  (process.env.REACT_APP_OPENSOOQ_BASE ?? '').replace("{country}", country ?? '').replace("{lang}", lang ?? '');
  const content = getSiteCustomContent(Intl);
return (
<>
  <section className="">
    <div className="container text-center text-md-start mt-5">
      <div className="row mt-3">
        <div className="col-md-3 col-lg-5 col-xl-3 me-10 mb-4">
          <div className="fs-6 text-uppercase fw-bold mb-4">
            {content.siteName}
          </div>
          <p  >
            <span >&copy; {content.siteUrl} {new Date().getFullYear()}, {Intl.formatMessage({id : 'footer.rights'})}</span>
          </p>
          <div data-id="footer-icons-block" className={`d-flex gap-5 ${isMobileDevice() ? 'justify-content-center' : ''}`}>
            <a aria-label="Facebook" href={isShobiddak() ? "https://www.facebook.com/Shobiddak2008" :  footerSocialUrls.facebook.length > 0 ? footerSocialUrls.facebook : "https://twitter.com/OpenSooqJordan"} target="_blank" className=" link-secondary">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 15.0005C30 22.5765 24.3843 28.8396 17.089 29.856C16.4068 29.9506 15.7089 30 15.0005 30C14.1828 30 13.3798 29.9348 12.5978 29.8087C5.45493 28.6589 0 22.4661 0 15.0005C0 6.71618 6.71618 0 14.9995 0C23.2828 0 30 6.71618 30 15.0005Z" fill="#1877F2"/>
                <path d="M17.0864 12.0444V15.3121H21.1287L20.4886 19.7139H17.0864V29.8554C16.4042 29.95 15.7063 29.9994 14.9979 29.9994C14.1802 29.9994 13.3772 29.9343 12.5952 29.8081V19.7139H8.86719V15.3121H12.5952V11.3139C12.5952 8.83348 14.6059 6.82178 17.0874 6.82178V6.82388C17.0948 6.82388 17.1011 6.82178 17.1084 6.82178H21.1297V10.6287H18.5021C17.7212 10.6287 17.0874 11.2624 17.0874 12.0434L17.0864 12.0444Z" fill="white"/>
              </svg>
              {/* <img width="30" height="30" src={toAbsoluteUrl('/media/custom/facebook.svg')} alt="facebook" /> */}
            </a>
            <a aria-label="Twitter" href={ footerSocialUrls.twitter.length > 0 ? footerSocialUrls.twitter : "https://twitter.com/OpenSooqJordan"} target="_blank" className=" link-secondary">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M29.9979 15.0005C29.9979 22.5765 24.3824 28.8396 17.0873 29.856C16.4052 29.9506 15.7073 30 14.9989 30C14.1813 30 13.3783 29.9348 12.5963 29.8087C5.45474 28.6589 0 22.4661 0 15.0005C0 6.71618 6.71595 0 15 0C23.2841 0 30 6.71618 30 15.0005H29.9979Z" fill="#1C1C1B"/>
                <path d="M6.08216 6.61523L12.9999 15.8644L6.03906 23.3847H7.60612L13.7009 16.8009L18.6249 23.3847H23.9567L16.65 13.6152L23.1295 6.61523H21.5625L15.9501 12.6787L11.415 6.61523H6.0832H6.08216ZM8.38596 7.76928H10.8348L21.6508 22.2306H19.2019L8.38596 7.76928Z" fill="white"/>
              </svg>
              {/* <img width="26" height="26" src={toAbsoluteUrl('/media/custom/x_icon_sqaure.svg')} alt="twitter" /> */}
            </a>
            <a aria-label="Instagram" href={isShobiddak() ? "https://www.instagram.com/shobiddak.ps/" : footerSocialUrls.instagram.length > 0 ? footerSocialUrls.instagram : "https://www.instagram.com/opensooq.jordan/"} target="_blank" className=" link-secondary">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0342 0H14.9658C6.70044 0 0 6.70044 0 14.9658V15.0342C0 23.2996 6.70044 30 14.9658 30H15.0342C23.2996 30 30 23.2996 30 15.0342V14.9658C30 6.70044 23.2996 0 15.0342 0Z" fill="url(#paint0_linear_1145_416)"/>
                <path d="M19.6427 6.10352H10.3599C7.79531 6.10352 5.70898 8.18984 5.70898 10.7544V19.2468C5.70898 21.8114 7.79531 23.8977 10.3599 23.8977H19.6427C22.2073 23.8977 24.2936 21.8114 24.2936 19.2468V10.7544C24.2936 8.18984 22.2073 6.10352 19.6427 6.10352ZM7.34967 10.7544C7.34967 9.09479 8.70026 7.7442 10.3599 7.7442H19.6427C21.3023 7.7442 22.6529 9.09479 22.6529 10.7544V19.2468C22.6529 20.9064 21.3023 22.257 19.6427 22.257H10.3599C8.70026 22.257 7.34967 20.9064 7.34967 19.2468V10.7544Z" fill="white"/>
                <path d="M14.9999 19.3258C17.3848 19.3258 19.326 17.3856 19.326 14.9997C19.326 12.6138 17.3858 10.6736 14.9999 10.6736C12.6141 10.6736 10.6738 12.6138 10.6738 14.9997C10.6738 17.3856 12.6141 19.3258 14.9999 19.3258ZM14.9999 12.3153C16.4809 12.3153 17.6854 13.5198 17.6854 15.0007C17.6854 16.4817 16.4809 17.6862 14.9999 17.6862C13.519 17.6862 12.3145 16.4817 12.3145 15.0007C12.3145 13.5198 13.519 12.3153 14.9999 12.3153Z" fill="white"/>
                <path d="M19.7262 11.3732C20.3683 11.3732 20.8918 10.8506 20.8918 10.2072C20.8918 9.56378 20.3694 9.04126 19.7262 9.04126C19.0829 9.04126 18.5605 9.56378 18.5605 10.2072C18.5605 10.8506 19.0829 11.3732 19.7262 11.3732Z" fill="white"/>
                <defs>
                <linearGradient id="paint0_linear_1145_416" x1="4.38286" y1="25.6171" x2="25.6171" y2="4.38391" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FAAD4F"/>
                  <stop offset="0.35" stop-color="#DD2A7B"/>
                  <stop offset="0.62" stop-color="#9537B0"/>
                  <stop offset="1" stop-color="#515BD4"/>
                </linearGradient>
                </defs>
              </svg>
              {/* <img width="30" height="30" src={toAbsoluteUrl('/media/custom/instagram.svg')} alt="instagram" /> */}
            </a>
            <a aria-label="Youtube" href="https://www.youtube.com/opensooq" target="_blank" className=" link-secondary">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M30 15C30 6.71573 23.2843 0 15 0C6.71574 0 0 6.71573 0 15C0 23.2843 6.71574 30 15 30C23.2843 30 30 23.2843 30 15Z" fill="#FF0209"/>
                <path d="M21.3656 21.6436C20.9187 21.6921 20.4613 21.7005 20.0228 21.6994C16.6024 21.6963 13.1821 21.6931 9.76276 21.691C8.68659 21.691 7.5145 21.6562 6.68286 20.9722C5.74582 20.1995 5.55083 18.8599 5.47494 17.6477C5.36954 15.9866 5.3611 14.3201 5.44753 12.6579C5.49497 11.7451 5.57823 10.8102 5.97139 9.98382C6.25387 9.3904 6.72502 8.85706 7.32793 8.57668C8.02887 8.25099 8.76248 8.30896 9.51717 8.3079C11.3238 8.3058 13.1304 8.30474 14.937 8.30263C16.8153 8.30053 18.6947 8.29947 20.573 8.29736C21.4605 8.29736 22.4133 8.31528 23.1375 8.8286C24.0724 9.49053 24.3264 10.7501 24.4434 11.8895C24.6595 13.9881 24.6626 16.1088 24.4518 18.2074C24.3644 19.0728 24.2189 19.9877 23.6487 20.6444C23.0837 21.2957 22.2457 21.5466 21.3667 21.6425L21.3656 21.6436Z" fill="white"/>
                <path d="M18.0602 14.9995L12.9629 12.0566V17.9424L18.0602 14.9995Z" fill="#FF0209"/>
              </svg>
              {/* <img width="30" height="30" src={toAbsoluteUrl('/media/custom/youtube.svg')} alt="youtube" /> */}
            </a>
            <a aria-label="Linkedin" target="_blank" href="https://www.linkedin.com/company/opensooq/mycompany/" className=" link-secondary">
              <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.0342 0H14.9658C6.70045 0 0 6.70241 0 14.9702V15.0386C0 23.3064 6.70045 30.0088 14.9658 30.0088H15.0342C23.2996 30.0088 30 23.3064 30 15.0386V14.9702C30 6.70241 23.2996 0 15.0342 0Z" fill="#007EBB"/>
                <path d="M7.15423 9.97512C6.75903 9.6082 6.5625 9.15401 6.5625 8.61362C6.5625 8.07322 6.76008 7.59906 7.15423 7.23108C7.54942 6.86416 8.05813 6.68018 8.6814 6.68018C9.30467 6.68018 9.79341 6.86416 10.1876 7.23108C10.5827 7.59801 10.7793 8.05955 10.7793 8.61362C10.7793 9.16768 10.5817 9.6082 10.1876 9.97512C9.79236 10.342 9.291 10.526 8.6814 10.526C8.07179 10.526 7.54942 10.342 7.15423 9.97512ZM10.4472 12.0799V23.3294H6.89357V12.0799H10.4472Z" fill="#FEFFFC"/>
                <path d="M22.2766 13.1913C23.0512 14.0324 23.438 15.1868 23.438 16.6566V23.1308H20.0631V17.1129C20.0631 16.3717 19.8708 15.7955 19.4871 15.3855C19.1035 14.9755 18.5864 14.7694 17.939 14.7694C17.2915 14.7694 16.7744 14.9744 16.3907 15.3855C16.0071 15.7955 15.8148 16.3717 15.8148 17.1129V23.1308H12.4199V12.0485H15.8148V13.5183C16.1585 13.0284 16.622 12.6415 17.2043 12.3565C17.7866 12.0716 18.4414 11.9297 19.1697 11.9297C20.4667 11.9297 21.503 12.3502 22.2766 13.1913Z" fill="#FEFFFC"/>
              </svg>
              {/* <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M29 3H11C6.58172 3 3 6.58172 3 11V29C3 33.4183 6.58172 37 11 37H29C33.4183 37 37 33.4183 37 29V11C37 6.58172 33.4183 3 29 3Z" fill="#0277B4"></path><path fillRule="evenodd" clipRule="evenodd" d="M11.2173 14.4346C9.44046 14.4346 8 12.9942 8 11.2173C8 9.44046 9.44046 8 11.2173 8C12.9942 8 14.4346 9.44046 14.4346 11.2173C14.4346 12.9942 12.9943 14.4346 11.2173 14.4346ZM13.8667 30.9922C13.8667 31.4316 13.5105 31.7878 13.0711 31.7878H9.6845C9.2451 31.7878 8.88892 31.4316 8.88892 30.9922V16.7956C8.88892 16.3562 9.2451 16 9.6845 16H13.0711C13.5105 16 13.8667 16.3562 13.8667 16.7956V30.9922ZM30.7246 31.1373C31.1286 31.1373 31.4561 30.8098 31.4561 30.4058V23.5349L31.4561 23.4842C31.4563 20.4868 31.4567 15.1113 25.6703 15.1113C23.0425 15.1113 21.8083 16.0733 20.9778 17.3196V16.0811C20.9778 15.6771 20.6503 15.3495 20.2463 15.3495H16.7315C16.3275 15.3495 16 15.6771 16 16.0811V30.4058C16 30.8098 16.3275 31.1373 16.7315 31.1373H20.2463C20.6503 31.1373 20.9778 30.8098 20.9778 30.4058V22.7253C21.069 21.6934 21.5233 19.3938 23.763 19.3938C26.4377 19.3938 26.3847 22.2767 26.3624 23.491C26.3606 23.5876 26.359 23.6736 26.359 23.7468V30.4058C26.359 30.8098 26.6866 31.1373 27.0905 31.1373H30.7246Z" fill="white"></path></svg> */}
            </a>
        </div>
        </div>
        <div className="col-md-2 col-lg-3 col-xl-2 me-10 mb-4" data-id="footer-aboutOpensooq-block">
          <div className="fs-6 text-uppercase fw-bold mb-4">
              {content.aboutUsHeader}
          </div>
          <p>
            <a onClick={redirectionInterceptor} href={`${openSooqUrl}/site/about-us`} target='_blank' className='text-reset'>
              {content.aboutUsContent}
            </a>
          </p>
          <p>
            <a href={`${openSooqUrl}/${footerLinksMapper['our-products'][lang]}`} target="_blank" className="text-reset">
              {Intl.formatMessage({id : 'footer.aboutOpenSooq.ourProducts'})}
            </a>
          </p>
          <p>
            <a onClick={redirectionInterceptor} href={`${openSooqUrl}/${footerLinksMapper['site-map'][lang]}`} className="text-reset" target="_blank">
              {Intl.formatMessage({id : 'footer.aboutOpenSooq.sitemap'})}
            </a>
          </p>
          <p>
            <a onClick={redirectionInterceptor} href={`${process.env.REACT_APP_OPENSOOQ_WWW}${lang}?${footerLinksMapper['other-countries'][lang]}`} className="text-reset" target="_blank">
              {Intl.formatMessage({id : 'footer.aboutOpenSooq.otherCountries'})}
            </a>
          </p>
        </div>
        <div className="col-md-3 col-lg-3 col-xl-2  mb-4" data-id="footer-help-block">
          <div className="fs-6 text-uppercase fw-bold mb-4">
            {Intl.formatMessage({id : 'footer.support'})}
          </div>
          <p>
            <a onClick={redirectionInterceptor} href={`${openSooqUrl}/${footerLinksMapper['support'][lang]}`} target="_blank" className="text-reset">{Intl.formatMessage({id : 'footer.support.help'})}</a>
          </p>
          <p>
            <a onClick={redirectionInterceptor} href={`${openSooqUrl}/${footerLinksMapper['sales-representatives'][lang]}`} target="_blank" className="text-reset">{Intl.formatMessage({id : 'footer.support.salesTeam'})}</a>
          </p>
          <p>
            <a onClick={redirectionInterceptor} href={`${openSooqUrl}/${footerLinksMapper['term-of-use'][lang]}`} target="_blank" className="text-reset">{Intl.formatMessage({id : 'footer.support.termsOfUse'})}</a>
          </p>
          <p>
            <a onClick={redirectionInterceptor} href={`${openSooqUrl}/${footerLinksMapper['privacy-policy'][lang]}`} target="_blank" className="text-reset">{Intl.formatMessage({id : 'footer.support.privacyPolicy'})}</a>
          </p>
          <p>
            <a onClick={redirectionInterceptor} href={`${openSooqUrl}/${footerLinksMapper['safety-tips'][lang]}`} target="_blank" className="text-reset">{Intl.formatMessage({id : 'footer.support.safetyTips'})}</a>
          </p>
        </div>
        {/* <div className="col-md-4 col-lg-3 col-xl-3 mb-md-0 mb-4" data-id="footer-contactUs-block">
          <div className="fs-6 text-uppercase fw-bold mb-4">{Intl.formatMessage({id : 'footer.contact'})}</div>
          {footerCountry.sales && <p>
            <svg width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M11.987 0.333252C5.54698 0.333252 0.320312 5.55992 0.320312 11.9999C0.320312 18.4399 5.54698 23.6666 11.987 23.6666C18.427 23.6666 23.6536 18.4399 23.6536 11.9999C23.6536 5.55992 18.427 0.333252 11.987 0.333252ZM16.1986 7.72992C17.447 7.72992 18.4503 8.73325 18.4503 9.98159C18.4503 11.2299 17.447 12.2333 16.1986 12.2333C14.9503 12.2333 13.947 11.2299 13.947 9.98159C13.9353 8.73325 14.9503 7.72992 16.1986 7.72992ZM9.19865 5.88659C10.7153 5.88659 11.952 7.12325 11.952 8.63992C11.952 10.1566 10.7153 11.3933 9.19865 11.3933C7.68198 11.3933 6.44531 10.1566 6.44531 8.63992C6.44531 7.11159 7.67031 5.88659 9.19865 5.88659ZM9.19865 16.5383V20.9133C6.39865 20.0383 4.18198 17.8799 3.20198 15.1266C4.42698 13.8199 7.48365 13.1549 9.19865 13.1549C9.81698 13.1549 10.5986 13.2483 11.4153 13.4116C9.50198 14.4266 9.19865 15.7683 9.19865 16.5383ZM11.987 21.3333C11.672 21.3333 11.3686 21.3216 11.0653 21.2866V16.5383C11.0653 14.8816 14.4953 14.0533 16.1986 14.0533C17.447 14.0533 19.6053 14.5083 20.6786 15.3949C19.3136 18.8599 15.942 21.3333 11.987 21.3333Z"
                fill="#0179FF" />
            </svg>
            <a  className='ps-3 text-reset' onClick={redirectionInterceptor} href={`${openSooqUrl}/sales-representatives`} target="_blank">{Intl.formatMessage({id : 'footer.contactSales'})}</a>
          </p>}
          <p>
            <svg width="15" height="15"  viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.3359 0.666748H2.66927C1.38594 0.666748 0.347604 1.71675 0.347604 3.00008L0.335938 17.0001C0.335938 18.2834 1.38594 19.3334 2.66927 19.3334H21.3359C22.6193 19.3334 23.6693 18.2834 23.6693 17.0001V3.00008C23.6693 1.71675 22.6193 0.666748 21.3359 0.666748ZM21.3359 5.33341L12.0026 11.1667L2.66927 5.33341V3.00008L12.0026 8.83341L21.3359 3.00008V5.33341Z"
                fill="#0179FF" />
            </svg>
            <span className='ps-3'>{(isShobiddak()) ? 'info@shobiddak.com' : `${footerCountry.countryCode}@opensooq.com`}</span>
          </p>
          <p>
            <svg  width="15" height="15" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20.224 3.72825C18.0423 1.53492 15.1373 0.333252 12.0456 0.333252C5.67562 0.333252 0.483959 5.52492 0.483959 11.8949C0.483959 13.9366 1.02063 15.9199 2.02396 17.6699L0.390625 23.6666L6.51563 22.0566C8.20729 22.9783 10.109 23.4683 12.0456 23.4683C18.4156 23.4683 23.6073 18.2766 23.6073 11.9066C23.6073 8.81492 22.4056 5.90992 20.224 3.72825ZM12.0456 21.5083C10.319 21.5083 8.62729 21.0416 7.14563 20.1666L6.79563 19.9566L3.15563 20.9133L4.12396 17.3666L3.89063 17.0049C2.93396 15.4766 2.42063 13.7033 2.42063 11.8949C2.42063 6.59825 6.73729 2.28159 12.034 2.28159C14.6006 2.28159 17.0156 3.28492 18.824 5.10492C20.644 6.92492 21.6356 9.33992 21.6356 11.9066C21.659 17.2033 17.3423 21.5083 12.0456 21.5083ZM17.319 14.3216C17.0273 14.1816 15.604 13.4816 15.3473 13.3766C15.079 13.2833 14.8923 13.2366 14.694 13.5166C14.4956 13.8083 13.9473 14.4616 13.784 14.6483C13.6206 14.8466 13.4456 14.8699 13.154 14.7183C12.8623 14.5783 11.929 14.2633 10.8323 13.2833C9.96896 12.5133 9.39729 11.5683 9.22229 11.2766C9.05896 10.9849 9.19896 10.8333 9.35063 10.6816C9.47896 10.5533 9.64229 10.3433 9.78229 10.1799C9.92229 10.0166 9.98063 9.88825 10.074 9.70159C10.1673 9.50325 10.1206 9.33992 10.0506 9.19992C9.98063 9.05992 9.39729 7.63659 9.16396 7.05325C8.93062 6.49325 8.68563 6.56325 8.51063 6.55159C8.33563 6.55159 8.14896 6.55159 7.95062 6.55159C7.75229 6.55159 7.44896 6.62159 7.18062 6.91325C6.92396 7.20492 6.17729 7.90492 6.17729 9.32825C6.17729 10.7516 7.21563 12.1283 7.35563 12.3149C7.49563 12.5133 9.39729 15.4299 12.2906 16.6783C12.979 16.9816 13.5156 17.1566 13.9356 17.2849C14.624 17.5066 15.254 17.4716 15.7556 17.4016C16.3156 17.3199 17.4706 16.7016 17.704 16.0249C17.949 15.3483 17.949 14.7766 17.8673 14.6483C17.7856 14.5199 17.6106 14.4616 17.319 14.3216Z"
                fill="#25D366" />
            </svg>
            <a className=" text-reset" href={`${process.env.REACT_APP_WHATSAPP_CONTACT}${footerCountry.whatsapp}&text&type=phone_number&app_absent=0`}><span dir={"ltr"} className="ltrDir ps-3" style={{display:"inline-block"}}>{Intl.formatMessage({id : "footer.whatsapp"})}</span></a>
          </p>
          {footerCountry.internationalWhatsNumber != '' && <p>
            <svg width="15" height="15" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M20.3333 15.0833C18.875 15.0833 17.475 14.85 16.1683 14.4183C15.76 14.29 15.305 14.3833 14.9783 14.6983L12.4117 17.265C9.11 15.585 6.40333 12.89 4.72333 9.57667L7.29 6.99833C7.61667 6.695 7.71 6.24 7.58167 5.83167C7.15 4.525 6.91667 3.125 6.91667 1.66667C6.91667 1.025 6.39167 0.5 5.75 0.5H1.66667C1.025 0.5 0.5 1.025 0.5 1.66667C0.5 12.6217 9.37833 21.5 20.3333 21.5C20.975 21.5 21.5 20.975 21.5 20.3333V16.25C21.5 15.6083 20.975 15.0833 20.3333 15.0833ZM19.1667 11H21.5C21.5 5.20167 16.7983 0.5 11 0.5V2.83333C15.515 2.83333 19.1667 6.485 19.1667 11ZM14.5 11H16.8333C16.8333 7.78 14.22 5.16667 11 5.16667V7.5C12.9367 7.5 14.5 9.06333 14.5 11Z"
                fill="#0179FF" />
            </svg>
            <span dir={"ltr"} className="ltrDir ps-3" style={{display:"inline-block"}}>{footerCountry.internationalWhatsNumber}</span>
          </p>}
        </div> */}
      </div>
    </div>
  </section>
</>
)
}

export {Footer}