import { memo } from "react";

const CloseButton = memo(({ handleClose }: { handleClose: () => void }) => {
  return (
    <svg
      className="vas"
      width="76"
      height="76"
      viewBox="0 0 76 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        filter="url(#filter0_d_4815_3176)"
        className="pointerCursor"
        onClick={handleClose}
      >
        <circle cx="38" cy="38" r="18" fill="white" />
      </g>
      <g clip-path="url(#clip0_4815_3176)">
        <path
          d="M46 31.6114L44.3886 30L38 36.3886L31.6114 30L30 31.6114L36.3886 38L30 44.3886L31.6114 46L38 39.6114L44.3886 46L46 44.3886L39.6114 38L46 31.6114Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4815_3176"
          x="0"
          y="0"
          width="76"
          height="76"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4815_3176"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4815_3176"
            result="shape"
          />
        </filter>
        <clipPath id="clip0_4815_3176">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(27 27)"
          />
        </clipPath>
      </defs>
    </svg>
  );
});

export default CloseButton;
