import React, {useState, useRef, useEffect} from 'react'
import {generateStyleClasses} from '../helper'

const CustomDropdown = ({handlePostAction, action, listingId}) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className='more-actions-dropdown' key={listingId} ref={dropdownRef}>
      <div
        className='more-actions-dropdown-toggle gap-2'
        onClick={handleToggle}
        data-id={`my-listing-actions-${listingId}-${action?.actionType}`}
        style={{color: action.textColor}}
      >
        <img src={action.icon} width={18} height={18} alt='' />
        <span className={`more-actions-dropdown-text ${generateStyleClasses(action.style)}`}>
          {action?.text ?? ''}
        </span>
      </div>
      {isOpen && (
        <div className='more-actions-dropdown-menu'>
          {action.items.map((item, idx) => (
            <div
              key={idx}
              className='more-actions-dropdown-item gap-2'
              data-id={`my-listing-actions-${listingId}-${item?.actionType}`}
              onClick={() => handlePostAction(item.actionType, true)}
            >
              <img src={item.icon} width={18} height={18} alt='' />
              <span
                className={`more-actions-dropdown-item-text ${generateStyleClasses(item.style)}`}
              >
                {item.text}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default CustomDropdown
