import { useIntl } from "react-intl";
import DynamicObject from "../../../models/dynamic-object";
import { generateOpenSooqRedirectUrl, getCookies, getNoImageLogo, redirectionInterceptor } from "../../helpers/commonFunctions";
import { useLang } from "../../i18n/OpenSooqi18n";
import OpenSooqIcons from "../common/OpenSooqIcons";
import { JOB_POSTER_TYPE, JOB_SEEKER_TYPE } from "./my-listings/helper";

type Props = {
    listing : DynamicObject
}
const ListingViewCell = ({listing}: Props) => {
    const lang = useLang();
    const Intl = useIntl();
    const redirectToPost = (e: any,listing : DynamicObject) => {
        redirectionInterceptor();
        if(e.target.classList.contains('bigContainer')){
            let link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang) + '/search/' + listing?.listing_id;
            if(listing.listing_type == JOB_POSTER_TYPE || listing.listing_type == JOB_SEEKER_TYPE){
                link =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang) + '/job-posters/' + listing?.listing_id;
            }
            window.open(link, "_blank");
        }
    }

    const getListingImage = (listing : DynamicObject) => {
        let image = getNoImageLogo(lang);
        if(listing.medias_count.images > 0){
            image = listing.main_image_uri;
        }
        return image;
    }

    return (
        <div key="postCell" className={`mb-32  p-3 position-relative  bigContainer  mt-1`} onClick={(e) => {redirectToPost(e,listing)}}>
            <div  className="flex flexNoWrap  blackColor radius-8 bigContainer  ripple boxShadow2 relative" >
                <div className={`sc-265a2526-1 jRUVEn postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px ixKSmm`} >
                    <img src={getListingImage(listing)} width="272" height="204"  className="width-100 bigContainer customImg height-100"  alt={listing.title} loading="eager"/>
                </div>
                <div className="sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn">
                    <div className="flex flexSpaceBetween">
                        <span className="fs-2 fw-bold  breakWord bigContainer overflowHidden  ">{listing.title}</span>
                    </div>
                    <div className="fs-16 bigContainer  fs-lg-3  fs-sm-4">{listing.sub_title}</div>
                    <div className="flex alignItems bigContainer ">
                        <div className=" fs-16 category fs-lg-3  fs-sm-4">{listing.category + ` ${Intl.formatMessage({id : "in"})} ` + listing.city}</div>
                    </div>
                </div>
            </div>
            <div className="flex alignItems mt-3">
                <div className="pe-1  bd-highlight">
                    <div className="sc-265a2526-2 iRqeKu numOfCont flex bigContainer  alignItems radius-8 whiteColor fs-13 gap-5 " >
                        <span className="flex alignItems radius-8 bigContainer">
                            <svg viewBox="0 0 12 12" width="12" height="12" className="me-2 bigContainer" data-name="iconCamera2">
                                <path fill="#fff" d="M12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667ZM3.66667 7L5.33333 9.00667L7.66667 6L10.6667 10H1.33333L3.66667 7Z"></path>
                            </svg>{listing.medias_count.images}
                        </span>
                    </div>
                </div>
                <div className="pe-1  bd-highlight">
                    <div className="sc-265a2526-2 iRqeKu numOfCont flex bigContainer  alignItems radius-8 whiteColor fs-13 gap-5 " >
                        <span className="flex alignItems  radius-8 bigContainer ">
                            <OpenSooqIcons className="me-1 fw-semibold d-block fs-7 text-white" width={20} height={15} icon={'video'} /> {listing.medias_count.videos}
                        </span>
                    </div>
                </div>
                <div className="pe-1  bd-highlight">
                    <div className="sc-265a2526-2 iRqeKu numOfCont flex bigContainer  alignItems radius-8 whiteColor fs-13 gap-5 " >
                        <span className="flex alignItems radius-8 bigContainer">
                            <OpenSooqIcons className="me-1 fw-semibold d-block fs-7 text-white" width={20} height={15} icon={'360'} /> {listing.medias_count.count360}
                        </span>
                    </div>
                </div>
                <div className="ms-1 bd-highlight">
                    <div className="d-flex justify-content-start">
                        {listing.vas_icons.map((icon : any,index : number) => { 
                            return <OpenSooqIcons key={index} className="me-1 fw-semibold d-block fs-7" width={25} height={25} icon={icon.toLowerCase()} />
                        })}
                    </div>
                </div>
                <div className="postPrice ms-auto bold alignSelfCenter fs-3">{listing.price != 0 && <span className="fw-bolder" dir="rtl">{listing.price.toLocaleString() + ' ' + listing.price_currency } </span>}</div>
            </div>
        </div>
    );
};
export { ListingViewCell };
