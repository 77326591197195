interface IProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  stroke?: string;
  strokeWidth?: string;
  dataFlip?: string;
}

const IconRightArrowHead = ({
  width = "24",
  height = "24",
  className = "",
  fill = "#000",
}: IProps) => {
  return (
    <svg
      width={height}
      height={width}
      className={className ? className : null}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-name="IconRightArrowHead"
      data-flip={true}
    >
      <path
        d="M15.9757 11.1744L9.89504 5.79836C9.77246 5.68939 9.62671 5.60291 9.46618 5.54389C9.30565 5.48488 9.13351 5.4545 8.95965 5.4545C8.7858 5.4545 8.61365 5.48488 8.45313 5.54389C8.2926 5.60291 8.14685 5.68939 8.02427 5.79836C7.9013 5.90688 7.80374 6.03576 7.73718 6.17763C7.67061 6.31951 7.63635 6.47159 7.63635 6.62518C7.63635 6.77877 7.67061 6.93085 7.73718 7.07273C7.80374 7.2146 7.9013 7.34348 8.02427 7.452L13.1696 12.0012L8.02427 16.5493C7.90143 16.6579 7.80399 16.7868 7.73751 16.9286C7.67104 17.0705 7.63682 17.2226 7.63682 17.3761C7.63682 17.5297 7.67104 17.6817 7.73751 17.8236C7.80399 17.9655 7.90143 18.0944 8.02427 18.2029C8.1471 18.3115 8.29293 18.3976 8.45343 18.4564C8.61392 18.5152 8.78594 18.5454 8.95965 18.5454C9.13337 18.5454 9.30539 18.5152 9.46588 18.4564C9.62637 18.3976 9.7722 18.3115 9.89504 18.2029L15.9757 12.828C16.0987 12.7195 16.1962 12.5907 16.2628 12.4488C16.3294 12.3069 16.3636 12.1548 16.3636 12.0012C16.3636 11.8476 16.3294 11.6956 16.2628 11.5537C16.1962 11.4118 16.0987 11.2829 15.9757 11.1744Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconRightArrowHead;
