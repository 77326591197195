import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';

import Auth from 'opensooq-sso';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer } from 'react-toastify';
import { getCookies, getOriginDomain, hasNoPassword } from './app/helpers/commonFunctions';
import { AppRoutes } from './app/routing/AppRoutes';
import { OpenSooqI18nProvider } from './app/i18n/OpenSooqi18n';

const queryClient = new QueryClient();
const container = document.getElementById('root');
const isMobile = window.innerWidth <= 900;

const initSSO = async () => {
  if (!getCookies().ecountry){
    if (process.env.REACT_APP_ENV !== 'localhost') {
      window.open(process.env.REACT_APP_PUBLIC_URL.replace("my","www") + '/','_self')
    }
    throw new Error("country not found");
  }else{
    const newAuth = Auth.getInstance();
    const isLoggedIn = await newAuth.init(
      getOriginDomain(),
      'sso',
      getCookies().NEXT_LOCALE,
      getCookies().ecountry,
      getCookies().source,
      getCookies().device_uuid,
      getCookies().cityId
    );
  
    if (isLoggedIn.state === 'LoggedOut' || hasNoPassword()) {
      if (process.env.REACT_APP_ENV !== 'localhost') {
        await Auth.getInstance().authenticate(window.location.href, true);
      }
    }

    // Determine the selected language here
    const selectedLanguage = getCookies().NEXT_LOCALE || 'en'; // Default to 'en' if not available

    // Dynamically import CSS based on the selected language
    if (selectedLanguage === 'ar') {
      await import('./assets/css/style.rtl.min.css')
    } else {
      await import('./assets/css/style.ltr.min.css');
    }

    const favicon = document.querySelector('link[rel="icon"]') as HTMLLinkElement;
    if (favicon) {
      favicon.href = '/favicon.ico';
      if(window.location.hostname.split('.')[window.location.hostname.split('.').length-2]+'.'+window.location.hostname.split('.')[window.location.hostname.split('.').length-1] === 'shobiddak.com'){
        favicon.href = 'https://opensooqui-new.s3.amazonaws.com/os_web/desktop/shobiddak_favicon.ico';
      }
    }
    
    var toSetLang = 'ar'
    if(getCookies().NEXT_LOCALE){
        toSetLang = getCookies().NEXT_LOCALE
    }

    let selectedLang = {
      "selectedLang" : toSetLang
    }

    localStorage.setItem("i18nConfig",JSON.stringify(selectedLang))
  }
};

initSSO().then(() => {
  if (container) {
    createRoot(container).render(
      <QueryClientProvider client={queryClient}>
        <OpenSooqI18nProvider>
          <ToastContainer style={{ width: isMobile ? '100%' : '1200px' }} autoClose={false} draggableDirection="y" draggablePercent={60} />
          <AppRoutes />
        </OpenSooqI18nProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    );
  }
}).catch(() => {
  if (process.env.REACT_APP_ENV !== 'localhost') {
    window.location.href = process.env.REACT_APP_PUBLIC_URL.replace("my","www") + '/'
  }
  if (container) {
    createRoot(container).render(
     <></>
    );
  }
});