import { useIntl } from "react-intl";
type Props = {
    docs : any,
}
const DocumentNotice = ({docs} : Props) => {
    const Intl = useIntl();
    return <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed mb-9 p-6'>
        <i className='fa-regular fa-lightbulb fs-1 mt-2'></i>
        <div className='d-flex flex-stack flex-grow-1'>
            <div className='fw-semibold'>
                <div className='fs-6 text-gray-700'>
                    <ul>
                        <li>{Intl.formatMessage({id: 'noticeText3'})}</li>
                        <li>{Intl.formatMessage({id: 'noticeText4'})}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
}

export default DocumentNotice