import { PaymentWidget } from 'package-bundle'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ActionDimensions } from '../../../logging/dimensions/ActionDimensions'
import { KTSVG } from '../../helpers'
import { defaultOptions, generateToken, getCookies, setCookie } from '../../helpers/commonFunctions'
import { useLang } from '../../i18n/OpenSooqi18n'

type Props = {
  show: boolean
  handleClose: () => void
  type : string[]
  id?: number
  pos : string
  backUrl?: string
  sku?: string
}

const BundleModal = ({show,handleClose,type,id,pos,backUrl,sku}: Props) => {
    const lang = useLang();
    const cookiesData = getCookies()
    const [token, setToken] = useState();
    const loggingConfig = {
        version: `${process.env.REACT_APP_PUBLIC_VERSION}`, 
        loggingEnv : {
            url : process.env.REACT_APP_NEXT_PUBLIC_LOGGING_URL_CLIENT,
            token : ''
        },
        sessionFunnelSource: ActionDimensions.getInstance().currScreen
    }

    useEffect(() => {
        getToken();
    },[])

    useEffect(()=>{
        if(show){
            const actionDimensions = ActionDimensions.getInstance();
            actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
            actionDimensions.sessionFunnelPointOfEntry = pos;
        }
    }, [show])
    
    const getToken = async() => {
        const generatedToken = await generateToken();
        setToken(generatedToken);
    }

    const closeModal = () => {
        handleClose()
        ActionDimensions.getInstance().prevScreen = 'order_packages'
        setCookie('prevScreen', ActionDimensions.getInstance().currScreen, {...defaultOptions, expires: 10}) 
    }
    
    const isMobile = window.innerWidth <= 800
    return <>
        <Modal id='iframe'tabIndex={-1} aria-hidden='true'   dialogClassName={`modal-dialog modal-dialog-centered ${(isMobile) ? 'modal-fullscreen' : 'modal-lg'}`} show={show}  style={{overflowY:"scroll"}}onHide={closeModal}>
            <div className='modal-header p-5 border-bottom-0'>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeModal}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-content px-5 overflow-scroll'>
                {token && <PaymentWidget sku={sku && sku} loggingConfig={loggingConfig} pos_source={pos} referral_url={backUrl} products={type ?? []} modelId={id ?? 0} token={token ?? ''} language={lang} domain={window.location.hostname} api={process.env.REACT_APP_OPENSOOQ_API_URL_BASE + 'v2.1' ?? ''}  source={'desktop'} countryAbbreviation={cookiesData.ecountry ?? 'jo'}/>}
            </div>
        </Modal>
    </>
}

export { BundleModal }
