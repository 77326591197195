import React, {useEffect, useState} from 'react'
import {AddFieldType, ListingCPI, RemoveFieldType} from '../../../../interfaces/MyListings'
import useFiltersStore from '../../my-listings/FilterStore'
interface ListingCpProps {
  cp: ListingCPI
  addField: AddFieldType
  removeField: RemoveFieldType
  reset: boolean
}
const ListingCp: React.FC<ListingCpProps> = ({cp, addField, removeField, reset}) => {
  const {label, type, fieldId, value} = cp
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const {globalFiltersDisable} = useFiltersStore()

  useEffect(() => {
    if (reset) setIsSelected(false)
  }, [reset])

  const clickHandler = () => {
    setIsSelected((oldValue: boolean) => {
      if (oldValue) removeField(fieldId, {value: value}, 'SINGLE')
      else addField(fieldId, {value: value, label: label}, 'SINGLE', false)
      return !oldValue
    })
  }

  return (
    <div
      data-id={fieldId}
      key={`listing_cp_${fieldId}`}
      className={`d-flex align-items-center hoverPointer text-center fs-6 text-dark user-select-none ${
        globalFiltersDisable ? 'hoverNotAllowed' : ''
      }`}
      onClick={() => {
        if (!globalFiltersDisable) clickHandler()
      }}
    >
      <span
        className={`fs-2 mx-1 ${isSelected ? 'fas fa-check-square text-primary' : 'far fa-square'} ${
          globalFiltersDisable ? 'hoverNotAllowed' : ''
        }`}
        style={{
          color: isSelected ? '#0179FF' : '#ccc',
        }}
      />
      <label className={`pt-1 hoverPointer ${
        globalFiltersDisable ? 'hoverNotAllowed text-muted' : ''
      }`}>{label}</label>
    </div>
  )
}

export default ListingCp
