import React from 'react'
import {useLocation} from 'react-router'
import {checkIsActive, WithChildren} from '../../../../helpers'
import { Navbar } from 'react-bootstrap'

type Props = {
  title: string
  to: string
  comingFrom ?: string
  newIcon ?: any
  listOptions ? : {expandList: boolean, arrow: string}
  handleMenusToggle ?: (e: any, title: string)=>void
  dataId: string
}

const SideMenuList: React.FC<Props & WithChildren> = ({
  children,
  title,
  to,
  comingFrom,
  listOptions,
  handleMenusToggle,
  dataId,
  newIcon
}) => {
  const {pathname} = useLocation()
  pathname.indexOf('1');
  pathname.toLowerCase();
  const isActive = checkIsActive(pathname, to)

  // use ${title}-${comingFrom} in the literal template to prevent the side menu and the head menu from collapsing-expanding together 
  // use ${title} in the literal template to make the side menu and the head menu collapse-expand together
  return (<div onClick={(e)=> handleMenusToggle(e, title)}><div className="my-2 menu-accordion toggle-dropDown" data-bs-toggle="collapse" data-bs-target={`#${title}-${comingFrom}`} role="button" aria-expanded="false" aria-controls={`${title}-${comingFrom}`}>
        <div className='d-flex align-items-center justify-content-start px-3 toggle-dropDown' data-id={`${dataId}${comingFrom?'-'+comingFrom: ''}`}>
            <div className='d-flex gap-2 flex-grow-1 toggle-dropDown'>
              {newIcon && (<span className='toggle-dropDown'>{newIcon}</span>)}
              <span className={`fs-3 ${(comingFrom == "headMenu") ? 'fw-bold' : 'fw-normal'} toggle-dropDown`}>{title}</span>
            </div>
            <i className={`d-block fa-solid fa-angle-${listOptions?.arrow} toggle-dropDown`}></i>
        </div>
      </div>
      <div className={`${listOptions?.expandList ? "collapse show": "collapse hide"}  '${isActive ? "menu-active-bg": ''} menu-item toggle-dropDown`} id={`${title}-${comingFrom}`}>
        <Navbar.Toggle className='w-100'>
          {children}
        </Navbar.Toggle>
      </div>
    </div>
  )
}

export {SideMenuList}