import { PopupChat, useChatFunctions } from 'opensooq-chat';
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import getAdApi from "../../api/listing/getAd.api";
import { logScreenLoad } from "../../logging/helpers/commonLoggingFunctions";
import DynamicObject from "../../models/dynamic-object";
import { showChatInCountry } from "../helpers/commonFunctions";
import './ChatPopup.css';
import { Modal } from 'react-bootstrap';
import { KTSVG } from '../helpers';

interface ChatOpenPageProps {
    children?: React.ReactNode;
}

const DEFAULT_ROOM = undefined
const ChatOpenPage = (props : ChatOpenPageProps): JSX.Element => {
    const [room, setRoom] = useState<any>(DEFAULT_ROOM)
    const {createRoom} = useChatFunctions()
    const roomId : string = new URLSearchParams(window.location.search).get('room_id') ?? '';
    const navigate = useNavigate();

    const closeModalHandler = () => {
        setRoom(DEFAULT_ROOM)
        navigate('/chats');
    }

    const createRoomHandler = (data: DynamicObject) => {
        createRoom(data as any).then((room) => {
            setRoom(room)
        })
    }
    useEffect(() => {
        if(!showChatInCountry()){
            navigate('/');
        }
        const [postId, ownerId, userId] = roomId.split("-", 3)
        getAdApi(parseInt(postId)).then((response) => {
            const listing = response.data
            const data = {
                postId: listing.id,
                ownerId: listing.members_id,
                userId: userId,
                post_country: listing.country_abbr,
                post_img: listing.first_image_thumb,
                post_title: listing.title,
                room: {
                    avatar: listing.searchMember.profile_picture,
                    name: listing.searchMember.full_name
                }
            }
            createRoomHandler(data)
        })
        logScreenLoad()
    }, [])


    return (
        // <div style={{
        //     height: "100vh",
        // }}>
        //     {room && <PopupChat newChat={room} onClear={closeModalHandler}/> }
        // </div>
        <Modal id='iframe' tabIndex={-1} aria-hidden='true'
                    dialogClassName='modal-dialog modal-dialog-centered modal-fullscreen' show={room !== DEFAULT_ROOM}>
                 <div className='modal-header p-0 m-0'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeModalHandler}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg'/>
                    </div>
                </div>
                <div className='modal-body popup w-100 p-0 m-0' style={{overflowY: "hidden"}}>
                {room && <PopupChat newChat={room} onClear={closeModalHandler}/> }
            </div>
        </Modal>
    )
}

export default ChatOpenPage