import {useState, useEffect} from 'react'
import {AddFieldType, RemoveFieldType, TextFieldI} from '../../../../interfaces/MyListings'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import {styleHelper} from '../../my-listings/helper'
import { TextFieldMobileI } from '../../../../interfaces/MyListingsMobile'
import useFiltersStore from '../../my-listings/FilterStore'
import { useDebounce } from '../../../../helpers'


const ListingSearchFilterMobile = ({
  searchField,
  addField,
  removeField,
  value
}: {
  searchField: TextFieldMobileI
  addField: AddFieldType
  removeField: RemoveFieldType
  value: string
}) => {
  const {placeHolder, fieldId, type} = searchField
  const [search, setSearch] = useState<string>('')

  const debouncedSearch = useDebounce(search, 200) // 200ms debounce delay
  const {globalFiltersDisable} = useFiltersStore()

  const searchHandler = (e: any) => {
    setSearch(e.target.value)
  }

  useEffect(() => {
    if (debouncedSearch !== '') {
      addField(fieldId, {value: debouncedSearch}, 'SINGLE')
    } else {
      removeField(fieldId, {value: debouncedSearch}, 'SINGLE')
    }
  }, [debouncedSearch, fieldId])

  useEffect(() => {
    setSearch(value)
  }, [value])

  return (
    <div className='my-1 p-1 w-100 fs-2 text-dark'>
      <input
        type='text'
        onChange={searchHandler}
        className='rounded-3 border w-100 p-4'
        value={search}
        placeholder={placeHolder}
        readOnly={globalFiltersDisable}
      />
    </div>
    )
}

export default ListingSearchFilterMobile