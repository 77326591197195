import {create} from 'zustand'

type State = {
  globalFiltersDisable: boolean
}

type Actions = {
  setGlobalFiltersDisable: (globalFiltersDisable: boolean) => void
}

const useFiltersStore = create<State & Actions>((set, get, state) => ({
  globalFiltersDisable: true,
  setGlobalFiltersDisable: (globalFiltersDisable) => set({globalFiltersDisable}),
}))

export default useFiltersStore
