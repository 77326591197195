import {useEffect, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import getSalesLeadApi from '../../../../api/getSalesLead.api'
import {getCookies, redirectionInterceptor, deepLinkHandler} from '../../../helpers/commonFunctions'
import {useLang} from '../../../i18n/OpenSooqi18n'
import {SalesLeadInterFace} from '../../../interfaces/SalesLead'

interface Iprops {
  serviceName: string
  subCatReportingName: string
  changeHeaderHandler: (header: string) => void
  changeSubHeaderHandler: (subHeader: string) => void
  learnMore: boolean
  handleClose?: () => void
  toggleLearnMore: () => void
}

type UserInfo = {
  full_name: string
  phone_number: string
  email: string
  city_id: string
  picker: number
  type: string
  reporting_name: string
  landing_page_title: 'Payment_VIP'
}

const BundlePromotionVIPCredits = ({
  subCatReportingName,
  changeHeaderHandler,
  changeSubHeaderHandler,
  serviceName,
  learnMore,
  toggleLearnMore,
  handleClose,
}: Iprops) => {
  const [userInfo, setUserInfo] = useState<UserInfo>({
    full_name: '',
    phone_number: '',
    email: '',
    city_id: '',
    picker: undefined,
    type: 'vip',
    reporting_name: subCatReportingName,
    landing_page_title: 'Payment_VIP',
  })

  const [salesLead, setSalesLead] = useState<SalesLeadInterFace>(undefined)
  const lang = useLang()
  const country = getCookies().ecountry
  const openSooqUrl = (process.env.REACT_APP_OPENSOOQ_BASE ?? '')
    .replace('{country}', country ?? '')
    .replace('{lang}', lang ?? '')

  useEffect(() => {
    getSalesLeadApi('vip').then((response) => {
      setSalesLead(response.data)
    })
  }, [])

  useEffect(() => {
    if (!salesLead) return
    setUserInfo({
      ...userInfo,
      full_name: salesLead.content.inputs.full_name.value,
      phone_number: salesLead.content.inputs.phone_number.value,
      email: salesLead.content.inputs.email.value,
    })
    changeHeaderHandler(salesLead.headerContent.header)
    changeSubHeaderHandler('')
  }, [salesLead])

  const handleContactUs = (deeplink) => {
    redirectionInterceptor()
    deepLinkHandler(deeplink)
  }

  return (
    <>
      {!learnMore && (
        <div className='d-flex flex-column justify-content-start align-items-start gap-6 '>
          {salesLead && (
            <p
              onClick={toggleLearnMore}
              className='text-primary text-decoration-underline fs-4 hoverPointer'
            >
              {salesLead.headerContent.link}
            </p>
          )}
          {!salesLead && <Skeleton className='h-20px w-150px' />}
          {salesLead && <span className='h1'>{salesLead.content.title}</span>}
          {!salesLead && <Skeleton className='h-30px w-300px' />}
          {salesLead && (
            <ul>
              {salesLead.content.description.map((desc, index) => {
                return (
                  <li key={index} className='fs-4 text-muted'>
                    {desc}
                  </li>
                )
              })}
            </ul>
          )}
          <div className='d-flex gap-5 w-100 justify-content-center'>
            {salesLead && salesLead.contacts?.contact_us && (
              <button
                onClick={()=>{handleContactUs(salesLead.contacts?.contact_us?.deeplink)}}
                className='btn btn-lg d-flex gap-2 mb-1 border-radius text-18 justify-content-center align-items-center fw-bold border w-90'
              >
                <img
                  src='/media/custom/phone-blue.svg'
                  alt='Phone Icon'
                  className='svg-icon svg-icon-2 me-2'
                  width='24'
                  height='24'
                />
                {salesLead.contacts?.contact_us?.text ?? ''}
              </button>
            )}
          </div>
        </div>
      )}
    </>
  )
}

export {BundlePromotionVIPCredits}
