import React from 'react'
import {KTSVG} from '../../../helpers'
import DynamicObject from 'opensooq-notification/dist/esm/dynamic-object'
import {getNoImageLogo, isDeviceMobile} from '../../../helpers/commonFunctions'
import {useIntl} from 'react-intl'
import {useLang} from '../../../i18n/OpenSooqi18n'
import OpenSooqIcons from '../../common/OpenSooqIcons'
import {ListingWidgetTypes} from '../../listing/my-listings/helper'

type props = {
  listing: DynamicObject
  index: number
  changeSelectListingHandler: (id: number) => void
  selectedListingMap: DynamicObject
  isAllowedToSelect: boolean
  Icon: any
}
export default function MobileBundleCard({
  listing,
  index,
  changeSelectListingHandler,
  Icon,
}: props) {
  const Intl = useIntl()
  const lang = useLang()

  const getListingImage = (listing: DynamicObject) => {
    let image = getNoImageLogo(lang)
    if (listing.medias_count.images > 0) {
      image = process.env.REACT_APP_ARBOK_URL + '200x0/' + listing.main_image_uri + '.jpg'
    }
    return image
  }

  const getWidgetHeaderText = (type: string) => {
    let headerText = ''
    switch (type) {
      case ListingWidgetTypes.STATUS:
        headerText = Intl.formatMessage({id: 'myListings.mobile.List.status'})
        break
      case ListingWidgetTypes.VIEWS:
        headerText = Intl.formatMessage({id: 'myListings.mobile.List.views'})
        break
      case ListingWidgetTypes.PQS:
        headerText = Intl.formatMessage({id: 'myListings.mobile.List.pqs'})
        break
      case ListingWidgetTypes.APPLICANTS:
        headerText = Intl.formatMessage({id: 'myListings.mobile.List.applicants'})
        break
      default:
        break
    }
    return headerText
  }

  return (
    <div
      className={`hoverAction mt-3 bg-white rounded-3 shadow1 p-3`}
      data-id={listing.listing_id}
      key={index}
      onClick={() => changeSelectListingHandler(listing.listing_id)}
    >
      {Icon}
      <div className='border-top-0 d-block'>
        <div className='card-body'>
          <div className='row'>
            {listing.listing_type == 'Regular' && (
              <div className='col-12'>
                <div className='d-flex align-items-start'>
                  <div className='flex flexNoWrap  pt-4 pb-2 blackColor radius-8 bigContainer ripple relative'>
                    <div
                      className={`sc-265a2526-1 jRUVEn postImgCont bigContainer position-relative radius-8 overflowHidden marginEnd-16px myListingsImageContainer`}
                    >
                      <img
                        src={getListingImage(listing)}
                        width='272'
                        height='204'
                        className='width-100 bigContainer customImg height-100'
                        alt={listing.title}
                        loading='eager'
                      />
                    </div>
                    <div className='sc-265a2526-3 zoIKj postDet bigContainer flex-1 flex flexSpaceBetween flexDirectionColumn'>
                      <div className='flex flexSpaceBetween'>
                        <span
                          className={` ${
                            isDeviceMobile() ? 'fs-4' : 'fs-2'
                          } fw-bold  useNowModalTitle bigContainer overflowHidden `}
                        >
                          {listing.title}
                        </span>
                      </div>
                      <div className={`${isDeviceMobile() ? 'fs-5' : 'fs-3'}  bigContainer`}>
                        {listing.sub_title}
                      </div>
                      <div className='flex alignItems bigContainer '>
                        {listing.category} {Intl.formatMessage({id: 'in'})} <b>{listing.city}</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='d-flex justify-content-between'>
                  <div className='d-flex flex-row bd-highlight mb-3'>
                    <div className='mt-3 sc-265a2526-2 iRqeKu numOfCont flex bigContainer alignItems radius-8 whiteColor fs-13 '>
                      <span className='flex alignItems radius-8 bigContainer me-1'>
                        <svg
                          viewBox='0 0 12 12'
                          width='12'
                          height='12'
                          className='me-2 bigContainer'
                          data-name='iconCamera2'
                        >
                          <path
                            fill='#fff'
                            d='M12 10.6667V1.33333C12 0.6 11.4 0 10.6667 0H1.33333C0.6 0 0 0.6 0 1.33333V10.6667C0 11.4 0.6 12 1.33333 12H10.6667C11.4 12 12 11.4 12 10.6667ZM3.66667 7L5.33333 9.00667L7.66667 6L10.6667 10H1.33333L3.66667 7Z'
                          ></path>
                        </svg>
                        {listing.medias_count.images}
                      </span>
                      <span className='flex alignItems me-1 radius-8 bigContainer'>
                        <OpenSooqIcons
                          className='me-1 fw-semibold d-block fs-7 text-white'
                          width={20}
                          height={15}
                          icon={'video'}
                        />{' '}
                        {listing.medias_count.videos}
                      </span>
                      <span className='flex alignItems radius-8 bigContainer'>
                        <OpenSooqIcons
                          className='me-1 fw-semibold d-block fs-7 text-white'
                          width={20}
                          height={15}
                          icon={'360'}
                        />{' '}
                        {listing.medias_count[360]}
                      </span>
                    </div>
                    <div className='flex alignItems radius-8 bigContainer mt-3'>
                      {listing.vas_icons.map((service: any, index: number) => {
                        return (
                          <OpenSooqIcons
                            key={index}
                            className=''
                            width={30}
                            height={30}
                            icon={service.toLowerCase()}
                          />
                        )
                      })}
                    </div>
                  </div>
                  <div className='mt-3 sc-265a2526-2 fs-1 text-danger fw-bolder numOfCont flex bigContainer alignItems radius-8   '>
                    <b>
                      {!listing?.has_price ? (
                        Intl.formatMessage({id: 'noPrice'})
                      ) : listing.priceVal ? (
                        <span className='' dir='rtl'>
                          {listing.priceVal.toLocaleString() + ' ' + listing.price_currency}
                        </span>
                      ) : (
                        ''
                      )}
                    </b>
                  </div>
                </div>
              </div>
            )}
            {listing.listing_type != 'Regular' && (
              <div className='col-12 mb-5'>
                <div className='text-muted d-flex justify-content-between aligns-items-center'>
                  <div className='d-flex aligns-items-center' style={{alignItems: 'center'}}>
                    {listing.city_neighborhood}
                  </div>
                  <div className='flex alignItems '>
                    {listing.vas_icons.map((service: any, index: number) => {
                      return (
                        <OpenSooqIcons
                          key={index}
                          className=''
                          width={30}
                          height={30}
                          icon={service.toLowerCase()}
                        />
                      )
                    })}
                  </div>
                </div>
                <div className='text-muted d-flex justify-content-between aligns-items-center mt-5'>
                  <h1 className='d-flex aligns-items-center' style={{alignItems: 'center'}}>
                    {listing.title}
                  </h1>
                </div>
                <div className='text-muted d-flex justify-content-between aligns-items-center mt-5'>
                  <div className='d-flex aligns-items-center' style={{alignItems: 'center'}}>
                    {listing.tags.map((tag: any, index: number) => {
                      return index <= 2 ? (
                        <span key={index} className='badge badge-secondary me-2'>
                          {tag}
                        </span>
                      ) : (
                        ''
                      )
                    })}
                  </div>
                </div>
                <div className='text-muted d-flex justify-content-between aligns-items-center mt-5'>
                  <div className='d-flex aligns-items-center' style={{alignItems: 'center'}}>
                    {listing.sub_title}
                  </div>
                </div>
              </div>
            )}
            {listing.widgets &&
              listing.widgets.map((widget: DynamicObject, index: number) => {
                const isLastElement = index === listing.widgets.length - 1
                const isOddIndex = index % 2 === 0
                return (
                  <div
                    key={index}
                    className={`${
                      isOddIndex && isLastElement ? 'col-12' : 'col-6'
                    } accordion-header mt-3`}
                  >
                    <div
                      className={`border py-3  ${
                        widget.color
                          ? widget.color == 'GREEN'
                            ? 'bg-light-success border-success'
                            : 'bg-light-danger border-gray-300'
                          : 'border-gray-300'
                      } border-dashed rounded w-100 p-2`}
                    >
                      <div className={`d-flex align-items-center h-50px`}>
                        {widget.color && (
                          <span
                            className={`bullet bullet-vertical h-40px ${
                              widget.color
                                ? widget.color == 'GREEN'
                                  ? 'bg-success'
                                  : 'bg-light'
                                : ''
                            }`}
                          ></span>
                        )}
                        <div
                          className={`d-flex  justify-content-start flex-column ${
                            widget.type == ListingWidgetTypes.STATUS ? 'ms-3' : ''
                          }  w-100`}
                        >
                          <div
                            className={`d-flex ${
                              widget.percentage !== undefined ? 'mt-3' : ''
                            } justify-content-between text-dark fs-5`}
                          >
                            <div className='flex-grow-1 fw-bold'>
                              {!widget.title ? getWidgetHeaderText(widget.type) : widget.title}
                            </div>
                          </div>
                          <span className={`fw-bolder d-block fs-6 mt-2`}>
                            <span
                              className={`customPostTitleText ${
                                widget.type == ListingWidgetTypes.INFO ? 'text-danger' : 'text-dark'
                              } fs-3`}
                            >
                              <span className=''>
                                <b>
                                  {widget.text
                                    ? widget.text
                                    : widget.subTitle
                                    ? widget.subTitle
                                    : ''}
                                </b>
                              </span>
                            </span>
                            {widget.percentage !== undefined && (
                              <div className='h-5px  w-100 bg-light mb-3'>
                                <div
                                  className='bg-success rounded h-5px'
                                  style={{
                                    width: `${widget.percentage}%`,
                                  }}
                                ></div>
                              </div>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
