import {useState, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {
  AddFieldType,
  RangeTextFieldI,
  RemoveFieldType,
  SelectedFieldsType,
} from '../../../../interfaces/MyListings'
import Select from 'react-select'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import {customUnitsSelectStyles} from '../../my-listings/helper'
import useFiltersStore from '../../my-listings/FilterStore'

const RangeTextFilter = ({
  rangeTextLabel,
  addField,
  removeField,
  reset,
  selectedFields,
}: {
  rangeTextLabel: RangeTextFieldI
  addField: AddFieldType
  removeField: RemoveFieldType
  reset: boolean
  selectedFields: SelectedFieldsType
}) => {
  const {fieldId, units, label, type} = rangeTextLabel
  let fieldIdStr = fieldId.toString()
  const Intl = useIntl()
  const lang = useLang()
  const [showFilter, setShowFilter] = useState(false)
  const [minValue, setMinValue] = useState('')
  const [maxValue, setMaxValue] = useState('')
  const [minError, setMinError] = useState(false)
  const [maxError, setMaxError] = useState(false)
  const [selectedUnit, setSelectedUnit] = useState(units[0].value ?? '')
  const [isSaveClicked, setIsSaveClicked] = useState(false)
  const containerRef = useRef(null)
  const {globalFiltersDisable} = useFiltersStore()

  useEffect(() => {
    if (reset) {
      resetInputs()
    }
  }, [reset])

  useEffect(() => {
    if (units && !selectedUnit) {
      if (selectedFields[fieldIdStr]?.unit) {
        setSelectedUnit(selectedFields[fieldIdStr]?.unit)
      } else {
        setSelectedUnit(units[0].value)
      }
    }
  }, [units, showFilter])

  const toggleFilterOn = () => {
    setShowFilter((old) => !old)
  }

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const resetInputs = () => {
    setMinValue('')
    setMaxValue('')
    if (units) {
      setSelectedUnit(units[0].value)
    }
    setMaxError(false)
    setMinError(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      if (maxError || minError) {
        resetInputs()
        setIsSaveClicked(false)
      } else {
        setMaxValue(selectedFields[fieldIdStr]?.to)
        setMinValue(selectedFields[fieldIdStr]?.from)
        setSelectedUnit(selectedFields[fieldIdStr]?.unit ?? null)
      }
      setShowFilter(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  const getDisplayText = () => {
    const displayText = `${minValue && !maxValue ? 'Min: ' : ''}${minValue ? minValue : ''} ${
      minValue && maxValue ? '-' : ''
    } ${maxValue && !minValue ? 'Max: ' : ''}${maxValue ? maxValue : ''}`
    return displayText
  }

  const fromHandler = (_: React.ChangeEvent, minValue: string) => {
    setMinValue(minValue)
    if (minValue && maxValue && parseInt(minValue) > parseInt(maxValue)) {
      setMinError(true)
    } else {
      setMinError(false)
      setMaxError(false)
    }
  }

  const toHandler = (_: React.ChangeEvent, maxValue: string) => {
    setMaxValue(maxValue)
    if (minValue && maxValue && parseInt(minValue) > parseInt(maxValue)) {
      setMaxError(true)
    } else {
      setMaxError(false)
      setMinError(false)
    }
  }

  const handleSelectChange = (selectedOption: any) => {
    if (units.length < 1) return
    setSelectedUnit(selectedOption.value)
  }

  const saveHandler = () => {
    if (!minValue) {
      removeField(fieldIdStr, {value: minValue, keyName: 'from'}, 'RANGE')
    } else {
      addField(fieldIdStr, {value: minValue, label: label, keyName: 'from'}, 'RANGE')
    }

    if (!maxValue) {
      removeField(fieldIdStr, {value: maxValue, keyName: 'to'}, 'RANGE')
    } else {
      addField(fieldIdStr, {value: maxValue, label: label, keyName: 'to'}, 'RANGE')
    }

    if (selectedUnit === '') {
      removeField(fieldIdStr, {value: selectedUnit, keyName: 'unit'}, 'RANGE')
    } else {
      addField(fieldIdStr, {value: selectedUnit, label: label, keyName: 'unit'}, 'RANGE')
    }
    setShowFilter(false)
    setIsSaveClicked(true)
  }

  const isRangePicked = minValue || maxValue

  const options = units.map((unit) => ({
    value: unit.value,
    label: unit.shortLabel,
  }))

  const isSaveButtonDisabled = minError || maxError

  return (
    <>
      <style>
        {`
                    .no-arrows::-webkit-outer-spin-button,
                    .no-arrows::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }
                `}
      </style>

      <div
        ref={containerRef}
        data-id={fieldId}
        className={`form-control user-select-none d-flex flex-row justify-content-between align-items-center desktop-filter-field ${
          !showFilter && 'hoverPointer'
        } ${globalFiltersDisable ? 'hoverNotAllowed' : ''}`}
        onClick={() => {
          if (!globalFiltersDisable) toggleFilterOn()
        }}
      >
        <div
          className='d-flex text-primary fs-7'
          style={{position: 'absolute', top: '0px', width: '100%'}}
        >
          {isRangePicked && isSaveClicked && label}
        </div>
        <div
          className={`d-flex flex-nowrap overflow-hidden fs-6 text-dark ${
            isRangePicked && isSaveClicked ? 'fw-bold' : 'fw-normal'
          }  ${globalFiltersDisable ? 'text-muted' : ''}`}
          style={{
            top: `${isRangePicked ? '5px' : '0px'}`,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '80%',
            position: 'relative',
          }}
        >
          {isRangePicked && isSaveClicked ? getDisplayText() : label}
        </div>
        <i className='fa fa-solid fa-angle-down fs-2' />
        {showFilter && (
          <div
            onClick={stopPropagation}
            className='p-5'
            style={{
              position: 'absolute',
              top: '100%',
              left: lang === 'ar' ? 'auto' : '0',
              right: lang === 'ar' ? '0' : 'auto',
              border: '1px solid #ccc',
              backgroundColor: 'white',
              borderRadius: '10px',
              marginTop: '4px',
              zIndex: 1000,
              width: '450px',
            }}
          >
            <div className={`d-flex justify-content-between align-items-center px-1 p-2`}>
              <div className='fs-2 text-dark fw-bold'>{label}</div>
              {options.length > 1 && (
                <div onClick={(e) => e.stopPropagation()} data-id={`${fieldId}-unit`}>
                  <Select
                    value={options.find((o) => o.value === selectedUnit)}
                    onChange={handleSelectChange}
                    options={options}
                    isDisabled={options.length < 2}
                    styles={customUnitsSelectStyles(options?.length)}
                    className='overflow-fixer'
                    isSearchable={false}
                  />
                </div>
              )}
            </div>
            {/* First Range */}
            <div className='d-flex flex-row justify-content-between px-1 pt-2 pb-1 '>
              <div style={{marginRight: '8px'}}>
                <label className='fs-5 text-dark mb-2'>{rangeTextLabel?.minLabel}</label>
                <input
                  type='number'
                  placeholder={Intl.formatMessage({id: 'myListings.price.from'})}
                  value={minValue}
                  onChange={(e) => fromHandler(e, e.target.value)}
                  data-id={`${fieldId}-from`}
                  className={`d-block w-100 p-3 form-control no-arrows ${
                    minError ? 'border-danger' : ''
                  }`}
                  dir={lang === 'ar' ? 'rtl' : 'ltr'}
                  style={{textAlign: lang === 'ar' ? 'right' : 'left'}} // Add text alignment
                />
                <div className='mt-2 text-15 fw-normal text-danger' style={{height: '16px'}}>
                  {minError && Intl.formatMessage({id: 'mylistings.filter.range.minError'})}
                </div>
              </div>
              {/* Middle Point */}
              <div className='mt-10 fs-1 fw-normal text-dark'>-</div>
              {/* Last Range */}
              <div style={{marginLeft: '8px'}}>
                <label className='fs-5 text-dark mb-2'>{rangeTextLabel?.maxLabel}</label>
                <input
                  type='number'
                  placeholder={Intl.formatMessage({id: 'myListings.price.to'})}
                  value={maxValue}
                  onChange={(e) => toHandler(e, e.target.value)}
                  data-id={`${fieldId}-to`}
                  className={`d-block w-100 p-3 form-control no-arrows ${
                    maxError ? 'border-danger' : ''
                  }`}
                  dir={lang === 'ar' ? 'rtl' : 'ltr'}
                  style={{textAlign: lang === 'ar' ? 'right' : 'left'}} // Add text alignment
                />
                <div className='mt-2 fw-normal  text-15  text-danger' style={{height: '16px'}}>
                  {maxError && Intl.formatMessage({id: 'mylistings.filter.range.maxError'})}
                </div>
              </div>
            </div>
            <div className='d-flex w-100 px-1 p-2'>
              <button
                className='btn btn-lg w-25 btn-primary'
                onClick={saveHandler}
                disabled={isSaveButtonDisabled}
              >
                {Intl.formatMessage({id: 'mylistings.filter.rangesave'})}
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default RangeTextFilter
