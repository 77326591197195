import { CarReportInterface } from '../../../interfaces/CarReportInterface'
import { CustomModal } from '../../../modales/CustomModal'

type Props = {
  show: boolean
  handleClose: () => void
  report: CarReportInterface
  extraClass?: string
}

interface IFrameProps {
    htmlCode: string;
}

const CarReportView = ({show,handleClose,report,extraClass}: Props) => {
    const IFrame: React.FC<IFrameProps> = ({ htmlCode }) => {
        const srcDoc = `<html>${htmlCode}</html>`;
        return ( <iframe style={{width:"100%",height:"100%"}} srcDoc={srcDoc} /> );
    };
    return <CustomModal handleClose={handleClose} extraClass={extraClass} show={show} children={<><IFrame htmlCode={report.report}/></>}/>
}

export {CarReportView}