import { useIntl } from "react-intl"
import { useLang } from "../../../i18n/OpenSooqi18n"
import { modifyFormat } from "../../../helpers/LastContacted"
import { getCookies } from "../../../helpers/commonFunctions"

interface LastContactedProps{
  listingId : number
  lastContacted : string
}

const LastContacted = (props : LastContactedProps) => {
    const Intl= useIntl();
    const lang = useLang();

    return <div className="fs-14 pt-2 pb-2 ps-4" style={{background: "#EEF8FF",padding :"8px 16px;",margin : ""}}>
        {Intl.formatMessage({id: "listing.lastContacted"})} {props.lastContacted || modifyFormat(getCookies().lastContacted , props.listingId, lang)}
    </div>
}

export default LastContacted