import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import 'react-tooltip/dist/react-tooltip.css';
import rechargeCardApi from "../../../../api/rechargeCard.api";
import { logOrderStartClick } from "../../../../logging/helpers/commonLoggingFunctions";
import { PointOfSales } from "../../../helper/PointOfSales";
import { showSuccess } from "../../../helper/SystemAlert";
import { KTSVG } from "../../../helpers";
import { getCookies, isDeviceMobile } from "../../../helpers/commonFunctions";
import { useLang } from "../../../i18n/OpenSooqi18n";
import { Hint, WalletDetailsInterface } from "../../../interfaces/WalletDetailsInterface";
import { RechargeModal } from "../../../layout/components/modals/RechargeModal";
import { ReactTooltipContainer } from "../../common/ReactTooltipContainer";
import { MemberShipModal } from "../../payment/MemberShipModal";
import { AmountDueModal } from "./amountDueModal/AmountDueModal";

type ToolTipMessages = {
    currentBalance?: JSX.Element, 
    promotionCredits?: JSX.Element, 
    listingCredits?: JSX.Element, 
    repostCredits?: JSX.Element
}
type Props = {
    walletDetails : WalletDetailsInterface
    action: () => void
}
const BalanceWidget = ({walletDetails, action} : Props) => {
    const memberInfo = getCookies().userInfo;
    const [showBundle,setShowBundle] = useState<boolean>(false);
    const [showPaymentModal, setShowPaymentModal] = useState<boolean>(false);
    const refBundle = useRef<null | HTMLDivElement>(null); 
    const [rechargeModal, setRechargeModal] = useState(false)
    const [isDisabled,setIsDisabled] = useState(false)
    const [error,setError] = useState(false)
    const [code,setCode] = useState('')
    const [toolTipMessages, setToolTipMessages] = useState<ToolTipMessages>({
        currentBalance : undefined,
        promotionCredits : undefined,
        listingCredits : undefined,
        repostCredits : undefined
    })

    const Intl = useIntl();
    const lang = useLang();

    const setRechargeCode = (e: any) => {
        setCode(e.target.value);
    };

    const recharge = () => {
        setError(false)
        setIsDisabled(true);
        const rechargeCodeString = code;
        let data = {
            "VoucherRedeemForm" : {
                "code" : rechargeCodeString, 
                "posSource" : PointOfSales.ACCOUNT_POS_WALLET_RECHARGE_WALLET
            }
        };
        rechargeCardApi(data).then((response) => {
            if(response.status == 200){
                setRechargeModal(false);
                showSuccess(Intl,lang)
                setIsDisabled(false);
            }else{
                setIsDisabled(false);
                setError(true)
            }
        }).catch(err => {
            setIsDisabled(false);
            setError(true)
        });
    };
    const showBundleModalHandler = useCallback(()=>{
        //logging
        logOrderStartClick()
        //
        action()
    }, [])

    const showPaymentModalHandler = ()=>{
        setShowPaymentModal(true);
    }

    const hidePaymentModalHandler = ()=>{
        setShowPaymentModal(false);
    }

    const handleMessageFormate = (hint: Hint) => {
        return <span className="fw-bold"><span className="m-0 fs-4">{hint.title}</span><span className="d-block">{hint.subTitle}</span></span>
    }

    useEffect(() => {
        if(!walletDetails) return;
        setToolTipMessages({
            currentBalance : handleMessageFormate(walletDetails.wallet?.walletHint),
            promotionCredits : handleMessageFormate(walletDetails?.wallet.promotedHint),
            listingCredits : handleMessageFormate(walletDetails?.wallet.elasHint),
            repostCredits : handleMessageFormate(walletDetails?.wallet.bumpupHint)
        })
        return () => {
            setToolTipMessages({
                currentBalance : undefined,
                promotionCredits : undefined,
                listingCredits : undefined,
                repostCredits : undefined
            })
        }

    }, [walletDetails])

    const textFormatter = (text: string) => {
        if (!text) return null;
        const newText = text?.split("*").map((part, idx) =>
          idx % 2 === 1 ? (
            <Fragment key={idx}>{part}</Fragment>
          ) : (
            <strong className="fs-2" key={idx}>
              {part}
            </strong>
          )
        );
        return newText;
      };


    return <><div className="card h-100 card-xxl-stretch mb-5 mb-xxl-10">
            <div className="card-header">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">{Intl.formatMessage({id: 'myWalletText1'})}</span>
                    <span className="text-gray-900 mt-1 fw-semibold fs-6">{Intl.formatMessage({id: 'myWalletText2'})}</span>
                </h3>
            </div>
            <div className="card-body pb-0">
                <div className={`d-flex flex-wrap w-100`}>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center fw-bold fs-2">
                            {!walletDetails  ? <div><Skeleton className="w-100px h-25px"/><Skeleton className="w-100px h-20px"/></div> : <span>{walletDetails.wallet.balance.toLocaleString()}</span>}
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">
                            {walletDetails && <div className="d-flex justify-content-between"><span>{walletDetails.wallet.currency}</span><span id="currentBalance" className="d-block px-2"><KTSVG path='/media/svg/general/exclamation.svg' /></span></div>}
                        </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center fw-bold fs-2">
                            {!walletDetails ? <div><Skeleton className="w-100px h-25px"/><Skeleton className="w-100px h-20px"/></div> : walletDetails.wallet.bundlesSummary.Promoted.toLocaleString()}
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">
                            {walletDetails && <div className="d-flex justify-content-between"><span className="d-block">{Intl.formatMessage({id:"myWallet.text2"})}</span><span id="promotionCredits"  className="d-block px-2"><KTSVG path='/media/svg/general/exclamation.svg' /></span></div>}
                        </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center fw-bold fs-2">
                            {!walletDetails  ? <div><Skeleton className="w-100px h-25px"/><Skeleton className="w-100px h-20px"/></div> : <span> {walletDetails.wallet.bundlesSummary.Elas.toLocaleString()}</span>}
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">
                            {walletDetails && <div className="d-flex justify-content-between"><span>{Intl.formatMessage({id: "current.balance.listing.credits"})}</span><span id="listingCredits"  className="d-block px-2"><KTSVG path='/media/svg/general/exclamation.svg' /></span></div>}
                        </div>
                    </div>
                    <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
                        <div className="d-flex align-items-center fw-bold fs-2">
                            {!walletDetails  ? <div><Skeleton className="w-100px h-25px"/><Skeleton className="w-100px h-20px"/></div> : <span> {walletDetails.wallet.bundlesSummary.Bumpup.toLocaleString()}</span>}
                        </div>
                        <div className="fw-semibold fs-6 text-gray-400">
                            {walletDetails && <div className="d-flex justify-content-between"><span>{Intl.formatMessage({id: "current.balance.repost.credits"})}</span><span id="repostCredits"  className="d-block px-2"><KTSVG path='/media/svg/general/exclamation.svg' /></span></div>}
                        </div>
                    </div>                    
                </div>
                {
                 (walletDetails?.totalOverdue && parseFloat(walletDetails?.totalOverdue?.amount) > 0) && <div className={`d-flex flex-wrap justify-content-between align-items-center mt-3 p-3 rounded-3 ${isDeviceMobile() ? 'mx-2 min-w-300px' : 'w-50'}`} style={{
                    backgroundColor: '#F1F1F1'
                }}>
                    <div>
                        <div className="text-muted fs-4">{!walletDetails ? <Skeleton className="w-100px h-20px"/> : Intl.formatMessage({id: 'myWalletText18'})}</div>
                        {<div className="text-danger">{!walletDetails ? <Skeleton className="w-90px h-20px"/> : textFormatter(`${walletDetails?.totalOverdue?.amount ?? '0'}* ${walletDetails?.totalOverdue?.currency ?? ''}`)}</div>}
                    </div>
                    <button data-id="my-wallet-ballanceW-amountDue-payBtn" className="btn btn-lg btn-warning" style={{minWidth: '35%'}} onClick={showPaymentModalHandler} disabled={!walletDetails}><span className="fw-bold fs-4">{Intl.formatMessage({id: 'myWalletText19'})}</span></button>
                </div>
                }
            </div>
            <ReactTooltipContainer currentBalance={toolTipMessages.currentBalance} promotionCredits={toolTipMessages.promotionCredits} listingCredits={toolTipMessages.listingCredits} repostCredits={toolTipMessages.repostCredits}/>
            <div className="card-footer" ref={refBundle}>
                <div className={`d-flex flex-wrap ${isDeviceMobile() ? 'justify-content-center' : ''}`} ref={refBundle}>
                    <button data-id="my-wallet-ballanceW-creditBtn" onClick={showBundleModalHandler} className='btn btn-primary me-3 align-self-end text-white'>
                        {Intl.formatMessage({id: 'myWalletText6'})}
                    </button>
                    <button data-id="my-wallet-ballanceW-rechargeBtn" className={'btn btn-primary  align-self-end'} onClick={() => {setRechargeModal(true)}}>{Intl.formatMessage({id: 'myWalletText7'})}</button>
                </div>
            </div>
        </div>
        <RechargeModal isDisabled={isDisabled} error={error} show={rechargeModal} setRechargeCode={(e : any) => {setRechargeCode(e)}} handleAction={recharge} handleClose={() => setRechargeModal(false)} />
        <MemberShipModal show={showBundle}  type={['Credit']} handleClose={() => {setShowBundle(false)}} id={memberInfo.id} pos={PointOfSales.ACCOUNT_POS_WALLET_ADD_CREDIT}/>
        <AmountDueModal show={showPaymentModal} handleClose={hidePaymentModalHandler}/>
    </>
}

export { BalanceWidget };
