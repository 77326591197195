/* eslint-disable jsx-a11y/anchor-is-valid */
import {useLang} from '../../../i18n/OpenSooqi18n'
import { useIntl } from 'react-intl'
import { generateOpenSooqRedirectUrl, getCookies, getSiteCustomContent, redirectionInterceptor} from '../../../helpers/commonFunctions'
import OpenSooqIcons from '../../../modules/common/OpenSooqIcons'

type props = {
  comingFrom?:string
  dataId: string
}

const BackToOpenSooq = ({comingFrom, dataId}: props) => {
  const lang = useLang()
  const Intl = useIntl();
  const content = getSiteCustomContent(Intl)
  return (
    <div  className={`menu-item ${comingFrom == "headMenu"? 'dropdown-item fw-bold':''}`}  data-id={`${dataId}${comingFrom?'-'+comingFrom: ''}`}>
      <a onClick={redirectionInterceptor} href={generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang)}  target="_blank" className='menu-link menu-item text-black'>
        <span className='menu-icon'>
            <OpenSooqIcons icon='logo' className='w-100'/>
        </span>
        <span className='menu-title position-relative fs-16'>
          {content.backToSiteDomain}
        </span>
      </a>
    </div>
  )
}

export {BackToOpenSooq}
