import { useEffect } from "react"
import { SubCategoryInterface } from "../../../interfaces/SubCategoryInterface"
import { updateScreenHistory } from "../../../../logging/helpers/updateScreenHistory"
import { LoggingScreenNames } from "../../../../logging/types/LoggingTypes"
import { updateWidgetHistory } from "../../../../logging/helpers/updateWidgetHistory"

interface Iprops {
    lang : string,
    searchData : SubCategoryInterface[]
    action :  (params: SubCategoryInterface) => void
}
const BundleSubCategoryComponent = ( {searchData, lang, action} : Iprops) => {
    useEffect(()=>{
        updateScreenHistory(LoggingScreenNames['HomeDrill-subcategory'])
        updateWidgetHistory('subcategories_list')
    })
    return <>{ searchData && searchData.map((sub : SubCategoryInterface,index : number) => {
        return <div key={index}  className='col-12 shadow-sm trHoverAction hoverPointer radius-8 my-1 fw-bolder' onClick={() => {action(sub)}}>
            <div className="d-flex  py-1 ">
                <div className="" >
                    <div className="symbol symbol-45px ">
                    </div>
                </div>
                <div style={{display:"flex"}} className="p-2 fs-5 align-self-center fw-bolder">{lang === 'ar' ? sub.name : sub.name}</div>
                <div style={{display:"flex"}} className="align-self-center ms-auto p-2 ">{lang === 'ar' ? <><i className="fas fa-arrow-left"></i></> : <><i className="fas fa-arrow-right"></i></>}</div>
            </div>      
        </div>
    })}</>
}

export {BundleSubCategoryComponent};