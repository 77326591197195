import CreditCell from './CreditCell'
import RadialBarChart from './RadialBarChat'
import CreditCellSkeleton from './CreditCellSkeleton'
import {useIntl} from 'react-intl'
import {useLang} from '../../../i18n/OpenSooqi18n'
import {generateOpenSooqRedirectUrl, getCookies} from '../../../helpers/commonFunctions'

const MyListingsLive = ({
  isLoadingLanding,
  liveListingData,
  setOpenLearnMore,
  setLearnMoreLink,
}: {
  isLoadingLanding: boolean
  liveListingData: any
  setOpenLearnMore: any
  setLearnMoreLink: any
}) => {
  const Intl = useIntl()
  const lang = useLang()
  const cookies = getCookies()
  const LoadingCells = () => {
    const cellLoader = []
    for (let cellIndex = 0; cellIndex < 3; cellIndex++) {
      cellLoader.push(<CreditCellSkeleton key={cellIndex} idx={cellIndex} width={45} />)
    }
    return cellLoader
  }

  const handleOpenLearnMore = (link) => {
    setOpenLearnMore(true)
    setLearnMoreLink(link)
  }

  const learnMoreLink = `${generateOpenSooqRedirectUrl(
    process.env.REACT_APP_OPENSOOQ_BASE,
    cookies.ecountry,
    lang
  )}/upselling/live-listings?webview=1`

  return (
    <div className='d-flex flex-column card p-5 justify-content-start custom-listing-card-width'>
      <div className='fs-2 fw-bold mb-5'>
        {Intl.formatMessage({id: 'mylistings.listingslive'})}
        <i
          className='mx-1 fs-5 fa fa-5x fa-circle-info'
          style={{color: 'grey'}}
          onClick={() => handleOpenLearnMore(learnMoreLink)}
        ></i>
      </div>
      <div className='d-flex align-items-center '>
        <div className='mt-5' data-id='liveListingsChart' style={{marginRight: "-9px" }}>
          <RadialBarChart
            percentage={(liveListingData?.liveListings / liveListingData?.totalListings) * 100}
            value={String(liveListingData?.liveListings || 0)}
            width={215}
            height={235}
            key={liveListingData?.liveListings}
            label='mylistings.listingslive.total'
            fillColor='#1284ed'
            nameFontSize='22px'
            valueFontSize='22px'
            totalFontSize='15px'
            hollowSize='68%'
          />
        </div>
        <div className='mt-12 d-flex flex-wrap w-100'>
          {isLoadingLanding && LoadingCells()}
          {!isLoadingLanding && (
            <>
              <CreditCell
                dataId='regularListings'
                width={200}
                title={Intl.formatMessage({id: 'mylistings.listingslive.regular'})}
                value={liveListingData?.regularListings}
              />
              <CreditCell
                dataId='promotedListings'
                width={200}
                title={Intl.formatMessage({id: 'mylistings.listingslive.promoted'})}
                value={liveListingData?.promotedListings}
              />
              <CreditCell
                dataId='averageQuality'
                width={200}
                title={Intl.formatMessage({id: 'mylistings.listingslive.quality'})}
                value={liveListingData?.averageQuality}
              />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default MyListingsLive
