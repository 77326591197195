import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from "react-intl"
import Select from 'react-select'
import deleteListingApi from '../../../../api/listing/deleteListing.api'
import deleteListingPriceApi from '../../../../api/listing/deletedListingPrice.api'
import DynamicObject from '../../../../models/dynamic-object'
import { showError, showSuccess } from '../../../helper/SystemAlert'
import { KTSVG } from '../../../helpers'
import { useLang } from '../../../i18n/OpenSooqi18n'
import { MemberShipModal } from '../../../modules/payment/MemberShipModal'
import { Loader } from '../opensooq/Loader'

type Props = {
  show: boolean
  handleClose: () => void
  id ?: number | 0
  type?:string | ''
  deleteListingFromSearchData? : (id?: number)=>Promise<void>
  reasons : DynamicObject
  pos ?: string
}

type FieldType = {
    type: number
    type_format?: string
    label : string
    key : number | undefined
    description_items: string[]
    description:string
    reasons? : DynamicObject[]
    child?: any
    show_vas?: boolean
    close_flow?: 1 | 0
}

type PrevFieldType = FieldType & {
    chosenReason: number | string
}

const DeactivateListModal = ({show,handleClose,id,type,reasons, deleteListingFromSearchData,pos}: Props) => {
    const [field, setField] = useState<FieldType>();
    const [selectedReason, setSelectedReason] = useState<number>(0);
    const [lastStep,setLastStep] = useState<boolean>(false)
    const[isOther, setIsOther] = useState<boolean>(false)
    const [note,setNote] = useState<string>('')
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [showBundle,setShowBundle] = useState (false);
    const [prevFields, setPrevFields] = useState<PrevFieldType[]>([]);
    const Intl = useIntl();
    const lang = useLang();

    useEffect(() => {
        if(reasons.type)
            setField({key: reasons.id, label:reasons.title, description_items: reasons.description_items, description : reasons.description, reasons:reasons.reasons, type: reasons.type});
    },[reasons])

    useEffect(()=>{
        setLastStep(false);
        setIsOther(false);
        setIsDeleting(false);
        setSelectedReason(0);
        setNote('');
    }, [showBundle])

    const handleValueChange = (e:any, reasons:DynamicObject[])=>{
        setIsOther(false);
        const reason = reasons?.find((reason) => reason.id === e.value);
        if(reason?.close_flow) {
            closeDeleteModal()
            return;
        }
        if(reason && reason.show_vas){
            closeDeleteModal()
            setShowBundle(true);
            return;
        }
        setSelectedReason(reason.id);
        if(!reason || !reason.child)
        {   
            if(!reason.child){
                setLastStep(true);
            }
            return;
        } else {
            setLastStep(false);
        }
        setPrevFields((old)=>[...old, {...field, chosenReason: reason.id}]);
        setField({key: reason.child.id, label:reason.child.title, description_items: reason.child.description_items,  description : reason.child.description, type: reason.child.type, ...(reason && reason.child && {type_format: reason.child.type_format}) ,...(reason.child.id !== 4 && {reasons: reason.child.reasons}), ...( reason.child && reason.child.type === 4 && reason.child.child && {child: reason.child.child})});
        if(reason.child.type === 1) {
            if(reason.child && reason.child.id){
                setSelectedReason(reason.child.id);
            }
            setIsOther(true);
        }
        setNote('');
    };

    const handleInputNextClick = ()=>{
        if(field.type === 4){
            let body = {user_input: note?.replace(/[^0-9]/g, ''),  post_id: `${id}`, delete_reason_id: `${field.key}`}
            deleteListingPriceApi(body)
        }
        setPrevFields((old)=>[...old, {...field, chosenReason: note}]);
        setField({key: field.child.id, label:field.child.title, description_items: field.child.description_items, description : field.child.description, type: field.child.type, ...(field.child.id !== 4 && {reasons: field.child.reasons}), ...( field.child && field.child.type === 4 && field.child.child && {child: field.child.child})});
        setNote('');
    }

    const handleBackButton = ()=>{
        if(prevFields.length < 1)
            return;
        const prevField = prevFields.pop();
        if(typeof prevField.chosenReason === 'string')
            setNote(prevField.chosenReason);
        setField(prevField);
        setLastStep(false);
    }

    const deleteListingApiAction= async() => {
                setIsDeleting(true);
                let reason = selectedReason;
                if(field.type === 4){
                    let body = {user_input: note?.replace(/[^0-9]/g, ''),  post_id: `${id}`, delete_reason_id: `${field.key}`}
                    setSelectedReason(field.key);
                    reason = field.key;
                    await deleteListingPriceApi(body)
                }
                const queryParams = new URLSearchParams();
                queryParams.append("PostDeleteReason[delete_reason_id]", reason.toString());
                queryParams.append("PostDeleteReason[user_input]",note);
                const queryString = queryParams.toString();
                deleteListingApi(id ?? 0,queryString).then(async (response) => {
                    showSuccess(Intl,lang);
                    if(deleteListingFromSearchData)
                        await deleteListingFromSearchData(id);
                    setTimeout(() => {
                        if(!deleteListingFromSearchData)
                            window.location.reload()
                        setIsDeleting(false);
                    }, 3000);
                closeDeleteModal();
                }).catch((error) => {
                    showError(Intl,lang);
                })
                setIsDeleting(false);
                closeDeleteModal();
            }

    const closeDeleteModal = ()=>{
        setLastStep(false);
        setPrevFields([]);
        setNote('');
        handleClose();
    }


    useEffect(() => {
        setNote(note.trimStart())
    },[note])

    const onNoteChange = (e: any, field: FieldType)=>{
        const input = e.target.value;
        if(input === '')
        {
            setNote('');
            return;
        }
        switch(field.type_format)
        {
            case 'alpha':
                if(/[a-zA-Z]/.test(input))
                    setNote(input);
                break;
            case 'numeric':
                if(input.length <= 15)
                    setNote(input.replace(/[^0-9]/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ','));
                break;
            default:
                setNote(input)
        }
    }

    return <>
            <Modal dialogClassName='modal-dialog  modal-dialog-centered' show={show} onHide={closeDeleteModal}  >
                <div className='modal-header d-flex flex-row-reverse justify-content-between p-5'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeDeleteModal}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>{prevFields.length >0 && <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleBackButton}>
                        <KTSVG path={`/media/icons/duotune/arrows/${lang === 'ar' ? 'arr064.svg' : 'arr063.svg'}`} className='svg-icon-1'/>
                    </div>}
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>{   
                    (field && (field.type === 4 || field.type === 1) )?(<>  
                        {field.type === 1 &&<>
                            <textarea key={field.key} value={note} placeholder={field.label} onChange={(e : any) => {onNoteChange(e, field)}} className='form-control mt-3'></textarea>
                        </>}
                        {field.type === 4 &&<> <h1>{field.label}</h1>
                            <input className='form-control spanw w-30' type='text' placeholder={Intl.formatMessage({id: "deactivate.price.field"})} onChange={(e)=>{onNoteChange(e, field)}} value={note}/> 
                        </>}
                        {field && field.child && <button className='btn btn-lg btn-primary mt-5 w-100' disabled={note.length < 1} onClick={handleInputNextClick}>{Intl.formatMessage({id: 'deactivate.next'})}</button>}</>) : ( field && <div key={field.key}>
                            <h1>{field.label}</h1>
                            {field.description && <h6 className='pt-3'>{field.description}</h6>}
                            <Select isSearchable={false} className="mt-3 mb-3 fw-bold" onChange={(e:any) =>{handleValueChange(e,field.reasons)}} placeholder={field.label} key={field.key} options={field.reasons?.map((reason: DynamicObject)=>{return {label:`${reason.label} ${(reason.description_items && reason.description_items.length ? `, ${reason.description_items.join(' ')}` : reason.description ? `, ${reason.description}`: '')}`, value: reason.id}})}></Select>
                        </div>)}
                    {(lastStep || (field && !field?.child && !field?.reasons)) && (isDeleting ? Loader() : <button disabled={isDeleting || (field.type === 4 && note.length < 1) || ( isOther && note.length < 1)} className='btn btn-primary mt-5 w-100' onClick={() => {deleteListingApiAction()}}>{Intl.formatMessage({id : "deactivate.delete"})}</button> )}
                </div>
            </Modal>
            <MemberShipModal  show={showBundle}  type={['Turbo','Premium','Bumpup']} handleClose={() => {setShowBundle(false)}} id={id} pos={pos}/>
    </>
}
export { DeactivateListModal }
