import { useScreenNames } from "./useScreenNames"
import { TechDimensions } from "../dimensions/TechDimensions";
import { GeoDimensions } from "../dimensions/GeoDimensions";
import { SessionDimensions } from "../dimensions//SessionDimensions";
import { useLang } from "../../app/i18n/OpenSooqi18n";
import { getCookies } from "../../app/helpers/commonFunctions";
import { UserDimensions } from "../dimensions/UserDimensions";
import { DEFAULT_VALUE, UTM_DATA } from "../types/LoggingTypes";
import { useEffect } from "react";
import { listenForMessages, sendMessage } from "../helpers/broadcastChannel";



function useQuery() {
    return new URLSearchParams(window.location.search);
}

const setUtmDataInSessionDimensions = (message: UTM_DATA)=>{
    SessionDimensions.getInstance().sessionSource = message.cSource;
    SessionDimensions.getInstance().sessionCampaign = message.cCampaign;
    SessionDimensions.getInstance().sessionMedium = message.cMedium;
    SessionDimensions.getInstance().setUtmDataInStorage();
}

const useLogging = ()=>{

    const query = useQuery();
    useScreenNames();
    
    useEffect(()=>{
        const cSource = query.get('cSource');
        const cMedium = query.get('cMedium');
        const cCampaign = query.get('cName');
        if(!cSource || !cMedium || !cCampaign)
        {
            listenForMessages((message: UTM_DATA) => {
                setUtmDataInSessionDimensions(message);
            });
            return;
        }
        const urlParams = {
            cSource: cSource,
            cMedium: cMedium,
            cCampaign: cCampaign
        };
        setUtmDataInSessionDimensions(urlParams)
        setInterval(()=>{
            sendMessage(urlParams);
        }, 1000)
    }, [])

    if(getCookies() && getCookies().ecountry !== undefined)
    {
        GeoDimensions.getInstance().geoAppCountry = getCookies().ecountry;
    }
    if(getCookies() && getCookies().userInfo && getCookies().userInfo.id)
    {   
        const userId = getCookies().userInfo.id.toString() ?? DEFAULT_VALUE;
        UserDimensions.getInstance().userMemberId = userId;
    }
    UserDimensions.getInstance().userTrackingId = (getCookies() && getCookies().device_uuid && getCookies().device_uuid.length > 0) ? getCookies().device_uuid : DEFAULT_VALUE;

    TechDimensions.getInstance().language = useLang() ?? DEFAULT_VALUE
    TechDimensions.getInstance().version = process.env.REACT_APP_PUBLIC_VERSION ?? DEFAULT_VALUE;
    
    SessionDimensions.getInstance().sessionId = getCookies().tgtTicket ? getCookies().tgtTicket : DEFAULT_VALUE;
}

export {useLogging}