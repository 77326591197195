import DynamicObject from '../../models/dynamic-object'
import {AxiosResponse} from 'axios'
import Api from '../api'

export default (fieldId: string, parentOptionId?: string | number): Promise<AxiosResponse> => {
  const path = `/my/v1/mylistings/field-options?fieldId=${fieldId}${
    parentOptionId && parentOptionId !== '0' ? `&parentOptionId=${parentOptionId}` : ''
  }`
  return Api.getInstance(false, false, process.env.REACT_APP_API_SUB_URL).request.get(
    `${path}`,
    path
  )
}
