interface IProps {
  width?: string;
  height?: string;
  fill?: string;
  className?: string;
  stroke?: string;
  strokeWidth?: string;
  dataFlip?: string;
}

const IconLeftArrowHead = ({
  width = "24",
  height = "24",
  className = "",
  fill = "#000",
}: IProps) => {
  return (
    <svg
      width={height}
      height={width}
      className={className ? className : null}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      data-name="IconLeftArrowHead"
      data-flip={true}
    >
      <path
        d="M8.02427 11.1744L14.1049 5.79836C14.2275 5.68939 14.3733 5.60291 14.5338 5.54389C14.6943 5.48488 14.8665 5.4545 15.0403 5.4545C15.2142 5.4545 15.3863 5.48488 15.5468 5.54389C15.7073 5.60291 15.8531 5.68939 15.9757 5.79836C16.0987 5.90688 16.1963 6.03576 16.2628 6.17763C16.3294 6.31951 16.3636 6.47159 16.3636 6.62518C16.3636 6.77877 16.3294 6.93085 16.2628 7.07273C16.1963 7.2146 16.0987 7.34348 15.9757 7.452L10.8304 12.0012L15.9757 16.5493C16.0986 16.6579 16.196 16.7868 16.2625 16.9286C16.329 17.0705 16.3632 17.2226 16.3632 17.3761C16.3632 17.5297 16.329 17.6817 16.2625 17.8236C16.196 17.9655 16.0986 18.0944 15.9757 18.2029C15.8529 18.3115 15.7071 18.3976 15.5466 18.4564C15.3861 18.5152 15.2141 18.5454 15.0403 18.5454C14.8666 18.5454 14.6946 18.5152 14.5341 18.4564C14.3736 18.3976 14.2278 18.3115 14.1049 18.2029L8.02427 12.828C7.90128 12.7195 7.80374 12.5907 7.73718 12.4488C7.67061 12.3069 7.63635 12.1548 7.63635 12.0012C7.63635 11.8476 7.67061 11.6956 7.73718 11.5537C7.80374 11.4118 7.90128 11.2829 8.02427 11.1744Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconLeftArrowHead;
