import FsLightbox from "fslightbox-react"
import React, { useCallback, useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import getCategoriesApi from '../../../../api/category/getCategories.api'
import createShopApi from '../../../../api/createShop.api'
import editShopApi from '../../../../api/listing/editShop.api'
import getCitiesApi from '../../../../api/listing/getCities.api'
import getShopApi from '../../../../api/listing/getShop.api'
import uploadShopCoverMediaApi from '../../../../api/shop/uploadShopCoverMedia.api'
import uploadShopMediaApi from '../../../../api/shop/uploadShopMedia.api'
import { useLang } from '../../../../app/i18n/OpenSooqi18n'
import { UploadCoverMedia } from '../../../../app/layout/components/modals/UploadCoverMedia'
import { UploadShopMedia } from '../../../../app/layout/components/modals/UploadShopMedia'
import { Button } from '../../../../app/layout/components/opensooq/Button'
import { logOrderStartClick } from '../../../../logging/helpers/commonLoggingFunctions'
import DynamicObject from '../../../../models/dynamic-object'
import { CallingHours } from '../../../components/CallingHours'
import { openHours } from '../../../helper/CallingHoursList'
import { showError, showSuccess } from '../../../helper/SystemAlert'
import { getCookies, isShobiddak } from '../../../helpers/commonFunctions'
import { CategoryInterface } from '../../../interfaces/CategoryInterface'
import { CityInterface } from '../../../interfaces/CityInterface'
import { DocsInterface } from '../../../interfaces/DocsInterface'
import { ILocation, ProfileInterface } from '../../../interfaces/ProfileInterface'
import { ShopInterface } from '../../../interfaces/ShopInterface'
import { MemberShipModal } from '../../payment/MemberShipModal'
import { MapWidget } from './MapComponent'
import { UploadShopImages } from "../../../layout/components/modals/UploadeShopImages"
import {ImageType} from 'react-images-uploading'
import uploadShopGalleryImagesApi from "../../../../api/shop/uploadShopGalleryImages.api"
import arbokUploadApi from "../../../../api/shop/arbokUpload.api"
import ImageWithFallback from "./ShopGalleryFallback"
import deleteShopGalleryImageApi from "../../../../api/shop/deleteShopGalleryImage.api"
import Swal from "sweetalert2"


export interface TouchedInterface {
  shopName: boolean
  shopAddress: boolean
  shopUrl: boolean
  shopDescription: boolean
}

export interface IShopProps {
  isShop: boolean | undefined
  lang: string
  profile ?: ProfileInterface
}



const Shop = (props: IShopProps) => {
  const [shopOpenHours, setShopOpenHours] = useState<DynamicObject[]>(openHours)
  const [shopCallAnyTime, setShopCallAnyTime] = useState<boolean>(false)
  const [categories, setCategories] = useState<CategoryInterface[]>([])
  const memberId = getCookies().userInfo.id ?? 0
  const [showUploadCoverImage, setShowUploadCoverImage] = useState(false)
  const [showUploadLogoImage, setShowUploadLogoImage] = useState(false)
  const [showUploadShopImage, setShowUploadShopImage] = useState(false)
  const [shopDescription, setShopDescription] = useState('')
  const [cities, setCities] = useState<CityInterface[]>([])
  const [selectedCity, setSelectedCity] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [shop, setShop] = useState<ShopInterface>()
  const [shopAddress, setShopAddress] = useState('')
  const [shopName, setShopName] = useState('')
  const [shopUrl, setShopUrl] = useState('')
  const [shopLogoImage, setShopLogoImage] = useState<DocsInterface>()
  const [shopCoverImage, setShopCoverImage] = useState<DocsInterface>()
  const [showBundle,setShowBundle] = useState (false);
  const [touched, setTouched] = useState<TouchedInterface>({
    shopAddress: false,
    shopDescription: false,
    shopName: false,
    shopUrl: false,
  })
  const [showImages, setShowImages] = useState(false)
  const [imagesLinks, setImagesLinks] = useState <string[]>([])
  const [isShopCreated, setIsShopCreated] = useState<boolean>(true);
  const [showCoverLoader, setShowCoverLoader] = useState<boolean>(false);
  const [shopShopImagesLoader, setShowShopImagesLoader] = useState<boolean>(false);
  const [showLogoLoader, setShowLogoLoader] = useState<boolean>(false);
  const Intl = useIntl()
  const lang = useLang()
  const urlRegex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')
  const [logoFile, setLogoFile] = useState<File | null>(null)
  const [coverFile, setCoverFile] = useState<File | null>(null);
  const [shopGalleryImages, setShopGalleryImages] = useState([]);
  const [deletedShopGalleryImages, setDeletedShopGalleryImages] = useState([]);
  const [shopLocation, setShopLocation] = useState<ILocation>(undefined);
  const [cityLanLng, setCityLanLng] = useState<ILocation>({lat: 0, lng: 0});

  useEffect(() => {    
    if(props.isShop){
      getShopApi(memberId).then((response) => {
        const results = response.data
        setIsShopCreated(true);
        setShopName(results.name)
        setShopDescription(results.description == null ? '' : results.description)
        setShopCallAnyTime(results.call_anytime)
        setShopAddress(results.address == null ? '' : results.address)
        setShopUrl(results.url)
        setShop(results)
        setShopLogoImage({
          uri: results?.avatar ? (results.avatar.includes('no_profile') && isShobiddak()) ? 'https://opensooqui-new.s3.amazonaws.com/os_web/desktop/shobiddak-shops.png' : results.avatar : '',
          member_id: results.member_id,
          id:results.id,
          media_type: 0,
          mime_type: ''
        });
        setShopCoverImage({
          uri: results?.cover_photo,
          member_id: results.member_id,
          id:results.id,
          media_type: 0,
          mime_type: ''
        })
        setShopGalleryImages(results.gallery)
        if(!results || (results && !results.category_id) )
          setIsShopCreated(false);
        setSelectedCategory(results.category_id);
        setSelectedCity(results.city_id);
        if (results.openHours.length && results.call_anytime == 0) {
          const data: any = []
          results.openHours.map((day: any) => {
            var dayOpenAndClose = openHours
              .map((o: any) => {
                return o.dow
              })
              .indexOf(day.dow.toString())
            openHours[dayOpenAndClose].open_time = day.open_time
            openHours[dayOpenAndClose].close_time = day.close_time
            data.push(openHours[dayOpenAndClose])
          })
          setShopOpenHours(data)
        }
        const location = {
          lat : results.lat ? results.lat : (results.city.lat ? results.city.lat : undefined),
          lng : results.long ? results.long : (results.city.lng ? results.city.lng : undefined),
        }
        setShopLocation(location)
    }).catch((err)=>{
      setIsShopCreated(false);
      console.error(err)
    })
    fetchCategories()
    getCities()
    }
  }, [props.isShop])

  const changeCallAnyTime = (e: any) => {
    if (e.target.classList.contains('checked')) {
      e.target.classList.remove('checked')
      setShopCallAnyTime(false)
    } else {
      e.target.classList.add('checked')
      setShopCallAnyTime(true)
    }
  }

  const changeShopName = (e: any) => {
    if (touched.shopName == false) {
      setTouched((old: any) => {
        return {...old, shopName: true}
      })
    }
    setShopName(e.target.value)
  }

  const changeShopDescription = (e: any) => {
    if (touched.shopDescription == false) {
      setTouched((old: any) => {
        return {...old, shopDescription: true}
      })
    }
    setShopDescription(e.target.value)
  }

  const changeShopAddress = (e: any) => {
    if (touched.shopAddress == false) {
      setTouched((old: any) => {
        return {...old, shopAddress: true}
      })
    }
    setShopAddress(e.target.value)
  }

  const changeShopUrl = (e: any) => {
    if (touched.shopUrl == false) {
      setTouched((old: any) => {
        return {...old, shopUrl: true}
      })
    }
    setShopUrl(e.target.value)
  }

  function getCities() {
    getCitiesApi().then((response) => {
      if(!response || !response.data || !response.data.items)
        return 
      const results = response.data.items
      setCities(()=>[...results])
      if(!shopLocation && response.data.items.length > 0)
      {
        setShopLocation({lat : response.data.items[0].lat, lng: response.data.items[0].lng});
      }
    })
  }

  function fetchCategories() {
    getCategoriesApi().then((response) => {
      const results = response.data.items
      setCategories(()=>[...results])
    })
  }

  const createShop = () =>{
    let lat,lng;
    if(cityLanLng && shopLocation && cityLanLng.lat == shopLocation.lat && cityLanLng.lng == shopLocation.lng){
      lat = lng = 0;
    } else {
      lat = shopLocation.lat;
      lng = shopLocation.lng;
    }
    const shop:ShopInterface = {
      name:shopName,
      address:shopAddress,
      url: urlRegex.test(shopUrl) ? shopUrl : '',
      call_anytime: shopCallAnyTime ? 1 : 0,
      city_id: +(selectedCity === '' ? cities[0].id : selectedCity),
      category_id: +(selectedCategory === '' ?  categories[0].id : selectedCategory),
      description: shopDescription,
      lat: lat,
      long: lng
    }
    const data = {
      Shop: shop,
      ShopHours: openHours,
    }

    createShopApi(data).then((response) => {
      showSuccess(Intl, lang);
        const results = response.data
        setIsShopCreated(true);
        setShopName(results.name)
        setShopDescription(results.description)
        setShopCallAnyTime(results.call_anytime)
        setShopAddress(results.address)
        setShopUrl(results.url)
        setShop(results)
      if(coverFile != null){
        uploadShopCover(coverFile);
      }
      if(logoFile != null )
      {
        uploadShopMedia(logoFile); 
      }
      if(shopGalleryImages.length > 0)
      {
        uploadShopGalleryImagesApi({media: shopGalleryImages}).then((response) => {
          setShopGalleryImages(response?.data?.media);
        }).catch((error) => {
          showError(Intl, lang, error.response.data);
        })
      }
      }).catch((error) => {
      if(error.response.data){
        showError(Intl, lang, error.response?.data);
      }
      else{
        showError(Intl, lang)
      }
      })
  }

  const editShop = async () => {
    let lat = shop.lat
    let lng = shop.long;
    let cityObj = cities.find((city) => city.id == +shop.city_id);

    if(cityObj?.lat != shopLocation?.lat && cityObj?.lng != shopLocation?.lng){
      lat = shopLocation.lat;
      lng = shopLocation.lng;
    } 
    
    const newShop:ShopInterface = {
      id: shop?.id,
      address:shopAddress,
      url: urlRegex.test(shopUrl) ? shopUrl : '',
      call_anytime:shopCallAnyTime ? 1 : 0,
      description: shopDescription,
      lat: lat,
      long: lng
    }
    const data = {
      Shop: newShop,
      ShopHours: openHours,
    }

    if(deletedShopGalleryImages.length > 0) {
      await deleteShopGalleryImageApi(deletedShopGalleryImages.join(","));
      setDeletedShopGalleryImages([]);
    }
    
    if(shopGalleryImages.length > 0)
      {
        const images = shopGalleryImages.filter((img) => img.id);
        const notUploadedIMages = shopGalleryImages.filter((img) => !img.id);
        try {
          const res = await uploadShopGalleryImagesApi({media: notUploadedIMages});
          setShopGalleryImages(prev => [...images, ...res?.data?.media]);
        } catch (error) {
          return showError(Intl, lang, error.response.data);
        }
      }
    editShopApi(newShop?.id ?? 0, data)
      .then((response) => {
        showSuccess(Intl, lang)
        setIsShopCreated(true);
      })
      .catch((error) => {
        if(error.response.data){
          showError(Intl, lang, error.response.data)
        }
        else{
          showError(Intl, lang)
        }
      })
  }

  const changeOpenHours = (day: string, element: any) => {
    var index = shopOpenHours
      .map(function (o: any) {
        return o.dow
      })
      .indexOf(day)
    shopOpenHours[index].open_time = element.target.value
    setShopOpenHours(()=>[...shopOpenHours])
  }

  const changeCloseHours = (day: string, element: any) => {
    var index = shopOpenHours
      .map(function (o: any) {
        return o.dow
      })
      .indexOf(day)
    shopOpenHours[index].close_time = element.target.value
    setShopOpenHours(()=>[...shopOpenHours])
  }


  const uploadShopCover = useCallback( (file: any) => {
    setShowCoverLoader(true);
    if(!isShopCreated)
    {   
      setShopCoverImage({
          uri:  URL.createObjectURL(file[0].file),
          member_id: memberId,
          id:-1,
          media_type: 0,
          mime_type: ''
      })
      setCoverFile(file);
      setShowCoverLoader(false)
      setShowUploadCoverImage(false)
    }else {
      const formData = new FormData()
      formData.append('Shop[imageFile]', file[0].file)
      formData.append(
        'Shop[is_image_review_enabled]',
        JSON.stringify({is_image_review_enabled: true})
      )
      uploadShopCoverMediaApi(shop?.id ?? 0, 'photo-cover', formData)
        .then((response) => {
          showSuccess(Intl, lang)
          setShowCoverLoader(false);
          setShowUploadCoverImage(false)
          getShopApi(memberId)
          .then((response) => {
            const results = response.data
            setShopCoverImage({
              uri: results?.cover_photo ? results.cover_photo : '',
              member_id: results.member_id,
              id:results.id,
              media_type: 0,
              mime_type: ''
            });
            }).catch((error) => {
              setShowCoverLoader(false);
              setShowUploadCoverImage(false)
              if(error.response.data){
                showError(Intl, lang, error.response.data);
              }
              else{
                showError(Intl, lang)
              }
            })
          })
        .catch((error) => {
          setShowCoverLoader(false);
          setShowUploadCoverImage(false)
          if(error.response.data){
            showError(Intl, lang, error.response.data);
          }
          else{
            showError(Intl, lang)
          }      
        })
    }
  },[shop, isShopCreated])

  const uploadShopMedia = useCallback((file: any) => {
    setShowLogoLoader(true)
    if(!isShopCreated)
    {  
      setShopLogoImage({
        uri:  URL.createObjectURL(file[0].file),
        member_id: memberId,
        id:-1,
        media_type: 0,
        mime_type: ''
      });
      setLogoFile(file)
      setShowLogoLoader(false)
      setShowUploadLogoImage(false)
    }else{
      const formData = new FormData()
      formData.append('Shop[logoFile]', file[0].file)
      formData.append(
        'Shop[is_image_review_enabled]',
        JSON.stringify({is_image_review_enabled: true})
      )
      uploadShopMediaApi(formData)
        .then((response) => {
          showSuccess(Intl, lang)
          setShowLogoLoader(false)
          setShowUploadLogoImage(false)
          getShopApi(memberId).then((response) => {
            const results = response.data
            setShopLogoImage({
              uri: results?.avatar ? results.avatar : '',
              member_id: results.member_id,
              id:results.id,
              media_type: 0,
              mime_type: ''
            });
            }).catch((error) => {
              setShowLogoLoader(false)
              setShowUploadLogoImage(false)
              showError(Intl, lang, error.response.data);
            })
          })
        .catch((error) => {
          setShowLogoLoader(false)
          setShowUploadLogoImage(false)
          showError(Intl, lang, error.response.data);
        })
    }
  }, [isShopCreated])


  const uploadFiles = async (file) => {
    if(shopGalleryImages.length >= 6) {
    return showError(Intl, lang, {message: lang == "en" ?  "You can't upload more than 6 images": "لا يمكنك رفع أكثر من 6 صور"});
    }
    const reader = new FileReader();
    reader.readAsArrayBuffer(file); // Reads the file as an ArrayBuffer
    reader.onload = async (e) => {          
      const arrayBuffer = e.target.result;
      const res = await arbokUploadApi(arrayBuffer);
      // if(isShopCreated){
      // uploadShopGalleryImagesApi({media: [{uri: res?.data?.uri}]}).then((response) => {
        
      //   const createdImg = response?.data?.media[0];    
      //   setShopGalleryImages((prevImages) => [...prevImages, {uri: createdImg?.uri, id: createdImg?.id}]);
      // }
      // ).catch((error) => {
      //   showError(Intl, lang, error.response?.data);
      // })
      // } else {
        setShopGalleryImages((prevImages) => [...prevImages, {uri: res?.data?.uri}]);
      // }
    };
  }


  const uploadShopImages = async (imagesData: ImageType[]) => {
    setShowShopImagesLoader(true);
    const uploadPromises = imagesData.map(image => uploadFiles(image.file));
  
    // Wait for all the uploads to complete
    await Promise.all(uploadPromises); 
    
    setTimeout(() => {
    setShowShopImagesLoader(false);
    setShowUploadShopImage(false);
    }, 1000);
    return;
  }

  const deleteShopGalleryImage = (imageId: string| number) => {
    const text = Intl.formatMessage({id: 'myProfile.shop.text52'})
    Swal.fire({
      icon: 'warning',
      html: `<div class="pe-10 ps-10 fw-bolder">${text}</div>`,
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: Intl.formatMessage({id: 'no'}),
      showLoaderOnConfirm: true,
      confirmButtonText: Intl.formatMessage({id: 'yes'}),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light-danger',
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        if(isShopCreated && typeof imageId == 'string') {
          setDeletedShopGalleryImages((prevImages) => [...prevImages, imageId]);
          setShopGalleryImages((prevImages) => prevImages.filter((img) => String(img.id) !== String(imageId)));
        } else {
          setShopGalleryImages((prevImages) => prevImages.filter((img, idx) => idx !== +imageId));
        }
      }
    })
  }

  const viewFile = (src: string) => {
    setImagesLinks([src])
    setShowImages(!showImages)
  }

  const selectCategoryHandler = (e :  React.ChangeEvent<HTMLSelectElement>)=>{
    setSelectedCategory(e.target.value);
  }

  const selectCityHandler = (e :  React.ChangeEvent<HTMLSelectElement>)=>{
    const cityCode = e.target.value;
    const cityObj = cities.find((city) => city.id === +cityCode);
    setCityLanLng({lat: cityObj.lat, lng:cityObj.lng})
    setShopLocation({lat: cityObj.lat, lng:cityObj.lng})
    setSelectedCity(cityCode);
  }

  const showBundleModalHandler = ()=>{
      logOrderStartClick()
      setShowBundle(true)
  }

  const onLocationPickHandler = (location : ILocation)=>{
    setShopLocation(location)
  }

  const NoticeComponent = ({messages} : {messages : string[]})=>{
      return (
              <div className='notice d-flex flex-row bg-light-warning rounded border-warning border border-dashed mb-5 px-5 py-2'>
                    <div className='d-flex flex-stack flex-grow-1 justify-content-start'>
                        <i className='fa-regular fa-lightbulb fs-1 align-self-center p-0'></i>
                        <div className='fw-semibold align-self-start'>
                            <div className='fs-6 text-gray-700 mt-3'>
                                <ul>
                                    {messages.map((message) =>{
                                        return <li>{message}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
      );
  }

  const NoShopNoticeComponent = ()=>{
    return (
      <div className='notice d-flex flex-row bg-light-danger rounded border-danger border border-dashed mb-10 px-5 py-2'>
            <div className='d-flex flex-stack flex-grow-1 justify-content-start'>
                <i className='fa-regular fa-lightbulb fs-1 align-self-center p-0'></i>
                <div className='fw-semibold align-self-start'>
                    <div className='fs-6 text-gray-700 mt-3'>
                        <ul>
                            {Intl.formatMessage({id: "myProfile.shop.noShopNotice"})}
                        </ul>
                    </div>
                </div>
            </div>
        </div>
      );
  }

  return (
    <>
      { !isShopCreated &&  <NoShopNoticeComponent />}
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold text-gray-800'>
              {shopName} {Intl.formatMessage({id: 'myProfile.shop.title'})}
            </span>
          </h3>
          <div className='card-toolbar'>
            <div className='d-flex justify-content-end' data-kt-filemanager-table-toolbar='base'>
              {props.isShop === undefined || props.isShop === false ? (
                <button className='btn btn btn-primary' onClick={showBundleModalHandler}>
                  <span className='svg-icon svg-icon-2'>
                    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' > {' '} <path opacity='0.3' d='M10 4H21C21.6 4 22 4.4 22 5V7H10V4Z' fill='currentColor' ></path>{' '} <path d='M10.4 3.60001L12 6H21C21.6 6 22 6.4 22 7V19C22 19.6 21.6 20 21 20H3C2.4 20 2 19.6 2 19V4C2 3.4 2.4 3 3 3H9.20001C9.70001 3 10.2 3.20001 10.4 3.60001ZM16 11.6L12.7 8.29999C12.3 7.89999 11.7 7.89999 11.3 8.29999L8 11.6H11V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H16Z' fill='currentColor' ></path> <path opacity='0.3' d='M11 11.6V17C11 17.6 11.4 18 12 18C12.6 18 13 17.6 13 17V11.6H11Z' fill='currentColor' ></path> </svg>
                  </span>
                  {Intl.formatMessage({id: 'myProfile.shop.text1'})}
                </button>
              ) : (
                <div></div>
              )}
            </div>
            <div
              className='d-flex justify-content-end align-items-center d-none'
              data-kt-filemanager-table-toolbar='selected'
            >
              <div className='fw-bold me-5'>
                <span className='me-2' data-kt-filemanager-table-select='selected_count'></span>
                Selected
              </div>
              <button
                type='button'
                className='btn btn-danger'
                data-kt-filemanager-table-select='delete_selected'
              >
                Delete Selected
              </button>
            </div>
          </div>
        </div>
        {props.isShop && (
          <div className='card-body border-top p-9'>
            <div className='d-flex flex-column gap-7 gap-lg-10 w-100  mb-7 me-lg-10'>
              <div className='card card-flush py-4'>
                <div className='card-body pt-0'>
                  <NoticeComponent messages={[Intl.formatMessage({id: 'myProfile.shop.text35'}), Intl.formatMessage({id: 'myProfile.shop.text36'})]}/>
                  <div className='mb-10 fv-row fv-plugins-icon-container'>
                    <label className='required form-label'>
                      {Intl.formatMessage({id: 'myProfile.shop.text0'})}
                    </label>
                    <input
                      data-id='edit-shop-shopNameField'
                      type='text'
                      className='form-control mb-2'
                      placeholder={Intl.formatMessage({id: 'myProfile.shop.text0'})}
                      value={shopName}
                      disabled={isShopCreated}
                      maxLength={150}
                      onChange={(e) => {
                        changeShopName(e)
                      }}
                    />
                    <div className='text-muted fs-7'>
                      {Intl.formatMessage({id: 'myProfile.shop.text2'})}
                    </div>
                    <div className='fv-plugins-message-container invalid-feedback'>
                      {shopName === '' && touched?.shopName
                        ? Intl.formatMessage({id: 'myProfile.shop.text3'})
                        : ''}
                    </div>
                  </div>
                  <div>
                    <label className='form-label required'>
                      {Intl.formatMessage({id: 'myProfile.shop.text4'})}
                    </label>
                    <textarea
                      data-id='edit-shop-shopDescriptionField'
                      id='kt_ecommerce_add_product_description'
                      className='min-h-100px mb-2 form-control '
                      value={shopDescription}
                      onChange={(e) => {
                        changeShopDescription(e)
                      }}
                    >
                      {shop?.description ?? ''}
                    </textarea>
                    <div className='text-muted fs-7'>
                      {Intl.formatMessage({id: 'myProfile.shop.text5'})}
                    </div>
                    <div className='fv-plugins-message-container invalid-feedback'>
                      {shopDescription && shopDescription.length < 10 && touched?.shopDescription
                        ? Intl.formatMessage({id: 'myProfile.shop.text24'})
                        : ''}
                    </div>
                  </div>
                  <div className='pt-10 d-flex flex-wrap gap-5'>
                  <div className='fv-row w-100 flex-md-root fv-plugins-icon-container'>
                  <label className='required form-label'>
                    {Intl.formatMessage({id: 'myProfile.shop.text6'})}
                  </label>
                  <select
                    data-id="edit-shop-shopCategorySelect"
                    className='form-select mb-2 select2-hidden-accessible'
                    onChange={selectCategoryHandler}
                    disabled={isShopCreated}
                    value={selectedCategory}
                  > 
                    <option value={""} disabled={true}>
                    {Intl.formatMessage({id: 'myProfile.shop.text7'})}
                    </option>
                    {categories &&
                      categories.map((c, index) => {
                        return (
                          <option key={index} value={c.id}>
                            {props.lang === 'en' ? c.label_en : c.label_ar}
                          </option>
                        )
                      })}
                  </select>
                  <div className='text-muted fs-7'>
                    {Intl.formatMessage({id: 'myProfile.shop.text7'})}
                  </div>
                  <div className='fv-plugins-message-container invalid-feedback'>
                  </div>
                </div>
                <div className='fv-row w-100 flex-md-root'>
                  <label className='form-label required'>
                    {Intl.formatMessage({id: 'myProfile.shop.text8'})}
                  </label>
                  <select
                    data-id="edit-shop-shopCitySelect"
                    value={selectedCity}
                    placeholder={Intl.formatMessage({id: 'myProfile.shop.text9'})}
                    className='form-select mb-2 select2-hidden-accessible'
                    onChange={selectCityHandler}
                    disabled={isShopCreated}
                  > 
                  <option value={""} disabled={true}>
                  {Intl.formatMessage({id: 'myProfile.shop.text9'})}
                  </option>
                    {cities &&
                      cities.map((c, index) => {
                        return (
                          <option key={index} value={c.id}>
                            {props.lang === 'en' ? c.name_english : c.name_arabic}
                          </option>
                        )
                      })}
                  </select>
                  <div className='text-muted fs-7'>
                    {Intl.formatMessage({id: 'myProfile.shop.text9'})}
                  </div>
                </div>
                </div>
              <div className='pt-10 fv-row fv-plugins-icon-container'>
                <label className='required form-label'>
                  {Intl.formatMessage({id: 'myProfile.shop.text10'})}
                </label>
                <NoticeComponent messages={[Intl.formatMessage({id: 'myProfile.shop.text37'})]}/>
                <input
                  data-id="edit-shop-shopAddressField"
                  type='text'
                  name='price'
                  className='form-control mb-2'
                  placeholder={Intl.formatMessage({id: 'myProfile.shop.text10'})}
                  value={shopAddress}
                  onChange={(e) => {
                    changeShopAddress(e)
                  }}
                />
                <div className='text-muted fs-7'>
                  {Intl.formatMessage({id: 'myProfile.shop.text11'})}
                </div>
                <div className='fv-plugins-message-container invalid-feedback'>
                  {shopAddress.length < 1 && touched?.shopAddress
                    ? Intl.formatMessage({id: 'myProfile.shop.text26'})
                    : ''}
                </div>
              </div>
              {selectedCity &&
              <div className='pt-10 fv-row fv-plugins-icon-container mb-5'>
                <label className='form-label fs-6'>
                  {Intl.formatMessage({id: 'myProfile.shop.text44'})}
                </label>
                <div className='text-muted fs-7 mb-5'>
                  {Intl.formatMessage({id: 'myProfile.shop.text45'})}
                </div>
                <MapWidget onLocationPick={onLocationPickHandler} location={shopLocation}/>
              </div>}
              <div className='pt-10 fv-row fv-plugins-icon-container'>
                <label className='form-label'>
                  {Intl.formatMessage({id: 'myProfile.shop.text46'})}
                </label>
                <input
                  data-id="edit-shop-shopURLField"
                  type='text'
                  name='price'
                  className='form-control mb-2'
                  placeholder={Intl.formatMessage({id: 'myProfile.shop.text12'})}
                  value={shopUrl}
                  onChange={(e) => {
                    changeShopUrl(e)
                  }}
                />
                <div className='text-muted fs-7'>
                  {Intl.formatMessage({id: 'myProfile.shop.text13'})}
                </div>
                <div className='fv-plugins-message-container invalid-feedback'>
                  {!urlRegex.test(shopUrl) && touched?.shopUrl
                    ? Intl.formatMessage({id: 'myProfile.shop.text25'})
                    : ''}
                </div>
              </div>
              <div className='pt-10  fv-row fv-plugins-icon-container'>
                <label className='form-label required'>
                  {Intl.formatMessage({id: 'myProfile.shop.text15'})}
                </label>
                <NoticeComponent messages={[Intl.formatMessage({id: 'myProfile.shop.text38'}), Intl.formatMessage({id: 'myProfile.shop.text39'})]}/>
                <div className='fv-row mb-2'>
                  <div
                    data-id='edit-shop-shopLogoBtn'
                    className='dropzone dz-clickable'
                    id='kt_ecommerce_add_product_media'
                    onClick={() => {
                      setShowUploadLogoImage(true)
                    }}
                  >
                    <div className='dz-message needsclick'>
                      <i className='fas fa-upload fs-1'></i>
                      <div className='ms-4'>
                        <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                          {Intl.formatMessage({id: 'myProfile.shop.text14'})}
                        </h3>
                        <span className='fs-7 fw-semibold text-gray-400'>
                          {Intl.formatMessage({id: 'myProfile.shop.text23'})}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='text-muted fs-7'>
                  {Intl.formatMessage({id: 'myProfile.shop.text16'})}
                </div>
                <div className='fv-plugins-message-container invalid-feedback'></div>
                <div>
                <div className='card'>
                  {shopLogoImage && <div className='card-body d-flex flex-center flex-column p-9'>
                  <div className='mb-5'>
                    <img
                      src={shopLogoImage.uri}
                      className='hoverPointer w-100'
                      height={'150'}
                      width={'300'}
                      onClick={() => viewFile(shopLogoImage.uri)}
                      alt='Logo'
                    />
                    </div>
                      <div className='d-flex align-items-center'>
                        <Button
                          action={() => viewFile(shopLogoImage.uri)}
                          className='btn-primary btn-sm'
                          text={Intl.formatMessage({id: 'view'})}
                        />
                      </div>
                    </div>}
                </div>
                </div>
              </div>
                <div className='pt-10  fv-row fv-plugins-icon-container'>
                  <label className='form-label required'>
                    {Intl.formatMessage({id: 'myProfile.shop.text17'})}
                  </label>
                  <NoticeComponent messages={[Intl.formatMessage({id: 'myProfile.shop.text40'}), Intl.formatMessage({id: 'myProfile.shop.text41'})]}/>
                  <div className='fv-row mb-2'>
                    <div
                      data-id='edit-shop-shopCoverBtn'
                      className='dropzone dz-clickable'
                      id='kt_ecommerce_add_product_media'
                      onClick={() => {
                        setShowUploadCoverImage(true)
                      }}
                    >
                      <div className='dz-message needsclick'>
                        <i className='fas fa-upload fs-1'></i>
                        <div className='ms-4'>
                          <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                            {Intl.formatMessage({id: 'myProfile.shop.text14'})}
                          </h3>
                          <span className='fs-7 fw-semibold text-gray-400'>
                            {Intl.formatMessage({id: 'myProfile.shop.text22'})}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='text-muted fs-7'>
                    {Intl.formatMessage({id: 'myProfile.shop.text18'})}
                  </div>
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                  {shopCoverImage && <div className='card-body d-flex flex-center flex-column p-9'>
                  <div className='mb-5'>
                    <img
                      src={shopCoverImage.uri}
                      className='hoverPointer w-100'
                      height={'250'}
                      width={'500'}
                      onClick={() => viewFile(shopCoverImage.uri)}
                      alt='Logo'
                    />
                  </div>
                  <div className='d-flex align-items-center'>
                    <Button
                      action={() => viewFile(shopCoverImage.uri)}
                      className='btn-primary btn-sm'
                      text={Intl.formatMessage({id: 'view'})}
                    />
                  </div>
                  </div>}
                  </div>

                  <div className='pt-10  fv-row fv-plugins-icon-container'>
                  <label className='form-label'>
                    {Intl.formatMessage({id: 'myProfile.shop.text48'})}
                  </label>
                  <NoticeComponent messages={[Intl.formatMessage({id: 'myProfile.shop.text49'})]}/>
                  <div className='fv-row mb-2'>
                    <div
                      data-id='edit-shop-shopCoverBtn'
                      className='dropzone dz-clickable'
                      id='kt_ecommerce_add_product_media'
                      onClick={() => {
                        setShowUploadShopImage(true)
                      }}
                    >
                      <div className='dz-message needsclick'>
                        <i className='fas fa-upload fs-1'></i>
                        <div className='ms-4'>
                          <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                            {Intl.formatMessage({id: 'myProfile.shop.text14'})}
                          </h3>
                          <span className='fs-7 fw-semibold text-gray-400'>
                            {Intl.formatMessage({id: 'myProfile.shop.text50'})}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='fv-plugins-message-container invalid-feedback mt-4'></div>
                  <div className="d-flex align-items-center justify-content-start w-100 flex-wrap gap-2">
                  {shopGalleryImages?.length > 0 && shopGalleryImages?.map((image, idx)=>
                 
                  <div className='d-flex flex-center flex-column mb-6' key={image?.uri}>
                  <div className='mb-5'>
                    <ImageWithFallback
                      src={`${process.env.REACT_APP_ARBOK_UPLOAD}${image?.uri}`}
                      alt="Uploaded image"
                      className='hoverPointer'
                      height='180'
                      width='180'
                      onClick={() => viewFile(`${process.env.REACT_APP_ARBOK_UPLOAD}${image?.uri}`)}
                      deleteAction={() => deleteShopGalleryImage(image?.id || idx)}
                      openAction={() => viewFile(`${process.env.REACT_APP_ARBOK_UPLOAD}${image?.uri}`)}
                    />
                  </div>
                  {/* <div className='d-flex align-items-center gap-1'>
                    <Button
                      action={() => viewFile(process.env.REACT_APP_ARBOK_UPLOAD +image?.uri)}
                      className='btn-primary btn-sm'
                      text={Intl.formatMessage({id: 'view'})}
                    />
                    <button
                      onClick={() => deleteShopGalleryImage(image?.id || idx)}
                      className='btn btn-danger btn-sm'
                    ><span className="fa fa-trash"></span></button>
                  </div> */}
                  </div>
                  )
                  }

                  </div>
                  </div>

                  <div className='pt-10  fv-row fv-plugins-icon-container'>
                    <label className='form-label'>
                      {Intl.formatMessage({id: 'myProfile.shop.text19'})}
                    </label>
                    <NoticeComponent messages={[Intl.formatMessage({id: 'myProfile.shop.text42'})]}/>
                    <div className='fv-row mb-2'></div>
                    <div className='fv-row w-100 flex-md-root'>
                      <div className='fv-row w-100 flex-md-root'>
                        <div className='fv-row w-100 flex-md-root'>
                          <div className='text-muted fs-7'>
                            {Intl.formatMessage({id: 'myProfile.shop.text20'})}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='row mb-6 mt-10 justify-content-between'>
                      <div className='col-6'>
                        <span className=' form-label fw-semibold '>
                          {Intl.formatMessage({id: 'myProfile.shop.text21'})}
                        </span>
                      </div>
                      <div className='col-6 text-end'>
                        <div
                          className='form-check form-switch form-check-custom form-check-solid '
                          style={{display: 'unset'}}
                        >
                          <input
                            data-id='edit-shop-callAnyTimeSwitch'
                            className={`form-check-input ${shopCallAnyTime ? 'checked' : ''}`}
                            type='checkbox'
                            id='flexSwitchCheckChecked'
                            checked={shopCallAnyTime}
                            onChange={(e) => {
                              changeCallAnyTime(e)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <CallingHours
                      lang={lang}
                      openHours={shopOpenHours}
                      changeOpenHours={changeOpenHours}
                      changeClosingHours={changeCloseHours}
                      disabled={shopCallAnyTime ? true : false}
                    />
                  <div className='fv-plugins-message-container invalid-feedback'></div>
                </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-column flex-row-fluid gap-7 gap-lg-10'>
              <div className='tab-content'>
                <div
                  className='tab-pane fade show active'
                  id='kt_ecommerce_add_product_general'
                  role='tab-panel'
                ></div>
              </div>
            </div>
            <div className='row'>
              <div className='d-flex justify-content-end'>
                <Button
                  setData='edit-shop-saveBtn'
                  disabled = { ((shopName === '') && ( touched?.shopName || !isShopCreated )) || !selectedCity || !selectedCategory}
                  text={Intl.formatMessage({id: 'myProfile.shop.text47'})}
                  action={!isShopCreated ? createShop : editShop}
                  className='btn-primary'
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <MemberShipModal  show={showBundle}  type={['MemberShip']} handleClose={() => {setShowBundle(false)}} id={props?.profile?.id ?? 0} pos={'profileShop'}/>
      <UploadShopMedia loader={showLogoLoader} uploadFile={(files: any) => { uploadShopMedia(files) }} show={showUploadLogoImage} handleClose={() => { setShowUploadLogoImage(false) }} />
      <UploadCoverMedia loader={showCoverLoader} uploadFile={(files: any) => { uploadShopCover(files) }} show={showUploadCoverImage} handleClose={() => { setShowUploadCoverImage(false) }} />
      <UploadShopImages loader={shopShopImagesLoader} uploadFiles={(files: ImageType[]) => { uploadShopImages(files) }} show={showUploadShopImage} handleClose={() => { setShowUploadShopImage(false) }} />
      <FsLightbox toggler={showImages} sources={imagesLinks} type="image"/>
    </>
  )
}

export { Shop }

