export enum ShareButtonType {
  FACEBOOK = 'Facebook',
  TWITTER = 'Twitter',
  WHATSAPP = 'WHATSAPP',
  MESSENGER = 'MSG',
  EMAIL = 'MAIL',
  OTHER = 'OTHER'
}

export enum ShareLoggingScreens {
  MY_Listings_List = "MY_LISTINGS_SHARE_BUTTON",
  MY_Listing_Manage_Listing = "LISTING_VIEW_MANAGE_LISTING_SHARE_BUTTON",
  MY_Listing_View_Social_Widget = "LISTING_VIEW_SHARE_WIDGET",
  MY_Listing_Manage_Listing_Native = "LISTING_VIEW_MANAGE_LISTING_STANDARD_SHARE",
  MY_Listing_Share_Standard = "MY_LISTINGS_SHARE_STANDARD_BUTTON",
  MY_CV_Standard_Share_Standard = "CV_VIEW_MANAGE_CV_SHARE_STANDARD_BUTTON",
  MY_CV_View_Share_Button = "CV_VIEW_MANAGE_CV_SHARE_BUTTON",
  MY_CV_View_Share_Widget = "CV_VIEW_SHARE_WIDGET",
  Invite_Friend = "INVITE_FRIENDS",
}