/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, { ApexOptions } from 'apexcharts'
import Moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useIntl } from "react-intl"
import { Link } from 'react-router-dom'
import { getCSS, getCSSVariableValue, isMobileDevice } from '../../../assets/ts/_utils'
import { ListingStatsInterface } from '../../../interfaces/ListingStatsInterface'
import { useThemeMode } from '../../../partials'
import { generateDateRangeObject } from '../../../helpers/commonFunctions'

type Props = {
  adId        : string
  className   : string
  chartType   : ListingStatsInterface
  title       : string
  description : string
  buttonText  ?: string
  total       : number,
  target      : string 
  extraText   ?: string
  chartName : string
}

const Chart: React.FC<Props> = ({className,chartType,title,description,buttonText,total,target,adId,extraText,chartName}) => {
    const minTimestamp = Number(chartType.min.toFixed()); 
    const minDate = new Date(minTimestamp);
    let min  : any= minDate.getTime();
    min = new Date(min * 1000)
    const maxTimestamp = Number(chartType.max.toFixed());
    const maxDate = new Date(maxTimestamp);
    let max  : any= maxDate.getTime();
    max = new Date(max * 1000)
    const chartRef = useRef<HTMLDivElement | null>(null)
    const {mode} = useThemeMode()
    const Intl = useIntl();
    useEffect(() => {
        const chart = refreshChart(Intl)
        return () => {
            if (chart) {
              chart.destroy()
            }
        }
    }, [chartRef, mode])

    const refreshChart = (Intl : any) => {
        if (!chartRef.current) {
            return
        }
        const height = parseInt(getCSS(chartRef.current, 'height'))
        let dateRangeArray = generateDateRangeObject(min,max,chartType.free,chartType.premium);
        const chart = new ApexCharts(chartRef.current, getChartOptions(Intl,height,dateRangeArray,min,max))
        if (chart) {
            chart.render()
        }
        return chart
    }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 '>
        <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
            <span className='text-muted fw-semibold fs-7'>{description}</span>
            {extraText && <span className='text-muted fw-semibold fs-7'>{extraText}</span>}
        </h3>
        <div className='card-toolbar'>
            <div className="d-flex flex-column mt-2">
                <div className="bd-highlight fs-3 fw-bolder">{total.toLocaleString()}</div>
                {buttonText && <div className="bd-highlight mt-1 fs-3">
                    <Link to={`/listings/my-listings/listing-leads?type=${target}&id=${adId}`} className='btn btn-primary btn-sm'>{buttonText}</Link>
                </div>}
            </div>
        </div>
      </div>

      <div className='card-body'>
        {total == 0 && <div className='p-15 text-center'><h1>{Intl.formatMessage({id : 'no'})} {chartName}</h1>  </div>}
        {total > 0 && <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />}
      </div>
    </div>

  )
}

export { Chart }


function getChartOptions(Intl : any,height: number,series : any,min: any,max:any): ApexOptions {
  const labelColor = getCSSVariableValue('--kt-gray-500')
  const borderColor = getCSSVariableValue('--kt-gray-200')

  const minTimestamp = Number(min); 
  const minDate = new Date(minTimestamp);
  let newmin  : any= minDate.getTime();
  newmin = new Date(newmin * 1000)
  const minyyyy = minDate.getFullYear();
  const minmm = (minDate.getMonth() + 1).toString().padStart(2, '0');
  const mindd = minDate.getDate().toString().padStart(2, '0');
  const minformattedDate = `${minyyyy}-${minmm}-${mindd}`;

  const maxTimestamp = Number(max); 
  const maxDate = new Date(maxTimestamp);
  let newmax  : any= maxDate.getTime();
  newmax = new Date(newmax * 1000)
  const yyyy = maxDate.getFullYear();
  const mm = (maxDate.getMonth() + 1).toString().padStart(2, '0');
  const dd = maxDate.getDate().toString().padStart(2, '0');
  const maxformattedDate = `${yyyy}-${mm}-${dd}`;
  return {
    series: [
      {
        name: Intl.formatMessage({id: "status.promoted"}),
        data: series.cat.length == 1 ? series.singlePremiumCat : series.premium
      },
      {
        name: Intl.formatMessage({id: "status.regular"}),
        data: series.cat.length == 1 ? series.singleFreeCat : series.free
      }
    ],

    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '80%',
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      type: series.cat.length == 1 ? 'category' : 'datetime',
      min: new Date(minformattedDate).getTime(),
      max: new Date(maxformattedDate).getTime(),
      categories : series.catModified,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      reversed: false,
      labels: {
        formatter: function(value : any) {
          return value
        },
        style: {
          colors: labelColor,
          fontSize: '10px',
        },
      },
    },
    fill: {
      opacity: 1,
      colors: ['#FFA500', '#4E4E61'],
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val : any) {
          return val;
        },
      },
    },
    colors: ['#FFA500', '#4E4E61'],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  }
}
