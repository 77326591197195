import { useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import { useNavigate, useSearchParams } from "react-router-dom";
import accountLimitsApi from "../../../../api/opensooq/accountLimits.api";
import getMemberProfileApi from "../../../../api/profile/getMemberProfile.api";
import { logAccountEditClick } from "../../../../logging/helpers/commonLoggingFunctions";
import { PointOfSales } from "../../../helper/PointOfSales";
import { useLang } from "../../../i18n/OpenSooqi18n";
import { ProfileInterface } from "../../../interfaces/ProfileInterface";
import { ShopLimitInterface } from "../../../interfaces/ShopLimitInterface";
import AddNewListing from "../../common/AddNewListing";
import { handleError } from "../../common/HandleError";
import { IframeLoader } from "../../common/IframeLoader";
import { ProfileHeader } from "../../common/ProfileHeader";
import { MyProfile } from "../MyProfile";
import { Rating } from "../Rating";
import { Shop } from "../profile/Shop";
import Documents from "./Documents";
import { logScreenLoad } from "../../../../logging/helpers/commonLoggingFunctions";
import { updateScreenHistory } from "../../../../logging/helpers/updateScreenHistory";
import { LoggingScreenNames } from "../../../../logging/types/LoggingTypes";
import { Summary } from "./Summary";

export const enum NAV_NAMES {
    SUMMARY = 'manageAccountSummary',
    EDIT_PROFILE = 'manageAccountEditProfile',
    EDIT_SHOP = 'manageAccountEditShop',
    DOCS = 'manageAccountDocs',
    RATING = 'manageAccountRating'
}

export function ManageAccount() {
    const Intl = useIntl();
    const lang = useLang();
    const [profileData,setProfileData] = useState<ProfileInterface>();
    const [shopLimit, setShopLimit] = useState<ShopLimitInterface>();
    const [searchParams, setSearchParams] = useSearchParams();

    const activeNav = searchParams.get('activeNav')

    const fetchProfileData = () => {
        getMemberProfileApi().then((response) => {
            setProfileData(response.data)
        }).catch((err) => {
            handleError(err, lang, Intl);
        })
    }

    useEffect(()=>{
        fetchProfileData();
        accountLimitsApi().then((response) => {
            setShopLimit(response.data);
        })
    }, [])


    const updateScreenNameBasedOnNav = (value : string)=>{

        setTimeout(()=>{
            switch (value) {
                case NAV_NAMES.EDIT_SHOP:
                    updateScreenHistory(LoggingScreenNames['MyAccount-shop']);
                    setTimeout(() => {
                        logScreenLoad();
                    }, 100)
                    break;
                case NAV_NAMES.SUMMARY:
                    updateScreenHistory(LoggingScreenNames['MyAccount-summary']);
                    logScreenLoad();
                    break;
                case NAV_NAMES.RATING:
                    updateScreenHistory(LoggingScreenNames['my-rating']);
                    logScreenLoad();
                    break;
                case NAV_NAMES.EDIT_PROFILE:
                    updateScreenHistory(LoggingScreenNames['edit-profile']);
                    logScreenLoad();
                    break;
                default:
                    break;
            }
        }, 100)
    }

    useEffect(()=>{
        if(!activeNav) {
            setActiveNavParam(NAV_NAMES.SUMMARY);
            return;
        }
        updateScreenNameBasedOnNav(activeNav);
    }, [])


    const routeTo = useNavigate();
    const setActiveNavParam = (value: NAV_NAMES, baseRoute?: string)=>{
        updateScreenNameBasedOnNav(value);
        setSearchParams({'activeNav' : value});
        if(baseRoute) {
            routeTo(`${baseRoute}?activeNav=${value}`)
        } else {
            routeTo(`/account/my-account?activeNav=${value}`)
        }
    }

    const editProfileClickHandler = ()=>{
        setActiveNavParam(NAV_NAMES.EDIT_PROFILE);
        if(profileData)
            logAccountEditClick({id: profileData.id});
    }

    return <>
        <ProfileHeader hideEdit={true}/>
        <ul className="nav nav-tabs fw-bolder nav-line-tabs nav-line-tabs-2x mb-5 fs-6 w-100 p-2 d-inline-block" style={{overflowX: 'auto', overflowY: 'hidden', whiteSpace: 'nowrap'}}>
            <li className="nav-item d-inline-block">
                <a className={`nav-link ${ (!activeNav || (activeNav === NAV_NAMES.SUMMARY) ) ? 'active' : 'border-0'}`} onClick={()=>{setActiveNavParam(NAV_NAMES.SUMMARY)}} data-bs-toggle="tab" href="#manageAccountSummary" data-id="manage-account-tabs-summary">
                    {Intl.formatMessage({id:'manageAccount.summary'})}
                </a>
            </li>
            <li className="nav-item d-inline-block">
                <a className={`nav-link ${activeNav === NAV_NAMES.RATING ? 'active' : 'border-0'}`} data-bs-toggle="tab" href="#manageAccountRating" onClick={()=>{setActiveNavParam(NAV_NAMES.RATING, "/account/my-rating")}} data-id="manage-account-tabs-rating">
                    {Intl.formatMessage({id:'manageAccount.rating'})}
                </a>
            </li>
            <li className="nav-item d-inline-block">
                <a className={`nav-link ${activeNav === NAV_NAMES.EDIT_PROFILE ? 'active' : 'border-0'}`}  data-bs-toggle="tab" href="#manageAccountEditProfile" onClick={editProfileClickHandler} data-id="manage-account-tabs-editProfile">
                    {Intl.formatMessage({id:'manageAccount.editProfile'})}
                </a>
            </li>
            <li className="nav-item d-inline-block">
                <a className={`nav-link ${activeNav === NAV_NAMES.EDIT_SHOP ? 'active' : 'border-0'}`}  data-bs-toggle="tab" href="#manageAccountEditShop" onClick={()=>{setActiveNavParam(NAV_NAMES.EDIT_SHOP)}} data-id="manage-account-tabs-editShop">
                    {Intl.formatMessage({id:'manageAccount.editShop'})}
                </a>
            </li>
            <li className="nav-item d-inline-block">
                <a className={`nav-link ${activeNav === NAV_NAMES.DOCS ? 'active' : 'border-0'}`}  data-bs-toggle="tab" href="#manageAccountDocs" onClick={()=>{setActiveNavParam(NAV_NAMES.DOCS)}} data-id="manage-account-tabs-docs">
                    {Intl.formatMessage({id:'manageAccount.docs'})}
                </a>
            </li>
        </ul>
        <div className="tab-content" id="myTabContent">
            <div className={`tab-pane fade ${(!activeNav || (activeNav === NAV_NAMES.SUMMARY) ) && 'show active'}`} id="manageAccountSummary"  role="tabpanel" >
                <Summary profileData={profileData} selected={activeNav === NAV_NAMES.SUMMARY}/>
            </div>
            <div className={`tab-pane fade ${(!activeNav || (activeNav === NAV_NAMES.EDIT_PROFILE) ) && 'show active'}`} id="manageAccountEditProfile" role="tabpanel">
                <MyProfile fetchProfileData={fetchProfileData} profileData={profileData} />
            </div>
            <div className={`tab-pane fade ${(!activeNav || (activeNav === NAV_NAMES.EDIT_SHOP) ) && 'show active'}`} id="manageAccountEditShop" role="tabpanel">
                {profileData && !profileData.isShop && shopLimit && <AddNewListing source={'shop'} pos={PointOfSales.MYACCOUNT_SHOP_TAB_WIDGET_UPGRADE_MEMBERSHIP_BUTTON} widgetName="upgrade_account_card"/>}
                {profileData && profileData.isShop && <Shop isShop={profileData.isShop} lang={lang} profile={profileData}/>}
                {!profileData && <IframeLoader />}
            </div>
            <div className={`tab-pane fade ${(!activeNav || (activeNav === NAV_NAMES.DOCS) ) && 'show active'}`} id="manageAccountDocs" role="tabpanel">
                <Documents/>
            </div>
            <div className={`tab-pane fade ${(!activeNav || (activeNav === NAV_NAMES.RATING) ) && 'show active'}`} id="manageAccountRating" role="tabpanel">
                <Rating/>
            </div>
        </div>
    </>
}