import DynamicObject from '../../models/dynamic-object'
import {days, hours} from '../helper/CallingHoursList'

interface Props {
  lang: string
  disabled: boolean
  changeOpenHours: (params: any, element: any) => any
  changeClosingHours: (params: any, element: any) => any
  openHours: DynamicObject
}

const CallingHours = (props: Props) => {
  const daysList = () => {
    return days.map((day, index) => {
      var dayOpenAndClose = props.openHours
        .map((o: any) => {
          return o.dow
        })
        .indexOf(day.dow)
      return (
        <div className='row' key={index}>
          <div className='col-lg-2 d-flex align-items-center'>
            <span className='form-label'>{props.lang == 'en' ? day.day_en : day.day_ar}</span>
          </div>
          <div className='col-5'>
            <select
              data-id={`${day.day_en}-from`}
              value={props?.openHours[dayOpenAndClose]?.open_time ?? '01:00'}
              onChange={(e) => {
                props.changeOpenHours(day.dow, e)
              }}
              disabled={props.disabled}
              className='form-select mb-2 select2-hidden-accessible'
              name='tax'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Select an option'
              data-select2-id='select2-data-16-g4ag'
              aria-hidden='true'
              data-kt-initialized='1'
            >
              {hours.map((hour, index) => {
                return (
                  <option data-id={`${day.day_en}-from-${hour.standard_format}${hour.time_of_day}`} key={index} value={hour.standard_format}>
                    {hour.view} {hour.time_of_day.toUpperCase()}
                  </option>
                )
              })}
            </select>
          </div>
          <div className='col-5'>
            <select
              data-id={`${day.day_en}-to`}
              value={props.openHours[dayOpenAndClose]?.close_time ?? '01:00'}
              onChange={(e) => {
                props.changeClosingHours(day.dow, e)
              }}
              disabled={props.disabled}
              className='form-select mb-2 select2-hidden-accessible'
              name='tax'
              data-control='select2'
              data-hide-search='true'
              data-placeholder='Select an option'
              data-select2-id='select2-data-16-g4ag'
              aria-hidden='true'
              data-kt-initialized='1'
            >
              {hours.map((hour, index) => {
                return (
                  <option data-id={`${day.day_en}-to-${hour.standard_format}${hour.time_of_day}`} key={index} value={hour.standard_format}>
                    {hour.view} {hour.time_of_day.toUpperCase()}
                  </option>
                )
              })}
            </select>
          </div>
        </div>
      )
    })
  }

  return <>{daysList()}</>
}

export {CallingHours}
