import FsLightbox from "fslightbox-react";
import { useCallback, useEffect, useState } from "react";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import { useIntl } from "react-intl";
import Select from 'react-select';
import uploadIDApi from "../../../../../api/uploadID.api";
import { showSuccess } from "../../../../helper/SystemAlert";
import { toAbsoluteUrl } from "../../../../helpers";
import { isDeviceMobile } from "../../../../helpers/commonFunctions";
import { useLang } from "../../../../i18n/OpenSooqi18n";
import { DocStatus, DocTabInterface, DocTypeInterface } from "../../../../interfaces/DocsInterface";
import { UploadMedia } from "../../../../layout/components/modals/UploadMedia";
import { handleError } from "../../../common/HandleError";

export const DocumentCard = (props : {id : number, docTypes: DocTypeInterface[], uploadedDoc?: DocTabInterface})=>{
    const {id, docTypes, uploadedDoc} = props;
    const Intl = useIntl();
    const [documentFileLoader, setDocumentFileLoader] = useState<boolean>(false);
    const [show, setShow] = useState(false);
    const [files, setFiles] = useState([]);
    const [isExpiryDateRequired, setIsExpiryDateRequired] = useState<boolean>(false);
    const [selectedDocTypeOption, setSelectedDocTypeOption] = useState<{label: string, value:string}>(undefined);
    const [expiryDate, setExpiryDate] = useState('');
    const cdn = process.env.REACT_APP_ARBOK_UPLOAD;
    const imgRegex = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/;
    const [imageLink, setImageLink] = useState<string[]>([]);
    const [showImage, setShowImage] = useState<boolean>(false);
    const [doc, setDoc] = useState<DocTabInterface>(undefined);
    const lang = useLang();
    const [docTypesOptions, setDocTypesOptions] = useState<{label : string, value : string}[]>([]);
    const [touched, setTouched] = useState<boolean>(false);

    const DEFAULT_DOCUMENT_TYPE = 5;



    useEffect(()=>{
        setDoc(uploadedDoc ? uploadedDoc : undefined)
        if(uploadedDoc && uploadedDoc.document_type && docTypes.length > 0)
        {       
            let selectedDoc = docTypes.find((docType) => docType.key === uploadedDoc.document_type);
            selectedDoc = selectedDoc ? selectedDoc : docTypes.find((docType) => docType.key === DEFAULT_DOCUMENT_TYPE); 
            setSelectedDocTypeOption({label : selectedDoc.label,  value : selectedDoc.key.toString()});
            setIsExpiryDateRequired(selectedDoc.expiry_date_enabled);
            if(uploadedDoc.expire_date)
                setExpiryDate(uploadedDoc.expire_date)
        }
    }, [docTypes, uploadedDoc])

    useEffect(()=>{
        if(docTypes.length > 0){
            const docTypesArray = [];
            docTypesArray.push({label: Intl.formatMessage({id :'commercial.documentType'}), value : 9999, isDisabled: true}); 
            for(let docType of docTypes)
            {
                docTypesArray.push({label : docType.label, value : docType.key})
            }
            setDocTypesOptions(docTypesArray);
        }
    }, [docTypes])

    const uploadDocumentFile = useCallback((files: any) => {
        setTouched(true);
        if(!selectedDocTypeOption || (isExpiryDateRequired && expiryDate === '') || (!files && !uploadedDoc && !doc))
        {
            return;
        }

        setDocumentFileLoader(true);
        const formData = new FormData();
        if(files)
        {
            const file = files[0].file;
            formData.append('MemberMedia[img_file]', file);
        }
        formData.append('MemberMedia[document_type]', selectedDocTypeOption.value);
        if(expiryDate)
            formData.append('MemberMedia[expire_date]', expiryDate);
        if(doc && doc.id)
            formData.append('MemberMedia[document_id]', doc.id.toString());
        uploadIDApi(formData).then((response) => {
            if (response.status === 200 || response.status === 201) {
                const parsedDoc : DocTabInterface = response.data;
                setDoc(parsedDoc)
                showSuccess(Intl, lang)
                hideDocumentFileModal();
            }
        }).catch((error) => {
            setDocumentFileLoader(false);
            handleError(error, lang, Intl);
            hideDocumentFileModal();
        })
    }, [Intl, doc, expiryDate, isExpiryDateRequired, lang, selectedDocTypeOption, uploadedDoc])

    const hideDocumentFileModal = () => {
        setShow(false)
        setDocumentFileLoader(false);
        setFiles([])
    }

    const showDocumentFileModal = () => {
        if(isExpiryDateRequired && expiryDate === '')
        {
            setTouched(true);
            return;
        }
        
        if(selectedDocTypeOption){
            setShow(true)
        }else{
            setTouched(true)
        }
    }

    const setFilesData = (files: any) => {
        setFiles(files)
    }


    const selectedDocTypeHandler = (e : {label: string, value : string})=>{
        const selectedDoc = docTypes.find((docType) => {
            return docType.key === +e.value;
        });
        if(!selectedDoc || !selectedDoc.expiry_date_enabled)
            setExpiryDate('');
        setIsExpiryDateRequired(selectedDoc && selectedDoc.expiry_date_enabled)
        if(selectedDoc)
            setSelectedDocTypeOption({label : selectedDoc?.label ?? '', value : selectedDoc?.key?.toString() ?? ''});
    }

    const expiryDateHandler = (e : any)=>{        
        if(e === '')
            setExpiryDate('')
        else if(e && e.format('YYYY-MM-DD') !== ''){
            setExpiryDate(e.format('YYYY-MM-DD'))
        }
    }

    const viewFile = useCallback((src: string) => {
        if(!imgRegex.test(src))
        {
          window.open(src, '_blank');
        }else{
          setImageLink([src])
          setShowImage((old)=>!old);
        }
    }, [])

    const docsImagesList = useCallback(() => {
        const SaveButton = (props: {className: string}) =>{
            return (
                <button data-id={`document-${id}-saveBtn`} onClick={()=>{uploadDocumentFile(undefined)}} className={`btn btn-primary btn-lg ${props.className}  ${isDeviceMobile() ? 'mt-10 align-self-center w-80' : 'm-10 h-xxl-50px w-xxl-150px'}`}><span className="fs-6">{Intl.formatMessage({id : 'save'})}</span></button>
            )
        }
        return ( <div className={`w-500 p-2 m-5 mb-10 d-flex ${ isDeviceMobile() ? 'flex-column align-items-start' + 'justify-content-between ' : 'flex-row justify-content-start align-items-end'}`}>  
            <div className="card-body text-start ">   
                {doc && doc.uri && <img src={ !imgRegex.test(doc.uri) ? toAbsoluteUrl('/media/custom/pdf.svg') :  cdn + doc.uri } className="rounded-3 mb-4 w-xxl-150px h-xxl-150px img-thumbnail shadow-md hoverPointer" style={{objectFit: 'cover'}} onClick={() =>   viewFile(cdn + doc.uri)} alt="" />}
                {isDeviceMobile() && <SaveButton className="w-100"/>}
            </div>
            {!isDeviceMobile() && <SaveButton className=""/>}
        </div>
        );
    }, [doc, imgRegex, cdn, Intl, uploadDocumentFile, viewFile])
    

    const DocumentCardUploadContainer = ({showFileModal, disabled} : {showFileModal :() => any, disabled: boolean}) => {
        const Intl = useIntl();
        return <div data-id={`document-${id}-uploadDocument`} className={`row mx-10 dropzone dz-clickable`} onClick={showFileModal}>
                    <div className='dz-message needsclick'>
                    <i className='fas fa-upload fs-1'></i>
                    <div className='ms-4'>
                        <h3 className='fs-5 fw-bold text-gray-900 mb-1'>
                        {Intl.formatMessage({id: 'commercial.click'})}
                        </h3>
                        <span className='fs-7 fw-semibold text-gray-400'>
                        {Intl.formatMessage({id: 'commercial.imageCount'})}
                        </span>
                    </div>
            </div>
        </div>
    }

    const DocumentStatus = ({status} : {status : number})=>{
        switch(status)
        {
            case DocStatus.ACTIVE:
                return <span className="fa-solid fa-circle-check fs-2 px-2" style={{color: '#107f3a'}} />
            case DocStatus.PENDING:
                return <span className="fa-solid fa-circle-exclamation fs-2 px-2" style={{color: 'orange'}} />
            default:
                return <span className="fa-solid fa-circle fs-2 px-2" style={{color: 'gray'}} />
        }
    }

    

    const docErrorMessage  = touched && !selectedDocTypeOption;
    const dateErrorMessage =  !docErrorMessage && touched && isExpiryDateRequired && !expiryDate;
    const fileErrorMessage =  !docErrorMessage && !dateErrorMessage && touched && !doc && !uploadedDoc ;

    return (
        <>
        <div className='card mb-5 mb-xl-10' key={id}>
            <div className='card-header'>
                <h3 className="card-title d-flex flex-row align-items-between">
                    <span className="card-label fw-bold text-gray-800">
                        <DocumentStatus status={doc && doc.status ? doc.status : DocStatus.NEW}/>
                        {Intl.formatMessage({id: 'commercial.document'}) + ` ${id}`}
                    </span>
                </h3>            
            </div>
            <div className='card-body border-top p-9'>
                <div className="row">
                    <div className='fv-row col-6 w-100 flex-md-root'>
                        <label className='form-label'>
                        {Intl.formatMessage({id: 'commercial.document'})}
                        </label>
                        <div data-id={`document-${id}-documentType`}>
                        <Select
                        value={selectedDocTypeOption}
                        placeholder={Intl.formatMessage({id : 'commercial.documentType'})}
                        aria-label={Intl.formatMessage({id : 'commercial.documentType'})}
                        className="select2-hidden-accessible"
                        onChange={selectedDocTypeHandler}
                        closeMenuOnSelect={true}
                        options= {docTypesOptions} 
                        isSearchable={true}
                        styles={{
                            control: (baseStyles, state) => ({...baseStyles})
                        }}
                        />
                        </div>
                        {docErrorMessage && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{Intl.formatMessage({id: 'docs.errorDoc'})}</div>
                            </div>
                        )}
                    </div>
                    <div className={`fv-row col-6 w-100 flex-md-root ${isDeviceMobile() && 'mt-6'}`}>
                        <label className='form-label'>
                        {Intl.formatMessage({id: 'commercial.expiryDate'})}
                        </label>
                        <div data-id={`document-${id}-expiryDate`}>
                        <Datetime onChange={expiryDateHandler} value={expiryDate} initialValue="" closeOnSelect={ true } dateFormat={"DD-MM-YYYY"} timeFormat={true} inputProps={{ readOnly: true, placeholder: Intl.formatMessage({id : "docs.expiryDate"}), value:expiryDate, className : `form-control ${selectedDocTypeOption && !isExpiryDateRequired && 'form-control-solid'}`, style:{padding: '.78rem'}, disabled: (selectedDocTypeOption && !isExpiryDateRequired)}}/>
                        </div>
                        {dateErrorMessage && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{Intl.formatMessage({id: 'docs.errorExpiryDate'})}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <DocumentCardUploadContainer showFileModal={showDocumentFileModal} disabled={!selectedDocTypeOption || (isExpiryDateRequired && !expiryDate)}/>
            {fileErrorMessage && (
                <div className='mx-10 fv-plugins-message-container'>
                    <div className='fv-help-block'>{Intl.formatMessage({id: 'doc.pleasePickDoc'})}</div>
                </div>
            )}
            {docsImagesList()}
        </div>
        <UploadMedia loader={documentFileLoader} uploadFile={uploadDocumentFile} setFilesData={setFilesData} files={files} show={show} handleClose={hideDocumentFileModal} />
        <FsLightbox toggler={showImage} sources={imageLink} type="image"/>
        </>
    );
}