import DynamicObject from '../../models/dynamic-object'
import {AxiosResponse} from 'axios'
import Api from '../api'

export default (subCategoryReportingName: string): Promise<AxiosResponse> => {
  const path = `/my/v1/mylistings/dynamic-fields/${subCategoryReportingName}`
  return Api.getInstance(false, false, process.env.REACT_APP_API_SUB_URL).request.get(
    `${path}`,
    path
  )
}
