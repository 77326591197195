import {toAbsoluteUrl} from '../../../helpers'
import {
  generateOpenSooqRedirectUrl,
  getCookies,
  redirectionInterceptor,
} from '../../../helpers/commonFunctions'
import {useLang} from '../../../i18n/OpenSooqi18n'
import {logViewListingClick} from '../../../../logging/helpers/commonLoggingFunctions'
import {JobItem} from '../../../interfaces/JobsInterfaces/AppliedJobsInterface'
import {FC} from 'react'

interface cellProps {
  job: JobItem
  action?: (params: any) => any
}

const JobCell: FC<cellProps> = ({job, action}) => {
  const date = job.apply_date
  const tagsList = () => {
      return job.cp_card.map((tag : string,index : number) => {
          return <span key={index} className="badge badge-light me-2 mt-2">{tag}</span>
      });
  }
  const customParamsList = () => {
    const visibleParams = job.cps.slice(0, 4);
    return visibleParams.map((customParam: string, index: number) => {
      const isLastItem = index === visibleParams.length - 1;
      return (
        <span key={index} className='me-2 mt-2'>
        {customParam}
          {!isLastItem && ','}
      </span>
      );
    });
};
  const lang = useLang()

  const redirectToPost = (e: any, listing: JobItem) => {
    redirectionInterceptor()
    const link =
      generateOpenSooqRedirectUrl(
        process.env.REACT_APP_OPENSOOQ_BASE ?? '',
        getCookies().ecountry,
        lang
      ) +
      '/job-posters/' +
      listing.post_id
    if (!e.target.classList.contains('action')) {
      window.open(link, '_blank')
    }
  }

  const onJobCellClickHandler = (e: any) => {
    redirectToPost(e, job)
    //logging
    logViewListingClick({
      id: job.post_id,
      city: {
        name: job.city,
      },
      category: {label: job.category_reporting_name},
      subCategory: job.subcategory_reporting_name,
      neighborhoodName: job.neighborhood,
      service:
        job.post_badges && job.post_badges.length > 0
          ? job.post_badges[job.post_badges.length - 1]
          : '',
    })
    //
  }
  return (
      <div
        className='card customShadow hoverPointer mt-3'
        onClick={onJobCellClickHandler}
        data-id={`job-applications-application-${job.post_id}`}
      >
        <div className='card-body'>
          <div className='row '>
            <div
              className='col-lg-8 col-8'
              data-id={`job-applications-application-infoBlock-${job.post_id}`}
            >
              <div
                className='d-flex align-items-start flex-column bd-highlight '
                style={{height: '100%'}}
              >
                <div className='bd-highlight'>
                  <span className='text-gray-400 text-hover-primary fs-7 fw-bold me-1'>
                    {job.city}, {job.neighborhood}
                  </span>
                </div>
                <div className='mb-auto  bd-highlight'>
                  <p className='fs-6  fw-semibold '>{job.title}</p>
                  <span className='text-gray-400 text-hover-primary fs-7 fw-bold me-1'>
                    {tagsList()}
                  </span>
                </div>
                <div className='bd-highlight mt-5'>
                  <p className='fs-6  fw-semibold'>{customParamsList()}</p>
                </div>
                <div className='bd-highlight bigContainer'>
                  <span className='fs-6 text-gray-600 fw-semibold bigContainer'>{date}</span>
                </div>
              </div>
            </div>
            <div className='col-lg-4 col-4 text-end '>
              {job.post_badges.map((badge, index) => {
                const service = badge === "turbo" ? "Turbo" : badge === "featured" ? "Premium" : badge
                return (
                  <img
                    key={index}
                    className=''
                    width='25'
                    height='25'
                    src={toAbsoluteUrl(`/media/custom/${service}-service.svg`)}
                    alt='service'
                  />
                )
              })}
              {
                <div className='pt-2 bd-highlight  action text-end'>
                  <button
                    data-id={`job-applications-application-favoriteBtn-${job.post_id}`}
                    disabled={false}
                    className={'btn btn-bg-light btn-sm border-1 action'}
                    onClick={action}
                  >
                    {job.bookmark_status ? (
                      <svg
                        className='action'
                        viewBox='0 0 32 32'
                        width='25'
                        height='25'
                        data-name='iconFavouriteFill'
                      >
                        <path
                          className='action'
                          fill='#ee5555'
                          stroke='#ee5555'
                          strokeWidth='3'
                          d='M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.333-11.4-15.373 0-4.107 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.787 1.453-1.707 3.68-2.787 6-2.787 4.107 0 7.333 3.227 7.333 7.333 0 5.040-4.533 9.147-11.4 15.387l-1.633 1.747z'
                        ></path>
                      </svg>
                    ) : (
                      <svg
                        className='action'
                        viewBox='0 0 32 32'
                        width='25'
                        height='25'
                        data-name='iconFavouriteFill'
                      >
                        <path
                          className='action'
                          fill='#fff'
                          stroke='#ee5555'
                          strokeWidth='3'
                          d='M16 28.467l-1.933-1.76c-6.867-6.227-11.4-10.333-11.4-15.373 0-4.107 3.227-7.333 7.333-7.333 2.32 0 4.547 1.080 6 2.787 1.453-1.707 3.68-2.787 6-2.787 4.107 0 7.333 3.227 7.333 7.333 0 5.040-4.533 9.147-11.4 15.387l-1.633 1.747z'
                        ></path>
                      </svg>
                    )}
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
  )
}

export {JobCell}
