import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { MyAccountFeatures } from "../../interfaces/MyAccountFeatures";

type Props = {
    accountFeature ?: MyAccountFeatures
    navigateTo : (param : any) => void
}

const AccountFeatures = (props : Props) => {
    const Intl = useIntl();
    return  <div className="card card-xxl-stretch mb-5 mb-xxl-10 h-lg-100">
        <div className="card-header border-0 mt-2">
            <div className="fs-3 card-title align-items-start flex-column mt-2">
                <span className="card-label fw-bold text-dark">
                    {Intl.formatMessage({id : "myOpenSooq.myAccountFeature"})}
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">{Intl.formatMessage({id : "myOpenSooq.myWalletDescription"})}</span>
            </div>
        </div>
        <div className="card-body pt-0">
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer"  onClick={() => {props.navigateTo('/listings/my-listings?status=regular,promoted')}} data-id="home-account-features-liveListings">
                <div className="d-flex fs-6 fw-semibold align-items-center h-30px">
                    <div className="bullet bg-primary me-3" style={{borderRadius: "3px", width: "12px", height: "12px"}}></div>
                    <div className="flex-grow-1">
                        <div className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "myOpenSooq.myAccountFeature.text1"})}</div>
                    </div>
                    {!props.accountFeature && <Skeleton className="w-30px" />} {props.accountFeature && <span className="badge badge-light fs-12 fw-bold" style={{color :"black"}}>{props.accountFeature.live_listings.total.toLocaleString()}</span>}
                </div>
            </div>
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" onClick={() => {props.navigateTo('/account/my-account')}} data-id="home-account-features-liveListingsLimit" >
                <div className="d-flex fs-6 fw-semibold align-items-center h-30px">
                    <div className="bullet bg-primary me-3" style={{borderRadius: "3px", width: "12px", height: "12px"}}></div>
                    <div className="flex-grow-1">
                        <div className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "myAccount.limitLiveListings"})}</div>
                    </div>
                    {!props.accountFeature && <Skeleton className="w-30px" />} {props.accountFeature && <span className="badge badge-light fs-12 fw-bold" style={{color :"black"}}>{props.accountFeature.account_quota.total.toLocaleString()}</span>}
                </div>
            </div>
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer"  onClick={() => {props.navigateTo('/account/my-wallet')}} data-id="home-account-features-walletCredit" >
                <div className="d-flex fs-6 fw-semibold align-items-center h-30px">
                    <div className="bullet bg-primary me-3" style={{borderRadius: "3px", width: "12px", height: "12px"}}></div>
                    <div className="flex-grow-1">
                        <div className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "myOpenSooq.myAccountFeature.text3"})}</div>
                    </div>
                    {!props.accountFeature && <Skeleton className="w-30px" />} {props.accountFeature && <span className="badge badge-light fs-12 fw-bold" style={{color :"black"}}>{props.accountFeature.wallet_credit.localBalance.toLocaleString()}</span>}
                </div>
            </div>
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer"   onClick={() => {props.navigateTo('/account/my-wallet?to=bundles')}} data-id="home-account-features-walletPromotion" >
                <div className="d-flex fs-6 fw-semibold align-items-center h-30px">
                    <div className="bullet bg-primary me-3" style={{borderRadius: "3px", width: "12px", height: "12px"}}></div>
                    <div className="flex-grow-1">
                        <div className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "myOpenSooq.myAccountFeature.text4"})}</div>
                    </div>
                    {!props.accountFeature && <Skeleton className="w-30px" />} {props.accountFeature && <span className="badge badge-light fs-12 fw-bold" style={{color :"black"}}>{props.accountFeature.wallet_promotion_bundles.avalable_units.toLocaleString()}</span>}
                </div>
            </div>
        </div>
    </div>
}

export default AccountFeatures