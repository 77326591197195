import { useEffect, useState } from "react";
import DynamicObject from "../../../../models/dynamic-object";
import { EDIT } from "../../../helper/Ctas";
import { getCookies, isDeviceMobile } from "../../../helpers/commonFunctions";
import { MemberShipModal } from "../../payment/MemberShipModal";
import { ShareLoggingScreens } from "../../../helper/ShareLogging";
import CvBody from "./CvBody";
import { CvFeatures } from "./CvFeatures";
import CvFooter from "./CvFooter";
import { CvLoader } from "./CvLoader";
import { CvReactTooltipContainer } from "./CvReactTooltipContainer";
import CvStatistics from "./CvStatistics";
import { ManageCv } from "./ManageCv";
import { ShareCv } from "./ShareCv";

type Props = {
    cv : DynamicObject
}

export function MyCv({cv} : Props) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const action = params.get('action') ?? '';
    const [showBundle, setShowBundle] = useState<boolean>(false);
    useEffect(() => {
        if(action && action == 'promoteCv' && getCookies().userInfo && getCookies().userInfo.id){
            setShowBundle(true);
        }
    },[])
    return<>{!cv && <CvLoader/>}
        {cv && <div className="row justify-content-center">
        <div className="col-lg-12">
            <div className="card">
                {<div className="card-body p-5 w-100"><CvBody cv={cv}/></div>}
                {<div className="card-footer p-5 w-100"><CvFooter cv={cv}/></div>}
            </div>
        </div>
        <div className="row mt-5 p-0">
            <div className="col-xl-3 col-md-6 col-12">
                <div className="row">
                    <div className="col-12">
                        <ManageCv listing={cv} />
                    </div>
                    <div className="col-12 mt-5">
                        {cv && cv.ctas?.find((cta: DynamicObject) => {
                            return cta.cta == EDIT
                        }) && <ShareCv dataComeFrom={ShareLoggingScreens.MY_CV_View_Share_Widget} comeFrom={'manageCv'} listingUrl={''} listing={cv}/>}
                    </div>
                </div>
            </div>
            <div className={`col-md-6 col-xl-9 col-12 ${(isDeviceMobile() ? "mt-5" : "")}`}>
                <div className="row">
                    <div className="col-12">
                        <CvStatistics cv={cv}/>
                    </div>
                    <div className="col-12 mt-5">
                        <CvFeatures cv={cv}/>
                    </div>
                </div>
            </div>
            <CvReactTooltipContainer/>
        </div>
        <MemberShipModal show={showBundle} backUrl={window.location.origin} type={['Turbo','Premium','Bumpup']} handleClose={() => {setShowBundle(false)}} id={cv.equivalent_post_id} pos={'manage_cv_promote'}/>
    </div>}</>
}