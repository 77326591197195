import { useEffect, useState } from "react";
import DynamicObject from "../../../models/dynamic-object";
import { CvLoader } from "./cv/CvLoader";
import { MyCv } from "./cv/MyCv";
import { NewCv } from "./cv/NewCv";
import myCvApi from "../../../api/account/myCv.api";

export function HandleCv() {
    const [hasCv,setHasCv] = useState <number>(0)
    const [cv,setCv] = useState <DynamicObject>(null)
    
    useEffect(() => {
        myCvApi().then((response) => {
            setHasCv(1)
            setCv(response.data)
            if(response.data){
                
                if(response.data.completion_score && response.data.completion_score >= 50){
                    setHasCv(1)
                }else{
                    setHasCv(2)
                }
            }else{
                setHasCv(2)
            }
        }).catch((error) => {
            setHasCv(2)
        });
    },[])
    
    return <>{(hasCv === 1 && cv) ? <MyCv cv={cv}/> : (hasCv === 2 ) ? <NewCv/> : <CvLoader/>}</>
}