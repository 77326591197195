import React from 'react'
import { Modal } from 'react-bootstrap'
import { KTSVG } from '../helpers'
import { isDeviceMobile } from '../helpers/commonFunctions'
import Sheet from 'react-modal-sheet';
import { RTEventResponseType } from '../interfaces/RealTimeModal';
import { useIntl } from 'react-intl';
import { useLang } from '../i18n/OpenSooqi18n';

type props = {
    show: boolean,
    setRealTimeModal: (show: boolean) => void,
    RTResponse?: RTEventResponseType
}

export default function RealTimeModal({show, setRealTimeModal, RTResponse}: props) {
    const Intl = useIntl()
    const lang = useLang()
    const ModalBodySelector = () => {
        switch (RTResponse.name) {
            case "continue_draft":
                return <ContinueDraftBody />
            default:
                return <></>
        }
    }

    const continueDraft = () => {
        let link = `${process.env.REACT_APP_ADD_POST}?v=2&source=${isDeviceMobile() ? 'mobile':'desktop'}&lang=${lang}&flow=draft&draft_id=${RTResponse.data.draft_id}&post_id=0&return=${window.location.href}`
        window.open(link, '_blank')
        setRealTimeModal(false)
    }

    const ContinueDraftBody = () => {
        return (<>
            <div className='btn btn-sm btn-icon btn-active-color-primary text-center align-self-start' onClick={()=>setRealTimeModal(false)}>
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
            <div className='d-flex justify-content-center align-self-center w-75'>
                <div className='modal-body py-5 d-flex flex-column justify-content-center align-items-start gap-4 w-100' >
                    <span className="fs-1 fw-bold">{Intl.formatMessage({id: "realTimeModal.title"})}</span>  
                    <div className='d-flex gap-4 w-100 justify-content-start'>
                        <img className='rounded-4' width="100px" height="100px" src={(RTResponse && RTResponse.data?.main_image && RTResponse.data?.main_image[lang].includes("https")) ? RTResponse.data?.main_image[lang] : (RTResponse && RTResponse.data?.main_image)? `${process.env.REACT_APP_ARBOK_UPLOAD}${RTResponse.data?.main_image[lang]}`: '' } alt={RTResponse.name} />
                        <div className='text-start align-self-center pt-3'>
                            {RTResponse?.data?.title1 && <p className='fw-bold w-175px fs-5'>{RTResponse.data?.title1[lang]}</p>}
                            {RTResponse?.data?.title2 && <p className='w-175px customPostTitleText fs-5'>{RTResponse.data?.title2[lang]}</p>}
                            {RTResponse?.data?.title3 && <p className='w-175px fs-5'>{RTResponse.data?.title3[lang]}</p>}
                        </div>
                    </div>       
                    <button className='btn btn-lg btn-warning fw-bold mb-7 w-100' onClick={continueDraft}>{Intl.formatMessage({id: "realTimeModal.button"})}</button>               
                </div>
            </div>
        </>
        )
    }
    if(!RTResponse) return <></>
  return (
    <>
      { isDeviceMobile()?  
                <Sheet detent="content-height" isOpen={show} onClose={()=>setRealTimeModal(false)} >
                    <Sheet.Container  className="pb-7">
                   {ModalBodySelector()}
                    </Sheet.Container>
                    <Sheet.Backdrop onTap={()=>setRealTimeModal(false)}  />  
                </Sheet>
                :<Modal tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered' show={show} onHide={()=>setRealTimeModal(false)}  >
                    {ModalBodySelector()}
                </Modal>}
            </>
  )
}
