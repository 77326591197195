import React, { useState, useEffect, memo, useMemo } from 'react';
import { SectionMobile, DropDownListItemMobile, DropDownListOptionMobile } from '../../../../interfaces/MyListingsMobile';
import { AddFieldType, RemoveFieldType, SelectedFieldsType, ValueType } from '../../../../interfaces/MyListings';
import fieldOptionsApi from '../../../../../api/newMyListings/fieldOptions.api';
import { useLang } from '../../../../i18n/OpenSooqi18n';
import { useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

const SectionFilterMobile = ({ section, addField, removeField, selectedFields }: { section: SectionMobile, addField: AddFieldType, removeField: RemoveFieldType, selectedFields: SelectedFieldsType }) => {  
  return (
    <div className='card shadow p-2'>
      {section.items.map((item: DropDownListItemMobile, index: number) => {
        const isEnabled = (item.parentId === 0 || (selectedFields[item.parentId] && (selectedFields[item.parentId] as ValueType[]).length === 1)) ? true : false;
        return (
          <React.Fragment key={item.fieldId + '' + index}>
            {index > 0 && <div key={section?.items[0]?.fieldId + '' + index} className='dotted-line'/>}
            <DropDownItem
              key={item.fieldId}
              item={item}
              isEnabled={isEnabled}
              addField={addField}
              removeField={removeField}
              selectedFields={selectedFields}
            />
          </React.Fragment>
        );
      })}
    </div>
  );
};

const DropDownItem = ({ item, isEnabled, addField, removeField, selectedFields }: { item: DropDownListItemMobile, isEnabled: boolean, addField: AddFieldType, removeField: RemoveFieldType, selectedFields: SelectedFieldsType }) => {
  const [options, setOptions] = useState<DropDownListOptionMobile[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<DropDownListOptionMobile[]>([]);
  const lang = useLang();
  const intl = useIntl();
  
  // const selectedOptions = useMemo(() => {
  //   const selected = selectedFields[item.fieldId];
  //   if (Array.isArray(selected)) {
  //     return options.filter(option => selected.some(selectedOp => selectedOp.value === option.value));
  //   }
  //   return [];
  // }, [selectedFields, options, item.fieldId]);

  useEffect(() => {
    if (selectedFields[item.fieldId]) {
      const selected: any =  Array.isArray(selectedFields[item.fieldId]) ? selectedFields[item.fieldId]: [selectedFields[item.fieldId]] || [];
      setSelectedOptions(selected.map((option: ValueType) => ({ value: option.value, label: option.label })));
    }
  }, []);

  const handleSelectedOption = (option: DropDownListOptionMobile) => {
    const isOptionSelected = selectedOptions.find((selectedOp) => option.value === selectedOp.value);
    if (isOptionSelected) {
      setSelectedOptions(selectedOptions.filter((selectedOp) => selectedOp.value !== option.value));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  }


  useEffect(() => {
    if (isEnabled && isModalOpen) {
      setIsLoading(true);
      const parentFieldValue = Array.isArray(selectedFields[item.parentId]) ? selectedFields[item.parentId][0]?.value?.toString() : null;
      fieldOptionsApi(item.fieldId.toString() ?? null, parentFieldValue).then((res) => {
        setIsLoading(false);
        setOptions([...res?.data]);
      });
    }
  }, [isModalOpen, isEnabled, item.fieldId]);

  const handleSelectOption = (option: DropDownListOptionMobile) => {
    
    const isOptionSelected = selectedOptions.find((selectedOp) => option.value === selectedOp.value);
    handleSelectedOption(option);
    if (isOptionSelected) {
      removeField(item.fieldId.toString(), { value: option.value }, 'MULTI');
    } else {
      addField(item.fieldId.toString(), { value: option.value, label: option.label, parentid: item?.parentId.toString() ?? null }, 'MULTI');
    }
  };

  useEffect(() => {
    if (!isEnabled) {
      selectedOptions.forEach(option => removeField(item.fieldId.toString(), { value: option.value }, 'MULTI'));
    }
  }, [isEnabled, selectedOptions, item.fieldId, removeField]);

  const showModalHandler = () => {
    if (!isEnabled) return;
    setIsModalOpen(true);
  };

  const filteredOptions = useMemo(() => {
    return options.filter(option => option.autoComplete.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [options, searchTerm]);

  return (
    <div className={`w-100 p-3 d-flex align-items-center justify-content-between flex-row p-5 ${isEnabled ? '' : 'disabled'}`} style={{ borderRadius: '0.75rem' }}  onClick={showModalHandler} key={item?.fieldId}>
      <div className='w-75'>
        <div className={`fw-bold fs-3 ${selectedOptions.length ? '' : 'my-3'} ${isEnabled ? 'text-dark' : 'text-muted'}`}>{item.label}</div>
        {selectedOptions.length > 0 && <div className={`fs-3 w-75 my-3 ${isEnabled ? 'text-dark' : 'text-muted'}`} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
          {selectedOptions.map(opt => opt.label).join(', ')}
        </div>}
      </div>
      <span className={`text-dark text-end w-25 fs-4 ${isEnabled ? 'hoverPointer' : 'text-muted'}`}>
        {intl.formatMessage({ id: 'mylistings.singleselect.mobile.select' })}
        <i className={`px-2 fa fa-chevron-${lang === 'ar' ? 'left' : 'right'} ${isEnabled ? 'text-primary' : 'text-muted'}`}></i>
      </span>
      {isModalOpen && (
        <div className="modal show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title fs-1">{item.label}</h5>
                <button type="button" className="btn-close" onClick={(e) => {e.stopPropagation(); setIsModalOpen(false)}}></button>
              </div>
              <div className="modal-body d-flex flex-column gap-2">
                <div className="mb-3">
                  {selectedOptions.length > 0 && (
                    <div className="d-flex flex-row gap-2 mb-2 py-4" style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
                      {selectedOptions.map(option => (
                        <div key={option.value} className="badge p-3 fs-4 bg-dark d-flex align-items-center">
                          {option.label}
                          <button type="button" className="btn-close btn-close-white fs-4 ms-2" onClick={() => handleSelectOption(option)}></button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mb-3">
                  <input 
                    type="text" 
                    className="form-control p-3" 
                    placeholder={intl.formatMessage({ id: 'mylistings.filters.search' })} 
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)} 
                  />
                </div>
                {isLoading ? (
                  <div>{Array.from({ length: 10 }).map((_, index) => <Skeleton key={item.fieldId + '' + index} className='my-5' width={330} height={50} />)}</div>
                ) : (
                  filteredOptions.map((option, index) => (
                    <div 
                      key={option.value + ',' +index} 
                      className={`card p-5 py-7 fs-2 fw-bold mb-3 shadow-sm d-flex flex-row justify-content-between align-items-center hoverPointer ${selectedOptions.find((op) => op.value === option.value) ? 'selected' : ''}`} 
                      style={{ borderRadius: '0.5rem'}} 
                      onClick={() => handleSelectOption(option)}
                    >
                      <span>{option?.label}</span>
                      <i className={`fs-1 ${selectedOptions.find((op) => op.value === option.value) ? 'fas fa-check-square text-primary' : 'far fa-square text-muted'}`}></i> 
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// const MemoizedDropDownItem = memo(DropDownItem, (prevProps, nextProps) => {
//   return (
//     prevProps.item === nextProps.item &&
//     prevProps.isEnabled === nextProps.isEnabled &&
//     prevProps.addField === nextProps.addField &&
//     prevProps.removeField === nextProps.removeField &&
//     prevProps.selectedFields === nextProps.selectedFields
//   );
// });

export default SectionFilterMobile;
