import { defaultOptions, setCookie } from "../../app/helpers/commonFunctions";
import { ActionDimensions } from "../dimensions/ActionDimensions";
import { DEFAULT_VALUE } from "../types/LoggingTypes";
import Cookies from 'js-cookie';

const updateScreenHistory = (screenName : string)=>{
  const prevScreenParsed = Cookies.get('prevScreen') ?? '';
  const prevScreenArr = prevScreenParsed.split(',')
  let prevScreen = '';
  if(prevScreenArr.length === 1)
    prevScreen = prevScreenArr[0];
  else
    prevScreen = prevScreenArr[1];
  if(!prevScreen)
    prevScreen = DEFAULT_VALUE;
  ActionDimensions.getInstance().currScreen = screenName;
  ActionDimensions.getInstance().prevScreen = prevScreen;
  if(ActionDimensions.getInstance().sessionFunnelSource === DEFAULT_VALUE)
  {
    const urlParams = new URLSearchParams(window.location.search)
    const flow = urlParams.get('flow') ?? 'add';
    switch(flow)
    {
        case 'add':
        case 'edit':
            ActionDimensions.getInstance().sessionFunnelSource = prevScreen;
            break;
        default:
          break;
    }
  }
  setCookie('prevScreen', screenName, {...defaultOptions, expires: 10})
}

export {updateScreenHistory}