import Skeleton from 'react-loading-skeleton'

export function UseBundleLoader() {
  return (
    <div className='text-center ' style={{height: '50vh'}}>
      <div className='row justify-content-center'>
        <div className='mt-3'>
          <Skeleton className='w-100  h-150px my-2' />
        </div>
      </div>

      <div className='row justify-content-center'>
        <div className=''>
          <Skeleton className='w-100 h-75px my-1' />
        </div>
      </div>
      <div className='row justify-content-center my-1'>
        <div className=''>
          <Skeleton className='w-100 h-35px' />
        </div>
      </div>
      <div className='row justify-content-center mt-1'>
        <div className="p-3">
          <Skeleton className='w-100  h-125px' style={{borderRadius: '6px'}} />
        </div>
      </div> <div className='row justify-content-center'>
        <div className="px-3">
          <Skeleton className='w-100  h-125px' style={{borderRadius: '6px'}} />
        </div>
      </div>
    </div>
  )
}
