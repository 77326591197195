import { useEffect, useMemo, useState } from "react";
import getCategoriesTextApi from "../../../../api/buyNow/getCategoriesText.api";

import { Modal } from 'react-bootstrap';
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import createPaymentApi from "../../../../api/buyNow/createPayment.api";
import getRedirectCartUrl from "../../../../api/buyNow/getRedirectCartUrl";
import getCategoriesApi from "../../../../api/category/getCategories.api";
import { PointOfSales } from "../../../helper/PointOfSales";
import { showError } from "../../../helper/SystemAlert";
import { KTSVG } from "../../../helpers";
import { getCookies, redirectionInterceptor } from "../../../helpers/commonFunctions";
import { useLang } from "../../../i18n/OpenSooqi18n";
import { BuyNowServiceInterface, FlowEnum } from "../../../interfaces/BuyNowServiceInterface";
import { CategoryInterface } from "../../../interfaces/CategoryInterface";
import { SubCategoryInterface } from "../../../interfaces/SubCategoryInterface";
import { BundleCategoryComponent } from "./BundleCategoryComponent";
import { BundleListingCredits } from "./BundleLisitingCredits";
import { BundlePromotionCredits } from "./BundlePromotionCredits";
import { BundleServiceDetailsCredits } from "./BundleServiceDetailsCredits";
import { BundleSubCategoryComponent } from "./BundleSubCategoryComponent";

type Props = {
  show: boolean
  handleClose: () => void
  children?: any
  flow: FlowEnum
  redirectTo ?: string
}

type ICategoryText = {
    header : string
    searchBoxPlaceHolder : string
    subHeader : string
}

type IServiceText = {
    header? : string
    blueUnderLineText? : string
    salesTeamButton? : string
    subHeader? : string
    buttonsHeader? : string
    whatsAppButtonValue? : string
    whatsAppButtonText? : string
}

const BuyNowModal = ({show,handleClose,children,flow,redirectTo}: Props) => {
    const lang = useLang()
    const Intl = useIntl()
    const [searchText, setSearchText] = useState<string>("");
    const [categories,setCategories] = useState<CategoryInterface[]>([])
    const [filteredCategories,setFilteredCategories] = useState<CategoryInterface[]>([])
    const [filteredSubCategories,setFilteredSubCategories] = useState<SubCategoryInterface[]>([])
    const [categoryPicked,setCategoryPicked] = useState<boolean>(false)
    const [categoryText, setCategoryText] = useState<ICategoryText>({header: "", searchBoxPlaceHolder: "", subHeader:""})
    const [subCategories,setSubCategories] = useState<SubCategoryInterface[]>([])
    const [subCategory, setSubCategory] = useState<SubCategoryInterface>()
    const [subCategoryPicked, setSubCategoryPicked] = useState<boolean>(false)
    const [subCategoryText, setSubCategoryText] = useState<ICategoryText>({header: "", searchBoxPlaceHolder: "", subHeader:""})
    const [service,setService] = useState<BuyNowServiceInterface>()
    const [servicePicked, setServicePicked] = useState<boolean>(false)
    const [serviceText, setServiceText] = useState<IServiceText>({})
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [productAssignId, setProductAssignId] = useState<number>()
    const [qty, setQty] = useState<number>()
    const [isRedirecting, setIsRedirecting] = useState<boolean>(false)
    const [headerText, setHeaderText] = useState('');
    const [subHeaderText, setSubHeaderText] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [learnMore, setLearnMore] = useState(false);

    const isService = !isLoading && !servicePicked && categoryPicked && subCategoryPicked
    const isSubCategory =  !isLoading && !servicePicked && categoryPicked && !subCategoryPicked && subCategories
    const isCategory = !isLoading && !servicePicked  && !categoryPicked && filteredCategories


    useEffect(()=>{
        if(!show) return
        setIsLoading(true)
        getCategoriesText()
        // getCategories()
    },[show]);


    const changeHeaderHandler = (header: string)=>{
        setHeaderText(header);
    }


    const changeSubHeaderHandler = (subHeader: string)=>{
        setSubHeaderText(subHeader);
    }

    const changeSelectedService = (selectedService: string) => {
        setSelectedService(selectedService);
    }
    
    const toggleLearnMore = ()=>{
        setLearnMore((old) => !old);
    }

    const getAdvertisementServicesDetailsUrl = () => {
        const adv = (lang === 'ar') ? 'الخدمات-الاعلانية' : 'advertising-products'
        return (process.env.REACT_APP_OPENSOOQ_BASE) ? process.env.REACT_APP_OPENSOOQ_BASE.replace("{country}", getCookies().ecountry ?? '').replace("{lang}", lang ?? '') + '/'+ adv : ''
    }

    const getCategories = ()=>{
        getCategoriesApi().then(res => {
            setCategories(res.data.items)
            setFilteredCategories(res.data.items)
            setIsLoading(false)
        }).catch(error=>{
            if(error.response && error.response.data){
                showError(Intl, lang, error.response.data)
              }
            else{
                showError(Intl, lang)
            }
        })
    }
    
    const getCategoriesText = ()=>{
        getCategoriesTextApi().then(res => {
            setCategories(res.data.items)
            setFilteredCategories(res.data.items)
            setCategoryText(res.data.mainCategories)
            setSubCategoryText(res.data.subCategories)
            changeHeaderHandler(res.data.mainCategories.header)
            changeSubHeaderHandler(res.data.mainCategories.subHeader)
            setIsLoading(false)
        }).catch(error=>{
            if(error.response && error.response.data){
                showError(Intl, lang, error.response.data)
              }
            else{
                showError(Intl, lang)
            }
        });
    }
    
    const createPayment = ()=>{
        if(!productAssignId || !qty)
        return;
        setIsRedirecting(true)
        createPaymentApi(productAssignId.toString(), qty, PointOfSales.ACCOUNT_POS_WALLET_BUY_PROMOTIONAL_CREDIT, "0").then(res=>{
            if(res && res.data){
                const sourceTransactionId = res.data.source_transaction_id
                getRedirectCartUrl(sourceTransactionId).then(res=>{
                    if(res && res.data && res.data.redirect){
                        window.location.href = res.data.redirect
                        setIsRedirecting(false)
                    }
                }).catch(error=>{
                    if(error.response && error.response.data){
                        showError(Intl, lang, error.response.data);
                    }else{
                        showError(Intl, lang)
                    }
                    setIsRedirecting(false)
                });
            }
        }).catch(error=>{
            if(error.response && error.response.data){
                showError(Intl, lang, error.response.data);
            }else{
                showError(Intl, lang)
            }
            setIsRedirecting(false)
        })
    }

    const setProductAssignIdFromChild = (productAssignIdState:number): void=>{
        setProductAssignId(productAssignIdState)
    }

    const setQtyFromChild = (qtyState : number) : void=>{
        setQty(qtyState)
    }

    const search = (value : string) => {
        if(!categoryPicked){
        setSearchText(value)
        let cats = categories
        if(value !== '' && value ){
            cats = categories?.filter((cat) => {
                 if(cat.label_ar.toLowerCase().includes(value.toLowerCase()) || cat.label_en.toLowerCase().includes(value.toLowerCase())){
                    return true
                 }
                 return false
            })
        }
        setFilteredCategories(cats);
        }else {
            setSearchText(value);
            let subCats = subCategories;
            if(value !== '' && value ){
                subCats = subCategories?.filter((cat) => {
                     if(cat.label_ar.toLowerCase().includes(value.toLowerCase()) || cat.label_en.toLowerCase().includes(value.toLowerCase())){
                        return true
                     }
                     return false
                })
            }
            setFilteredSubCategories(subCats);
        }
    }

    const pickCategory = (category : CategoryInterface) => {
        setCategoryPicked(true)
        setSearchText('')
        setSubCategories(category.subCategories)
        setFilteredSubCategories(category.subCategories)
        changeHeaderHandler(subCategoryText.header);
        changeSubHeaderHandler(subCategoryText.subHeader)
    }

    const backActionHandler = () => {
        if(learnMore){
            setLearnMore(false)
            
        }
        else if(selectedService)
        {
            setSelectedService('');
        }
        else if(servicePicked && categoryPicked && subCategoryPicked)
        {
            setServicePicked(false)
        }
        else if(categoryPicked && subCategoryPicked)
        {
            setSubCategoryPicked(false)
            setFilteredSubCategories(subCategories)
            setHeaderText(subCategoryText.header);
            setSubHeaderText(subCategoryText.subHeader);
            setSearchText('')  
        }else if(categoryPicked && !subCategoryPicked)
        {
            setCategoryPicked(false)
            setFilteredCategories(categories)
            setHeaderText(categoryText.header);
            setSubHeaderText(categoryText.subHeader);
            setSearchText('')
        }
    }

    const pickSubCat = (subCat : SubCategoryInterface) => {
        setSubCategoryPicked(true)
        setSubCategory(subCat)
        setSearchText('')
        setHeaderText(undefined);
        setSubHeaderText(undefined);
    }

    const closeHandler = () => {
        handleClose()
        setTimeout(()=>{
            setCategoryPicked(false)
            setSubCategoryPicked(false)
            setServicePicked(false)
            setService(undefined)
            setSelectedService('')
        }, 1000)
    }

    const skeletonRows = useMemo(()=>{
        let rows = []
        for (let i = 0; i < 10; i++) {
            rows.push(<Skeleton className="m-5 h-35px w-400px" key={i}/>)
        }
        return rows;
    }, []);

    return  <>
        <Modal id='iframe'tabIndex={-1} aria-hidden='true' dialogClassName={`modal-dialog modal-dialog-centered ${isService && !learnMore && flow === FlowEnum.ADD_LISTING ? 'modal-xl':''}`} show={show} onHide={closeHandler}  >
            <div className='modal-header p-5'>
                {!isLoading && headerText ? <div className="h1">
                    { (isSubCategory || isService) && <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={backActionHandler}>
                         <div style={{display:"flex"}} className="align-self-center p-2">{lang === 'ar' ? <><i className="fas fs-2 fa-arrow-right"></i></> : <><i className="fas fs-2 fa-arrow-left"></i></>}</div>
                    </div>}
                    {headerText}
                </div> : <span className="h1"><Skeleton className="h-30px w-300px"/></span>}
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeHandler}>
                    <KTSVG className='svg-icon-1 fa-5x' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className={`modal-body py-5 overflow-scroll ${ (isService) || (isLoading && service !== undefined) ? '' : 'h-800px'}`} >
                {!isLoading && subHeaderText && <div className="h4">{subHeaderText}</div>}
                {isLoading && <Skeleton className="h-20px w-250px"/>}
                {isService && serviceText && <a className="text-primary hoverPointer fs-4" target="_blank" onClick={redirectionInterceptor} href={`${getAdvertisementServicesDetailsUrl()}`}>{serviceText.blueUnderLineText}</a>}
                <div className={`row ${ categoryPicked && subCategoryPicked ? 'pt-5' : ''}`}>
                    {(!categoryPicked || !subCategoryPicked) && <div className="col-12 py-5">
                        <div className={`d-flex align-items-center position-relative my-1 w-100 `}>
                            <span className="svg-icon svg-icon-1 position-absolute ms-4">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                    <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                </svg>
                            </span>
                            { !isLoading ? <input type="text" data-kt-ecommerce-order-filter="search" value={searchText} onChange={(e) => {search(e.target.value)}} className="form-control form-control-solid ps-14" placeholder={isCategory ? categoryText.searchBoxPlaceHolder : subCategoryText.searchBoxPlaceHolder}/> : <Skeleton className="h-25px w-400px"/>}
                        </div>
                    </div>}
                    { isLoading && skeletonRows}
                    { isService && flow === FlowEnum.ADD_LISTING && <BundleListingCredits redirectTo={redirectTo} pos={PointOfSales.ACCOUNT_POS_WALLET_TOPUP_ADD_LISTING_CREDITS}  learnMore={learnMore} toggleLearnMore={toggleLearnMore} subCatReportingName={subCategory.reporting_name} changeHeaderHandler={changeHeaderHandler} changeSubHeaderHandler={changeSubHeaderHandler}/>}
                    { isService && flow === FlowEnum.ADD_PROMOTION && <BundlePromotionCredits handleClose={closeHandler} learnMore={learnMore} toggleLearnMore={toggleLearnMore} selectedService={selectedService} changeSelectedService={changeSelectedService} subCatReportingName={subCategory.reporting_name} flow={flow} changeHeaderHandler={changeHeaderHandler} changeSubHeaderHandler={changeSubHeaderHandler}/>}
                    { isService && flow === FlowEnum.ADD_REPOST && <BundleServiceDetailsCredits redirectTo={redirectTo} pos={PointOfSales.ACCOUNT_POS_WALLET_TOPUP_ADD_REPOST_CREDIT} learnMore={learnMore} toggleLearnMore={toggleLearnMore} serviceName="Bumpup" subCatReportingName={subCategory.reporting_name} changeHeaderHandler={changeHeaderHandler} changeSubHeaderHandler={changeSubHeaderHandler}/>}
                    { isSubCategory && <BundleSubCategoryComponent lang={lang} searchData={filteredSubCategories} action={pickSubCat}/>} 
                    { isCategory && <BundleCategoryComponent lang={lang} searchData={filteredCategories} action={pickCategory}/>}
                </div>
            </div>
            {isLoading && isService &&  <div className="modal-footer">
                <div className="col-12 ">
                    <div className="d-flex align-items-center">             
                        <span className="fw-semibold h1 text-gray-800 flex-grow-1 ">
                            <span className="h1"><Skeleton className="h-20px w-100px"/></span> 
                        </span> 
                        <span className="h1"><Skeleton className="h-20px w-100px"/></span> :
                    </div>
                </div>
                <button className="btn fs-3 btn-primary w-100 btn-block" onClick={createPayment} disabled={isRedirecting}>
                    {!isRedirecting && <span>{Intl.formatMessage({id : 'buyNow'})}</span>}
                    {isRedirecting && <span>{Intl.formatMessage({id : 'pleaseWait'})}
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>}
                </button>            
            </div>}
            {(isLoading && service !== undefined) && <div className="modal-footer">
                <div className="container ">
                    <div className="row-12 mb-5">
                    <div className="row-12 mx-auto d-flex flex-row justify-content-between">
                        <span className="h1"><Skeleton className="h-20px w-150px"/></span>
                        <span className="h1"><Skeleton className="h-20px w-150px"/></span>
                    </div>
                    </div>
                    <div className="row-12 mx-auto d-flex flex-row justify-content-center">
                        <span className="h1"><Skeleton className="h-50px w-400px"/></span>
                    </div>
                </div>                         
            </div>}
        </Modal>
    </>
}

export { BuyNowModal };
