import DynamicObject from 'opensooq-notification/dist/esm/dynamic-object'
import React from 'react'
import {getNoImageLogo} from '../../../helpers/commonFunctions'
import {useIntl} from 'react-intl'
import {useLang} from '../../../i18n/OpenSooqi18n'
import OpenSooqIcons from '../../common/OpenSooqIcons'

type props = {
  listing: DynamicObject
  index: number
  isSelected: boolean
  isDisabled: boolean
  changeSelectListingHandler: (id: number) => void
  selectedListingMap: DynamicObject
  isAllowedToSelect: boolean
}

export default function DesktopBundleCard({
  listing,
  index,
  isSelected,
  isDisabled,
  changeSelectListingHandler,
  isAllowedToSelect,
}: props) {
  const Intl = useIntl()
  const lang = useLang()

  const getListingImage = (listing: DynamicObject) => {
    let image = getNoImageLogo(lang)
    if (listing.medias_count.images > 0) {
      image = process.env.REACT_APP_ARBOK_URL + '200x0/' + listing.main_image_uri + '.jpg'
    }
    return image
  }

  return (
    <div
      className={`d-flex flex-row justify-content-between ${isAllowedToSelect ?  "hoverPointer": ''} align-items-center`}
      key={index}
      data-id={listing.listing_id}
      onClick={() => changeSelectListingHandler(listing.listing_id)}
    >
      <div style={{width: '90%'}} className=''>
        <div className='d-flex align-items-start'>
          <div
            className={`sc-265a2526-1 jRUVEn postImgCont position-relative radius-8 overflowHidden marginEnd-16px ixKSmm`}
          >
            <img
              src={getListingImage(listing)}
              width='272'
              height='204'
              className='width-100  customImg height-100 '
              alt={listing.title}
              loading='eager'
            />
          </div>
          <div
            className='d-flex justify-content-start flex-column '
            style={{overflowWrap: 'anywhere'}}
          >
            <span className='customPostTitleText fw-bolder '>{listing.title}</span>
            <span className='fw-semibold d-block fs-7 '>{listing.sub_title}</span>
            <span className='fw-semibold d-block fs-7 '>
              {listing.category} {Intl.formatMessage({id: 'in'})} {listing.city}
            </span>
            <span className='fw-semibold d-block fs-7 '>
              {' '}
              {!listing.has_price ? (
                Intl.formatMessage({id: 'noPrice'})
              ) : listing.priceVal ? (
                <span className='' dir='rtl'>
                  {listing.priceVal.toLocaleString() + ' ' + listing.price_currency}{' '}
                </span>
              ) : (
                <></>
              )}
            </span>
            <div
              className='d-flex justify-content-start flex-column'
              style={{overflowWrap: 'anywhere'}}
            >
              <span className='customPostTitleText fw-bolder'>{listing.listing_id}</span>
              <div
                className='d-flex fw-semibold d-block fs-7 justify-content-start flex-column'
                style={{overflowWrap: 'anywhere'}}
              >
                {listing.edits_left && (
                  <>
                    <div className='fw-400 d-inline'>
                      {Intl.formatMessage({id: 'myListings.text43'}) + ' '}
                      <span className='fw-bold'>
                        {' '}
                        {listing.edits_left === -1
                          ? Intl.formatMessage({
                              id: 'myOpenSooq.unlimited',
                            })
                          : listing.edits_left}{' '}
                      </span>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{width: '10%'}} className='d-flex justify-content-between'>
        <div className='text-start pe-0'>
          <div
            className='d-flex justify-content-start flex-column'
            style={{overflowWrap: 'anywhere'}}
          >
            <span
              className={`customPostTitleText fw-bolder ${
                listing.active ? 'text-success' : 'text-danger'
              } `}
            >
              {listing.listing_status_parent}
            </span>
            <span className='fw-semibold d-block fs-7'>{listing.listing_status}</span>
            <div className='d-flex flex-column bd-highlight mb-3'>
              {listing.service_name.map((service: any, index: number) => {
                return (
                  <div key={index} className='d-flex justify-content-start'>
                    <span>
                      <OpenSooqIcons
                        className='me-1 fw-semibold d-block fs-7'
                        width={20}
                        height={20}
                        icon={service.icon.toLowerCase()}
                      />
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <div>
          <div
            className='d-flex justify-content-center flex-column'
            style={{overflowWrap: '-moz-initial'}}
          >
            <div className='d-flex align-items-center hoverPointer'>
              <input
                type='checkbox'
                className='hoverPointer'
                disabled={isDisabled}
                checked={isSelected}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
