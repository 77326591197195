import { TransactionHistoryInterface } from "../../../interfaces/TransactionsHistoryInterface";
import getTransactionsHistoryApi from "../../../../api/getTransactionsHistory.api";
import { useLang } from './../../../../app/i18n/OpenSooqi18n'
import { useIntl } from "react-intl";
import React, { Fragment, lazy, useEffect, useRef, useState } from "react";
import Moment from 'moment';
import { BalanceWidget } from "./BalanceWidget";
import { BuyNowModal } from "./BuyNowModal";
import WalletTransactions from "./WalletTransactions";
import Bundles from "./Bundles";
import BuyBundles from "./BuyBundle";
import { BundleInterface, WalletDetailsInterface } from "../../../interfaces/WalletDetailsInterface";
import getWalletDetailsApi from "../../../../api/getWalletDetails.api";
import { logScreenLoad } from "../../../../logging/helpers/commonLoggingFunctions";
import { TopUpModal } from "./TopUpModal";
import { FlowEnum } from "../../../interfaces/BuyNowServiceInterface";
import { RechargeVoucherModal } from "./RechargeVoucherModal";
import { SliderModal } from "./SliderModal";
import { deepLinkHandler, deepLinks } from "../../../helpers/commonFunctions";
const AddCreditModal = lazy(() => import('./AddCreditModal'))


const MyWallet = () => {
    const [transactionsHistory,setTransactionHistory] = useState <TransactionHistoryInterface[]>([])
    const to : string = new URLSearchParams(window.location.search).get('to') ?? '';
    const [searchData,setSearchData] = useState <BundleInterface[]>([]);
    const [services,setServices] = useState <BundleInterface[]>([])
    const [walletDetails,setWalletDetails] = useState <WalletDetailsInterface>()
    const [transactionsLoader,setTransactionsLoader] = useState (true)
    const [servicesLoader,setShowServicesLoader] = useState (true)
    const refTransactions = useRef<null | HTMLDivElement>(null)
    const [searchString, setSearchString] = useState("")
    const refBundle = useRef<null | HTMLDivElement>(null);
    const Intl = useIntl();
    const lang = useLang()
    const [categoriesFilter,setCategoriesFilter] = useState()
    const [bundlesFilter,setBundlesFilter] = useState()
    const [showBuyNowModal, setShowBuyNowModal] = useState<boolean>(false);
    const [showTopUpModal, setShowTopUpModal] = useState<boolean>(false);
    const [flow, setFlow] = useState<FlowEnum>(undefined);
    const [showAddCreditModal, setShowAddCreditModal] = useState<boolean>(false);
    const [showRechargeVoucherModal, setShowRechargeVoucherModal] = useState<boolean>(false);
    const [showSlidingModal, setShowSlidingModal] = useState<boolean>(false);
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const action = params.get('action') ?? '';
    const actionFlow = params.get('actionFlow') ?? '';
    useEffect(() => {
        getTransactions();
        getWalletDetails();
    },[]);

    useEffect(()=>{
        logScreenLoad()
    }, [])

    const setFlowHandler = (flow : number)=>{
        setFlow(flow)
    }

    useEffect(()=>{
        setShowTopUpModal(false);
        if(action != '' && action == 'openWalletBundles'){
            showTopUpModalHandler()
        }
        switch(flow)
        {
            case FlowEnum.ADD_CREDIT:
                setShowAddCreditModal(true);
                break;
            case FlowEnum.ADD_LISTING:
            case FlowEnum.ADD_PROMOTION:
            case FlowEnum.ADD_REPOST:
                setShowBuyNowModal(true);
                break;
            case FlowEnum.RECHARGE:
                setShowRechargeVoucherModal(true);
                break;
            case FlowEnum.SLIDER:
                deepLinkHandler(deepLinks.contactUs[lang] + "/15/99")
                hideTopUpModal();
                break;
                
        }
    }, [flow])

    useEffect(()=>{
        if(actionFlow != '' && flow == undefined){
            if(isNaN(parseInt(actionFlow))) return;
            setFlow(parseInt(actionFlow));
            setShowTopUpModal(false)
            return;
        }
    },[])

    const getWalletDetails = () => {
        getWalletDetailsApi().then(response => {
            setWalletDetails(response.data);
            setServices(prevService =>{
                return response.data.bundle.slice();
            })

            setSearchData(prevService =>{
                return response.data.bundle.slice();
            })

            setShowServicesLoader(false);

            if(refBundle.current && to == 'bundles'){
                refBundle.current.scrollIntoView()
            }

        }).catch(() => {
            setShowServicesLoader(false);
        });
    }

    const getTransactions = () => {
        if(to == 'transactions' && refTransactions.current){
            refTransactions.current.scrollIntoView()
        }
        getTransactionsHistoryApi().then(response => {
            const transactions = response.data.items;
            setTransactionHistory(transactions);
            setTransactionsLoader(false);

        }).catch(() => {
            setTransactionsLoader(false);
        });
    }

    useEffect(() => {
        const bundles : any = [];
        const categoriesList : any = [];
        let categoriesFilterData : any = [{value : '',label : Intl.formatMessage({id : 'showAll'}) + ' ' + Intl.formatMessage({id : "category"})}]
        let bundlesFilterData : any = [{value : '',label : Intl.formatMessage({id : 'showAll'}) + ' ' + Intl.formatMessage({id : "bundles"})}]
        services.map((bundle : BundleInterface) => {
            let serviceName = bundle.label;
            if(!bundles.includes(serviceName)){
                bundles.push(serviceName);
                bundlesFilterData.push({value : serviceName,label : serviceName})
            }

            if(!categoriesList.includes(bundle.catLabel)){
                categoriesList.push(bundle.catLabel);
                categoriesFilterData.push({value : bundle.catLabel,label : bundle.catLabel})
            }
        });

        setCategoriesFilter(categoriesFilterData);
        setBundlesFilter(bundlesFilterData);

    },[services]);

    const collapse = (e: any) => {
        if(e.currentTarget.classList.contains('active')){
            e.currentTarget.classList.remove('active');
        }else{
            e.currentTarget.classList.add('active');
        }
    };

    const transactionsList = () => {
        return transactionsHistory.map((transaction  : TransactionHistoryInterface,index) => {
            const dt = transaction.record_insert_date;
            const credit = transaction.credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            const debit = transaction.debit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return <React.Fragment key={index}>
                <tr>
                    <td className="">
                        <span className="d-flex flex-column">
                            <span className="fs-6 fw-bold text-gray-900">{(transaction.credit > 0 ) ? Intl.formatMessage({id: 'myWallet.text9'}): Intl.formatMessage({id: 'myWallet.text8'})}</span>
                            <span className="fs-7 fw-semibold text-gray-700 text-muted">{transaction.ref_description}</span>
                        </span>
                    </td>
                    <td className="hideOnMobile">{ Moment(dt).format('DD-MM-YYYY')}</td>
                    <td className="hideOnMobile">{transaction.id}</td>
                    <td className='hideOnMobile'>
                        <span className={`${(transaction.credit > 0 ) ? `text-success`: `text-danger`}`}>{(transaction.credit > 0 ) ? `${credit}`: `-${debit}`}</span>
                    </td>
                    <td className="ps-9 hideOnDesktop text-center">
                        <button onClick={(e) => {collapse(e)}} type="button" id="kt_accordion_tr-1" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row" data-bs-toggle="collapse" data-bs-target={`#id-${transaction.id}`}>
                            <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1"
                                        transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                </svg>
                            </span>
                            <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                </svg>
                            </span>
                        </button>
                    </td>
                </tr>
                <tr id={`id-${transaction.id}`} className="hideOnDesktop collapse" data-bs-parent="#kt_accordion_tr-1"  >
                    <td colSpan={6} className="shadow1">
                        <dl className="row mb-0 ">
                            <dt className="col-5 text-gray-500 fw-bolder">{Intl.formatMessage({id: 'transactionId'})}</dt>
                            <dd className="col-7">{transaction.id}</dd>
                        </dl>
                        <dl className="row mb-0 ">
                            <dt className="col-5 text-gray-500 fw-bolder">{Intl.formatMessage({id: 'amount'})}</dt>
                            <dd className={`col-7`}><span className={`${(transaction.credit > 0 ) ? `text-success`: `text-danger`}`}>{(transaction.credit > 0 ) ? `${credit}`: `${debit}`}</span> </dd>
                        </dl>
                        <dl className="row mb-0 ">
                            <dt className="col-5 text-gray-500 fw-bolder">{Intl.formatMessage({id: 'date'})}</dt>
                            <dd className="col-7">{ Moment(dt).format('DD-MM-YYYY')}</dd>
                        </dl>
                    </td>
                </tr>
            </React.Fragment>
        });
    };

    const setSearch = (e: any) => {
        setSearchString(e.target.value);
    };

    useEffect(() => {
        const newList = services.filter(service => {
            if(service.label.toLowerCase().includes(searchString.toLowerCase())){
                return true
            }
            if(service.availableQuantity.toString().includes(searchString.toLowerCase())){
                return true;
            }
            if(service.catLabel.toLowerCase().includes(searchString.toLowerCase())){
                return true;
            }
            return false;
        })
        setSearchData(newList);
    },[searchString]);

    const filterByBundle =  (searchBy : any) => {
        let newList = [];
        if(searchBy.value == ''){
            newList = services.filter(service => {
                return service
            })
        }else{
            newList = services.filter(service => {
                let serviceName = service.label;
                return serviceName == searchBy.value
            })
        }

        setSearchData(newList);
    }

    const filterByCategory =  (e : any) => {
        let newList = [];
        if(e.value == ''){
            newList = services.filter(service => {
                return service
            })
        }else{
            newList = services.filter(service => service.catLabel.includes(e.value))
        }
        setSearchData(newList);
    }
    
    const hideBuyNowModalHandler = ()=>{
        setFlow(undefined);
        setShowBuyNowModal(false);
    }
    
    const showTopUpModalHandler = ()=>{
        setShowTopUpModal(true);
    }
    
    const hideAddCreditModal = ()=>{
        setShowAddCreditModal(false);
        setFlow(undefined)
    }
    const hideTopUpModal = ()=>{
        setShowTopUpModal(false);
        setFlow(undefined)
    }
    const hideRechargeVoucherModal = ()=>{
        setShowRechargeVoucherModal(false);
        setFlow(undefined)
    }
    const hideSlidingModal = ()=>{
        setShowSlidingModal(false);
        setFlow(undefined)
    }

    return (
        <div className="row g-5">
           <div className='col-lg-7 col-md-6'>
               <BalanceWidget action={showTopUpModalHandler} walletDetails={walletDetails}/>
            </div>
            <div className="col-lg-5 col-md-6 ">
                <BuyBundles dataID="my-wallet-buyBundleW-creditBtn"  action={showTopUpModalHandler} Intl={Intl} lang={lang} />
            </div>
            <div className="col-12">
                 <Bundles getWalletDetails={getWalletDetails} walletDetails={walletDetails} filterByBundle={filterByBundle} filterByCategory={filterByCategory} setSearch={setSearch} servicesLoader={servicesLoader} services={services} searchData={searchData} collapse={collapse} categoriesFilter={categoriesFilter} bundlesFilter={bundlesFilter}/>
            </div>
            <div className="col-12" >
                <WalletTransactions transactionsList={transactionsList} transactionsLoader={transactionsLoader}/>
            </div>
            <TopUpModal show={showTopUpModal} handleClose={hideTopUpModal} setFlow={setFlowHandler}/>
            {showAddCreditModal && <AddCreditModal show={showAddCreditModal} handleClose={hideAddCreditModal}/>}
            <BuyNowModal show={showBuyNowModal} handleClose={hideBuyNowModalHandler} children={<></>} flow={flow}></BuyNowModal>
            <RechargeVoucherModal show={showRechargeVoucherModal} handleClose={hideRechargeVoucherModal}/>
            <SliderModal show={showSlidingModal} handleClose={hideSlidingModal}/>
        </div>
    )
}

export {MyWallet}
