import {useCallback, useContext, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import Skeleton from 'react-loading-skeleton'
import {useNavigate} from 'react-router-dom'
import {Button} from '../../../app/layout/components/opensooq/Button'
import {
  logAccountEditClick,
  logOrderStartClick,
  logViewCvClick,
} from '../../../logging/helpers/commonLoggingFunctions'
import {DEFAULT_AVATAR} from '../../helper/ImagePaths'
import {SHOP_MEMBER_TYPE} from '../../helper/MemberTypes'
import {PointOfSales} from '../../helper/PointOfSales'
import {
  generateOpenSooqRedirectUrl,
  getCookies,
  getProfileImage,
  getSiteCustomContent,
  preloadImage,
  redirectionInterceptor,
} from '../../helpers/commonFunctions'
import {AuthContext} from '../../i18n/AuthProvider'
import {getProfileFunction} from '../../helpers/commonFunctions'
import {useLang} from '../../i18n/OpenSooqi18n'
import {MemberInfoInterface} from '../../interfaces/MemberInfoInterface'
import {NAV_NAMES} from '../accounts/manageAccount/ManageAccount'
import {MemberShipModal} from '../payment/MemberShipModal'
import {ImageType} from '../../interfaces/Image'

interface Props {
  action?: (params: any) => any
  profile?: MemberInfoInterface
  comeFrom?: string
  hideEdit?: boolean
}

export function ProfileHeader(props: Props) {
  const [profile, setProfile] = useState<MemberInfoInterface>()
  const defaultProfileImage = getProfileImage()
  const {userIdentity} = useContext(AuthContext)
  const [showBundle, setShowBundle] = useState<boolean>(false)
  const navigate = useNavigate()
  const Intl = useIntl()
  const lang = useLang()
  const [pos, setPos] = useState('')
  const [image, setImage] = useState<ImageType>({imageSrc: defaultProfileImage, isDefault: true})
  const [opensooqUrlProfile, setOpensooqUrlProfile] = useState('')

  useEffect(() => {
    if (props && props.hideEdit) {
      setPos(PointOfSales.ACCOUNT_PROFILE_HEADER)
      return
    }
    setPos(PointOfSales.UPGRADE_MEMBERSHIP_PROFILE_HEADER_WIDGET)
  }, [pos])

  useEffect(() => {
    if (!props.profile) {
      getProfileFunction().then((response) => {
        const results = response.data
        setProfile(results)
        if (props.action) {
          props.action(results)
        }
      })
    } else {
      setProfile(props.profile)
    }
  }, [])

  useEffect(() => {
    if (
      !userIdentity ||
      (userIdentity &&
        userIdentity.profile_picture &&
        userIdentity.profile_picture.includes('no_profile'))
    )
      return
    preloadImage(userIdentity.profile_picture, (src: string) => {
      setImage({imageSrc: src, isDefault: false})
    })
  }, [userIdentity])

  function navigateTo(url: string) {
    navigate(url)
  }

  const getImage = (image: string) => {
    return !image ? DEFAULT_AVATAR : image
  }

  const showBundleModalHandler = useCallback(() => {
    //logging
    logOrderStartClick()
    //
    setShowBundle(true)
  }, [])

  const editProfileClickHandler = () => {
    logAccountEditClick({id: profile.member_basic.id})
    navigateTo(`/account/my-account/edit-profile?activeNav=${NAV_NAMES.EDIT_PROFILE}`)
  }

  useEffect(() => {
    var baseUrl = process.env.REACT_APP_OPENSOOQ_BASE.replace('/{lang}', '')
    const url =
      generateOpenSooqRedirectUrl(baseUrl ?? '', getCookies().ecountry ?? '') +
      userIdentity?.memberLink +
      `?utm_source=opensooq&utm_medium=share&utm_campaign=view_account_from_myopensooq`
    setOpensooqUrlProfile(url)
  }, [userIdentity])

  const content = getSiteCustomContent(Intl)
  return (
    <div className='card mb-5 mb-xxl-8'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              <img
                width={160}
                height={160}
                src={image.imageSrc}
                className={image.isDefault && 'bg-noImage'}
                alt='profileImage'
              ></img>
            </div>
          </div>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  {!profile && <Skeleton className='w-300px h-25px' />}
                  {profile && (
                    <span className='text-gray-900 fs-2 fw-bold me-1'>
                      {profile.member_basic.full_name}
                    </span>
                  )}
                  {profile && profile.member_basic.authorised_seller == 1 && (
                    <span className='svg-icon svg-icon-1 svg-icon-primary'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='24px'
                        height='24px'
                        viewBox='0 0 24 24'
                      >
                        <path
                          d='M10.0813 3.7242C10.8849 2.16438 13.1151 2.16438 13.9187 3.7242V3.7242C14.4016 4.66147 15.4909 5.1127 16.4951 4.79139V4.79139C18.1663 4.25668 19.7433 5.83365 19.2086 7.50485V7.50485C18.8873 8.50905 19.3385 9.59842 20.2758 10.0813V10.0813C21.8356 10.8849 21.8356 13.1151 20.2758 13.9187V13.9187C19.3385 14.4016 18.8873 15.491 19.2086 16.4951V16.4951C19.7433 18.1663 18.1663 19.7433 16.4951 19.2086V19.2086C15.491 18.8873 14.4016 19.3385 13.9187 20.2758V20.2758C13.1151 21.8356 10.8849 21.8356 10.0813 20.2758V20.2758C9.59842 19.3385 8.50905 18.8873 7.50485 19.2086V19.2086C5.83365 19.7433 4.25668 18.1663 4.79139 16.4951V16.4951C5.1127 15.491 4.66147 14.4016 3.7242 13.9187V13.9187C2.16438 13.1151 2.16438 10.8849 3.7242 10.0813V10.0813C4.66147 9.59842 5.1127 8.50905 4.79139 7.50485V7.50485C4.25668 5.83365 5.83365 4.25668 7.50485 4.79139V4.79139C8.50905 5.1127 9.59842 4.66147 10.0813 3.7242V3.7242Z'
                          fill='currentColor'
                        ></path>
                        <path
                          d='M14.8563 9.1903C15.0606 8.94984 15.3771 8.9385 15.6175 9.14289C15.858 9.34728 15.8229 9.66433 15.6185 9.9048L11.863 14.6558C11.6554 14.9001 11.2876 14.9258 11.048 14.7128L8.47656 12.4271C8.24068 12.2174 8.21944 11.8563 8.42911 11.6204C8.63877 11.3845 8.99996 11.3633 9.23583 11.5729L11.3706 13.4705L14.8563 9.1903Z'
                          fill='white'
                        ></path>
                      </svg>
                    </span>
                  )}
                  {profile && profile.member_basic.member_type != SHOP_MEMBER_TYPE && (
                    <span
                      onClick={showBundleModalHandler}
                      data-id='profile-header-upgradeToShop'
                      className='btn btn-sm btn-light-success fw-bold ms-2 fs-8 py-1 px-3'
                    >
                      {Intl.formatMessage({id: 'profileHeader.upgradeToShop'})}
                    </span>
                  )}
                </div>
                <div className='d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2'>
                  <span className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    <span className='svg-icon svg-icon-4 me-1'>
                      <svg
                        width='18'
                        height='18'
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        {' '}
                        <path
                          opacity='0.3'
                          d='M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z'
                          fill='currentColor'
                        ></path>{' '}
                        <path
                          d='M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z'
                          fill='currentColor'
                        ></path>{' '}
                        <rect x='7' y='6' width='4' height='4' rx='2' fill='currentColor'>
                          {' '}
                        </rect>{' '}
                      </svg>
                    </span>
                    {!profile && <Skeleton className='w-100px' />}
                    {profile && (
                      <span data-id='profile-header-memberSince'>
                        {Intl.formatMessage({id: 'profileHeader.memberSince'})}{' '}
                        {profile.member_basic.member_since}
                      </span>
                    )}
                  </span>
                  <span
                    className='d-flex align-items-center text-gray-400 me-5 mb-2'
                    data-id='profile-header-accountId'
                  >
                    <span className='svg-icon svg-icon-4 me-1'>
                      <i className='fas fa-id-card'></i>
                    </span>
                    {!profile && <Skeleton className='w-100px' />}
                    {profile && (
                      <span>
                        {Intl.formatMessage({id: 'profileHeader.accountId'})}{' '}
                        {profile.member_basic.id}
                      </span>
                    )}
                  </span>
                  <span
                    onClick={() => {
                      navigateTo('/account/followers')
                    }}
                    data-id='profile-header-followers'
                    className='hoverPointer  text-hover-primary d-flex align-items-center text-gray-400  me-5 mb-2'
                  >
                    <span className='svg-icon svg-icon-4 me-1'>
                      <i className='fas fa-users'></i>
                    </span>
                    {!profile && <Skeleton className='w-100px' />}
                    {profile && (
                      <span>
                        {Intl.formatMessage({id: 'profileHeader.followers'})}{' '}
                        {profile.stats.follower_total.toLocaleString()}{' '}
                      </span>
                    )}
                  </span>
                  <span
                    onClick={() => {
                      navigateTo('/account/following')
                    }}
                    data-id='profile-header-following'
                    className='hoverPointer  d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <span className='svg-icon svg-icon-4 me-1'>
                      <i className='fa fa-user-check'></i>
                    </span>
                    {!profile && <Skeleton className='w-100px' />}
                    {profile && (
                      <span>
                        {Intl.formatMessage({id: 'profileHeader.following'})}{' '}
                        {profile.stats.following_total.toLocaleString()}{' '}
                      </span>
                    )}
                  </span>
                </div>
              </div>
              <div className='d-flex my-4'>
                {profile?.member_basic.id ? (
                  <Button
                    action={() => {
                      logViewCvClick({id: getCookies().userInfo.id})
                      navigateTo('/account/cv/edit')
                    }}
                    setData='profile-header-myCVBtn'
                    text={Intl.formatMessage({id: 'profileHeader.myCv'})}
                    className='btn fw-bold btn-sm btn-primary me-2'
                  />
                ) : (
                  <Skeleton className='me-1 w-75px h-40px me-2 fw-bold' />
                )}
                {profile?.member_basic.id && !props.hideEdit && (
                  <Button
                    action={editProfileClickHandler}
                    setData='profile-header-editProfileBtn'
                    className='btn btn-sm btn-primary me-2 fw-bold'
                    text={Intl.formatMessage({id: 'profileHeader.editProfile'})}
                  />
                )}
                {!profile?.member_basic.id && !props.hideEdit && (
                  <Skeleton className='me-1 w-100px h-40px me-2 fw-bold' />
                )}
                {profile?.member_basic.id ? (
                  <a
                    target='_blank'
                    data-id='profile-header-viewProfileBtn'
                    className='btn btn-sm btn-primary me-2 fw-bold'
                    onClick={redirectionInterceptor}
                    href={opensooqUrlProfile}
                  >
                    {content.viewOnSite}
                  </a>
                ) : (
                  <Skeleton className='me-1 w-150px h-40px me-2 fw-bold' />
                )}
              </div>
            </div>
            <div className='d-flex flex-wrap flex-stack w-100'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div className='d-flex align-items-center'>
                      <div
                        className='fs-2 fw-bold counted'
                        data-kt-countup='true'
                        data-kt-countup-value='80'
                        data-kt-initialized='1'
                      >
                        {!profile && <Skeleton className='w-100px' />}{' '}
                        {profile && <span>{profile.limit_account_report.membership_label}</span>}
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        navigateTo('/account/my-account?activeNav=manageAccountSummary')
                      }}
                      data-id='profile-header-accountType'
                      className='hoverPointer text-primary fw-semibold fs-6 '
                    >
                      {Intl.formatMessage({id: 'profileHeader.accountType'})}
                    </div>
                  </div>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                    <div
                      className='fs-2 fw-bold counted ltrDir text-start'
                      data-id='profile-header-customerRatingText'
                    >
                      <i className='fas fa-star ps-1 pe-1 fs-4 ' style={{color: '#FFAD0F'}}></i>
                      {!profile && <Skeleton className='w-100px' />}{' '}
                      {profile &&
                        (profile.member_rating.customer_rating
                          ? profile.member_rating.customer_rating.toFixed(2)
                          : Intl.formatMessage({id: 'myOpenSooq.noRating'}))}
                    </div>
                    <div
                      onClick={() => {
                        navigateTo(`/account/my-rating?activeNav=${NAV_NAMES.RATING}`)
                      }}
                      data-id='profile-header-customerRating'
                      className='hoverPointer text-primary fw-semibold fs-6'
                    >
                      {Intl.formatMessage({id: 'profileHeader.customerRating'})}
                    </div>
                  </div>
                  {profile && profile.member_basic && profile.member_basic.response_time && (
                    <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3'>
                      <div className='d-flex align-items-center'>
                        <div className='fs-2 fw-bold counted'>
                          {!profile && <Skeleton className='w-100px' />}{' '}
                          {profile && <span>{profile.member_basic.response_time}</span>}
                        </div>
                      </div>
                      <div className='fw-semibold fs-6 text-gray-400'>
                        {Intl.formatMessage({id: 'profileHeader.chatResponseTime'})}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3 hoverPointer h-50px'
                onClick={() => {
                  navigateTo('/account/my-account?activeNav=manageAccountEditProfile')
                }}
                data-id='profile-header-profileCompletion'
              >
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-semibold fs-6 text-gray-400'>
                    {Intl.formatMessage({id: 'profileHeader.profileCompletion'})}
                  </span>
                  {profile && (
                    <span className='fw-bold fs-6'>
                      {profile.member_completion_rate.toFixed(1)}%
                    </span>
                  )}
                  {!profile && (
                    <span className='fw-bold fs-6'>
                      {' '}
                      <Skeleton className='w-100px h-10px' />
                    </span>
                  )}
                </div>
                {profile && (
                  <div className='h-5px mx-3 w-100 bg-light mb-3'>
                    <div
                      className='bg-success rounded h-5px'
                      style={{
                        width: `${
                          profile &&
                          profile.member_completion_rate &&
                          profile.member_completion_rate
                        }%`,
                      }}
                    ></div>
                  </div>
                )}
                {!profile && <div className='h-5px mx-3 w-100 bg-light mb-3'></div>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MemberShipModal
        show={showBundle}
        type={['MemberShip']}
        handleClose={() => {
          setShowBundle(false)
        }}
        id={profile?.member_basic.id}
        pos={pos}
      />
    </div>
  )
}
