import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import { MyAccountStatesInterface } from "../../interfaces/MyAccountStatesInterface";

type Props = {
    accountState ?: MyAccountStatesInterface
    navigateTo : (param : any) => void
}

const AccountStats = (props : Props) => {
    const Intl = useIntl();

    return  <div className="card card-flush h-lg-100">
        <div className="card-header mt-2">
            <div className="fs-3 card-title align-items-start flex-column">
                <span  className="card-label fw-bold text-dark"> {Intl.formatMessage({id : "myOpenSooq.accountStatistics"})}</span>
                <span className="text-gray-400 mt-1 fw-semibold fs-6">{!props.accountState && <Skeleton className="w-30px" />}{props.accountState && (props.accountState.cvCountTotal ?? 0 + props.accountState.postCountTotal ?? 0 + props.accountState.viewsCountTotal ?? 0).toLocaleString()} {Intl.formatMessage({id : "myOpenSooq.total"})}</span>
            </div>
            <div className="card-toolbar"> </div>
        </div>
        <div className="card-body pt-0">
            <div className="border border-dashed border-gray-300 grayHoverBg w-100 rounded my-3 p-2 me-2">
                <div className="d-flex fs-6 fw-semibold h-30px align-items-center" data-id="home-account-statistics-totalListingsViews">
                    <div className="bullet bg-success me-3" style={{borderRadius: "3px", width: "12px", height: "12px"}}></div>
                    <div className="fs-5 fw-bold text-gray-600 me-5">{Intl.formatMessage({id : "myOpenSooq.totalListingViews"})}</div>
                    {!props.accountState && <Skeleton className="w-30px ms-auto" />} {props.accountState && <span className="ms-auto badge badge-light fs-12 fw-bold" style={{color :"black"}}>{props.accountState.postCountTotal ? props.accountState.postCountTotal.toLocaleString() : 0}</span>}
                </div>
            </div>
            <div className="border border-dashed border-gray-300 grayHoverBg w-100 rounded my-3 p-2 me-2" data-id="home-account-statistics-totalAccountViews">
                <div className="d-flex fs-6 fw-semibold h-30px align-items-center">
                    <div className="bullet bg-primary me-3" style={{borderRadius: "3px", width: "12px", height: "12px"}}></div>
                    <div className="fs-5 fw-bold text-gray-600 me-5">{Intl.formatMessage({id : "myOpenSooq.totalAccountViews"})}</div>
                    {!props.accountState && <Skeleton className="w-30px ms-auto" />} {props.accountState && <span className="ms-auto badge badge-light fs-12 fw-bold" style={{color :"black"}}>{props.accountState.viewsCountTotal ? props.accountState.viewsCountTotal.toLocaleString() : 0}</span>}
                </div>
            </div>
            <div className="border border-dashed border-gray-300 grayHoverBg w-100 rounded my-3 p-2 me-2" data-id="home-account-statistics-totalCVViews">
                <div className="d-flex fs-6 fw-semibold h-30px align-items-center">
                    <div className="bullet me-3" style={{borderRadius: "3px", backgroundColor: "rgb(228, 230, 239)", width: "12px", height: "12px"}}> </div>
                    <div className="fs-5 fw-bold text-gray-600 me-5">{Intl.formatMessage({id : "myOpenSooq.totalCvViews"})}</div>
                    {!props.accountState && <Skeleton className="w-30px ms-auto" />} {props.accountState && <span className="ms-auto badge badge-light fs-12 fw-bold" style={{color :"black"}}>{(props.accountState.cvCountTotal) ? props.accountState.cvCountTotal.toLocaleString() : 0}</span>}
                </div>
            </div>
            <br/>
            <button  onClick={() => {props.navigateTo('/account/my-account/account-stats')}} className="btn btn-primary px-6 flex-shrink-0 align-self-center" data-id="home-account-statistics-MyAccountStatisticsBtn">{Intl.formatMessage({id : "myOpenSooq.myAccountStats"})}</button>
        </div>
    </div>
}

export default AccountStats