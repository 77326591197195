import clsx from 'clsx'
import {ToolbarType, useLayout} from '../../core'
import {Toolbar} from './Toolbar'
import {PageTitleWrapper} from './page-title'
import { useIntl } from 'react-intl'
import { isMobile } from 'react-device-detect'
import { IframeModal } from '../modals/IframeModal'
import { useState } from 'react'
import { defaultOptions, extractQueryParams, getCookies, setCookie } from '../../../helpers/commonFunctions'
import { useLang } from '../../../i18n/OpenSooqi18n'
import { logAddListingClick } from '../../../../logging/helpers/commonLoggingFunctions'
import { EnumUxTypes } from '../../../../logging/types/LoggingTypes'

const ToolbarWrapper = () => {
  const {config, classes} = useLayout()
  const [show, invokeModal] = useState(false)
  const Intl = useIntl()
  const lang = useLang()
  if (!config.app?.toolbar?.display) {
    return null
  }

  const isPageTitleVisible = showPageTitle(
    config.app?.toolbar?.layout,
    config.app?.pageTitle?.display
  )

  const redirectToAddPost = () => {
    setCookie("addPostRef",encodeURIComponent(window.location.href),defaultOptions);
    const link = `${process.env.REACT_APP_ADD_POST_REDIRECT_URL}?v=2&source=${getCookies().source}&lang=${lang}&flow=add&return=${window.location.href}&${extractQueryParams()}`;
    logAddListingClick(EnumUxTypes.BUTTON_TOP_BAR);
    setTimeout(()=>{
      window.location.href= link;
    }, 250)
  }

  return (
    <div
      id='kt_app_toolbar'
      className={clsx('app-toolbar', classes.toolbar.join(' '), config?.app?.toolbar?.class)}
    >
      <div
        id='kt_app_toolbar_container'
        className={clsx(
          'app-container',
          classes.toolbarContainer.join(' '),
          config.app?.toolbar?.containerClass,
          config.app?.toolbar?.minimize?.enabled ? 'app-toolbar-minimize' : '',
          {
            'container-fluid': config.app?.toolbar?.container === 'fluid',
            'container-xxl': config.app?.toolbar?.container === 'fixed',
          }
        )}
      >
        {isPageTitleVisible && <PageTitleWrapper />}
        <Toolbar />
        {isMobile && <button onClick={() => {redirectToAddPost()}} className="btn opensooqButton" data-click="toolbar-addPost"><svg width="22" height="22" viewBox="0 0 24 24" className="inline vMiddle me-2" xmlns="http://www.w3.org/2000/svg" data-name="IconAddPost"><path fillRule="evenodd" clipRule="evenodd" d="M8.14179 2.65377C8.5208 2.23736 9.05779 2 9.62087 2H14.4844C15.0475 2 15.5845 2.23735 15.9635 2.65377L17.3911 4.22222H20.8947C22.1105 4.22222 23.1053 5.22222 23.1053 6.44444V19.7778C23.1053 21 22.1105 22 20.8947 22H3.21053C1.99474 22 1 21 1 19.7778V6.44444C1 5.22222 1.99474 4.22222 3.21053 4.22222H6.71421L8.14179 2.65377ZM6.52632 13.1112C6.52632 16.1778 9.00211 18.6667 12.0526 18.6667C15.1032 18.6667 17.5789 16.1778 17.5789 13.1112C17.5789 10.0445 15.1032 7.55561 12.0526 7.55561C9.00211 7.55561 6.52632 10.0445 6.52632 13.1112Z" fill="#fff"></path><path fillRule="evenodd" clipRule="evenodd" d="M15.3684 13.5874H12.5263V16.4445H11.5789V13.5874H8.73682V12.635H11.5789V9.77783H12.5263V12.635H15.3684V13.5874Z" fill="#fff" stroke="#fff" strokeWidth="0.1"></path></svg>{Intl.formatMessage({id: 'addListingButton'})}</button>}
      </div>
      <IframeModal show={show} handleClose={() => invokeModal(false)} />
    </div>
  )
}

const showPageTitle = (appToolbarLayout?: ToolbarType, appPageTitleDisplay?: boolean): boolean => {
  const viewsWithPageTitles = ['classic', 'reports', 'saas']
  if (!appToolbarLayout || !appPageTitleDisplay) {
    return false
  }

  return appPageTitleDisplay && viewsWithPageTitles.some((t) => t === appToolbarLayout)
}

export {ToolbarWrapper}
