import {UTM_DATA} from '../types/LoggingTypes'

export const channel = !typeof BroadcastChannel ? new BroadcastChannel('utm-params') : null

export const sendMessage = (message: UTM_DATA) => {
  if(!channel) return
  channel.postMessage(message)
}

export const listenForMessages = (callback: (message: UTM_DATA) => void) => {
  if(!channel) return
  channel.onmessage = (event) => {
    callback(event.data)
    channel.close()
  }
}
