import DynamicObject from '../../models/dynamic-object'
import axios, {AxiosResponse} from 'axios'
import Api from '../api'
import {generateToken} from '../../app/helpers/commonFunctions'


export default async (data: any): Promise<AxiosResponse> => {
  const path = 'originals/media/desktop-7'
  const token = await generateToken()
  return axios.put(`${process.env.REACT_APP_ARBOK_URL_BASE}${path}`, data, {
    headers: {Authorization: 'Bearer ' + token},
  })
}
