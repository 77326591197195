/* eslint-disable react/jsx-no-target-blank */
import {useIntl} from 'react-intl'
import { Languages } from '../../../../partials/layout/header-menus/Languages'
import { Logout } from '../../../../partials/layout/header-menus/Logout'
import { BackToOpenSooq } from '../../../../partials/layout/header-menus/BackToOpenSooq'
import { isMobile } from 'react-device-detect'
import { logViewCvClick } from '../../../../../logging/helpers/commonLoggingFunctions'
import { showChatInCountry,getSiteCustomContent} from '../../../../helpers/commonFunctions'
import SideBarItems from "./SideBarItems.json"
import { SideMenuList } from './SideMenuList'
import { Navbar } from 'react-bootstrap'
import SideMenuListItem from './SideMenuListItem'
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import AccountOwner from './AccountOwner'
import useAccountOwnerStore from './AccountOwnerStore'

type Props = {
  comingFrom ?: string
}
const SidebarMenuMain = ({comingFrom} : Props) => {
  const intl = useIntl()

  let comingFromSource = '';
  if(comingFrom && comingFrom != ''){
    comingFromSource = comingFrom
  }
  
  const {accountOwner} = useAccountOwnerStore()

  const [menuOptions, setMenuOptions] = useState({
    [`${intl.formatMessage({id: 'menu.listing'})}-headMenu`]: {
      expandList: false,
      arrow: 'down',
    },
    [`${intl.formatMessage({id: 'menu.listing'})}-sideMenu`]: {
      expandList: false,
      arrow: 'down',
    },

    [`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-headMenu`]: {
      expandList: false,
      arrow: 'down',
    },

    [`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-sideMenu`]: {
      expandList: false,
      arrow: 'down',
    },
  })

  const {pathname} = useLocation()
  pathname.indexOf('1');
  pathname.toLowerCase();
  var mainPath = pathname.split("/")[1];

  useEffect(() => {  
    if(pathname == '/' ){  
      if(!menuOptions[`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-${comingFrom}`].expandList){
      setMenuOptions(prev => ({...prev, [`${intl.formatMessage({id: 'menu.listing'})}-${comingFrom}`]: {expandList: true, arrow: 'up'}, [`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-${comingFrom}`]: {expandList: false, arrow: 'down'}}))
      }
    } else if (mainPath == "listings") {
      setMenuOptions(prev => ({...prev, [`${intl.formatMessage({id: 'menu.listing'})}-${comingFrom}`]: {expandList: true, arrow: 'up'}, [`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-${comingFrom}`]: {expandList: false, arrow: 'down'}}))
    }
    else if((pathname?.toLowerCase()?.includes(intl.formatMessage({id: 'MENU.ACCOUNT'}).toLowerCase()) ) || (mainPath == "account")){
      setMenuOptions(prev => ({...prev, [`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-${comingFrom}`]: {expandList: true, arrow: 'up'}, [`${intl.formatMessage({id: 'menu.listing'})}-${comingFrom}`]: {expandList: false, arrow: 'down'}}))
    }
  }, [pathname])

  

  const handleMenusToggle = (e, title: string) => {
    if(e.target?.className && typeof e.target?.className == 'string' && !e.target.className.includes('toggle-dropDown')) return
    if(title?.toLowerCase() == intl.formatMessage({id: 'menu.listing'}).toLowerCase()){
      if(menuOptions[`${intl.formatMessage({id: 'menu.listing'})}-${comingFrom}`].expandList){
        setMenuOptions(prev => ({...prev, [`${intl.formatMessage({id: 'menu.listing'})}-${comingFrom}`]: {expandList: false, arrow: 'down'}}))
      } else {
        // close account menu and open listing menu
        setMenuOptions(prev => ({...prev, [`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-${comingFrom}`]: {expandList: false, arrow: 'down'}, [`${intl.formatMessage({id: 'menu.listing'})}-${comingFrom}`]: {expandList: true, arrow: 'up'}}))
      }
    }else if(title?.toLowerCase() == intl.formatMessage({id: 'MENU.ACCOUNT'}).toLowerCase()){
      if(menuOptions[`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-${comingFrom}`].expandList){
        setMenuOptions(prev => ({...prev, [`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-${comingFrom}`]: {expandList: false, arrow: 'down'}}))
      } else {
        // close listing menu and open account menu
        setMenuOptions(prev => ({...prev, [`${intl.formatMessage({id: 'menu.listing'})}-${comingFrom}`]: {expandList: false, arrow: 'down'}, [`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-${comingFrom}`]: {expandList: true, arrow: 'up'}}))
      }
    }
  }

  const listingListHandler = useMemo(() => {
    return (
    <SideMenuList dataId="listings" to='' handleMenusToggle={handleMenusToggle} listOptions={menuOptions[`${intl.formatMessage({id: 'menu.listing'})}-${comingFrom}`]} title={intl.formatMessage({id: 'menu.listing'})} comingFrom={comingFromSource} newIcon={<><svg width="23" height="23" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg"> <rect x="5.5" y="2.5" width="14" height="19" rx="1.5" stroke="black"/> <rect x="7" y="9" width="11" height="11" rx="1" fill="black"/> <rect x="7" y="4" width="11" height="1" rx="0.5" fill="black"/> <rect x="7" y="6" width="7" height="1" rx="0.5" fill="black"/> </svg></>} >
    {SideBarItems.MyListing.map((item) => {
          return <SideMenuListItem  comingFrom={comingFromSource} to={item.to} title={intl.formatMessage({id: item.intlValue})} key={item.intlValue} dataId={item.data_id}/>
      })}
        </SideMenuList>)
  }, [menuOptions[`${intl.formatMessage({id: 'menu.listing'})}-${comingFrom}`].expandList])

  const accountListHandler = useMemo(() => {
    return ( <SideMenuList dataId='account' handleMenusToggle={handleMenusToggle} to='' listOptions={menuOptions[`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-${comingFrom}`]} comingFrom={comingFromSource}  title={intl.formatMessage({id: 'MENU.ACCOUNT'})} newIcon={<><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M8 6.28585C8 8.65155 10.015 10.5714 12.5001 10.5714C14.9861 10.5714 17 8.65155 17 6.28585C17 3.91751 14.9861 2 12.5001 2C10.015 2 8 3.91751 8 6.28585Z" fill="black"/> <path fillRule="evenodd" clipRule="evenodd" d="M5 18.9318C5 23.0227 20 23.0227 20 18.9318C20 14.8408 16.6416 11.5238 12.4998 11.5238C8.35787 11.5238 5 14.8408 5 18.9318Z" fill="black"/> </svg></>}>
    {SideBarItems.MyAccount.map((item) => {
        return <SideMenuListItem comingFrom={comingFromSource} to={item.to} title={intl.formatMessage({id: item.intlValue})}  key={item.intlValue} logFunction={item.intlValue == 'MENU.JCV'? logViewCvClick: undefined } dataId={item.data_id}/>
      })}
    </SideMenuList>)
  }, [menuOptions[`${intl.formatMessage({id: 'MENU.ACCOUNT'})}-${comingFrom}`].expandList])

  const content = getSiteCustomContent(intl)
  return (
    <>
    <Navbar.Toggle className='w-100 m-0'>
      <SideMenuListItem comingFrom={comingFromSource} dataId="my-opensooq" isMain={true} to='/' title={content.mainPageTitle}  newIcon={<><svg width="18" height="18" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M11.7821 0.310102C11.573 0.110071 11.2957 0 11 0C10.7049 0 10.4271 0.110071 10.2181 0.310102L0.276572 9.83237C-0.0566729 10.1513 -0.0178377 10.4253 0.0349454 10.5553C0.088002 10.6859 0.245668 10.9122 0.707861 10.9122H1.82725V19.3756C1.82725 20.2559 2.59123 21 3.45627 21H8.49569V15.2638H13.3721V21H18.5434C19.4117 21 20.1394 20.2411 20.1394 19.3756V10.9122H21.2922C21.7549 10.9122 21.9122 10.6859 21.9651 10.5553C22.0176 10.4253 22.057 10.1513 21.724 9.83237L11.7821 0.310102Z" fill="black"/></svg></>}/>
      {showChatInCountry() && <SideMenuListItem comingFrom={comingFromSource} dataId="chats" isMain={true} to='/chats'  title={intl.formatMessage({id: 'chatsTitle'})} newIcon={<><svg width="18" height="18" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19 4H17V13H4V15C4 15.55 4.45 16 5 16H16L20 20V5C20 4.45 19.55 4 19 4ZM15 10V1C15 0.45 14.55 0 14 0H1C0.45 0 0 0.45 0 1V15L4 11H14C14.55 11 15 10.55 15 10Z" fill="black"/></svg></>} applySSO={true}/>}
      <SideMenuListItem comingFrom={comingFromSource} dataId="notifications" isMain={true} to='/notifications'  title={intl.formatMessage({id: 'notifications'})}  newIcon={<><svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM14 14V9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.63 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14Z" fill="black"></path> </svg></>} applySSO={true}/>
        </Navbar.Toggle>
      {listingListHandler}
      {accountListHandler}
      <Navbar.Toggle className='w-100 m-0'>
      <SideMenuListItem isMain={true} comingFrom={comingFromSource} dataId='products' to='/products' title={intl.formatMessage({id : "menu.products"})} newIcon={<><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M3 3V11H11V3H3ZM9 9H5V5H9V9ZM3 13V21H11V13H3ZM9 19H5V15H9V19ZM13 3V11H21V3H13ZM19 9H15V5H19V9ZM13 13V21H21V13H13ZM19 19H15V15H19V19Z" fill="black"/> </svg></>}/>
      <SideMenuListItem isMain={true} comingFrom={comingFromSource} dataId='invite-friends' to='/invite-friends' title={intl.formatMessage({id: 'inviteFriend'})} newIcon={<><svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 20C9.1 20 10 19.1 10 18H6C6 19.1 6.89 20 8 20ZM14 14V9C14 5.93 12.36 3.36 9.5 2.68V2C9.5 1.17 8.83 0.5 8 0.5C7.17 0.5 6.5 1.17 6.5 2V2.68C3.63 3.36 2 5.92 2 9V14L0 16V17H16V16L14 14Z" fill="black"/></svg></>}/>
      <SideMenuListItem isMain={true} comingFrom={comingFromSource} dataId='request-feature' to='/request-feature' title={intl.formatMessage({id: 'requestFeature'})} newIcon={<><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_3190_12225)"><path d="M12 7C11.45 7 11 7.45 11 8V11H8C7.45 11 7 11.45 7 12C7 12.55 7.45 13 8 13H11V16C11 16.55 11.45 17 12 17C12.55 17 13 16.55 13 16V13H16C16.55 13 17 12.55 17 12C17 11.45 16.55 11 16 11H13V8C13 7.45 12.55 7 12 7ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#222222"/></g><defs><clipPath id="clip0_3190_12225"><rect width="24" height="24" fill="white"/></clipPath></defs></svg></>}/>
      {(isMobile || comingFrom == 'headMenu') ? <><BackToOpenSooq dataId='back-to-opensooq' comingFrom={comingFromSource}/>
      <Languages comingFrom={comingFromSource} dataId="language"/>
      <Logout comingFrom={comingFromSource} dataId="logout"/></> : ''}
      </Navbar.Toggle>
      {comingFrom == 'sideMenu' && accountOwner && <AccountOwner/>}
    </>
  )
}

export {SidebarMenuMain}