/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useContext, useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { SHOP_MEMBER_TYPE } from '../../../helper/MemberTypes'
import { social } from '../../../helper/social'
import { toAbsoluteUrl } from '../../../helpers'
import { getProfileImage, preloadImage } from '../../../helpers/commonFunctions'
import { SidebarMenu } from '../../../layout/components/sidebar/sidebar-menu/SidebarMenu'
import { AuthContext } from '../../../i18n/AuthProvider'
import { ImageType } from '../../../interfaces/Image'

const HeaderUserMenu: FC = () => {
  const {userIdentity} = useContext(AuthContext);
  const Intl = useIntl();
  const defaultProfileImage = getProfileImage();
  const [image, setImage] = useState<ImageType>({imageSrc: defaultProfileImage, isDefault: true});
  useEffect(()=>{
      if(!userIdentity || (userIdentity && userIdentity.profile_picture && userIdentity.profile_picture.includes('no_profile')))
          return;
      preloadImage(userIdentity.profile_picture, (src:string)=>{setImage({imageSrc: src, isDefault:false})});
    }, [userIdentity])
  return (
    <div >
      <div className='menu-item px-3'>
        <div className='menu-content d-flex align-items-center px-3'>
          <div className='symbol symbol-50px me-5'>
            <img alt='ProfileImage' src={image.imageSrc} className={image.isDefault && 'bg-noImage'}/>
          </div>
          <div className=''>
            <div className='fw-bolder align-items-center fs-5'>
              {userIdentity?.full_name}
            </div>
            {userIdentity && userIdentity.phone_number && <span  className='fw-bold text-muted text-hover-primary fs-7'>
              {userIdentity.phone_number}
            </span>}
            <div >
              <div className="badge badge-light-success  fw-bold fs-8 px-2 py-1 ">{userIdentity && userIdentity.member_type && userIdentity.member_type !== 0 ? Intl.formatMessage({id : "shop"}) : Intl.formatMessage({id : "free"})}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='separator my-2'></div>
      <SidebarMenu height='100%' comingFrom='headMenu'/>
      <div className='separator my-2'></div>
      <div className="flex-column-fluid ps-7 pe-7 border-1">
        <strong className="d-block">
          {Intl.formatMessage({id : "downloadOpenSooq"})}
        </strong>
        <div className="flex mt-4">
          <Dropdown.Item as="a" target="_blank" href={social.headerMenu.googlePlayStore}  aria-label="Download App" > 
            <img src={toAbsoluteUrl("/media/custom/googlePlay.png")}  className="w-100px  me-1" height="33px" alt="Google Play" loading="lazy" /> 
          </Dropdown.Item>
          <Dropdown.Item as="a" target="_blank" href={social.headerMenu.appleStore}  aria-label="Download App" > 
            <img src={toAbsoluteUrl("/media/custom/appStore.png")} className="w-100px  me-1" height="33px" alt="App Store" loading="lazy" /> 
          </Dropdown.Item>
          <Dropdown.Item as="a" target="_blank" href={social.headerMenu.huaweiStore}  aria-label="Download App" >
              <img src={toAbsoluteUrl("/media/custom/appGallery.png")}  className="w-100px " height="33px" alt="App Gallery" loading="lazy" /> 
          </Dropdown.Item>
        </div>
      </div>
  </div>
  )
}

export { HeaderUserMenu }

