import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";

const ProfileDetailsLoader = () => {
    const Intl = useIntl();
    return <div className="card mb-5 mb-xl-10" data-select2-id="select2-data-134-v21y">
            <div className="card-header">
                <h3 className="card-title align-items-start flex-column">
                    <span className="card-label fw-bold text-gray-800">{Intl.formatMessage({id : "myProfile.profileDetails.text1"})}</span>
                </h3>
            </div>
            <div id="kt_account_settings_profile_details" className="collapse show" data-select2-id="select2-data-kt_account_settings_profile_details">
                <div className="card-body border-top p-9" data-select2-id="select2-data-362-ogaw">
                    <div className="row mb-6">
                        <label className="col-lg-2 col-form-label fw-semibold fs-6">{Intl.formatMessage({id : "myProfile.profileDetails.text2"})}</label>
                        <div className="col-lg-10">
                            <div className="image-input image-input-outline" data-kt-image-input="true">
                                <Skeleton className="image-input-wrapper w-125px h-125px"/>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-6">
                        <label className="col-lg-2 col-3 col-form-label  fw-semibold fs-6">{Intl.formatMessage({id : "myProfile.profileDetails.text3"})}</label>
                        <div className="col-lg-10 col-9">
                            <div className="row">
                                <div className="col-6 fv-row fv-plugins-icon-container">
                                    <Skeleton  className="form-control form-control-lg form-control-solid "/>
                                </div>
                                <div className="col-6 fv-row fv-plugins-icon-container">
                                    <Skeleton  className="form-control form-control-lg form-control-solid "/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-6">
                        <label className="col-lg-2 col-3 col-form-label  fw-semibold fs-6">{Intl.formatMessage({id : "myProfile.profileDetails.text4"})}</label>
                        <div className="col-lg-10 col-9">
                            <div className="row">
                                <div className="col-6 fv-row fv-plugins-icon-container">
                                    <Skeleton  className="form-control form-control-lg form-control-solid "/>
                                </div>
                                <div className="col-6 fv-row fv-plugins-icon-container">
                                    <Skeleton  className="form-control form-control-lg form-control-solid "/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-6">
                        <label className="col-lg-2 col-3 col-form-label  fw-semibold fs-6">{Intl.formatMessage({id : "myProfile.profileDetails.text5"})} <i id="phone" className="fas fa-exclamation-circle ms-1 fs-7"></i></label>
                        <div className="col-lg-10 col-9">
                            <div className="row">
                                <div className="col-6 fv-row fv-plugins-icon-container">
                                    <Skeleton  className="form-control form-control-lg form-control-solid "/>
                                </div>
                                <div className="col-lg-6 col-12 fv-row fv-plugins-icon-container">
                                    <Skeleton  className="form-control form-control-lg form-control-solid "/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-6">
                        <label className="col-lg-2 col-3 col-form-label  fw-semibold fs-6">{Intl.formatMessage({id : "myProfile.profileDetails.text6"})}</label>
                        <div className="col-lg-10 col-9">
                            <div className="row">
                                <div className="col-6 fv-row fv-plugins-icon-container">
                                    <Skeleton  className="form-control form-control-lg form-control-solid "/>
                                </div>
                                <div className="col-lg-6 col-12 fv-row fv-plugins-icon-container">
                                    <Skeleton  className="form-control form-control-lg form-control-solid "/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
}

export { ProfileDetailsLoader };
