import DynamicObject from "opensooq-notification/dist/esm/dynamic-object";
import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useIntl } from 'react-intl';
import getCartReportsApi from "../../../api/servicrs/getCartReports.api";
import { toAbsoluteUrl } from "../../../app/helpers";
import { SearchComponent } from "../../../app/layout/components/opensooq/SearchComponent";
import { TableLoader } from "../../../app/layout/components/opensooq/TableLoader";
import { generateOpenSooqRedirectUrl, getCookies, isDeviceMobile, redirectionInterceptor } from "../../helpers/commonFunctions";
import { useLang } from "../../i18n/OpenSooqi18n";
import { CarReportInterface } from "../../interfaces/CarReportInterface";
import { CarReportView } from "../../layout/components/modals/CarReportView";
import { Loader } from "../../layout/components/opensooq/Loader";
import { handleError } from "../common/HandleError";
export function CarfaxReport() {
    const Intl = useIntl();
    const [searchData,setSearchData] = useState<CarReportInterface[]>([])
    const [carFaxRequested,setCarFaxRequested] = useState<CarReportInterface>()
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showCarFaxLoader,setShowCarFaxLoader] = useState<boolean>(true)
    const [url, setUrl] = useState<string>('')
    const [lastPage, setLastPage] = useState<number | undefined>()
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [searchString, setSearchString] = useState("")
    const [reportData,setReportData] = useState<CarReportInterface[]>([])
    
    useEffect(() => {
        fetchCarFaxReports()
    },[])

    const setSearch = (e: any) => {
        setSearchString(e.target.value);
    };

    const fetchCarFaxReports = ()=>{     
        try{   
            getCartReportsApi(url,'carfax').then (response => {
                if(response && response.data && response.data.items && response.data._meta && response.data._links)
                {   
                    setSearchData((prev) => [...prev, ...response.data.items])
                    setReportData((prev) => [...prev, ...response.data.items])
                    setShowCarFaxLoader(false)
                    setLastPage(response.data._meta.pageCount ?? 1)
                    setCurrentPage(response.data._meta.currentPage ?? 1)
                    setUrl( response.data._links.next ? response.data._links.next.href : '')
                }else 
                    throw new Error('Empty or not found response');
            })
        }catch(err){
            handleError(err, lang, Intl)
        }
    }

    const collapse = (e: any) => {
        if(e.currentTarget.classList.contains('active')){
            e.currentTarget.classList.remove('active');
        }else{
            e.currentTarget.classList.add('active');
        }
    };

    const showReport = (carReport : CarReportInterface) => {
        if(carReport.is_url){
            window.open(carReport.report,'_blank')
        }else{
            setCarFaxRequested(carReport);
            setShowModal(true)
        }
    }

    const closePopup = () => {
        setShowModal(false)
    }
  
    const getCarType = (record_check : DynamicObject) => {
        let cartType = '-';
        if(record_check.make && record_check.model && record_check.year ){
            cartType = `${record_check.make} - ${record_check.model} ${record_check.year}`
        }
        return cartType;
    }

    useEffect(() => {
        let newList = searchData.filter(post => post.vin_number === searchString)
        if(searchString == '' ){
             newList = reportData
        }
        setSearchData(newList);
    },[searchString]);

    const CarFaxList:React.FC<{searchData:CarReportInterface[]}> = ({searchData}) => {
        return <InfiniteScroll
            hasMore = {currentPage < (lastPage ? lastPage : currentPage)}
            dataLength={searchData.length}
            loader={
                <Loader />
            }
            pullDownToRefresh={false}
            pullDownToRefreshThreshold={100}
            pullDownToRefreshContent={null}
            releaseToRefreshContent={null}
            next={fetchCarFaxReports}
            >
            <table className="table table-hover align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
                {!isDeviceMobile() && <thead>
                    <tr className="text-start text-gray-900 fw-bold fs-7 text-uppercase gs-0">
                        <th className="min-w-100px sorting_disabled" rowSpan={1} colSpan={1} >{Intl.formatMessage({id: 'report.carType'})} </th>
                        <th className="text-start min-w-100px sorting_disabled hideOnMobile" rowSpan={1} colSpan={1} >  {Intl.formatMessage({id: 'vinNumber'})} </th>
                        <th className="text-start min-w-125px sorting_disabled hideOnMobile" rowSpan={1} colSpan={1}> {Intl.formatMessage({id: 'dateOfIssue'})}</th>
                        <th className="text-start sorting_disabled" rowSpan={1} colSpan={1} ></th>
                    </tr>
                </thead>}
                <tbody className="">
                    {searchData.map((carFax  : CarReportInterface,index) => {
                    return <React.Fragment key={index}>
                        <tr className={(index%2 === 0) ? "even": "odd" }>
                            <td className="">
                                <div className="d-flex align-items-center">
                                    <div className="d-flex justify-content-start flex-column">
                                        {getCarType(carFax.record_check)}
                                    </div>
                                </div>
                            </td>
                            <td className="hideOnMobile">
                                {carFax.vin_number}
                            </td>
                            <td className="hideOnMobile">
                                {carFax.expire_at ?? '-'}
                            </td>
                            <td className="hideOnMobile text-end">
                                <button onClick={() => showReport(carFax)} className="btn  btn-lg bg-primary text-white pt-2 pb-2 ">{Intl.formatMessage({id : "carFax.viewCarFax"})}</button>
                            </td>
                            <td className="text-end hideOnDesktop">
                                <button onClick={(e) => {collapse(e)}} type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row"  data-bs-toggle="collapse" data-bs-target={`#id-${carFax.id}`}>
                                    <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1"
                                                transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                    <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                </button>
                            </td>
                        </tr>
                        <tr id={`id-${carFax.id}`} className="hideOnDesktop collapse" data-bs-parent="#kt_accordion_tr-1"  >
                            <td colSpan={6} className="shadow1">
                                <dl className="row">
                                    <dd className="col-8 text-gray-600 fw-bold">{carFax.vin_number}</dd>
                                </dl>
                                <dl className="row">
                                    <dd className="col-8 text-gray-600 fw-bold">{carFax.expire_at}</dd>
                                </dl>
                                <dl className="row">
                                    <dd className="col-12">
                                        <button  onClick={() => showReport(carFax)} className="btn  btn-lg bg-primary text-white pt-2 pb-2 ">{Intl.formatMessage({id : "carFax.viewCarFax"})}</button>
                                    </dd>
                                </dl>
                            </td>
                        </tr>
                    </React.Fragment>
                    })}
                </tbody>
            </table>
        </InfiniteScroll>
    };
    
    const lang = useLang()
    const serviceUrl = (lang === 'ar') ? 'كارفاكس' : 'carfax'
    const baseUrl =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE,getCookies().ecountry,lang)
    const openSooqUrl =  baseUrl + '/' + serviceUrl;
    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className='row w-100 align-items-center justify-content-between'>
                        <div className='col-lg-4 col-md-6 col-6'>
                            <SearchComponent action={setSearch}  placeholder={`${Intl.formatMessage({id: 'searchForCarfaxReport'})}`}/>
                        </div>
                        <div className='col-xl-3 col-md-3 col-6'>
                            <div className="d-flex justify-content-end " data-kt-filemanager-table-toolbar="base">
                                <a onClick={redirectionInterceptor} href={openSooqUrl} target="_blank" className=" btn btn-primary" >
                                    {Intl.formatMessage({id: 'requestCarfaxReport'})}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    {showCarFaxLoader && <TableLoader/>}
                    {!showCarFaxLoader && searchData.length == 0 && <div className="text-center p-10">
                        <h1>{Intl.formatMessage({id:"carFax.noCarFax"})}</h1>
                        <img  src={toAbsoluteUrl('/media/custom/noCarFax.svg')} alt="no-carFax" />
                    </div>}
                    {!showCarFaxLoader && searchData.length > 0 && <CarFaxList searchData={searchData}/>}
                </div>
            </div>
            {showModal && carFaxRequested && <CarReportView  extraClass={'modal-fullscreen'} show={showModal} handleClose={() => closePopup()} report={carFaxRequested}/>}
        </>
    )
}