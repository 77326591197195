import React, {useState, useEffect, useCallback, useRef} from 'react'
import MultiRangeSlider from '../MultiRangeSlider'
import {AddFieldType, RemoveFieldType} from '../../../../../interfaces/MyListings'
import {RangeFieldWithUnitsI} from '../../../../../interfaces/MyListingsMobile'
import Select from 'react-select'
import {customUnitsSelectStyles} from '../../../my-listings/helper'
import {useIntl} from 'react-intl'

// Debounce function
const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}

type MultiSelectPillsProps = {
  data: RangeFieldWithUnitsI
  addField: AddFieldType
  removeField: RemoveFieldType
  selectedFields: any
}

const RangeTextFilterWithUnitMobile = ({addField, removeField, data, selectedFields}: MultiSelectPillsProps) => {
  // const currentYear = new Date().getFullYear()
  const Intl = useIntl()
  const maxText = Intl.formatMessage({id: 'mylistings.range.maxValue'})
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [unitsOptions, setUnitsOptions] = useState([])
  const [step, setStep] = useState(1)
  const [minValue, setMinValue] = useState(null)
  const [maxValue, setMaxValue] = useState(null)
  const [initialMinValue, setInitialMinValue] = useState(null)
  const [initialMaxValue, setInitialMaxValue] = useState(null)
  const [tempMinValue, setTempMinValue] = useState(null)
  const [tempMaxValue, setTempMaxValue] = useState(null)
  const [minValueError, setMinValueError] = useState(false)
  const [maxValueError, setMaxValueError] = useState(false)
  const [maxLabelHolder, setMaxLabelHolder] = useState<string | number>(maxText)
  const timeoutRef = useRef(null)

  const hideMinRange = minValue == maxValue && minValue == initialMinValue
  const hideMaxRange = maxValue == minValue && maxValue == initialMaxValue

  useEffect(() => {
    if (minValueError || minValue === '')
      removeField(data.fieldId, {value: minValue, keyName: 'from'}, 'RANGE')
    if (maxValueError || maxValue === '')
      removeField(data.fieldId, {value: maxValue, keyName: 'to'}, 'RANGE')
    if (!minValueError && !isNaN(parseInt(maxValue)))
      addField(data.fieldId, {value: maxValue, keyName: 'to'}, 'RANGE')
    if (!maxValueError && !isNaN(parseInt(minValue)))
      addField(data.fieldId, {value: minValue, keyName: 'from'}, 'RANGE')
  }, [minValueError, maxValueError, minValue, maxValue])
  

  useEffect(() => {
    if (selectedUnit)
      addField(
        data.fieldId,
        {value: selectedUnit.value, label: selectedUnit.label, keyName: 'unit'},
        'RANGE'
      )
    // else removeField(data.fieldId, {value: selectedUnit?.value ?? '', keyName: 'unit'}, 'RANGE')
  }, [selectedUnit])

  useEffect(() => {
    if (data?.units && data?.units?.length > 0 && !selectedFields[data.fieldId]?.unit) {
      setSelectedUnit(data.units[0])
      setInitialMinValue(data.units[0]?.config?.min)
      setInitialMaxValue(data.units[0]?.config?.max)
      setMinValue(data.units[0]?.config?.min)
      setMaxValue(data.units[0]?.config?.max)
      // handle step later
      // setStep(data.units[0]?.config?.step)
      setTempMinValue(data.units[0]?.config?.min)
      setTempMaxValue(data.units[0]?.config?.max)
      setUnitsOptions(data.units)
    }
  }, [data.units])

  useEffect(() => {    
    if (selectedFields && selectedFields[data.fieldId]) {
      const selected = selectedFields[data.fieldId]
      if(!selected) return
      if (selected?.unit) {
        const fullUnit = data.units.find((unit) => unit.value === selected.unit)
        setSelectedUnit(fullUnit)
        setInitialMinValue(fullUnit?.config?.min)
        setInitialMaxValue(fullUnit?.config?.max)
        setUnitsOptions(data.units)
      }
      if (selected?.from) {
        setMinValue(selected.from)
        setTempMinValue(selected.from)
      }
      if (selected?.to) {
        setMaxValue(selected.to)
        setTempMaxValue(selected.to)
      }
    }
  }, [])

  const optionsSelect = data.units.map((unit) => ({
    value: unit.value,
    label: unit.shortLabel,
  }))

  const handleSelectChange = (selectedOption) => {
    const fullUnit = unitsOptions.find((unit) => unit.value === selectedOption.value)
    handleChangeUnit(fullUnit)
  }

  const handleChangeUnit = (unit) => {
    setSelectedUnit(unit)
    setInitialMinValue(unit?.config?.min)
    setInitialMaxValue(unit?.config?.max)
    setMinValue(unit?.config?.min)
    setMaxValue(unit?.config?.max)
    // handle step later
    // setStep(unit?.config?.step)
  }

  const handleSliderChange = ({min, max}) => {
    let maxVal = max
    let minVal = min
    if (maxVal + step > initialMaxValue) {
      maxVal = initialMaxValue
    }
    if (minVal + step > initialMaxValue) {
      minVal = initialMaxValue
    }
    if (minVal - step < initialMinValue) {
      minVal = initialMinValue
    }
    if (maxVal - step < initialMinValue) {
      maxVal = initialMinValue
    }

    setTempMinValue(minVal)
    setTempMaxValue(maxVal)

    if (max != initialMaxValue) {
      setMaxLabelHolder(maxVal)
    } else if (max == initialMaxValue) {
      setMaxLabelHolder(maxText)
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      validateAndSetValue('min', minVal)
      validateAndSetValue('max', maxVal)
    }, 500)
  }

  const validateAndSetValue = (type, value) => {
    const intValue = Number(value)
    const isValid =
      /^\d+$/.test(value) && intValue >= initialMinValue && intValue <= initialMaxValue

    if (type === 'min') {
      if (isValid && intValue <= maxValue) {
        setTempMinValue(intValue)
        setMinValue(intValue)
        setMinValueError(false)
      } else {
        setMinValueError(true)
      }
    } else {
      if (isValid && intValue >= minValue) {
        setTempMaxValue(intValue)
        setMaxValue(intValue)
        setMaxValueError(false)
      } else {
        setMaxValueError(true)
      }
      if (initialMaxValue === value) {
        setMaxLabelHolder(maxText)
      } else if (initialMaxValue != value) {
        setMaxLabelHolder(value)
      }
    }
  }

  const handleInputChange = useCallback(
    debounce((type, value) => {
      if (isNaN(+value)) return
      validateAndSetValue(type, value)
    }, 0),
    [maxValue, minValue]
  )

  const handleMinValueChange = (e) => {
    const value = e.target.value
    if (isNaN(+value)) return
    setTempMinValue(value)
  }

  const handleMaxValueChange = (e) => {
    const value = e.target.value
    if (isNaN(+value)) return
    setTempMaxValue(value)
    setMaxLabelHolder(value)
  }

  return (
    <div className='card p-4 gap-5 shadow' key={data.fieldId}>
      <div className={`d-flex justify-content-between align-items-center px-1 p-2`}>
        <div className='fw-bold mb-4 fs-1 px-2'>{data.label}</div>
        {unitsOptions.length > 1 && (
          <div onClick={(e) => e.stopPropagation()}>
            <Select
              value={optionsSelect.find((o) => o.value === selectedUnit?.value)}
              onChange={handleSelectChange}
              options={optionsSelect}
              isDisabled={optionsSelect.length < 2}
              styles={customUnitsSelectStyles(unitsOptions?.length || 0)}
              className='overflow-fixer'
              isSearchable={false}
            />
          </div>
        )}
      </div>
      <div className='d-flex justify-content-between mb-4'>
        <div className='form-control-container'>
          <span className='form-control-container__time fs-3'>{data.minLabel}</span>
          <input
            type='text'
            className={`form-control-container__time__input range-input range-filter-input ${
              minValueError ? 'border-danger' : ''
            }`}
            value={tempMinValue}
            onChange={handleMinValueChange} // Update temp state immediately
            onBlur={(e) => handleInputChange('min', tempMinValue)} // Apply debounce validation on blur
          />
          <div className='mt-2 text-15 fw-normal text-danger' style={{height: '16px'}}>
            {minValueError && Intl.formatMessage({id: 'mylistings.filter.range.minError'})}
          </div>
        </div>
        <div className='form-control-container'>
          <span className='form-control-container__time fs-3'>{data.maxLabel}</span>
          <input
            type='text'
            className={`form-control-container__time__input range-input range-filter-input ${
              maxValueError ? 'border-danger' : ''
            }`}
            onClick={(e) => setMaxLabelHolder(tempMaxValue)}
            value={maxLabelHolder}
            onChange={handleMaxValueChange} // Update temp state immediately
            onBlur={(e) => handleInputChange('max', tempMaxValue)} // Apply debounce validation on blur
          />
          <div className='mt-2 fw-normal  text-15  text-danger' style={{height: '16px'}}>
            {maxValueError && Intl.formatMessage({id: 'mylistings.filter.range.maxError'})}
          </div>
        </div>
      </div>
      <MultiRangeSlider
        min={initialMinValue}
        max={initialMaxValue}
        hideMinRange={hideMinRange}
        hideMaxRange={hideMaxRange}
        minVal={minValue}
        maxVal={maxValue}
        onChange={handleSliderChange}
        step={1}
      />
    </div>
  )
}

export default RangeTextFilterWithUnitMobile
