import React, { useState, useMemo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useLang } from '../../../../../i18n/OpenSooqi18n';
import { CityFieldItemMobileI, LocationFieldMobileI } from '../../../../../interfaces/MyListingsMobile';
import { AddFieldType, RemoveFieldType } from '../../../../../interfaces/MyListings';

type CityFilterProps = {
    addField: AddFieldType;
    removeField: RemoveFieldType;
    selectedCity: CityFieldItemMobileI | null;
    setSelectedCity: React.Dispatch<React.SetStateAction<CityFieldItemMobileI | null>>;
    location: LocationFieldMobileI;
    selectedFields: any;
};

const CityFilter = ({ addField, removeField, selectedCity, setSelectedCity, location, selectedFields }: CityFilterProps) => {
    const [isCityModalOpen, setIsCityModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const intl = useIntl();
    const lang = useLang();

    const handleCitySelect = (city: CityFieldItemMobileI) => {
        setSelectedCity((oldCity) => {
            if (oldCity) {
                removeField(location?.citiesWithNHoods?.fieldId, { value: oldCity.value }, 'SINGLE');
            }
            addField(location?.citiesWithNHoods?.fieldId, { value: city.value, label: city.label }, 'SINGLE');
            return city;
        });
        setIsCityModalOpen(false);
    };

    const filteredCities = useMemo(() => {
        return location.citiesWithNHoods.items.filter(city =>
            city.autoComplete.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [location.citiesWithNHoods.items, searchTerm]);

    useEffect(() => {
        if(selectedFields[location?.citiesWithNHoods?.fieldId]) {
            setSelectedCity(location.citiesWithNHoods.items.find(city => city.value === selectedFields[location?.citiesWithNHoods?.fieldId].value));
        }
    }, []);

    return (
        <>
            <div
                className={`w-100 p-3 d-flex align-items-center justify-content-between flex-row py-5 ${selectedCity ? '' : 'disabled'}`}
                onClick={() => setIsCityModalOpen(true)}
            >
                <div className='w-75'>
                    <div className={`fw-bold fs-3 ${selectedCity ? 'text-primary': 'text-dark'}`}>
                        <img className={`${lang === 'ar' ? 'ms-1' : 'me-1'}`} width={24} height={24} src={location?.citiesWithNHoods?.icon} />
                        {selectedCity ? location?.citiesWithNHoods?.label : location?.citiesWithNHoods?.label}
                    </div>
                    <div className={`fs-3 mt-2 mx-9`}>
                        {selectedCity ? selectedCity.label : ''}
                    </div>
                </div>
                <span className={`text-dark text-end w-25 fs-4`}>
                    {intl.formatMessage({ id: 'mylistings.singleselect.mobile.select' })}
                    <i className={`px-2 text-primary fa fa-chevron-${lang === 'ar' ? 'left' : 'right'}`}></i>
                </span>
            </div>
            {isCityModalOpen && (
                <div className="modal show d-block" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog modal-fullscreen">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fs-1">{location?.citiesWithNHoods?.label}</h5>
                                <button type="button" className="btn-close" onClick={() => setIsCityModalOpen(false)}></button>
                            </div>
                            <div className="modal-body d-flex flex-column gap-2">
                                <input
                                    type="text"
                                    className="form-control p-3"
                                    placeholder={intl.formatMessage({ id: 'mylistings.filters.search' })}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                {filteredCities.map((city) => (
                                    <div
                                        key={city.value}
                                        className="card p-5 py-7 fs-2 fw-bold mb-3 shadow-sm d-flex flex-row justify-content-between align-items-center hoverPointer"
                                        onClick={() => handleCitySelect(city)}
                                    >
                                        <span>{city.label}</span>
                                        <i className={`fs-1 fa fa-chevron-${lang === 'ar' ? 'left' : 'right'}`}></i>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CityFilter;
