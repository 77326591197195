import Sheet from 'react-modal-sheet'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../../helpers'
import {PostActionInput} from './lisitingtypes'

type props = {
  show: boolean
  handlePostAction: ({action, listing}: PostActionInput) => void
  selectedListing: any
  setShowMoreOptionsMobile: (show: boolean) => void
  setMoreOptionsMobile: (arr: any[]) => void
  options: {
    text: string
    fontSize: string
    textColor: string
    style: {underline: boolean; bold: boolean}
    icon: string
    actionType: string
  }[]
}

export default function MobileListingShowMore({
  show,
  setShowMoreOptionsMobile,
  selectedListing,
  setMoreOptionsMobile,
  options,
  handlePostAction,
}: props) {
  const Intl = useIntl()
  const handleClose = () => {
    setMoreOptionsMobile([])
    setShowMoreOptionsMobile(false)
  }

  return (
    <Sheet detent='content-height' isOpen={show} onClose={handleClose} className='unset-overflow' disableScrollLocking={true} >
      <Sheet.Container className='pb-7 p-3'>
        <div onClick={handleClose} className='mb-5'>
          <KTSVG
            className='svg-icon-1 border rounded-circle shadow'
            path='/media/icons/duotune/arrows/arr061.svg'
          />
        </div>
        <Sheet.Content>
          <div className='d-flex flex-column gap-3'>
            <span className='fs-1 fw-bold mb-3'>
              {Intl.formatMessage({id: 'mylistings.more.title.mobile'})}
            </span>
            {options?.map((item, idx) => (
              <>
                {idx !== 0 && <div className='dotted-line py-1 my-2' />}
                <div
                  key={idx}
                  className='d-flex align-items-center fs-3 gap-3'
                  data-id={`my-listing-actions-${selectedListing?.listingId}-${item.actionType}`}
                  onClick={() =>
                    handlePostAction({action: item?.actionType, listing: selectedListing})
                  }
                >
                  <img src={item.icon} width={18} height={18} alt='' />
                  <span className={`${item.style}`}>{item.text}</span>
                </div>
              </>
            ))}
          </div>
        </Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop onTap={handleClose} />
    </Sheet>
  )
}
