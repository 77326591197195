import React from 'react'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import RadialBarChart from '../../../new-my-listings/RadialBarChat'

export default function MobileListSkeleton() {
  const skeletonWidth = '100%'
  const skeletonHeight = 20
  const imageSkeletonHeight = 80
  const imageSkeletonWidth = 80

  return (
    <div style={{maxWidth: '375px'}} className='card py-5 d-flex flex-column gap-4 w-100'>
      <div className='d-flex justify-content-between px-5 '>
        <div className='d-flex gap-2'>
          <Skeleton width={50} height={skeletonHeight} />
          <Skeleton width={100} height={skeletonHeight} />
        </div>
        <Skeleton width={120} height={skeletonHeight} />
      </div>
      <div id='listing-info' className='d-flex gap-2 hoverPointer px-5'>
        <div className='d-flex flex-column'>
          <Skeleton
            width={imageSkeletonWidth}
            height={imageSkeletonHeight}
            className='rounded-3 customImg'
          />
        </div>
        <div className='d-flex flex-column gap-1 w-75'>
          <Skeleton width={skeletonWidth} height={skeletonHeight} />
          <Skeleton width={skeletonWidth} height={skeletonHeight} style={{height: '40px'}} />
          <Skeleton width={skeletonWidth} height={skeletonHeight} />
        </div>
      </div>
      <div className='d-flex flex-wrap gap-2 px-5 w-100'>
        {Array(2)
          .fill(1)
          .map((_, index) => (
            <span key={index} className='d-flex'>
              <span>
                <Skeleton width={15} height={20} className='mx-2' />
              </span>
              <Skeleton width={115} height={20} />
            </span>
          ))}
      </div>
      <div className='dotted-line py-1 my-2' />
      <div className='d-flex justify-content-between align-items-center px-5'>
        <div
          className='quality-mobile-chart-container'
        >
          <RadialBarChart
            percentage={0}
            value='0'
            width={160}
            height={160}
            label='mylistings.container.quality'
            fillColor='#28B936'
            nameFontSize='17px'
            valueFontSize='17px'
            totalFontSize='13px'
            hollowSize='63%'
          />
        </div>
        <div className='d-flex flex-column'>
          <Skeleton width={25} height={20} />
          <Skeleton width={40} height={20} />
        </div>
        <div className='d-flex flex-column'>
          <Skeleton width={25} height={20} />
          <Skeleton width={40} height={20} />
        </div>
        <Skeleton width={75} className='btn' />
      </div>
      <div className='dotted-line py-1 my-2' />
      <div>
        <div id='listing-actions' className='d-flex justify-content-between px-5'>
          {Array(4)
            .fill(1)
            .map((_, index) => (
              <span key={index} className='d-flex flex-column gap-2 align-items-center'>
                <Skeleton width={22} height={22} />
                <Skeleton width={40} height={20} />
              </span>
            ))}
        </div>
      </div>
    </div>
  )
}
