import {ActionDimensions} from "../dimensions/ActionDimensions";
import {sendLoggingInformation} from "../sendLoggingInformation";
import {
    DEFAULT_VALUE,
    EnumActionNameLevel1,
    EnumActionNameLevel2,
    EnumActionNameLevel3,
    EnumElementName,
    EnumGeneratedBy,
    EnumUxTypes,
    Member,
    Post
} from "../types/LoggingTypes";
import {updateActionHistory} from "./updateActionHistory";
import {updateWidgetHistory} from "./updateWidgetHistory";

function getCurrentFormattedDate(): string {
    const date = new Date();

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

// const cellInteractionHandler = (listingData:{listingID: number, listingListerID: number, listingCountry:string, listingCategory: string, listingSubCategory: string, listingStatus: string})=>{
//   console.log(listingData.listingListerID, 'idd');
//   ImpressionDimensions.getInstance().actionsScreen = ActionDimensions.getInstance().currScreen;
//   ImpressionDimensions.getInstance().actionsElement = ActionDimensions.getInstance().actionListingCellType;
//   ImpressionDimensions.getInstance().actionsDate = getCurrentFormattedDate();
//   ImpressionDimensions.getInstance().setDimensions({actionType: 'interacted', actionsElement: ActionDimensions.getInstance().uxType, listingID: listingData.listingID, listingCategory: listingData.listingCategory, listingSubcategory: listingData.listingSubCategory, listingCountry: listingData.listingCountry, listingStatus: listingData.listingStatus, listingListerID: listingData.listingListerID.toString()});
// };


const logScreenLoad = () => {
    updateActionHistory(EnumActionNameLevel1.SCREEN, EnumActionNameLevel2.LOAD, EnumActionNameLevel3.SUCCESS);
    updateWidgetHistory('')
    ActionDimensions.getInstance().filterMember = 'NA';
    sendLoggingInformation({uxType: EnumUxTypes.SCREEN, actionGeneratedBy: EnumGeneratedBy.USER});
}

const logShareApp = (member: Member) => {
    updateActionHistory(EnumActionNameLevel1.SHARE, EnumActionNameLevel2.MEMBER, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('')
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'member',
        action_reference_id: member.id
    });
}

const logOpenShareListing = (post: Post) => {
    updateActionHistory(EnumActionNameLevel1.SHARE, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('export_listing_options');
    sendLoggingInformation({
        uxType: EnumUxTypes.LIST_CELL,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        action_reference_id: post.id,
        post: post
    });
}

const logViewListingClick = (post: Post, actionElementName?: EnumElementName) => {
    updateActionHistory(EnumActionNameLevel1.VIEW, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('listing_cell');
    sendLoggingInformation({
        uxType: EnumUxTypes.LIST_CELL,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        action_reference_id: post.id,
        post: post,
        actionElementName: actionElementName
    });
}

const logViewListingSuccess = (post: Post) => {
    updateActionHistory(EnumActionNameLevel1.VIEW, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.SUCCESS);
    updateWidgetHistory('')
    sendLoggingInformation({
        uxType: EnumUxTypes.LIST_CELL,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        action_reference_id: post.id,
        post: post
    })
}

const logCallListingClick = (post: Post, filterMember: 'Yes' | 'No') => {
    updateActionHistory(EnumActionNameLevel1.CALL, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('listing_leads');
    ActionDimensions.getInstance().filterMember = filterMember;
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'member',
        action_reference_id: post.memberId,
        post: post,
        actionElementName: EnumElementName.CARD_CELL_RESULT
    });
}

const logCallListingSuccess = (post: Post, filterMember: 'Yes' | 'No', timeout: number) => {
    setTimeout(() => {
        updateActionHistory(EnumActionNameLevel1.CALL, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.SUCCESS);
        updateWidgetHistory('listing_leads');
        ActionDimensions.getInstance().filterMember = filterMember;
        sendLoggingInformation({
            uxType: EnumUxTypes.BUTTON,
            actionGeneratedBy: EnumGeneratedBy.USER,
            action_reference_entity: 'member',
            action_reference_id: post.memberId,
            post: post,
            actionElementName: EnumElementName.CARD_CELL_RESULT
        });
    }, timeout)
}

const logFollowRemoveClick = (member: Member) => {
    updateActionHistory(EnumActionNameLevel1.FOLLOW, EnumActionNameLevel2.REMOVE, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('');
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'member',
        action_reference_id: member.id,
        userTargetId: member.id.toString()
    });
}

const logFollowAddClick = (member: Member) => {
    updateActionHistory(EnumActionNameLevel1.FOLLOW, EnumActionNameLevel2.ADD, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('');
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'member',
        action_reference_id: member.id,
        userTargetId: member.id.toString()
    });
}

const logFollowAddSuccess = (member: Member) => {
    updateActionHistory(EnumActionNameLevel1.FOLLOW, EnumActionNameLevel2.ADD, EnumActionNameLevel3.SUCCESS);
    updateWidgetHistory('');
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'member',
        action_reference_id: member.id,
        userTargetId: member.id.toString()
    });
}

const logFollowRemoveSuccess = (member: Member) => {
    updateActionHistory(EnumActionNameLevel1.FOLLOW, EnumActionNameLevel2.REMOVE, EnumActionNameLevel3.SUCCESS);
    updateWidgetHistory('');
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'member',
        action_reference_id: member.id,
        userTargetId: member.id.toString()
    });
}

const logViewMemberClick = (member: Member) => {
    updateActionHistory(EnumActionNameLevel1.VIEW, EnumActionNameLevel2.MEMBER, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('');
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'member',
        action_reference_id: member.id,
        userTargetId: member.id.toString()
    });
}

const logViewMemberSuccess = (member: Member) => {
    updateActionHistory(EnumActionNameLevel1.VIEW, EnumActionNameLevel2.MEMBER, EnumActionNameLevel3.SUCCESS);
    updateWidgetHistory('');
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'member',
        action_reference_id: member.id,
        userTargetId: member.id.toString()
    });

}

const logSearchFiltersClick = (uxType: EnumUxTypes) => {
    updateActionHistory(EnumActionNameLevel1.SEARCH, EnumActionNameLevel2.FILTERS, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('filters');
    sendLoggingInformation({uxType: uxType, actionGeneratedBy: EnumGeneratedBy.USER});
}

const logFavoriteRemoveClick = (post: Post, filterMember: 'Yes' | 'No',) => {
    updateActionHistory(EnumActionNameLevel1.FAVORITE, EnumActionNameLevel2.LISTING_REMOVE, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('listing_leads');
    ActionDimensions.getInstance().filterMember = filterMember;
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        action_reference_id: post.id,
        post: post,
        actionElementName: EnumElementName.CARD_CELL_RESULT
    });
}

const logFavoriteRemoveSuccess = (post: Post, filterMember: 'Yes' | 'No',) => {
    updateActionHistory(EnumActionNameLevel1.FAVORITE, EnumActionNameLevel2.LISTING_REMOVE, EnumActionNameLevel3.SUCCESS);
    updateWidgetHistory('listing_leads');
    ActionDimensions.getInstance().filterMember = filterMember;
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        action_reference_id: post.id,
        post: post,
        actionElementName: EnumElementName.CARD_CELL_RESULT
    });
}

const logFavoriteAddClick = (post: Post, filterMember: 'Yes' | 'No', timeout: number) => {
    setTimeout(() => {
        updateActionHistory(EnumActionNameLevel1.FAVORITE, EnumActionNameLevel2.LISTING_ADD, EnumActionNameLevel3.CLICK);
        updateWidgetHistory('listing_leads');
        ActionDimensions.getInstance().filterMember = filterMember;
        sendLoggingInformation({
            uxType: EnumUxTypes.BUTTON,
            actionGeneratedBy: EnumGeneratedBy.USER,
            action_reference_entity: 'post',
            action_reference_id: post.id,
            post: post,
            actionElementName: EnumElementName.CARD_CELL_RESULT
        });
    }, timeout)
}

const logFavoriteAddSuccess = (post: Post, filterMember: 'Yes' | 'No',) => {
    updateActionHistory(EnumActionNameLevel1.FAVORITE, EnumActionNameLevel2.LISTING_ADD, EnumActionNameLevel3.SUCCESS);
    updateWidgetHistory('listing_leads');
    ActionDimensions.getInstance().filterMember = filterMember;
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        action_reference_id: post.id,
        post: post,
        actionElementName: EnumElementName.CARD_CELL_RESULT
    });
}

const logSavedSearchRemoveClick = (savedSearchId: number) => {
    updateActionHistory(EnumActionNameLevel1.SEARCHES_SAVE, EnumActionNameLevel2.REMOVE, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('');
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'saved_searches',
        action_reference_id: savedSearchId
    });
}

const logSavedSearchRemoveSuccess = (savedSearchId: number) => {
    updateActionHistory(EnumActionNameLevel1.SEARCHES_SAVE, EnumActionNameLevel2.REMOVE, EnumActionNameLevel3.SUCCESS);
    updateWidgetHistory('');
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'saved_searches',
        action_reference_id: savedSearchId
    });
}

const logAddListingClick = (uxType: EnumUxTypes, widgetName: string = 'add_listing') => {
    updateActionHistory(EnumActionNameLevel1.ADD, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CLICK);
    updateWidgetHistory(widgetName);
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = `${actionDimensions.currScreen ?? DEFAULT_VALUE}${widgetName ? '_' + widgetName : ''}${uxType ? '_' + uxType : ''}`;
    actionDimensions.filterMember = 'NA';
    return sendLoggingInformation({uxType: uxType, actionGeneratedBy: EnumGeneratedBy.USER});
}

const logViewCvClick = (member: Member, post?: Post) => {
    updateActionHistory(EnumActionNameLevel1.VIEW, EnumActionNameLevel2.CV, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('');
    sendLoggingInformation({
        post: post ?? undefined,
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'member',
        action_reference_id: member.id,
        userTargetId: member.id.toString()
    });
}

const logViewCvClickAsListing = (post?: Post) => {
    updateActionHistory(EnumActionNameLevel1.VIEW, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('listing_cell');
    sendLoggingInformation({
        post: post ?? undefined,
        uxType: EnumUxTypes.LIST_CELL,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'member',
        action_reference_id: post.memberId,
        userTargetId: post.memberId.toString()
    });
}

const logOrderStartClick = (widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.ORDER, EnumActionNameLevel2.START, EnumActionNameLevel3.CLICK);
    updateWidgetHistory(widgetName ?? '');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    ActionDimensions.getInstance().sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    sendLoggingInformation({uxType: uxType ? uxType : EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER});
}

const logOrderProductsView = () => {
    updateActionHistory(EnumActionNameLevel1.ORDER, EnumActionNameLevel2.PRODUCTS, EnumActionNameLevel3.VIEW);
    updateWidgetHistory('')
    sendLoggingInformation({uxType: EnumUxTypes.SCREEN, actionGeneratedBy: EnumGeneratedBy.USER});
}

const logOrderProductsClickAddCart = () => {
    updateActionHistory(EnumActionNameLevel1.ORDER, EnumActionNameLevel2.PRODUCTS, EnumActionNameLevel3.CLICK_ADD_TO_CART);
    updateWidgetHistory('promote_listing')
    sendLoggingInformation({uxType: EnumUxTypes.BUTTON, actionGeneratedBy: EnumGeneratedBy.USER});
}

const logPermissionLocationAllow = () => {
    updateActionHistory(EnumActionNameLevel1.PERMISSION, EnumActionNameLevel2.LOCATION, EnumActionNameLevel3.ALLOW);
    updateWidgetHistory('');
    sendLoggingInformation({actionGeneratedBy: EnumGeneratedBy.USER, uxType: EnumUxTypes.NA});
}

const logPermissionLocationDeny = () => {
    updateActionHistory(EnumActionNameLevel1.PERMISSION, EnumActionNameLevel2.LOCATION, EnumActionNameLevel3.DENY);
    updateWidgetHistory('');
    sendLoggingInformation({actionGeneratedBy: EnumGeneratedBy.USER, uxType: EnumUxTypes.NA});
}

const logEditListingClick = (post: Post, uxType?: EnumUxTypes, widgetName?: string) => {
    updateActionHistory(EnumActionNameLevel1.EDIT, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CLICK);
    updateWidgetHistory(widgetName ? widgetName : 'listing_cell');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = `${actionDimensions.currScreen ?? DEFAULT_VALUE}${widgetName ? '_' + widgetName : ''}${uxType ? '_' + uxType : ''}`;
    sendLoggingInformation({
        uxType: uxType ? uxType : EnumUxTypes.LIST_CELL,
        post: post,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        action_reference_id: post.id
    })
}

const logMessageMethodChat = (post: Post, isSeller: boolean, isLeads: boolean = true, filterMember: 'Yes' | 'No') => {
    if (isLeads) {
        updateActionHistory(EnumActionNameLevel1.MESSAGE, isSeller ? EnumActionNameLevel2.SELLER : EnumActionNameLevel2.BUYER, EnumActionNameLevel3.METHOD_CHAT)
        ActionDimensions.getInstance().filterMember = filterMember;
        sendLoggingInformation({
            uxType: EnumUxTypes.BUTTON,
            post: post,
            actionGeneratedBy: EnumGeneratedBy.USER,
            action_reference_entity: 'post',
            action_reference_id: post.id,
            actionElementName: EnumElementName.CARD_CELL_RESULT
        })
        logSendMessage(post, isSeller, 200);
        return;
    }
    logMessageClick(post, isSeller);
}

const logMessageMethodSMS = (post: Post, isSeller: boolean, filterMember: 'Yes' | 'No') => {
    updateActionHistory(EnumActionNameLevel1.MESSAGE, isSeller ? EnumActionNameLevel2.SELLER : EnumActionNameLevel2.BUYER, EnumActionNameLevel3.METHOD_SMS)
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        post: post,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        action_reference_id: post.id,
        actionElementName: EnumElementName.CARD_CELL_RESULT
    })
    ActionDimensions.getInstance().filterMember = filterMember;
    logSendMessage(post, isSeller, 200);
}

const logMessageMethodWhatsapp = (post: Post, isSeller: boolean, filterMember: 'Yes' | 'No') => {
    updateActionHistory(EnumActionNameLevel1.MESSAGE, isSeller ? EnumActionNameLevel2.SELLER : EnumActionNameLevel2.BUYER, EnumActionNameLevel3.METHOD_WHATSAPP)
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        post: post,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        action_reference_id: post.id,
        actionElementName: EnumElementName.CARD_CELL_RESULT
    })
    ActionDimensions.getInstance().filterMember = filterMember;
    logSendMessage(post, isSeller, 200);
}

const logBoostListingFreeBumpUp = (post: Post, uxType: EnumUxTypes, widgetName: string) => {
    updateActionHistory(EnumActionNameLevel1.BOOST, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.FREE_BUMPUP)
    updateWidgetHistory(widgetName);
    sendLoggingInformation({
        uxType: uxType,
        post: post,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        action_reference_id: post.id
    })
}

const logSendMessage = (post: Post, isSeller: boolean, timeOut: number = 0) => {
    setTimeout(() => {
        updateActionHistory(EnumActionNameLevel1.MESSAGE, isSeller ? EnumActionNameLevel2.SELLER : EnumActionNameLevel2.BUYER, EnumActionNameLevel3.SEND);
        updateWidgetHistory('listing_leads');
        sendLoggingInformation({
            uxType: EnumUxTypes.BUTTON,
            actionGeneratedBy: EnumGeneratedBy.USER_SHORTCUT,
            action_reference_entity: 'member',
            action_reference_id: post.memberId,
            post: post,
            actionElementName: EnumElementName.CARD_CELL_RESULT
        });
    }, timeOut)
}

const logAccountEditClick = (member: Member) => {
    updateActionHistory(EnumActionNameLevel1.ACCOUNT, EnumActionNameLevel2.EDIT, EnumActionNameLevel3.CLICK);
    updateWidgetHistory('')
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        action_reference_entity: 'member',
        action_reference_id: member.id,
        actionGeneratedBy: EnumGeneratedBy.USER
    });
}

const logUpgradeAccountClick = (widgetName: string, member: Member) => {
    updateActionHistory(EnumActionNameLevel1.UPGRADE, EnumActionNameLevel2.ACCOUNT, EnumActionNameLevel3.CLICK);
    updateWidgetHistory(widgetName);
    sendLoggingInformation({
        uxType: EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'member',
        action_reference_id: member.id
    });
}

const logMessageClick = (post: Post, isSeller: boolean, timeOut: number = 0) => {
    setTimeout(() => {
        updateActionHistory(EnumActionNameLevel1.MESSAGE, isSeller ? EnumActionNameLevel2.SELLER : EnumActionNameLevel2.BUYER, EnumActionNameLevel3.CLICK);
        sendLoggingInformation({
            uxType: EnumUxTypes.BUTTON,
            post: post,
            actionGeneratedBy: EnumGeneratedBy.USER,
            action_reference_entity: 'post',
            action_reference_id: post.id,
            actionElementName: EnumElementName.CARD_CELL_RESULT
        })
        logSendMessage(post, isSeller, 200)
    }, timeOut);
}

const logMyListingsFilter = (widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.LISTER, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.FILTERS);
    updateWidgetHistory(widgetName ?? '');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    ActionDimensions.getInstance().sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    sendLoggingInformation({uxType: uxType ? uxType : EnumUxTypes.INPUT, actionGeneratedBy: EnumGeneratedBy.USER});
}

const logMyListingsEditListingClick = (post: Post, widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.EDIT, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CLICK);
    updateWidgetHistory(widgetName ? widgetName : 'listing_cell');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    ;
    sendLoggingInformation({
        uxType: uxType ? uxType : EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        post: post,
        action_reference_id: post.id
    })
}

const logMyListingsDeleteListingClick = (post: Post, widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.LISTER, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.DELETE);
    updateWidgetHistory(widgetName ? widgetName : 'wallet_cell');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    ;
    sendLoggingInformation({
        uxType: uxType ? uxType : EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        post: post,
        action_reference_id: post.id
    })
}

const logMyListingsListingChatClick = (post: Post, widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.MESSAGE, EnumActionNameLevel2.SELLER, EnumActionNameLevel3.CLICK);
    updateWidgetHistory(widgetName ? widgetName : 'listing_cell');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    ;
    sendLoggingInformation({
        uxType: uxType ? uxType : EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        post: post,
        action_reference_id: post.id
    })
}

const logMyListingsShareListingClick = (post: Post, widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.SHARE, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CLICK);
    updateWidgetHistory(widgetName ? widgetName : 'listing_cell');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    ;
    sendLoggingInformation({
        uxType: uxType ? uxType : EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        post: post,
        action_reference_id: post.id
    })
}

const logMyListingsCopyListingClick = (post: Post, widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.ADD, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CLICK);
    updateWidgetHistory(widgetName ? widgetName : 'listing_cell');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    ;
    sendLoggingInformation({
        uxType: uxType ? uxType : EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        post: post,
        action_reference_id: post.id
    })
}

const logMyListingsActiveListingClick = (post: Post, widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.LISTER, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.ACTIVATE);
    updateWidgetHistory(widgetName ? widgetName : 'wallet_cell');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    sendLoggingInformation({
        uxType: uxType ? uxType : EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        post: post,
        action_reference_id: post.id
    })
}

const logMyListingsStatsListingClick = (post: Post, widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.LISTER, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.STATS);
    updateWidgetHistory(widgetName ? widgetName : 'wallet_cell');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    sendLoggingInformation({
        uxType: uxType ? uxType : EnumUxTypes.BUTTON,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        post: post,
        action_reference_id: post.id
    })
}

const logMyListingsQualityListingClick = (post: Post, widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.EDIT, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CLICK);
    updateWidgetHistory(widgetName ? widgetName : 'listing_cell');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    ;
    sendLoggingInformation({
        uxType: uxType ? uxType : EnumUxTypes.LINK,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        post: post,
        action_reference_id: post.id
    })
}

const logMyListingsIncreaseLeadsListingClick = (post: Post, widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.ORDER, EnumActionNameLevel2.START, EnumActionNameLevel3.CLICK);
    updateWidgetHistory(widgetName ? widgetName : 'wallet_cell');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    ;
    sendLoggingInformation({
        uxType: uxType ? uxType : EnumUxTypes.LINK,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        post: post,
        action_reference_id: post.id
    })
}

const logMyListingsViewListingClick = (post: Post, widgetName?: string, uxType?: EnumUxTypes, pos?: string) => {
    updateActionHistory(EnumActionNameLevel1.VIEW, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.CLICK);
    updateWidgetHistory(widgetName ? widgetName : 'listing_cell');
    const actionDimensions = ActionDimensions.getInstance();
    actionDimensions.sessionFunnelSource = ActionDimensions.getInstance().currScreen;
    actionDimensions.sessionFunnelPointOfEntry = pos ? pos : DEFAULT_VALUE;
    ;
    sendLoggingInformation({
        uxType: uxType ? uxType : EnumUxTypes.LIST_CELL,
        actionGeneratedBy: EnumGeneratedBy.USER,
        action_reference_entity: 'post',
        post: post,
        action_reference_id: post.id
    })
}

const logStatsListingServe = (posts: Post[], timeOut: number = 0) => {
    setTimeout(() => {
        updateActionHistory(EnumActionNameLevel1.STATS, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.SERVE);
        const payloads = [];
        ActionDimensions.getInstance().filterMember = 'Yes';
        posts.forEach((post) => {
            const payload = {
                uxType: EnumUxTypes.LIST_CELL,
                post: post,
                actionGeneratedBy: EnumGeneratedBy.USER,
                action_reference_entity: 'post',
                action_reference_id: post.id,
                actionElementName: EnumElementName.CARD_CELL_RESULT
            }
            payloads.push(payload);
        })
        sendLoggingInformation(payloads);
    }, timeOut)
}

const logStatsListingPrint = (post: Post) => {
    ActionDimensions.getInstance().filterMember = 'Yes';
    updateActionHistory(EnumActionNameLevel1.STATS, EnumActionNameLevel2.LISTING, EnumActionNameLevel3.PRINT);
    sendLoggingInformation({uxType:EnumUxTypes.LIST_CELL, post: post, actionGeneratedBy: EnumGeneratedBy.USER, action_reference_entity: 'post', action_reference_id: post.id, actionElementName: EnumElementName.CARD_CELL_RESULT})
  }
export { logAccountEditClick, logAddListingClick, logBoostListingFreeBumpUp, logCallListingClick, logCallListingSuccess, logEditListingClick, logFavoriteAddClick, logFavoriteAddSuccess, logFavoriteRemoveClick, logFavoriteRemoveSuccess, logFollowAddClick, logFollowAddSuccess, logFollowRemoveClick, logFollowRemoveSuccess, logMessageMethodChat, logMessageMethodSMS, logMessageMethodWhatsapp, logOpenShareListing, logOrderProductsClickAddCart, logOrderProductsView, logOrderStartClick, logPermissionLocationAllow, logPermissionLocationDeny, logSavedSearchRemoveClick, logSavedSearchRemoveSuccess, logScreenLoad, logSearchFiltersClick, logSendMessage, logShareApp, logViewCvClick, logViewCvClickAsListing, logViewListingClick, logViewListingSuccess, logViewMemberClick, logViewMemberSuccess ,logUpgradeAccountClick,logMessageClick, logStatsListingPrint, logStatsListingServe, logMyListingsFilter, logMyListingsEditListingClick, logMyListingsDeleteListingClick, logMyListingsListingChatClick, logMyListingsShareListingClick, logMyListingsActiveListingClick, logMyListingsStatsListingClick, logMyListingsQualityListingClick, logMyListingsIncreaseLeadsListingClick, logMyListingsViewListingClick, logMyListingsCopyListingClick};
