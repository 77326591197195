import React, {useState, useEffect, useRef, useCallback} from 'react'
import {
  AddFieldType,
  DynamicFilterDropDownOptionI,
  Neighborhood,
  Neighborhoods,
  RemoveFieldType,
} from '../../../../interfaces/MyListings'
import fieldOptionsApi from '../../../../../api/newMyListings/fieldOptions.api'
import Skeleton from 'react-loading-skeleton'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import {useLocation} from 'react-router-dom'
import useFiltersStore from '../../my-listings/FilterStore'

type ValueType = {
  value: number | string
  label: string
  keyName?: string
  parentid?: string
}

const MultiSelect = ({
  items,
  disabled,
  addField,
  label,
  removeField,
  parentid,
  fieldId,
  parentidValue,
}: {
  label?: string
  items?: Neighborhoods | DynamicFilterDropDownOptionI
  disabled: boolean
  addField: AddFieldType
  removeField: RemoveFieldType
  fieldId?: string
  parentid?: string
  parentidValue?: string | number
}) => {
  const fieldIdentifier = fieldId ? fieldId : (items as Neighborhoods)?.fieldId ?? ''
  const [options, setOptions] = useState<(Neighborhood | DynamicFilterDropDownOptionI)[]>([])
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOptions, setSelectedOptions] = useState<ValueType[]>([])
  const containerRef = useRef<HTMLDivElement>(null)
  const displayTextRef = useRef<HTMLSpanElement>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filteredOptions, setFilteredOptions] = useState<
    (Neighborhood | DynamicFilterDropDownOptionI)[]
  >([])
  const intl = useIntl()
  const lang = useLang()
  const location = useLocation()
  const direction = lang === 'ar' ? 'rtl' : 'ltr'
  const {globalFiltersDisable} = useFiltersStore()

  // useEffect(() => {
  //   if (parentid && !parentidValue) return;

  //   const searchParams = new URLSearchParams(decodeURIComponent(location.search) ?? '');
  //   const arrayParams: { [key: string]: string[] } = {};
  //   const keysArray = Array.from(searchParams.keys());

  //   for (let i = 0; i < keysArray.length; i++) {
  //     const key = keysArray[i];
  //     const match = key.match(/^([^\[]+)\[\d+\]$/);
  //     if (match) {
  //       const baseKey = match[1];
  //       if (!arrayParams[baseKey]) {
  //         arrayParams[baseKey] = [];
  //       }
  //       arrayParams[baseKey].push(searchParams.get(key)!);
  //     }
  //   }

  //   const selectedOptionsFromUrl = arrayParams[fieldIdentifier] ?? [];
  //   const selectedParentValue = searchParams.get(parentid);

  //   if (selectedOptionsFromUrl.length > 0) {
  //     console.log(selectedOptionsFromUrl, 'sssssss1');
  //     if (!options.length) {
  //       fieldOptionsApi(fieldId, selectedParentValue).then((res) => {
  //         let ops = [...res.data];
  //         for (let i = 0; i < selectedOptionsFromUrl.length; i++) {
  //           const selectedOp = selectedOptionsFromUrl[i];
  //           console.log(selectedOp, options, 'ssssss2');
  //           const op = ops.find((op) => selectedOp === op.value.toString());
  //           selectOption(op);
  //         }
  //       });
  //       return;
  //     }

  //     for (let i = 0; i < selectedOptionsFromUrl.length; i++) {
  //       const selectedOp = selectedOptionsFromUrl[i];
  //       console.log(selectedOp, options, 'ssssss2');
  //       const op = options.find((op) => selectedOp === op.value.toString());
  //       selectOption(op);
  //     }
  //   }
  // }, [parentid, parentidValue]);

  useEffect(() => {
    if (showOptions) {
      setOptions(
        items
          ? [
              {
                value: -1,
                label: lang === 'ar' ? 'الكل' : 'All',
                autoComplete: 'allالكل',
                parentId: '-1',
              },
              ...((items as Neighborhoods)?.items ?? []),
            ]
          : []
      )
    }
  }, [items, showOptions])

  useEffect(() => {
    if (items || !showOptions) return
    fieldOptionsApi(fieldId, parentidValue ?? null)
      .then((res) => {
        setOptions([
          {value: -1, label: lang === 'ar' ? 'الكل' : 'All', autoComplete: 'allالكل'},
          ...res?.data,
        ])
      })
      .catch((e) => {
        console.error(e)
      })
    return () => {
      if (items) return
      setOptions([])
    }
  }, [items, showOptions])

  useEffect(() => {
    setSelectedOptions([])
  }, [items])

  useEffect(() => {
    if (disabled) setSelectedOptions([])
  }, [disabled])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (searchQuery) {
      const filteredOps = options.filter((option: Neighborhood | DynamicFilterDropDownOptionI) =>
        option.autoComplete?.toLowerCase().includes(searchQuery.toLowerCase())
      )
      setFilteredOptions(filteredOps)
    } else {
      setFilteredOptions(options)
    }
  }, [searchQuery, options])

  const toggleOptions = () => {
    if (disabled) return
    setShowOptions((old) => !old)
  }

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowOptions(false)
    }
  }

  const selectOption = (option: ValueType) => {
    if (option.value === -1 && selectedOptions[0]?.value !== option.value) {
      setSelectedOptions((old: ValueType[]) => {
        old.forEach((option) => {
          removeField(fieldIdentifier, {value: option.value}, 'MULTI')
        })
        return [options[0]]
      })
      return
    }
    if (!selectedOptions.some((opt) => opt.value === option.value)) {
      if (selectedOptions.length === 1 && selectedOptions[0].value !== -1) {
        const firstOption = selectedOptions[0]
        removeField(fieldIdentifier, {value: firstOption.value}, 'MULTI')
        addField(fieldIdentifier, {...firstOption, parentid: parentid ?? '0'}, 'MULTI')
      }
      addField(fieldIdentifier, {...option, parentid: parentid ?? '0'}, 'MULTI')
      setSelectedOptions((old: ValueType[]) => {
        if (old[0]?.value === options[0].value) return [option]
        return [...selectedOptions, option]
      })
    } else {
      removeField(fieldIdentifier, {value: option.value}, 'MULTI')
      setSelectedOptions(selectedOptions.filter((item) => item.value !== option.value))
    }
  }

  const getDisplayText = () => {
    if (selectedOptions.length === 0) {
      return label ?? items?.label
    }

    const containerWidth = containerRef.current ? containerRef.current.clientWidth : 0
    const paddingAndIconWidth = 80 // Adjust based on padding and icon width
    const availableWidth = containerWidth - paddingAndIconWidth
    let displayText = ''
    let overflowIndex = -1

    for (let i = 0; i < selectedOptions.length; i++) {
      const option = selectedOptions[i]
      const testText = displayText ? `${displayText}, ${option.label}` : option.label

      if (displayTextRef.current) {
        displayTextRef.current.innerText = testText
        const textWidth = displayTextRef.current.offsetWidth

        if (textWidth > availableWidth) {
          overflowIndex = i
          break
        } else {
          displayText = testText
        }
      }
    }

    if (overflowIndex >= 0) {
      const remainingCount = selectedOptions.length - (overflowIndex > 0 ? overflowIndex : 1)
      let remainingText = '...'
      if (selectedOptions.length > 1) remainingText = `, +${remainingCount}`
      if (overflowIndex === 0) {
        let truncatedText = selectedOptions[0].label
        remainingText = '...' + remainingText
        if (displayTextRef.current) {
          displayTextRef.current.innerText = truncatedText + remainingText
        }

        while (
          displayTextRef.current &&
          displayTextRef.current.offsetWidth > availableWidth &&
          truncatedText.length > 0
        ) {
          truncatedText = truncatedText.slice(0, -1)
          if (displayTextRef.current) {
            displayTextRef.current.innerText = truncatedText + remainingText
          }
        }
        displayText = truncatedText + remainingText
      } else {
        if (displayTextRef.current) {
          displayTextRef.current.innerText = displayText + remainingText
        }

        while (
          displayTextRef.current &&
          displayTextRef.current.offsetWidth > availableWidth &&
          displayText.length > 0
        ) {
          displayText = displayText.slice(0, -1)
          if (displayTextRef.current) {
            displayTextRef.current.innerText = displayText + remainingText
          }
        }
        displayText = displayText + remainingText
      }
    }

    return displayText
  }

  const loader = useCallback(() => {
    const skeletons: JSX.Element[] = []
    for (let index = 0; index < 9; index++) {
      const element = <Skeleton width={100} height={20} className='m-3' />
      skeletons.push(element)
    }
    return skeletons
  }, [])

  return (
    <div
      ref={containerRef}
      data-id={fieldId}
      className={`form-control user-select-none desktop-filter-field  ${
        !disabled ? 'transparent' : 'bg-light'
      } ${!showOptions && !disabled && 'hoverPointer'}  ${globalFiltersDisable ? 'hoverNotAllowed' : ''}`}
      onClick={()=>{if(!globalFiltersDisable)toggleOptions()}}
    >
      <div className='d-flex align-items-center pt-1' style={{position: 'relative', width: '100%'}}>
        <div
          className={`d-flex text-primary fs-7`}
          style={{position: 'absolute', top: '-8px', width: '100%'}}
        >
          {selectedOptions.length > 0 && (label ?? items?.label)}
        </div>
        <div
          className={`d-flex flex-nowrap overflow-hidden fs-6 text-dark pt-1 ${
            selectedOptions.length > 0 ? 'fw-bold' : 'fw-normal'
          }  ${globalFiltersDisable ? 'text-muted' : ''}`}
          style={{
            top: `${selectedOptions.length > 0 ? '5px' : '0px'}`,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '80%',
            position: 'relative',
          }}
        >
          {selectedOptions.length === 0 ? label ?? items?.label : getDisplayText()}
        </div>
        <span
          ref={displayTextRef}
          style={{position: 'absolute', visibility: 'hidden', whiteSpace: 'nowrap'}}
        ></span>
        <i
          className='fa fa-solid fa-angle-down fs-2'
          style={{
            position: 'absolute',
            right: direction === 'rtl' ? 'unset' : '0px',
            left: direction === 'rtl' ? '0px' : 'unset',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      </div>
      {showOptions && (
        <div
          onClick={stopPropagation}
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            border: '1px solid #ccc',
            backgroundColor: 'white',
            padding: '8px',
            borderRadius: '10px',
            marginTop: '4px',
            zIndex: 1000,
            width: '100%',
            minHeight: '100px',
          }}
        >
          {options && options[1] && options[1].autoComplete && (
            <input
              type='text'
              className='form-control mb-2'
              placeholder={intl.formatMessage({id: 'mylistings.filters.search'})}
              value={searchQuery}
              data-id={`search-${fieldId}`}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          )}
          <div className='mb-2' style={{maxHeight: '100px', overflowY: 'auto'}}>
            <div className='d-flex flex-wrap'>
              {/* {selectedOptions.map((option, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center bg-primary text-light border m-1 p-2 px-3"
                  style={{ borderRadius: '15px', maxWidth: '100%' }}
                >
                  <i
                    className="fa fa-times fs-3 text-light hoverPointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      selectOption(option);
                    }}
                  ></i>
                  <span
                    className="ms-2"
                    style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '80%' }}
                  >
                    {option.label}
                  </span>
                </div>
              ))} */}
            </div>
          </div>
          <div style={{maxHeight: '350px', overflowY: 'auto', overflowX: 'auto'}}>
            {filteredOptions &&
              filteredOptions.map(
                (option: Neighborhood | DynamicFilterDropDownOptionI, index: number) => (
                  <div
                    key={index}
                    data-id={option.value}
                    className='d-flex align-items-center p-2'
                    style={{cursor: 'pointer'}}
                    onClick={() => selectOption(option)}
                  >
                    <div className='d-flex align-items-center hoverPointer text-center fs-4 text-dark user-select-none'>
                      <span
                        className={`fs-2 mx-1 ${
                          selectedOptions.some((opt) => opt.value === option.value)
                            ? 'fas fa-check-square text-primary'
                            : 'far fa-square'
                        }`}
                        style={{
                          color: selectedOptions.some((opt) => opt.value === option.value)
                            ? '#0179FF'
                            : '#ccc',
                        }}
                      />
                      <span
                        className='pt-1'
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '80%',
                        }}
                      >
                        {option.label}
                      </span>
                    </div>
                  </div>
                )
              )}
            {options.length === 0 && loader()}
          </div>
        </div>
      )}
    </div>
  )
}

export default MultiSelect
