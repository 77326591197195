export interface BuyNowServiceInterface {
    services: Service[]
    header: string
    subHeader: string
    blueUnderLineText: string
    buttonsHeader: string
    salesTeamButton: string
    whatsAppButtonText: string
    whatsAppButtonValue: string
  }
  
  export interface Service {
    service: string
    iconUrl: string
    icon: string
    text: string
    isDeepLink: boolean
    deepLink: string
    description?: string[]
  }

export const enum FlowEnum {
    ADD_CREDIT = 1,
    ADD_LISTING = 2,
    ADD_PROMOTION = 3,
    ADD_REPOST = 4,
    RECHARGE = 5,
    SLIDER = 6
}