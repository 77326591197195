import { Dropdown } from 'react-bootstrap'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Member } from '../../../../../logging/types/LoggingTypes'
import { checkIsActive } from '../../../../helpers'
import { checkIfLogin, clearSharedTokenPromise, generateToken, getCookies } from '../../../../helpers/commonFunctions'

type Props = {
  to: string
  title: string
  newIcon?: any
  isMain?: boolean
  applySSO?: boolean
  comingFrom?: string
  dataId: string
  logFunction?: (member: Member) => void
}

export default function SideMenuListItem({
  to,
  title,
  newIcon,
  isMain,
  comingFrom,
  logFunction,
  applySSO,
  dataId,
}: Props) {
  const navigate = useNavigate()
  const navigateTo = () => {
    if (to) navigate(to)
  }
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)

  const logFunctionHandler = () => {
    clearSharedTokenPromise()
    if (logFunction) logFunction({id: getCookies().userInfo.id})
    if (applySSO) {
      const isLoggedIn = checkIfLogin()
      generateToken().then(() => {
        if (!isLoggedIn) window.location.reload()
      })
    }
  }

 const sideMenuListItem = 
  <Dropdown.Item data-id={`${dataId}${comingFrom?'-'+comingFrom: ''}`} onClick={logFunctionHandler} aria-label={title}> <Link to={to} className='d-block my-2 text-black'> {newIcon && (<div
            className={`d-flex align-items-center justify-content-start px-4  w-100 gap-3 py-3 ${isActive && pathname === to.split('?')[0] ? 'activeDropdownItem' : ''}`}>
            {newIcon && <span className=''>{newIcon}</span>}
            <p className={`fs-3 my-auto ${comingFrom == 'headMenu' ? 'fw-bold' : 'fw-normal'}`}>{title}</p>
          </div>)}
        {!newIcon && (<div className={`d-flex align-items-center justify-content-start gap-3 w-100 px-8 py-2 ${isActive && pathname === to.split('?')[0] ? 'activeDropdownItem' : ''}`}>
            <span className='bullet bullet-dot'></span>
            <p className={`fs-5 my-auto ${comingFrom == 'headMenu' ? 'fw-bold' : 'fw-normal'}`}>{title}</p>
          </div>)}
      </Link>
    </Dropdown.Item>
  return sideMenuListItem
}
