import { defaultOptions, getCookies, setCookie } from "./commonFunctions";

export const checkForListingIdInCookiesIfExist = (
    listing: any,
    handleRerenderTrigger
  ) => {
    const thirtyDaysFromNow = new Date();
    thirtyDaysFromNow.setDate(thirtyDaysFromNow.getDate() + 30);
    const MAX_LAST_CONTACTED_ENTRIES = 1000;
    const INDEX_NOT_FOUND = -1;
    if (!getCookies().lastContacted) {
      const lastContactedArr =  createNewLastContactedEntry(listing, thirtyDaysFromNow);
      handleRerenderTrigger && handleRerenderTrigger(lastContactedArr);
      return;
    }
    let lastContactedArr = getCookies().lastContacted;
  
    if (lastContactedArr.length === MAX_LAST_CONTACTED_ENTRIES)
      lastContactedArr.shift();
  
    const currentDate = new Date();
    const filteredContactedArr =
      filterRecentLastContactedEntries(lastContactedArr);
    const existingListingIndex = findExistingListingIndex(
      filteredContactedArr,
      listing.id
    );
    existingListingIndex === INDEX_NOT_FOUND
      ? addNewLastContactedEntry(filteredContactedArr, listing, currentDate)
      : updateLastContactedEntry(
          filteredContactedArr,
          existingListingIndex,
          currentDate
        );
    lastContactedArr = setLastContactedCookie(filteredContactedArr, thirtyDaysFromNow);
    handleRerenderTrigger && handleRerenderTrigger(lastContactedArr);
  };
  
  const createNewLastContactedEntry = (listing, thirtyDaysFromNow) => {
    const newLastContactedArr = [{
        post_id: listing.id,
        last_contacted: new Date().toLocaleDateString(),
      },
    ];
  
    defaultOptions.expires = thirtyDaysFromNow
    setCookie(
      'lastContacted',
      JSON.stringify(newLastContactedArr),
      defaultOptions
    );
    return newLastContactedArr;
  };
  
  const filterRecentLastContactedEntries = (lastContactedArr) => {
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  
    return lastContactedArr.filter(
      (item) => new Date(item.last_contacted) >= thirtyDaysAgo
    );
  };
  
  const findExistingListingIndex = (lastContactedArr, listingId) => {
    return lastContactedArr.findIndex((item) => item.post_id === listingId);
  };
  
  const addNewLastContactedEntry = (lastContactedArr, listing, currentDate) => {
    lastContactedArr.push({
      post_id: listing.id,
      last_contacted: currentDate.toLocaleDateString(),
    });
  };
  
  const updateLastContactedEntry = (lastContactedArr, index, currentDate) => {
    lastContactedArr[index].last_contacted = currentDate.toLocaleDateString();
  };
  
  const setLastContactedCookie = (lastContactedArr, thirtyDaysFromNow) => {
    defaultOptions.expires = thirtyDaysFromNow
    setCookie(
      'lastContacted',
      JSON.stringify(lastContactedArr),
      defaultOptions
    );
    return lastContactedArr;
  };
  
  export const modifyFormat = (lastContactedArr, listingId, locale) => {
    const splited = lastContactedArr?.filter(
      (item) => +item.post_id === listingId
      )[0].last_contacted.split("/");
    return locale === "en" ? `${splited[1]}-${splited[0]}-${splited[2]}` : `${splited[2]}-${splited[0]}-${splited[1]}`;
  }