import React, { useEffect, useState } from 'react';
import { CheckBoxFieldMobileI, OtherOptionsMobileI } from '../../../../interfaces/MyListingsMobile';
import { useLang } from '../../../../i18n/OpenSooqi18n';
import { AddFieldType, RemoveFieldType } from '../../../../interfaces/MyListings';

interface OtherFiltersMobileProps {
  options: OtherOptionsMobileI;
  addField: AddFieldType;
  removeField: RemoveFieldType;
  selectedFields: any;
}

const OtherFiltersMobile: React.FC<OtherFiltersMobileProps> = ({ options, addField, removeField, selectedFields }) => {
  const { items, label } = options;
  const [selectedOptions, setSelectedOptions] = useState<CheckBoxFieldMobileI[]>([]);
  const lang = useLang();
  const isRTL = lang === 'ar';

  const toggleSelection = (selectedOption: CheckBoxFieldMobileI) => {
    if (selectedOptions.some(option => option.fieldId === selectedOption.fieldId)) {
      setSelectedOptions(selectedOptions.filter(option => option.fieldId !== selectedOption.fieldId));
      removeField(selectedOption.fieldId, { value: selectedOption.value }, 'SINGLE');
    } else {
      setSelectedOptions([...selectedOptions, selectedOption]);
      addField(selectedOption.fieldId, { label: selectedOption.label, value: selectedOption.value }, 'SINGLE', false);
    }
  };

  useEffect(() => {
    if (selectedFields) {
      const selectedOptions = items.filter((item: CheckBoxFieldMobileI) => {
        return selectedFields[item?.fieldId];
      });
      setSelectedOptions(selectedOptions);
    }
  }, [selectedFields]);

  return (
    <div className="rounded" style={{ borderRadius: '0.75rem' }} key={label}>
      <div className="p-5 mb-4 bg-white rounded shadow-sm ">
        <h5 className="fw-bold mb-4 mt-0 fs-3 mt-3" style={{ fontSize: '1.5rem' }}>{label}</h5>
        <div className="d-flex flex-column align-items-start justify-content-center gap-1">
          {items.map((item, index) => (
            <div
              key={index}
              onClick={() => toggleSelection(item)}
              className="d-flex align-items-center cursor-pointer py-2 px-3 rounded"
            >
              <span
                className={`fs-1 pb-1 me-3 ${selectedOptions.some(option => option.fieldId === item.fieldId) ? 'fas fa-check-square text-primary' : 'far fa-square'}`}
                style={{
                  color: selectedOptions.some(option => option.fieldId === item.fieldId) ? '#0179FF' : '#ccc',
                }}
              />
              <label className="fs-3 my-0">{item.label}</label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OtherFiltersMobile;
