import React from 'react'
import {
  CategoryFieldMobileI,
  CheckBoxFieldMobileI,
  LocationFieldMobileI,
  MultiSelectPillMobileI,
  NumberFieldMobileI,
  OtherOptionsMobileI,
  PerPageFieldMobileI,
  RangeFieldMobileI,
  RangeFieldWithUnitsI,
  SectionMobile,
  SortFieldMobileI,
  TextFieldMobileI,
} from '../../../../interfaces/MyListingsMobile'
import CategoryFilterMobile from './CategoryFilterMobile'
import {
  AddFieldType,
  RemoveFieldType,
  SelectedFieldsType,
  ValueType,
} from '../../../../interfaces/MyListings'
import OtherFiltersMobile from './OtherFIltersMobile'
import SearchFilterMobile from './SearchFilterMobile'
import SingleSelectMobile from './SingleSelectMobile'
import MultiSelectPills from './MultiSelectPillsMobile'
import SectionFilterMobile from './SectionFilterMobile'
import RangeTextFilterWithUnitMobile from './rangeWithUnits/RangeTextFilterWithUnitMobile'
import RangeTextFilterMobile from './range/RangeTextFilterMobile'
import LocationFilter from './Location/LocationFilterMobile'

interface FilterMobileMapperProps {
  filter:
    | CategoryFieldMobileI
    | LocationFieldMobileI
    | TextFieldMobileI
    | NumberFieldMobileI
    | SortFieldMobileI
    | PerPageFieldMobileI
    | CheckBoxFieldMobileI
    | OtherOptionsMobileI
    | MultiSelectPillMobileI
    | SectionMobile
    | RangeFieldWithUnitsI
    | RangeFieldMobileI
  addField: AddFieldType
  removeField: RemoveFieldType
  selectedFields: SelectedFieldsType
  fetchDynamicFields: (reportingName: string) => void
  resetDynamicFields: () => void
  categoryReportingName?: string
  subCatReportingName?: string
  isNhoodDisabled?: boolean
}

const FiltersMobileMapper: React.FC<FilterMobileMapperProps> = ({
  filter,
  addField,
  removeField,
  resetDynamicFields,
  fetchDynamicFields,
  selectedFields,
  categoryReportingName,
  subCatReportingName,
  isNhoodDisabled
}) => {
  const {type} = filter

  const getParentIdValue = (parentId: number) => {
    if (!parentId || parentId === 0) {
      return null
    }
    const parentField = selectedFields[parentId]
    if (!parentField) return null
    if (typeof parentField === 'object' && ('to' in parentField || 'from' in parentField)) {
      return null
    }
    if (!Array.isArray(parentField) && 'value' in parentField) {
      return (parentField as ValueType).value
    }
    if (Array.isArray(parentField) && parentField.length === 1) {
      return parentField[0].value
    }
    return null
  }

  switch (type) {
    case 'categoryField':
      return (
        <CategoryFilterMobile
          category={filter}
          addField={addField}
          removeField={removeField}
          resetDynamicFields={resetDynamicFields}
          fetchDynamicFields={fetchDynamicFields}
          selectedFields={selectedFields}
        />
      )
    case 'checkBox':
      return <></>
    case 'locationField':
      return <LocationFilter addField={addField}  removeField={removeField} location={filter} selectedFields={selectedFields} isNhoodDisabled={isNhoodDisabled}/>
    case 'sortField':
    case 'perPage':
      return <SingleSelectMobile addField={addField} removeField={removeField} field={filter} subCatReportingName={subCatReportingName} categoryReportingName={categoryReportingName} selectedFields={selectedFields}/>
    case 'textField':
    case 'numberField':
      return <SearchFilterMobile addField={addField} removeField={removeField} field={filter} value={selectedFields[filter?.fieldId]?.value || ''} />
    case 'cpsField':
      return <OtherFiltersMobile options={filter} addField={addField} removeField={removeField} selectedFields={selectedFields} />
    case 'multiSelectPills':
      return <MultiSelectPills addField={addField} removeField={removeField} data={filter} selectedFields={selectedFields} />
    case 'rangeFieldWithUnits':
      return <RangeTextFilterWithUnitMobile addField={addField} removeField={removeField} data={filter} selectedFields={selectedFields} />
      case 'rangeField':
        return <RangeTextFilterMobile addField={addField} removeField={removeField} data={filter} selectedFields={selectedFields} />
    case 'section':
      return (
        <SectionFilterMobile
          addField={addField}
          removeField={removeField}
          section={filter}
          selectedFields={selectedFields}
        />
      )
    default:
      return <></>
  }
}

export default FiltersMobileMapper
