import React, { useEffect, useState } from 'react';
import CityFilter from './CityFilterMobile';
import NeighborhoodFilter from './NhoodFilterMobile';
import { AddFieldType, RemoveFieldType, SelectedFieldsType } from '../../../../../interfaces/MyListings';
import { CityFieldItemMobileI, LocationFieldMobileI } from '../../../../../interfaces/MyListingsMobile';

type LocationFilterProps = {
    addField: AddFieldType;
    removeField: RemoveFieldType;
    selectedFields: SelectedFieldsType;
    location: LocationFieldMobileI;
    isNhoodDisabled: boolean;
};

const LocationFilter = ({ addField, removeField, location, selectedFields, isNhoodDisabled}: LocationFilterProps) => {
    const [selectedCity, setSelectedCity] = useState<CityFieldItemMobileI | null>(null);

    useEffect(()=>{
        if(!selectedFields[location?.citiesWithNHoods?.fieldId])
            setSelectedCity(null);
    }, [location, selectedFields])

    return (
        <div className='card shadow p-2' key={"mobile-location-filter"}>
            <div className='p-3 fs-2 fw-bold'>{location?.label}</div>
            <div key={location?.label+1} className='dotted-line' />
            <CityFilter
                addField={addField}
                removeField={removeField}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                location={location}
                selectedFields={selectedFields}
            />
            <div key={location?.label+2} className='dotted-line' />
            <NeighborhoodFilter
                addField={addField}
                removeField={removeField}
                selectedFields={selectedFields}
                selectedCity={selectedCity}
                location={location}
                disabled={isNhoodDisabled}
            />
        </div>
    );
};

export default LocationFilter;
