import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PageLink, PageTitle } from '../../app/layout/core';
import { CarReportDetails } from '../modules/services/CarReportDetails';
import { CarReports } from '../modules/services/CarReports';
import { CarfaxReport } from '../modules/services/CarfaxReport';
import { CarseerReport } from '../modules/services/CarseerReport';

const history:string[] = [];
const ServicesRoutes: FC = () => {
    const Intl = useIntl()
    const location = useLocation()
    
    useEffect(()=>{
      if(history.length == 2)
        history.shift();
      history.push(location.pathname)
    }, [location]);

    const services: Array<PageLink> = [
        {
          title: Intl.formatMessage({id: 'services'}),
          path: '',
          isSeparator: false,
          isActive: false,
        }
    ]
    return (
        <Routes>
            <Route path='/*' element={<Navigate to='/error/404' state= {{ 'currentPath': window.location.pathname, 'previousPath' : history.length < 1 ? '' : history[history.length - 1] }}/>} />
            <Route path='carfax-report' element={ <> <PageTitle breadcrumbs={services}>{Intl.formatMessage({id: 'carfaxReport'})}</PageTitle> <CarfaxReport /> </> } />
            <Route path='carseer-report' element={ <> <PageTitle breadcrumbs={services}>{Intl.formatMessage({id: 'carseer.title'})}</PageTitle> <CarseerReport /> </> } />
            <Route path='car-reports' element={ <><PageTitle breadcrumbs={services}>{Intl.formatMessage({id: 'products.carReports'})}</PageTitle><CarReports /></>}/>
            <Route path='car-reports/carReportDetails/:id/:type/' element={ <><PageTitle breadcrumbs={services}>{Intl.formatMessage({id: 'products.carReports'})}</PageTitle><CarReportDetails /></>}/>
        </Routes>
    )
}

export default ServicesRoutes
