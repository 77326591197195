import {TableRecordDataType, TableRecordType} from '../types/ScrollableOverdueListType'

const TableRecord = ({
  data,
  contFunction,
  key,
}: {
  data: TableRecordDataType
  contFunction: (payload: any) => void
  key: string
}) => {
  const checkbox = data.checkbox
  const overdue = data.overdue
  const subtitle = data.subtitle
  const title = data.title
  const checkBoxBGColor = checkbox?.disabled ? '#575757' : checkbox?.isChecked ? '#0379FE' : 'gray';
  const toggleHandler = () => {
    if (!checkbox || checkbox?.disabled) return;
    contFunction(checkbox?.payload)
  }
  return (
    <div className='d-flex flex-column m-0'>
      <div className='w-100 p-2 px-4 d-flex justify-content-between align-items-start'>
        <div className='d-flex gap-2 align-items-start'>
          <i
            onClick={toggleHandler}
            className={`${checkbox?.disabled ? '': 'hoverPointer'} ${
              checkbox?.isChecked ? 'fa-solid fa-square-check' : ' fa-regular fa-square'
            } fs-1`}
            style={{color: checkBoxBGColor}}
          ></i>
          <div className='d-flex gap-1 flex-column'>
            <span
              className={`fs-5 ${title?.style?.bold ? 'fw-bold' : ''} ${
                title?.style?.underline ? 'text-decoration-underline' : ''
              }`}
              style={{color: title?.textColor}}
            >
              {title?.text ?? ''}
            </span>
            <span
              className={`fs-5 text-muted ${
                subtitle?.style.bold ? 'fw-bold' : ''
              } ${subtitle?.style?.underline ? 'text-decoration-underlin' : ''}`}
              style={{color: title?.textColor}}
            >
              {subtitle?.text ?? ''}
            </span>
          </div>
        </div>
        <div
          className={`fs-4 ${overdue?.style.bold ? 'fw-bold' : ''} ${
            overdue?.style?.underline ? 'text-decoration-underline' : ''
          }`}
          style={{color: overdue?.textColor}}
        >
          {overdue?.text ?? '-.- JOD'}
        </div>
      </div>
      <hr
        className='mt-0'
        style={{height: '1px', border: '0 none', color: '#888', backgroundColor: '#888'}}
      />
    </div>
  )
}

export default TableRecord
