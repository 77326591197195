import { Modal } from 'react-bootstrap'
import { KTSVG } from '../../../helpers'

type Props = {
  show: boolean
  handleClose: () => void
}

const CustomPopup = ({show,handleClose}: Props) => {
  return <Modal id='custom-popup' tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered modal-fullscreen' show={show} onHide={handleClose}  >
            <div className='modal-header p-5'>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='modal-body py-lg-10 px-lg-10' style={{height : "80vh"}}>
                Custom PopUp
            </div>
        </Modal>
}

export { CustomPopup }
