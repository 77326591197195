import React, { useState, useRef, useEffect } from 'react';
import { MultiSelectPillMobileI, PillOption } from '../../../../interfaces/MyListingsMobile';
import { AddFieldType, RemoveFieldType } from '../../../../interfaces/MyListings';
import { useIntl } from 'react-intl';
import { useLang } from '../../../../i18n/OpenSooqi18n';

type MultiSelectPillsProps = {
  data: MultiSelectPillMobileI;
  addField: AddFieldType;
  removeField: RemoveFieldType;
  selectedFields: any;
};

const MultiSelectPills: React.FC<MultiSelectPillsProps> = ({ data, addField, removeField, selectedFields }) => {
  const [selectedOptions, setSelectedOptions] = useState<PillOption[]>([]);
  const [showAll, setShowAll] = useState(false);
  const [showMoreButton, setShowMoreButton] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();
  const lang = useLang();

  const toggleOption = (option: PillOption) => {
    const isSelected = selectedOptions.some(selected => selected.value === option.value);
    if (isSelected) {
      removeField(data.fieldId.toString(), { value: option.value }, 'MULTI');
      setSelectedOptions(selectedOptions.filter(selected => selected.value !== option.value));
    } else {
      addField(data.fieldId.toString(), { value: option.value, label: option.label }, 'MULTI');
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  useEffect(() => {
    if (containerRef.current && optionsRef.current) {
      const containerHeight = containerRef.current.clientHeight;
      const optionsHeight = optionsRef.current.scrollHeight;
      
      setShowMoreButton(optionsHeight > containerHeight);
    }
  }, [showAll, data.options]);

  useEffect(() => {
    if (selectedFields && selectedFields[data.fieldId]) {
      setSelectedOptions(selectedFields[data.fieldId].map((option: PillOption) => option));
    }
  }, [selectedFields, showMoreButton]);

  return (
    <div className="card shadow-sm container-fluid m-0 p-0" key={data?.fieldId}>
      <div className="card w-100 p-5 rounded-3">
        <h5 className="card-title my-3 fs-3 fw-bold">{data.label}</h5>
        <div
          ref={containerRef}
          style={{
            maxHeight: showAll ? 'none' : '8.8rem', // Adjust based on your design
            overflow: 'hidden',
            position: 'relative'
          }}
        >
          <div
            ref={optionsRef}
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '2px',
            }}
          >
            {data.options.map((option, index) => (
              <span
                key={index}
                className={`px-4 fs-4 py-3 badge hoverPointer user-select-none bg-light fw-normal ${selectedOptions.some(selected => selected.value === option.value) ? 'bg-primary text-white' : 'text-dark'}`}
                style={{
                  borderRadius: '1rem',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
                onClick={() => toggleOption(option)}
              >
                {option && option.icon &&             
                  <span className="mx-1" style={{ maxWidth: '20px', maxHeight: '20px' }}>
                    <img
                      src={`${process.env.REACT_APP_OPENSOOQ_CUSTOM_ICON_URL}/cp/${option.icon}`}
                      className='h-100 w-100'
                      alt=''
                    />
                  </span>}
                {option.label}
              </span>
            ))}
          </div>
        </div>
        {showMoreButton && (
          <button
            className="btn fs-4 fw-bold btn-outline-transparent text-primary text-center mt-3 d-flex justify-content-center align-items-center"
            onClick={() => setShowAll(!showAll)}
            style={{ borderRadius: '1rem' }}
          >
            {intl.formatMessage({ id: 'mylisting.multipill.more' })}
            <span className={`fs-6 ${lang === 'ar' ? 'me-2' : 'ms-2'} text-primary fa ${showAll ? 'fa-minus' : 'fa-plus'}`}></span>
          </button>
        )}
      </div>
    </div>
  );
};

export default MultiSelectPills;
