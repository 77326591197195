import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { ActionDimensions} from "../dimensions/ActionDimensions"
import { DEFAULT_VALUE, LoggingScreenNames } from "../types/LoggingTypes"
import Cookies from "js-cookie";
import { defaultOptions, setCookie } from "../../app/helpers/commonFunctions";

const useScreenNames = ()=>{
  const location = useLocation()
  useEffect(()=>{ 
    const prevScreenParsed = Cookies.get('prevScreen') ?? '';
    const prevScreenArr = prevScreenParsed.split(',')
    let prevScreen = '';
    if(prevScreenArr.length === 1)
      prevScreen = prevScreenArr[0];
    else
      prevScreen = prevScreenArr[1];
    const pathname = location.pathname;
    const paths = pathname.split('/');
    let screenName = paths[paths.length - 1];
    const currScreen = LoggingScreenNames[screenName.trim()] ?? DEFAULT_VALUE;
    if(currScreen === LoggingScreenNames['my-account'])
      return;
    ActionDimensions.getInstance().currScreen = currScreen;
    if(currScreen === prevScreen)
      return;
    ActionDimensions.getInstance().prevScreen = prevScreen ?? DEFAULT_VALUE;
    setCookie('prevScreen', currScreen, {...defaultOptions, expires: 10})
  },[location])
}

export {useScreenNames}