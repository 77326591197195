import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {useIntl} from 'react-intl'

interface RadialBarChartProps {
  value: string
  percentage: number
  width: number
  height: number
  label: string
  fillColor: string
  nameFontSize: string
  valueFontSize: string
  totalFontSize: string
  hollowSize: string
}

const RadialBarChart: React.FC<RadialBarChartProps> = ({
  value,
  width,
  height,
  label,
  fillColor,
  percentage,
  nameFontSize,
  valueFontSize,
  totalFontSize,
  hollowSize,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const intl = useIntl()

  useEffect(() => {
    if (!chartRef.current) return

    const options: ApexOptions = {
      chart: {
        type: 'radialBar',
        // height: height,
        width: width,
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: nameFontSize,
              offsetY: parseInt(nameFontSize),
            },
            value: {
              fontSize: valueFontSize,
              offsetY: -parseInt(valueFontSize),
              fontWeight: 800,
            },
            total: {
              show: true,
              label: intl.formatMessage({id: label}),
              formatter: () => value,
              fontSize: totalFontSize,
              fontWeight: 400,
              color: '#788087',
            },
          },
          hollow: {
            size: hollowSize,
          },
          track: {
            background: '#D3D3D9',
          },
        },
      },
      fill: {
        colors: [fillColor],
      },
      yaxis: {
        min: 0,
        max: 5000,
      },
      labels: ['Progress'],
      series: [percentage],
    }

    const chart = new ApexCharts(chartRef.current, options)
    chart.render()

    return () => {
      chart.destroy()
    }
  }, [
    intl,
    value,
    width,
    height,
    label,
    fillColor,
    percentage,
    nameFontSize,
    valueFontSize,
    totalFontSize,
    hollowSize,
  ])

  return <div ref={chartRef} />
}

export default RadialBarChart
