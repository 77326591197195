import {useEffect } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../app/helpers';
import { Button } from '../../app/layout/components/opensooq/Button';
import { NotificationsPage } from 'opensooq-notification'
import { useLang } from '../../app/i18n/OpenSooqi18n';
import { MenuComponent } from '../assets/ts/components';
import { NotificationDropdown } from '../partials/content/dropdown/NotificationsDropdown';
import { generateOpenSooqRedirectUrl, getCookies, redirectionInterceptor } from '../helpers/commonFunctions';
import { logScreenLoad } from '../../logging/helpers/commonLoggingFunctions';


export function Notification() {
    const ICON_IMAGE_SIZE = 'xh';
    const Intl = useIntl();
    const lang = useLang();

    const checkLink = (notification : any,notificationCta : any,onView : any) => {
        onView(notification.timeline_id,notification.event_id);
        redirectionInterceptor()
        const deepLinkBase =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry,lang) + '/';
        const url = `${deepLinkBase}site/dl?requestUri=${notificationCta.link}`;
        window.open(url,'_blank');
    }

    useEffect(() => {
        window.moveTo(0,0);
        MenuComponent.reinitialization()
        logScreenLoad()
      }, [])
    return  <><NotificationsPage
        header={({actions,states}) => {
            return (
                <div className="card-header">
                    <h3 className="card-title align-items-start flex-column">
                    </h3>
                    <div className="card-toolbar">
                        
                        <div className="d-flex flex-stack flex-wrap gap-4">
                            <div className="d-flex align-items-center fw-bold">
                                <div className="text-gray-400 fs-7 me-2">{Intl.formatMessage({id : "notification.card.header.status"})}</div>
                                <select onChange={(e : any) => {actions.onChangeReadableFilter(parseInt(e.target.value))}} className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto select2-hidden-accessible">
                                    <option value={-1}>{Intl.formatMessage({id : "notification.card.header.status.all"})}</option>
                                    <option value={1}>{Intl.formatMessage({id : "notification.card.header.status.read"})}</option>
                                    <option value={2}>{Intl.formatMessage({id : "notification.card.header.status.unRead"})} </option>
                                </select>
                            </div>
                            <div className="d-flex align-items-center fw-bold">
                                <div className="text-gray-400 fs-7 me-2">{Intl.formatMessage({id : "notification.card.header.listings"})}</div>
                                <select onChange={(e : any) => {actions.onChangeListing(parseInt(e.target.value))}} className="form-select form-select-transparent text-dark fs-7 lh-1 fw-bold py-0 ps-3 w-auto select2-hidden-accessible">
                                    <option value='-1'>{Intl.formatMessage({id : "notification.card.header.listings.all"})} </option>
                                    <option value="1">{Intl.formatMessage({id : "notification.card.header.listings.sell"})}</option>
                                    <option value='2'>{Intl.formatMessage({id : "notification.card.header.listings.buy"})}</option>
                                </select>
                            </div>
                            <div className="position-relative">
                                <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                        <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                                    </svg>
                                </span>
                                <input type="text" value={states.searchQuery} onChange={(e : any) => {actions.onSearchAds(e.target.value)}}  className="form-control w-200px fs-7 ps-12"  placeholder={Intl.formatMessage({id : 'notification.card.header.search'})}/>
                            </div>
                            <div className="dropdown">
                                <button type='button'  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" >
                                    <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                                </button>
                                <NotificationDropdown action={actions.onClickMarkAllButtonHandler} />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }}
        NotificationItem={(props) => {
            const {notification, onView, isRead} = props
            return (
                <div className={`row mt-2`}>
                    <div className='col-12'>
                        <div className="timeline-label customNotificationDiv p-5 bg-white">
                            <div className="timeline-item">
                                <div className="d-flex flex-column">
                                    <div className="">
                                        <div className="timeline-badge">
                                            <img width="22" height="22"  src={`https://opensooqui2.os-cdn.com/api/apiV/android/${ICON_IMAGE_SIZE}/${notification.group_icon}`} alt="carfax"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="fw-mormal timeline-content ps-3">
                                    <span className='fs-12 text-gray-600'>{notification.date}</span>
                                    <div className="d-flex align-items-start   ">
                                        <div className="p-2 mt-1 d-none">
                                            <i className="fas fa-bolt fs-3"></i>
                                        </div>
                                        <div className="p-2 w-100">
                                            {(lang == 'en') && <div className="d-flex align-items-center fs-6 w-100">
                                                <div className="me-2 fs-7">
                                                    {(notification.title_en) ? notification.title_en + '-' : ''} {notification.text_en}
                                                </div>
                                                <div className="symbol symbol-circle symbol-25px" ></div>
                                            </div>}
                                            {(lang == 'ar') && <div className="d-flex align-items-center fs-6 w-100">
                                                <div className="me-2 fs-7">
                                                    {(notification.title_ar) ? notification.title_ar + '-' : ''} {notification.text_ar}
                                                </div>
                                                <div className="symbol symbol-circle symbol-25px" ></div>
                                            </div>}
                                            <div className='mt-4'>
                                                <div className="d-flex justify-content-between ">
                                                    <div className="">
                                                        {notification.cta_1.text_en != '' && <Button action={() => checkLink(notification,notification.cta_1,onView)}  text={(lang == 'ar') ? notification.cta_1.text_ar : notification.cta_1.text_en} className={'btn btn-primary btn-sm pt-1 pb-1 me-2'} style={{bgColor: notification.cta_1.bg_color, fontColor: notification.cta_1.font_color}}/>}
                                                        {notification.cta_2.text_en != '' && <Button action={() => checkLink(notification,notification.cta_2,onView)} text={(lang == 'ar') ? notification.cta_2.text_ar : notification.cta_2.text_en} className={'btn btn-primary btn-sm pt-1 pb-1 me-2'} style={{bgColor: notification.cta_2.bg_color, fontColor: notification.cta_2.font_color}}/>}
                                                        {notification.cta_3.text_en != '' && <Button action={() => checkLink(notification,notification.cta_3,onView)}  text={(lang == 'ar') ? notification.cta_3.text_ar : notification.cta_3.text_en} className={'btn btn-primary btn-sm pt-1 pb-1 me-2'} style={{bgColor: notification.cta_3.bg_color, fontColor: notification.cta_3.font_color}}/>}
                                                    </div>
                                                    {!isRead && <div className=""><span
                                                        className="badge badge-primary badge-circle w-7px h-7px me-1"></span>
                                                    </div>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }}
    />
</>
}

