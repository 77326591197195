import React from "react";

interface IIconCloseProps {
  width?: string;
  height?: string;
  stroke?: string;
  strokeWidth?: string;
  fill?: string;
  className?: string;
  onClick?: () => void;
}

const IconClose = ({
  width = "20",
  height = "20",
  stroke = "#000",
  strokeWidth = "1",
  fill = "#000",
  className = "",
  onClick,
}: IIconCloseProps) => {
  return (
    <svg
      viewBox="0 0 32 32"
      width={width}
      height={height}
      className={className ? className : null}
      data-name="IconClose"
      onClick={onClick}
    >
      <path
        fill={fill}
        stroke={stroke}
        strokeWidth={strokeWidth}
        d="M25.333 8.547l-1.88-1.88-7.453 7.453-7.453-7.453-1.88 1.88 7.453 7.453-7.453 7.453 1.88 1.88 7.453-7.453 7.453 7.453 1.88-1.88-7.453-7.453z"
      />
    </svg>
  );
};

export default IconClose;
