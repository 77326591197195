
import { useChatFunctions } from "opensooq-chat"
import { useEffect, useState } from "react"
import { useIntl } from "react-intl"
import myCvApi from "../../../../api/account/myCv.api"
import getAdStatsApi from "../../../../api/getAdStats.api"
import { ListingStatsInterface } from "../../../../app/interfaces/ListingStatsInterface"
import { Chart } from "../../../../app/layout/components/opensooq/Chart"
import { logScreenLoad } from "../../../../logging/helpers/commonLoggingFunctions"
import DynamicObject from "../../../../models/dynamic-object"
import { MemberShipModal } from "../../payment/MemberShipModal"
import CvBody from "./CvBody"
import CvFooter from "./CvFooter"

const CvStats = () => {
    const listingId : any = new URLSearchParams(window.location.search).get('id') ?? 0;
    const [favorite,setFavorite] = useState <ListingStatsInterface>()
    const [calls,setCalls] = useState <ListingStatsInterface>()
    const [views,setViews] = useState <ListingStatsInterface>()
    const [chat,setChat] = useState <ListingStatsInterface>()
    const [isLoading,setISLoading] = useState <boolean>(true)
    const [showBundle,setShowBundle] = useState <boolean>(false)
    const {getUnreadPostRooms} = useChatFunctions()
    const Intl = useIntl();
    const [cv,setCv] = useState <DynamicObject>({})

    useEffect(() => {
        logScreenLoad()
        myCvApi(listingId).then((response) => {
            setCv(response.data)
        })

        getUnreadPostRooms(listingId).then((value : number) => {
        })

        getAdStatsApi(listingId).then(response => {
            setViews(response.data.views);
            setCalls(response.data.calls);
            setChat(response.data.rooms);
            setFavorite(response.data.bookmarks);
            setISLoading(false)
        });
    },[]);

    return (
        <>
            {cv && cv.cv_id && <div className="row justify-content-center">
                <div className="col-lg-12">
                    <div className="card">
                        {<div className="card-body p-5 w-100"><CvBody cv={cv}/></div>}
                        {<div className="card-footer p-5 w-100"><CvFooter cv={cv}/></div>}
                    </div>
                </div>
            </div>}
            {!isLoading && <>
                {/* <div className="row justify-content-between mt-5">
                    <div className="col-2">
                        <select className="bg-white form-select form-select-solid"  data-kt-select2="true"  data-allow-clear="true">
                            <option value="7">Last 7 days</option>
                            <option value="30">Last Month</option>
                        </select>                         
                    </div>
                </div> */}
                <div className="row">
                    {views &&  <div className="col-12">
                        <Chart className='mb-5 mt-5' total={views.total} buttonText={Intl.formatMessage({id: 'adViews.text3'})} description={Intl.formatMessage({id: 'adViews.text2'})} title={Intl.formatMessage({id: 'adViews.text1'})} chartType={views} target={'views'} adId={listingId} extraText={Intl.formatMessage({id: "listing.stats.viewsDesc"})} chartName={Intl.formatMessage({id: 'adViews.text1'})}/>
                    </div>}
                    {calls &&  <div className="col-12">
                        <Chart className='mb-5 mt-5' total={calls.total} buttonText={Intl.formatMessage({id: 'adViews.text6'})} description={Intl.formatMessage({id: 'adViews.text5'})} title={Intl.formatMessage({id: 'adViews.text4'})}  chartType={calls} target={'calls'} adId={listingId} chartName={Intl.formatMessage({id: 'adViews.text4'})}/>
                    </div>}
                    {chat &&  <div className="col-12">
                        <Chart className='mb-5 mt-5' total={chat.total} buttonText={Intl.formatMessage({id: 'adViews.text12'})} description={Intl.formatMessage({id: 'adViews.text8'})} title={Intl.formatMessage({id: 'adViews.text7'})}  chartType={chat} target={'msgs'} adId={listingId} chartName={Intl.formatMessage({id: 'adViews.text7'})}/>
                    </div>}
                    {favorite && <div className="col-12">
                        <Chart className='mb-5 mt-5' total={favorite.total} buttonText={Intl.formatMessage({id: 'adViews.text11'})} description={Intl.formatMessage({id: 'adViews.text10'})} title={Intl.formatMessage({id: 'adViews.text9'})}  chartType={favorite} target={'bookmarks'} adId={listingId} chartName={Intl.formatMessage({id: 'adViews.text9'})}/>
                    </div>}
                    {/* {chat && <div className="col-12">
                        <Chart className='mb-5 mt-5' total={0} buttonText={Intl.formatMessage({id: 'adViews.text12'})} description={Intl.formatMessage({id: 'adViews.text13'})} title={Intl.formatMessage({id: 'adViews.text13'})}  chartType={chat} target={Intl.formatMessage({id: 'adViews.text13'})} adId={listingId} chartName={Intl.formatMessage({id: 'adViews.text13'})}/>
                    </div>} */}
                </div>
                <div className="row">
                </div>
                <MemberShipModal  show={showBundle}  type={['Turbo','Premium','Bumpup']} handleClose={() => {setShowBundle(false)}} id={listingId} pos={'stats'}/>
            </>}
        </>
)}

export { CvStats }

