import {useState} from 'react'
import {useLang} from '../../../i18n/OpenSooqi18n'
import {getNoImageLogo} from '../../../helpers/commonFunctions'
import {KTSVG} from '../../../helpers'

const ImageWithFallback = ({src, alt, deleteAction, openAction, ...props}) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [imageError, setImageError] = useState(false)
  const [retryCount, setRetryCount] = useState(0)
  const lang = useLang()
  const maxRetries = 10 // Set the maximum number of retries
  const retryDelay = 1000 // Retry delay in milliseconds (e.g., 2000ms = 2 seconds)

  const handleImageLoad = () => {
    setImageLoaded(true)
    setImageError(false)
  }

  const handleImageError = () => {
    if (retryCount < maxRetries) {
      setRetryCount(retryCount + 1)
      setImageError(true)
      setTimeout(() => {
        setImageError(false) // Reset error state to trigger a re-render
      }, retryDelay)
    }
  }

  const handleDelete = (e) => {
    e.stopPropagation()
    deleteAction()
  }

  const handleOpen = (e) => {
    e.stopPropagation()
    openAction()
  }

  return (
    <div onClick={handleOpen} style={{cursor: 'pointer', height: '180px'}}>
      <img
        src={!imageError ? src : getNoImageLogo(lang)} // Placeholder image path
        alt={alt}
        onLoad={handleImageLoad}
        onError={handleImageError}
        {...props}
      />
      <div
        style={{
          height: '50px',
          background: 'linear-gradient(to top, #000000, transparent)',
          position: 'relative',
          top: '-50px',
        }}
      >
        <span
          style={{
            display: 'block',
            position: 'relative',
            right: lang == 'en' ? '-150px' : '150',
            top: '20px',
            width: '30px',
          }}
          onClick={handleDelete}
        >
          <KTSVG className='svg-icon-1' path='/media/svg/general/gellery-trash.svg' />
        </span>
      </div>
    </div>
  )
}

export default ImageWithFallback
