import { FC } from 'react';
import { Routes } from 'react-router-dom';

const SearchesRoutes: FC = () => {
    return (
        <Routes>
        </Routes>
    )
}

export default SearchesRoutes
