import React from "react";
import { useIntl } from "react-intl";
import uuid from "react-uuid";
import { KTSVG } from "../../helpers";
import { generateOpenSooqRedirectUrl, getCookies, isDeviceMobile, redirectionInterceptor } from "../../helpers/commonFunctions";
import { useLang } from "../../i18n/OpenSooqi18n";
import { SavedSearchInterface } from '../../interfaces/SavedSearchInterface';
import { RecentSearchInterface } from "./RecentSearch";

export const SearchesList:React.FC<{searchData: RecentSearchInterface[] | SavedSearchInterface[], recentSearch?: boolean, savedSearch?: boolean, handleUpdateSavedSearch?, dataId}> = ({searchData, recentSearch = false, savedSearch = false, handleUpdateSavedSearch, dataId}) => {
    const Intl = useIntl();
    const lang = useLang();
    const deepLinkBase =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry ?? '',lang) + '/';

    const handleBellClick = ({md5, id, index}) => {
        if(savedSearch || recentSearch){
            handleUpdateSavedSearch(md5, id, index)
        } 
    }
    
    return <React.Fragment key={'recentSearch'}>
      {!isDeviceMobile() ? <div className="align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
            <div className="container">
            {searchData.map((item  : RecentSearchInterface,index) => {
                return <React.Fragment key={uuid()}>
                <div key={index} className="row grayHoverBg mt-3 mb-4" data-id={`${dataId}-search-${item.id}`}>
                    <div className="col-3 d-grid">
                        <p className={`fs-7 text-black-50 pe-none pb-6 justify-self-start`} style={{minWidth: "100px"}}>{item.date || '' }</p>
                        <div className="symbol symbol-45px me-5">
                            <img className="mt-n12" src={item.category_image} alt=""/>
                        </div>
                    </div>
                    <div className="col-5 d-flex align-items-center">
                        <div data-id={`${dataId}-search-categorySubCategory-${item.id}`} style={{overflow: "hidden", maxHeight: "125px", maxWidth: "100%"}} className="d-flex justify-content-start flex-wrap">
                            {item.tags.map((item) => { 
                                return<span style={{display:'inline-block', maxWidth :"100%" ,overflow:'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis', height: "19px"}} key={uuid()} className="badge badge-secondary mx-1 my-1">{item}</span>
                            })}
                        </div>
                    </div>
                    <div className="col-1 text-end  h-100px" style={{position:'relative'}}>
                        <span  className="text-dark fw-bold fs-3"  style={{ height: '100%', width: '100%'}}>{item.term}</span>
                    </div>
                    {(savedSearch || recentSearch) && <div className="col-3 d-flex flex-wrap justify-content-center align-items-center" data-id={`${dataId}-search-cpsBlock-${item.id}`}>
                        <>
                        <a data-id={`${dataId}-search-viewListings-${item.id}`} target="_blank" onClick={redirectionInterceptor} href={`${deepLinkBase}site/dl?requestUri=${item.deeplink}`} className="btn btn-primary btn-sm me-4 d-block">{Intl.formatMessage({id: 'viewListings'})}</a>
                        <span data-id={`${dataId}-search-bell-${item.id}`}  onClick={() => handleBellClick({md5: item?.md5, id: item?.id, index: index})}> {item.enable_notification == 1 ? <i className="p-5 hoverPointer fa-regular fa-bell fs-1 w-25px d-block" /> : <KTSVG path='/media/svg/shapes/Vector.svg' className='btn btn-sm svg-icon-1 w-25px' />}</span>
                        </>
                    </div> }
                </div>
                <div className="border border-dashed mt-n2"></div>
             </React.Fragment>
            })}
            </div>
        </div> :searchData.map((item  : RecentSearchInterface, index) => {
            return <React.Fragment key={uuid()}>
            <p className={`fs-7 text-black-50 pe-none mb-n1 pb-1 mt-5`} style={{minWidth: "100px"}}>
                {item.date || '' }
            </p>
            <div className="d-flex flex-column gap-3 bg-white rounded-3 p-4" data-id={`${dataId}-search-${item.id}`}>
                <div className="d-flex justify-content-between" >
                    <div className="symbol symbol-45px me-5">
                        <img  src={item.category_image} alt=""/>
                    </div>
                    <div>
                        <span className="text-dark fw-bold fs-3"  style={{ height: '100%', width: '100%'}}>{item.term}</span>
                    </div>
                    <div className="symbol symbol-45px me-5" >
                        <span data-id={`${dataId}-search-bell-${item.id}`} onClick={() => handleBellClick({md5: item?.md5, id: item?.id, index: index})}> {item.enable_notification == 1 ? <i className="fa fa-bell fs-1" /> : <KTSVG path='/media/svg/shapes/Vector.svg' className='svg-icon-1' />}</span>
                    </div>
                </div>
                <div data-id={`${dataId}-search-categorySubCategory-${item.id}`} style={{overflow: "hidden", maxHeight: "123px"}} >{item.tags.map((item) => {
                    return  <span key={uuid()} style={{
                        display:'inline-block', maxWidth :"100%" , whiteSpace: 'nowrap', textOverflow: 'ellipsis', height: "19px"}} className="badge badge-secondary me-3 text-truncate">{item}</span>
                    })}
                </div>
                <div className="d-flex justify-content-between" data-id={`${dataId}-search-cpsBlock-${item.id}`}>  
                    <a onClick={redirectionInterceptor} href={`${deepLinkBase}site/dl?requestUri=${item.deeplink}`} data-id={`${dataId}-search-viewListings-${item.id}`} target="blank"  className="btn btn-primary btn-md me-3">{Intl.formatMessage({id: 'viewListings'})}</a>
                </div>
            </div>
        </React.Fragment> })}
    </React.Fragment>  
};
