import { MyWalletTableLoader } from "../../../components/loaders/MyWalletTableLoader"
import { useLang } from "../../../i18n/OpenSooqi18n";
import React, { Fragment, useRef, useState } from "react";
import { useIntl } from "react-intl"
import Select from "react-select";
import { BundleInterface, WalletDetailsInterface } from "../../../interfaces/WalletDetailsInterface";
import { generateOpenSooqRedirectUrl, getCookies, redirectionInterceptor } from "../../../helpers/commonFunctions";
import { useNavigate } from "react-router-dom";

type Props = {
    filterByCategory :  (param : any) => any
    filterByBundle :  (param : any) => any
    setSearch :  (param : any) => any
    collapse :  (param : any) => any
    getWalletDetails : () => any
    bundlesFilter: any
    searchData : BundleInterface[]
    services : BundleInterface[]
    servicesLoader : boolean
    categoriesFilter : any
    walletDetails : WalletDetailsInterface
}



const Bundles = ({walletDetails,filterByCategory,filterByBundle,setSearch,servicesLoader,services,collapse,getWalletDetails,categoriesFilter,bundlesFilter,searchData} : Props) => {
    const refTransactions = useRef<null | HTMLDivElement>(null)
    const Intl = useIntl();
    const lang = useLang();
    const navigate = useNavigate();
    const handleUseBundle = (bundle : BundleInterface) => {
        if(bundle.serviceLabel == 'CarFax' || bundle.serviceLabel == 'CarSeer' || bundle.serviceLabel == 'Mojaz'){
            redirectionInterceptor()
            let openSooqUrl =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE,getCookies().ecountry,lang)
            const reportUrl = (lang === 'ar') ? 'تقارير-المركبات' : 'car-reports'
            const serviceUrl =  openSooqUrl + "/"+ reportUrl + "?id=" + bundle.id + '&type=' + bundle.serviceLabel;
            window.location.href = serviceUrl;
        }else{
            navigate(`bundle-usage/${bundle.id}`)
        }
    }
    const ServicesList:React.FC<{searchData:BundleInterface[]}> = ({searchData}) => {
        return <table className="table table-hover align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
            <thead>
                <tr className="text-start text-gray-900 fw-bold fs-7 text-uppercase gs-0">
                    <th className="min-w-100px sorting_disabled" rowSpan={1} colSpan={1} >{Intl.formatMessage({id: 'myWalletText13'})} </th>
                    <th className="text-start min-w-100px sorting_disabled hideOnMobile" rowSpan={1} colSpan={1} >  {Intl.formatMessage({id: 'myWalletText12'})} </th>
                    {/* <th className="text-start min-w-125px sorting_disabled hideOnMobile" rowSpan={1} colSpan={1}> {Intl.formatMessage({id: 'myWalletText14'})}</th> */}
                    <th className="text-start min-w-100px sorting_disabled hideOnMobile" rowSpan={1} colSpan={1}> {Intl.formatMessage({id: 'myWalletText15'})}</th>
                    <th className="text-start min-w-100px sorting_disabled hideOnMobile" rowSpan={1} colSpan={1} > {Intl.formatMessage({id: 'myWalletText16'})}</th>
                    <th className="text-start sorting_disabled" rowSpan={1} colSpan={1} ></th>
                </tr>
            </thead>
            <tbody className="">
                {searchData.map((bundle  : BundleInterface,index) => {
                return <React.Fragment key={index}>
                        <tr className={(index%2 === 0) ? "even": "odd" }>
                            <td className="">
                                <span className="text-dark fw-bolder text-hover-primary">{bundle.catLabel}</span>
                            </td>
                            <td className="text-start hideOnMobile">
                                <div className="d-flex">
                                    <div><img className="me-3" width="25" height="25" src={`${bundle.icon.replace('{platform}','android').replace("{size}","xh")}`} alt="altImage"/></div>
                                    <div className="d-flex flex-column">
                                        <span className="text-dark text-hover-primary mb-1 fs-6">
                                            {bundle.label}
                                        </span>
                                        { bundle.features &&<ul className="fs-7 px-5">
                                            {  bundle.features.map((item, index) => {
                                                return <li key={index}>{textFormatter(item)}</li>
                                            })}
                                        </ul>}
                                    </div>
                                </div>
                            </td>
                            <td className="text-start hideOnMobile"><span className="text-dark text-hover-primary d-block fs-6">{bundle.availableQuantity}</span></td>
                            <td className="text-start hideOnMobile">
                                <span className="text-dark"><span className="text-dark text-hover-primary d-block fs-6">{bundle.expiry}</span></span>
                            </td>
                            <td className="text-start hideOnMobile">
                                <button onClick={() =>{handleUseBundle(bundle)}} disabled={bundle.viewOnly} className='btn btn-primary btn-sm' data-id={`my-wallet-bundles-bundle-useBtn-${bundle.id}`}>
                                    {Intl.formatMessage({id: 'myWalletText10'})}
                                </button>
                            </td>
                            <td className="text-end hideOnDesktop">
                                <button onClick={(e) => {collapse(e)}} type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row"  data-bs-toggle="collapse" data-bs-target={`#id-${bundle.id}`}>
                                    <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1"
                                                transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                    <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                </button>
                            </td>
                        </tr>
                        <tr id={`id-${bundle.id}`} className="hideOnDesktop collapse" data-bs-parent="#kt_accordion_tr-1"  >
                            <td colSpan={6} className="shadow1">
                                <dl className="row mb-0">
                                    <dt className="col-5 text-gray-500 fw-bolder">{Intl.formatMessage({id: 'myWalletText12'})}</dt>
                                    <dd className="col-7 p-0">
                                        <img className="me-3 h-100" width="25" height="25" src={`${bundle.icon.replace('{platform}','android').replace("{size}","xh")}`} alt="altImage"/> {bundle.label}
                                    </dd>
                                </dl>
                                <dl className="row mb-0">
                                    <dt className="col-5 text-gray-500 fw-bolder">{Intl.formatMessage({id: 'myWalletText15'})}</dt>
                                    <dd className="col-7">{bundle?.availableQuantity}</dd>
                                </dl>
                                <dl className="row mb-0">
                                    <dt className="col-5 text-gray-500 fw-bolder">{Intl.formatMessage({id: 'myWalletText16'})}</dt>
                                    <dd className="col-7">{bundle.expiry}</dd>
                                </dl>
                                <dl className="row mb-0">
                                    <dd className="col-12">
                                        <button className='btn btn-primary btn-sm' disabled={bundle.viewOnly} onClick={()=>{handleUseBundle(bundle)}}>
                                            {Intl.formatMessage({id: 'myWalletText10'})}
                                        </button>
                                    </dd>
                                </dl>
                            </td>
                        </tr>
                    </React.Fragment>
                })}
            </tbody>
        </table>
    };

    return <div className="card ">
        <div className="card-header p-3" style={{display : "unset"}}>
            <div className="row justify-content-around">
                <div className="col-lg-3 col-12">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label fw-bold text-gray-800">{Intl.formatMessage({id:"myWallet.text3"})}</span>
                        <span className="text-gray-900 mt-1 fw-semibold fs-6">{Intl.formatMessage({id:"myWallet.text4"})} </span>
                    </h3>
                </div>
                <div className="col-lg-3 col-4">
                    <div data-id="my-wallet-bundles-category-filter"><Select onChange={(e) => filterByCategory(e)} placeholder={Intl.formatMessage({id : "categories"})} className="mt-1 fw-bold" options={categoriesFilter}/></div>
                </div>
                <div className="col-lg-2 col-4">
                    <div data-id="my-wallet-bundles-bundle-filter"><Select onChange={(e) => filterByBundle(e)} placeholder={Intl.formatMessage({id : "myWallet.text5"})} className="mt-1 fw-bold" options={bundlesFilter}/></div>
                </div>
                <div className="col-lg-3 col-4">
                    <div className="position-relative my-1">
                        <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor"></rect>
                                <path d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z" fill="currentColor"></path>
                            </svg>
                        </span>
                        <input type="text" onKeyUp={(e) => setSearch(e)} className="form-control w-100 fs-7 ps-12"  placeholder={Intl.formatMessage({id : 'searchLabel'})} data-id="my-wallet-bundles-search-box"/>
                    </div>
                </div>
            </div>
        </div>

        <div className="card-body" >
            {servicesLoader && <MyWalletTableLoader/>}
            {!servicesLoader && <div id="kt_table_widget_4_table_wrapper" className="dataTables_wrapper dt-bootstrap4 no-footer h-100">
                <div className="table-responsive ">
                    {services && <ServicesList searchData={searchData}/>}
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                    <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"></div>
                </div>
            </div>}
        </div>
        <div className="card-footer" ref={refTransactions}></div>
    </div>
}

export const textFormatter = (text: string) => {
    if (!text) return null;
    const newText = text?.split("*").map((part, idx) =>
      idx % 2 === 0 ? (
        <Fragment key={idx}>{part}</Fragment>
      ) : (
        <strong key={idx}>
          {part}
        </strong>
      )
    );
    return newText;
  };

export default Bundles