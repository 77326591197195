import { isDeviceMobile } from '../../../../../helpers/commonFunctions'
import {ScrollableOverDueListDataType, TableRecordType} from '../types/ScrollableOverdueListType'
import TableHeader from './TableHeader'
import TableRecord from './TableRecord'

const ScrollableOverDueList = ({
  data,
  contFunction,
}: {
  data: ScrollableOverDueListDataType
  contFunction: (payload: any) => void
}) => {
  const tableHeaders = data.tableHeaders;
  const tableRecords = data.tableRecords
  return (
    <>
      <TableHeader data={tableHeaders} contFunction={contFunction} />
      <div id='body' className='w-100' style={{overflowY: 'scroll', height: isDeviceMobile() ? '' :"300px"}}>
        {tableRecords.map((tableReord: TableRecordType, index: number)=>{
            return <TableRecord data={tableReord.data} contFunction={contFunction} key={index + '_record'}/>;
        })}
      </div>
    </>
  )
}

export default ScrollableOverDueList
