import {
  CityItemI,
  DynamicFilterI,
  RangeFieldType,
  SearchFields,
  SelectedFieldsType,
  ValueType,
} from '../../../interfaces/MyListings'
import ListingCp from './filters/ListingCp'
import MultiSelect from './filters/MultiSelect'
import SearchField from './filters/SearchField'
import ListingPill from './filters/ListingPill'
import CategorySelector from './filters/CategoryFilter'
import SingleSelect from './filters/SingleSelect'
import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import RangeTextFilter from './filters/RangeTextFilter'
import RangeDateFilter from './filters/RangeDateFilter'
import dynamicFieldsApi from '../../../../api/newMyListings/dynamicFields.api'
import DynamicFiltersMapper from './DynamicFiltersMapper'
import Skeleton from 'react-loading-skeleton'
import {useIntl} from 'react-intl'
import {isDeviceMobile} from '../../../helpers/commonFunctions'
import ListingSearchFilterMobile from './mobileFilters/ListingsSearchFilterMobile'
import ListingFiltersMobile from './ListingFiltersMobile'
import {DynamicFilterMobileI, FilterFieldMobileI} from '../../../interfaces/MyListingsMobile'
import { logMyListingsFilter } from '../../../../logging/helpers/commonLoggingFunctions'
import { EnumUxTypes } from '../../../../logging/types/LoggingTypes'

const ListingFilters = ({
  urlSearchSetter,
  statusCount,
  handlePageChange,
  landingDataFilters,
}: {
  urlSearchSetter: (url: string) => void
  statusCount: Map<string, number>
  handlePageChange: (page: number) => void
  landingDataFilters: SearchFields | FilterFieldMobileI | null
}) => {
  const [selectedFields, setSelectedFields] = useState<SelectedFieldsType>({})
  const [city, setCity] = useState<CityItemI>(undefined)
  const [isPillAllEnabled, setIsPillAllEnabled] = useState(false)
  const [dynamicFields, setDynamicFields] = useState<DynamicFilterI[] | DynamicFilterMobileI[]>([])
  const [isDynamicFieldsLoading, setIsDynamicFieldsLoading] = useState<boolean>(false)
  const [catReportingName, setCatReportingName] = useState<string>('')
  const [subCatReportingName, setSubCatReportingName] = useState<string>('')
  const [isNhoodDisabled, setIsNhoodDisabled] = useState<boolean>(false)
  const [reset, setReset] = useState<boolean>(false)
  const isFirstRender = useRef(true)
  const Intl = useIntl()

  // logic to handle enable and disable of reset button
  // const pillsFieldsIds = (landingDataFilters as SearchFields)?.listingsPills?.map(
  //   (pill) => pill.fieldId
  // ) || []
  // const selectedPills = {}
  // Object.keys(selectedFields).forEach((key) => {
  //   if (pillsFieldsIds?.includes(key)) {
  //     selectedPills[key] = selectedFields[key]
  //   }
  // })

  // const enableReset = Object.keys(selectedFields).length - Object.keys(selectedPills).length > 2

  const enableReset = useMemo(() => {
    const pillsFieldsIds =
      (landingDataFilters as SearchFields)?.listingsPills?.map((pill) => pill.fieldId) || []
    const selectedPills = {}
    Object.keys(selectedFields).forEach((key) => {
      if (pillsFieldsIds?.includes(key)) {
        selectedPills[key] = selectedFields[key]
      }
    })

    return Object.keys(selectedFields).length - Object.keys(selectedPills).length > 2
  }, [selectedFields, landingDataFilters])

  // end of logic

  const fetchDynamicFields = (subCategoryReportingName: string) => {
    setIsDynamicFieldsLoading(true)
    dynamicFieldsApi(subCategoryReportingName)
      .then((res) => {
        setDynamicFields(res?.data ?? [])
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsDynamicFieldsLoading(false)
      })
  }

  const enableNeighborhood = (city: CityItemI | undefined) => {
    setCity(city)
  }

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
      return
    }
    const searchParams = new URLSearchParams()
    handlePageChange(1)
    Object.entries(selectedFields).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((v: ValueType, index: number) => {
          searchParams.append(`${key}[${index}]`, v.value.toString())
        })
      } else if (typeof value === 'object' && value !== null) {
        if ('from' in value || 'to' in value) {
          const rangeField = value as RangeFieldType
          if (rangeField.from) {
            searchParams.append(`${key}[from]`, rangeField.from.toString())
          }
          if (rangeField.to) {
            searchParams.append(`${key}[to]`, rangeField.to.toString())
          }
          if ((rangeField.to || rangeField.from) && rangeField.unit) {
            searchParams.append(`${key}[unit]`, rangeField.unit.toString())
          }
        } else if ('value' in value) {
          searchParams.append(key, (value as ValueType).value.toString())
        }
      }
    })

    const newUrl = `${window.location.pathname}?${searchParams.toString()}`
    window.history.pushState(null, '', newUrl)
    urlSearchSetter(searchParams.toString())
  }, [selectedFields])

  const resetDynamicFields = () => {
    if (dynamicFields && !dynamicFields.length) return
    setSelectedFields((old) => {
      dynamicFields.forEach((field) => {
        delete old[field.fieldId]
      })
      return {...old}
    })
    setDynamicFields([])
  }

  const addField = (
    fieldId: string,
    option: {
      value: number | string
      label?: string
      keyName?: string
      parentid?: string
    },
    type: 'MULTI' | 'SINGLE' | 'RANGE',
    hasLogging: boolean = true,
    isDefaultValue: boolean = false
  ) => {
    
    if (hasLogging && !isDefaultValue) {
      logMyListingsFilter("filters", EnumUxTypes.INPUT)
    }

    option.parentid = option.parentid ? option.parentid : '0'
    setSelectedFields((old) => {
      const newFields = {...old}
      if (newFields[fieldId]) {
        switch (type) {
          case 'MULTI':
            if (Array.isArray(newFields[fieldId])) {
              (newFields[fieldId] as ValueType[]).push({
                value: option.value,
                parentid: option.parentid,
                label: option.label,
              })
            } else {
              newFields[fieldId] = [
                {
                  value: option.value,
                  parentid: option.parentid,
                  label: option.label,
                },
              ]
            }
            break
          case 'SINGLE':
            newFields[fieldId] = {
              value: option.value,
              parentid: option.parentid,
            }
            break
          case 'RANGE':
            newFields[fieldId] = {
              from: '',
              to: '',
              ...(newFields[fieldId] as RangeFieldType),
              [option.keyName!]: option.value,
            }
            break
        }
      } else {
        switch (type) {
          case 'MULTI':
            newFields[fieldId] = [
              {
                value: option.value,
                parentid: option.parentid,
                label: option.label,
              },
            ]
            break
          case 'SINGLE':
            newFields[fieldId] = {
              value: option.value,
              parentid: option.parentid,
            }
            break
          case 'RANGE':
            // Ensure to provide `to` and `from` properties for `RangeFieldType`
            newFields[fieldId] = {
              to: '',
              from: '',
              [option.keyName!]: option.value,
            } as RangeFieldType
            break
        }
      }
      return newFields
    })
  }

  const removeField = (
    fieldId: string | number,
    option: {value: string; keyName?: string},
    type: 'MULTI' | 'SINGLE' | 'RANGE'
  ) => {
    setSelectedFields((old) => {
      const newFields = {...old}

      // Helper function to recursively remove child fields
      const dfsRemoveChildren = (parentId: string) => {
        Object.keys(newFields).forEach((key) => {
          if (newFields[key] && Array.isArray(newFields[key])) {
            newFields[key] = (newFields[key] as ValueType[]).filter((item) => {
              if (item.parentid && item.parentid === parentId) {
                dfsRemoveChildren(key)
                return false
              }
              return true
            })
            if (newFields[key].length === 0) delete newFields[key]
          } else if (newFields[key] && newFields[key].parentid === parentId) {
            dfsRemoveChildren(key)
            delete newFields[key]
          }
        })
      }

      if (newFields[fieldId]) {
        switch (type) {
          case 'MULTI':
            if (Array.isArray(newFields[fieldId])) {
              newFields[fieldId] = (newFields[fieldId] as ValueType[]).filter(
                (val) => val.value !== option.value
              )
              if ((newFields[fieldId] as ValueType[]).length === 0) {
                dfsRemoveChildren(fieldId.toString())
                delete newFields[fieldId]
              }
            }
            break
          case 'SINGLE':
            dfsRemoveChildren(fieldId.toString())
            delete newFields[fieldId]
            break
          case 'RANGE':
            if (option.keyName && newFields[fieldId] && typeof newFields[fieldId] === 'object') {
              const rangeField = newFields[fieldId] as RangeFieldType
              delete rangeField[option.keyName]
              if (Object.keys(rangeField).length === 0) {
                dfsRemoveChildren(fieldId.toString())
                delete newFields[fieldId]
              }
            }
            break
          default:
            break
        }
      }
      return newFields
    })
  }

  const staticFieldsSkeleton = useCallback(() => {
    const skeletons: JSX.Element[] = []
    for (let index = 0; index < 9; index++) {
      const element = <Skeleton key={index} width={250} height={45} />
      skeletons.push(element)
    }
    return skeletons
  }, [])

  const dynamicFieldsSkeleton = useCallback(() => {
    const skeletons: JSX.Element[] = []
    for (let index = 0; index < 12; index++) {
      const element = <Skeleton key={index} width={250} height={45} />
      skeletons.push(element)
    }
    return skeletons
  }, [])

  const subCategoryReportingNameHandler = (name: string) => {
    setSubCatReportingName(name)
  }

  const catReportingNameHandler = (name: string) => {
    setCatReportingName(name)
  }

  const handleSetIsNhoodDisabled = (value: boolean) => {
    setIsNhoodDisabled(value)
  }

  const togglePillAllHandler = () => {
    setIsPillAllEnabled((old) => !old)
  }

  const resetAllFilters = () => {
    setReset(true)

    const pillsFieldsIds =
      (landingDataFilters as SearchFields)?.listingsPills?.map((pill) => pill.fieldId) || []

    const selectedPills = {}
    Object.keys(selectedFields).forEach((key) => {
      if (pillsFieldsIds.includes(key)) {
        selectedPills[key] = selectedFields[key]
      }
    })
    setSelectedFields({...selectedPills})
    setDynamicFields([])
    // setIsPillAllEnabled(false)
    setSubCatReportingName('')
    setCatReportingName('')
    setTimeout(() => {
      setReset(false)
    }, 50)
  }

  useEffect(() => {
    if (isDeviceMobile()) return
    let pillsSelected = 0
    ;(landingDataFilters as SearchFields)?.listingsPills?.forEach((pill) => {
      if (selectedFields[pill.fieldId]) pillsSelected += 1
    })
    if (pillsSelected === (landingDataFilters as SearchFields)?.listingsPills?.length)
      setIsPillAllEnabled(true)
  }, [landingDataFilters, selectedFields])

  const DesktopFilters = () => {
    landingDataFilters = landingDataFilters as SearchFields
    return (
      <div className='card col-12 d-flex flex-column m-0'>
        <div className='w-100 desktop-filter-container p-5'>
          {!landingDataFilters && staticFieldsSkeleton()}
          {landingDataFilters?.searchFields?.categories && (
            <CategorySelector
              categoryField={landingDataFilters?.searchFields?.categories}
              addField={addField}
              removeField={removeField}
              fetchDynamicFields={fetchDynamicFields}
              resetDynamicFields={resetDynamicFields}
              subCategoryReportingNameHandler={subCategoryReportingNameHandler}
              catReportingNameHandler={catReportingNameHandler}
              handleSetIsNhoodDisabled={handleSetIsNhoodDisabled}
              selectedFields={selectedFields}
              reset={reset}
            />
          )}
          {landingDataFilters?.searchFields?.cities && (
            <SingleSelect
              addField={addField}
              fields={landingDataFilters?.searchFields?.cities}
              enableChildField={enableNeighborhood}
              removeField={removeField}
              // selectedFields={selectedFields}
              reset={reset}
            />
          )}
          {landingDataFilters?.searchFields?.cities && (
            <MultiSelect
              addField={addField}
              disabled={!city || isNhoodDisabled}
              items={city?.neighborhoods}
              label={landingDataFilters?.searchFields?.cities?.neighborhoodLabel}
              removeField={removeField}
              // selectedFields={selectedFields}
              parentidValue={city?.value}
              parentid={landingDataFilters?.searchFields?.cities?.fieldId}
            />
          )}
          {landingDataFilters?.searchFields?.date && (
            <RangeDateFilter
              rangeField={landingDataFilters?.searchFields?.date}
              addField={addField}
              removeField={removeField}
              reset={reset}
            />
          )}
          {landingDataFilters?.searchFields?.listingIdSearch && (
            <SearchField
              searchField={landingDataFilters.searchFields.listingIdSearch}
              addField={addField}
              removeField={removeField}
              reset={reset}
            />
          )}
          {landingDataFilters?.searchFields?.titleSearch && (
            <SearchField
              searchField={landingDataFilters.searchFields.titleSearch}
              addField={addField}
              removeField={removeField}
              reset={reset}
            />
          )}
          {landingDataFilters?.searchFields?.price && (
            <RangeTextFilter
              rangeTextLabel={landingDataFilters?.searchFields?.price}
              addField={addField}
              removeField={removeField}
              reset={reset}
              selectedFields={selectedFields}
            />
          )}
          {isDynamicFieldsLoading && dynamicFieldsSkeleton()}
          {dynamicFields && !isDynamicFieldsLoading && (
            <DynamicFiltersMapper
              filters={dynamicFields as DynamicFilterI[]}
              addField={addField}
              removeField={removeField}
              selectedFields={selectedFields}
            />
          )}
          {landingDataFilters?.searchFields?.sort && (
            <SingleSelect
              addField={addField}
              fields={landingDataFilters?.searchFields?.sort}
              removeField={removeField}
              catReportingName={catReportingName}
              subCatReportingName={subCatReportingName}
              reset={reset}
            />
          )}
          {landingDataFilters?.searchFields?.perPage && (
            <SingleSelect
              addField={addField}
              fields={landingDataFilters?.searchFields?.perPage}
              removeField={removeField}
              reset={reset}
            />
          )}
        </div>
        {!landingDataFilters && <Skeleton width={800} height={45} className='mx-5 p-5' />}
        <div className='d-flex justify-content-between align-items-center gap-5 p-5'>
          <div className='d-flex flex-wrap gap-5'>
            {landingDataFilters?.listingCps?.map((cp) => {
              return (
                <ListingCp
                  key={cp?.fieldId}
                  addField={addField}
                  cp={cp}
                  removeField={removeField}
                  reset={reset}
                />
              )
            })}
          </div>
          {landingDataFilters && enableReset && (
            <span
              className='mx-5 my-5 fw-bold fs-3 text-decoration-underline hoverPointer justify-self-end'
              onClick={resetAllFilters}
            >
              {Intl.formatMessage({id: 'mylistings.filters.reset'})}
            </span>
          )}

          {landingDataFilters && !enableReset && (
            <span className='mx-5 my-5 fw-bold fs-3 text-decoration-underline hoverNotAllowed text-muted justify-self-end'>
              {Intl.formatMessage({id: 'mylistings.filters.reset'})}
            </span>
          )}
        </div>
        {!landingDataFilters && <Skeleton width={1000} height={45} className='mx-5 p-5' />}
        <div className='d-flex flex-row flex-wrap gap-5 p-5'>
          {landingDataFilters?.listingsPills && (
            <ListingPill
              count={statusCount['all']}
              addField={addField}
              removeField={removeField}
              isAllEnabled={isPillAllEnabled}
              pill={{
                fieldId: 'status_all',
                label: Intl.formatMessage({id: 'mylistings.pills.all'}),
                value: 0,
              }}
              togglePillAllHandler={togglePillAllHandler}
              reset={reset}
            />
          )}
          {landingDataFilters?.listingsPills?.map((pill) => {
            return (
              <ListingPill
                count={statusCount[pill?.fieldId]}
                key={pill?.fieldId}
                addField={addField}
                pill={pill}
                removeField={removeField}
                isAllEnabled={isPillAllEnabled}
                reset={reset}
                togglePillAllHandler={togglePillAllHandler}
              />
            )
          })}
        </div>
      </div>
    )
  }

  const MobileFilters = () => {
    if (!landingDataFilters) {
      return <></>
    }
    landingDataFilters = landingDataFilters as FilterFieldMobileI
    return (
      <>
        {landingDataFilters?.titleSearch && (
          <ListingSearchFilterMobile
            addField={addField}
            removeField={removeField}
            searchField={landingDataFilters?.titleSearch}
            value={selectedFields[landingDataFilters?.titleSearch?.fieldId]?.value || ''}
          />
        )}
        {landingDataFilters && (
          <ListingFiltersMobile
            filterWidget={landingDataFilters}
            dynamicFields={dynamicFields as DynamicFilterMobileI[]}
            addField={addField}
            removeField={removeField}
            resetDynamicFields={resetDynamicFields}
            fetchDynamicFields={fetchDynamicFields}
            resetAllFilters={resetAllFilters}
            selectedFields={selectedFields}
            statusCount={statusCount['all']}
            isPillAllEnabled={isPillAllEnabled}
            togglePillAllHandler={togglePillAllHandler}
            counts={statusCount}
          />
        )}
      </>
    )
  }

  return <>{isDeviceMobile() ? MobileFilters() : DesktopFilters()}</>
}

export default ListingFilters
