import {useCallback, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import deleteForeverApi from '../../../api/listing/deleteForever.api'
import repostListingApi from '../../../api/listing/repost.listing.api'
import {
  logBoostListingFreeBumpUp,
  logOpenShareListing,
  logOrderStartClick,
} from '../../../logging/helpers/commonLoggingFunctions'
import {EnumUxTypes} from '../../../logging/types/LoggingTypes'
import DynamicObject from '../../../models/dynamic-object'
import {
  ACTIVATE,
  BUMPUP,
  BUMPUP_FREE,
  COPY,
  DELETE,
  DELETE_FOR_EVER,
  EDIT,
  LISTING_STATISTICS,
  PROMOTE,
  SHARE,
  VERIFY_NAFATH,
  VIEW_CHATS,
  VIEW_ON_OPENSOOQ,
} from '../../helper/Ctas'
import {PointOfSales} from '../../helper/PointOfSales'
import {ShareLoggingScreens} from '../../helper/ShareLogging'
import shareLogging from '../../../api/shareLogging'
import {TWITTER_SHARE_URL} from '../../helper/ShareLinks'
import {showError, showSuccess} from '../../helper/SystemAlert'
import {
  editListing,
  extractQueryParams,
  generateOpenSooqRedirectUrl,
  getAddPostIntegrationUrl,
  getCookies,
  getSiteCustomContent,
  isDeviceMobile,
  redirectionInterceptor,
  setBackURLForAddPost,
  shorten,
  showChatInCountry,
} from '../../helpers/commonFunctions'
import {useLang} from '../../i18n/OpenSooqi18n'
import {IframeModal} from '../../layout/components/modals/IframeModal'
import {CustomModal} from '../../modales/CustomModal'
import {CheckCtasIcon} from '../CheckCtasIcon'
import {ShareListings} from '../common/ShareListing'
import {WantMoreViews} from '../common/WantMoreViews'
import {MemberShipModal} from '../payment/MemberShipModal'
import {TrLoader} from './TrLoader'
import {
  ChatFactoryTypes,
  JOB_POSTER_TYPE,
  JOB_SEEKER_TYPE,
  ListingWidgetTypes,
  getWidgetByType,
} from './my-listings/helper'
import { trackEvent } from '../../../gtm/trackEvent'

type Props = {
  listing?: DynamicObject
  refresh: () => any
  count?: number
}

export function ManageListing({listing, refresh, count}: Props) {
    const Intl = useIntl()
  const [listingIntegrationActionType, setListingIntegrationActionType] = useState('')
  const [showEditListingModal, setShowEditListingModal] = useState(false)
  const [showCustomModal, setShowCustomModal] = useState<boolean>(false)
  const [pickedListing, setPickedListing] = useState<DynamicObject>()
  const [showBundle, setShowBundle] = useState(false)
  const [type, setType] = useState<string[]>([])
  const [pos, setPos] = useState('')
  const [cta, setCta] = useState<string>('')

  const navigate = useNavigate()
  const lang = useLang()
  let statusType = null
  if (listing) {
    statusType = getWidgetByType(listing.widgets, ListingWidgetTypes.STATUS)
  }

  const redirectToDeletePost = (listing_id, type: string) => {
    setBackURLForAddPost()
    window.location.href = getAddPostIntegrationUrl(
      getCookies().NEXT_LOCALE,
      type,
      listing_id ?? 0,
      0,
      false
    )
  }

  const redirectToNafathFlow = (listing_id, type: string) => {
    setBackURLForAddPost()
    window.location.href = getAddPostIntegrationUrl(
      getCookies().NEXT_LOCALE,
      type,
      listing_id ?? 0,
      0,
      false
    )
  }

  const deleteListing = (listingId: number, showConfirmation: boolean) => {
    if (showConfirmation) {
      Swal.fire({
        icon: 'warning',
        html: `<div class="pe-10 ps-10 fw-bolder">${Intl.formatMessage({id: 'deleteListingPopup'})}</div>`,
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: Intl.formatMessage({id: 'no'}),
        showLoaderOnConfirm: true,
        confirmButtonText: Intl.formatMessage({id: 'yes'}),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-light-danger',
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((result) => {
        if (result.isConfirmed) {
          redirectToDeletePost(listingId, 'delete')
        }
      })
    } else {
      redirectToDeletePost(listingId, 'delete')
    }
  }
  const takeAction = (cta: string) => {
    setPickedListing(listing)
    let link =
      generateOpenSooqRedirectUrl(
        process.env.REACT_APP_OPENSOOQ_BASE ?? '',
        getCookies().ecountry ?? '',
        lang
      ) +
      '/search/' +
      listing?.listing_id
    if (listing?.listing_type === JOB_POSTER_TYPE || listing?.listing_type === JOB_SEEKER_TYPE) {
      link =
        generateOpenSooqRedirectUrl(
          process.env.REACT_APP_OPENSOOQ_BASE ?? '',
          getCookies().ecountry ?? '',
          lang
        ) +
        '/job-posters/' +
        listing?.listing_id
    }
    switch (cta) {
      case PROMOTE:
        showBundleModalHandler()
        setType(['Turbo', 'Premium', 'Bumpup'])
        setPos(PointOfSales.MY_LISTING_VIEW_POS_MANAGE_LISTING_PROMOTE)
        setCta('premium')
        break
      case BUMPUP:
        setPos(PointOfSales.MY_LISTING_VIEW_POS_MANAGE_LISTING_REPOST)
        setShowBundle(true)
        showBundleModalHandler()
        setType(['Bumpup'])
        setCta('bumpup')
        break
      case BUMPUP_FREE:
        logBoostListingFreeBumpUp(
          {
            id: listing.listing_id,
            service:
              listing.service_name && listing.service_name.length > 0
                ? listing.service_name[listing.service_name.length - 1]?.icon
                : '',
            category: {label: listing.category_reporting_name},
            city: {name: listing.city_label_en},
            country: listing.country_abbreviation,
            neighborhoodName: listing.neighborhood_name_en ?? '',
            subCategory: listing.subcategory_reporting_name,
          },
          EnumUxTypes.LIST_CELL,
          'export_listing_options'
        )
        repost(listing?.listing_id ?? 0)
        break
      case VIEW_ON_OPENSOOQ:
        redirectionInterceptor()
        window.open(link, '_blank')
        break
      case VIEW_CHATS:
        viewChat(listing?.listing_id ?? 0)
        break
      case LISTING_STATISTICS:
        navigate(`/listings/my-listings/listing-stats?id=${listing?.listing_id}`)
        break
      case EDIT:
        if (listing && listing.listing_id) {
          editListing(listing, 'edit')
        } else {
          showError(Intl, lang)
        }
        break
      case DELETE:
        let isPromoted = listing?.vas_icons?.length > 0 || false
        trackEvent("InitDeletePost","Sellers","TopDeletPostBtn_MyAds_MyAdsScreen")
        deleteListing(listing.listing_id, isPromoted)
        break
      case DELETE_FOR_EVER:
        deleteForeverHandler(listing?.listing_id ?? 0)
        break
      case VERIFY_NAFATH:
        redirectToNafathFlow(listing?.listing_id, "nafath")
        break
      case SHARE:
        //logging
        if (listing) {
          logOpenShareListing({
            id: listing.listing_id,
            service:
              listing.service_name && listing.service_name.length > 0
                ? listing.service_name[listing.service_name.length - 1]?.icon
                : '',
            category: {label: listing.category_reporting_name},
            city: {name: listing.city_label_en},
            country: listing.country_abbreviation,
            neighborhoodName: listing.neighborhood_name_en ?? '',
            subCategory: listing.subcategory_reporting_name,
          })
        }
        //
        if (isDeviceMobile()) {
          handleSharing(
            link +
              'utm_source=opensooq&utm_medium=share&utm_campaign=account_manage_cv_standard_from_mobile_myopensooq'
          )
        } else {
          setShowCustomModal(true)
        }
        break
      case COPY:
        if (listing && listing.listing_id) {
          editListing(listing, 'republish')
        } else {
          showError(Intl, lang)
        }
        break
      case ACTIVATE:
        setListingIntegrationActionType('activate')
        setBackURLForAddPost()
        window.location.href = `${process.env.REACT_APP_ADD_POST_REDIRECT_URL}?v=2&source=${
          isDeviceMobile() ? 'mobile' : 'desktop'
        }&lang=${lang}&flow=activate&post_id=${listing?.listing_id}&from=my-opensooq&return=${
          window.location.href
        }&${extractQueryParams()}`
        break
      default:
        break
    }
  }

  const deleteForeverHandler = (listingId: number) => {
    const text = Intl.formatMessage({id: 'deleteForever'})
    Swal.fire({
      icon: 'warning',
      html: `<div class="pe-10 ps-10 fw-bolder">${text}</div>`,
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText: Intl.formatMessage({id: 'no'}),
      showLoaderOnConfirm: true,
      confirmButtonText: Intl.formatMessage({id: 'yes'}),
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-light-danger',
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        deleteForeverApi(listingId)
          .then((response) => {
            showSuccess(Intl, lang)
            setTimeout(() => {
              window.location.reload()
            }, 2000)
          })
          .catch((error) => {
            showError(Intl, lang)
          })
      }
    })
  }

  const viewChat = (listingId: number) => {
    navigate('/chats', {
      state: {
        type: ChatFactoryTypes.LISTING_CHATS,
        data: {
          listing_id: listingId,
        },
      },
    })
  }

  const repost = (listingId: number) => {
    repostListingApi(listingId)
      .then((response) => {
        showSuccess(Intl, lang)
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      })
      .catch(() => {
        showError(Intl, lang)
      })
  }

  const handleSharing = (link: string) => {
    if (navigator.share) {
      shareLogging({
        Logging: {
          model: 'Post',
          model_id: listing?.listing_id,
          share_to: 'social',
          data: ShareLoggingScreens.MY_Listing_Manage_Listing_Native,
        },
      })
      const data = {links: {link}}
      shorten(data)
        .then((response) => {
          if (response.data && response.data.links && response.data.links.link) {
            const downloadApp = TWITTER_SHARE_URL
            const text = `${Intl.formatMessage({id: 'checkMyListing'})} \n ${
              response.data.links.link
            } \n\n ${Intl.formatMessage({id: 'downloadApp'})} ${downloadApp}`
            navigator
              .share({
                title: listing?.title,
                text: text,
                url: response.data.links.link,
              })
              .then(() => {})
              .catch((error) => {
                console.error('Something went wrong', error)
              })
          } else {
            showError(Intl, lang)
          }
        })
        .catch((err) => {
          showError(Intl, lang)
        })
    }
  }

  const handleClose = () => {
    setShowEditListingModal(false)
    refresh()
  }

  const getLabel = (cta: DynamicObject) => {
    let label = cta.label.replace('{{unread_messages}}', ` (${count}) `)
    if (cta.cta === VIEW_ON_OPENSOOQ) {
      label = getSiteCustomContent(Intl).viewOnSite
    }
    return label
  }

  const HtmlComponent = ({cta}: {cta: DynamicObject}) => {
    return (
      <tr
        key={cta.cta}
        className='hoverPointer grayHoverBg'
        onClick={(e) => {
          takeAction(cta.cta)
        }}
      >
        <td className='label'>
          <div className='d-flex align-items-center'>
            <span className='svg-icon svg-icon-2 me-2'>
              <CheckCtasIcon cta={cta.cta} />
            </span>
            <span className='ltrDir'>{getLabel(cta)}</span>
          </div>
        </td>
      </tr>
    )
  }

  const showBundleModalHandler = useCallback(() => {
    //logging
    logOrderStartClick()
    //
    setShowBundle(true)
  }, [])

  return (
    <>
      <div className='card card-flush py-4 '>
        {listing && (
          <div className='card-header'>
            <div className='card-title align-items-start flex-column'>
              <h2>{Intl.formatMessage({id: 'myListingView.text57'})}</h2>
              {statusType && (
                <span className={`customPostTitleText fw-bolder mt-2 fs-4`}>
                  <span
                    className={`${
                      statusType && statusType.color === 'GREEN' ? 'text-success' : 'text-danger'
                    }`}
                  >
                    {statusType.text}
                  </span>
                </span>
              )}
            </div>
            <div className='card-toolbar'>
              {listing && (
                <div className=' customPostTitleText fw-bolder align-items-start flex-column'>
                  <span className='card-label fw-bold text-gray-800'>
                    {Intl.formatMessage({id: 'myListingView.text1'})} : {listing.listing_id}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
        <div className='card-body'>
          <div className='table-responsive'>
            <table className='table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-250px'>
              <tbody className='fw-semibold '>
                {!listing && <TrLoader />}
                {!listing && <TrLoader />}
                {!listing && <TrLoader />}
                {!listing && <TrLoader />}
                {!listing && <TrLoader />}
                {!listing && <TrLoader />}
                {!listing && <TrLoader />}
                {!listing && <TrLoader />}
                {!listing && <TrLoader />}
                {listing &&
                  listing.ctas.map((cta: DynamicObject, index: number) => {
                    return cta.cta === VIEW_CHATS && showChatInCountry() ? (
                      <HtmlComponent key={index} cta={cta} />
                    ) : cta.cta !== VIEW_CHATS ? (
                      <HtmlComponent key={index} cta={cta} />
                    ) : (
                      ''
                    )
                  })}
              </tbody>
            </table>
          </div>
        </div>
        {listing &&
          listing.ctas.find((cta: DynamicObject) => {
            return cta.cta === PROMOTE
          }) && (
            <div className='card-footer py-6 px-9'>
              <WantMoreViews
                showPromote={
                  listing &&
                  listing.ctas.find((cta: DynamicObject) => {
                    return cta.cta === PROMOTE
                  })
                }
                listingId={listing.listing_id}
                pointOfSale={PointOfSales.MY_LISTING_VIEW_MANAGE_LISTING_WANT_MORE_VIEWS}
              />
            </div>
          )}
      </div>
      <MemberShipModal
        show={showBundle}
        type={type}
        handleClose={() => {
          setShowBundle(false)
        }}
        id={listing?.listing_id}
        pos={pos}
        cta={cta}
      />
      <IframeModal
        show={showEditListingModal}
        id={listing?.listing_id}
        type={listingIntegrationActionType}
        handleClose={() => {
          handleClose()
        }}
      />
      {pickedListing && (
        <CustomModal
          show={showCustomModal}
          handleClose={() => {
            setShowCustomModal(false)
          }}
          children={
            <>
              <ShareListings
                dataComeFrom={ShareLoggingScreens.MY_Listing_Manage_Listing}
                opened={showCustomModal}
                comeFrom={'myListingView'}
                listingUrl=''
                listing={pickedListing}
              />
            </>
          }
        />
      )}
    </>
  )
}
