import React from 'react'
import {Widget} from './types/ResType'
import FinalizeButton from './components/FinalizeButton'
import InfoHeader from './components/InfoHeader'
import Header from './components/Header'
import ScrollableOverDueList from './components/ScrollableOverDueList'

type MapperProps = {
  widget: Widget
  functions: {
    handleClose: () => void
    contFunction: (payload: any) => void,
  },
  isLoading: boolean
}

const AmountDueMapper = ({widget, functions, isLoading}: MapperProps) => {
  const {data, type} = widget
  const {handleClose, contFunction} = functions
  const selectedComponent = (): JSX.Element => {
    switch (type) {
      case 'header':
        return <Header data={data} handleClose={handleClose} key={'header'}/>
      case 'infoHeader':
        return <InfoHeader data={data} key={'infoHeader'}/>
      case 'scrollableOverdueList':
        return <ScrollableOverDueList data={data} contFunction={contFunction} key={'scrollableOverdueList'} />
      case 'finalizeButton':
        return <FinalizeButton data={data} contFunction={contFunction} key={'finalizeButton'} isLoading={isLoading}/>
      default:
        return <></>
    }
  }
  return selectedComponent()
}

export default AmountDueMapper
