import {Link} from 'react-router-dom'
import {useLayout} from '../../core'
import { isShobiddak } from '../../../helpers/commonFunctions'
import OpenSooqIcons from '../../../modules/common/OpenSooqIcons'

const SidebarLogo = () => {
  return (
    <div className='app-sidebar-logo px-6 justifyContentUnset border-bottom-0' id='kt_app_sidebar_logo ' >
      <Link to='/'>
          <>
            <OpenSooqIcons icon='logo' className={`app-sidebar-logo-default theme-light-show h-100 ${(isShobiddak()) ? 'w-100px' : 'w-150px'}`}/>
          </>
          <OpenSooqIcons  icon='logo' className='h-25px app-sidebar-logo-minimize'/>
      </Link>
    </div>
  )
}

export {SidebarLogo}
