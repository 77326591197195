import React, {useMemo, useState, useEffect} from 'react'
import {useIntl} from 'react-intl'
import Skeleton from 'react-loading-skeleton'
import {
  AddFieldType,
  RemoveFieldType,
  SelectedFieldsType,
} from '../../../../../interfaces/MyListings'
import {
  CityFieldItemMobileI,
  LocationFieldMobileI,
  NeighborhoodItemMobileI,
} from '../../../../../interfaces/MyListingsMobile'
import {useLang} from '../../../../../i18n/OpenSooqi18n'

type NeighborhoodFilterProps = {
  addField: AddFieldType
  removeField: RemoveFieldType
  selectedFields: SelectedFieldsType
  selectedCity: CityFieldItemMobileI | null
  disabled: boolean
  location: LocationFieldMobileI
}

const NeighborhoodFilter = ({
  addField,
  removeField,
  selectedFields,
  selectedCity,
  location,
  disabled,
}: NeighborhoodFilterProps) => {
  const [neighborhoods, setNeighborhoods] = useState<NeighborhoodItemMobileI[]>([])
  const [isNeighborhoodModalOpen, setIsNeighborhoodModalOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')

  const isNhoodFieldDisabled = disabled || !selectedCity

  useEffect(() => {
    if (selectedCity) {
      setNeighborhoods(selectedCity.neighborhoods.items)
      setIsLoading(false)
    } else {
      setNeighborhoods([])
      setIsLoading(true)
    }
  }, [selectedCity])

  useEffect(() => {
    if (disabled) {
      // clear selected neighborhoods
      for (
        let i = 0;
        i < selectedFields[location?.citiesWithNHoods?.items[0]?.neighborhoods?.fieldId]?.length;
        i++
      ) {
        removeField(
          location?.citiesWithNHoods?.items[0]?.neighborhoods?.fieldId,
          selectedFields[location?.citiesWithNHoods?.items[0]?.neighborhoods?.fieldId][i],
          'MULTI'
        )
      }
    }
  }, [disabled])

  const handleNeighborhoodSelect = (neighborhood: NeighborhoodItemMobileI) => {
    const nhoodFieldId = selectedCity?.neighborhoods?.fieldId
    const isSelected = selectedFields[nhoodFieldId]?.find(
      (selected: any) => selected.value === neighborhood.value
    )
    if (isSelected) {
      removeField(nhoodFieldId, {value: neighborhood.value}, 'MULTI')
    } else {
      addField(
        nhoodFieldId,
        {
          value: neighborhood.value,
          label: neighborhood.label,
          parentid: location?.citiesWithNHoods?.fieldId,
        },
        'MULTI'
      )
    }
  }

  const filteredNeighborhoods = useMemo(() => {
    return neighborhoods.filter((option) =>
      option.autoComplete.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }, [neighborhoods, searchTerm])

  const intl = useIntl()
  const lang = useLang()

  const isNhoodSelected =
    selectedCity && selectedFields[selectedCity?.neighborhoods?.fieldId]?.length > 0

  return (
    <>
      <div
        className={`w-100 p-3 d-flex align-items-center justify-content-between flex-row py-5 ${
          isNhoodFieldDisabled ? 'disabled' : ''
        }`}
        onClick={() => !isNhoodFieldDisabled && setIsNeighborhoodModalOpen(true)}
      >
        <div className='w-75'>
          <div
            className={`fs-3 ${
              isNhoodFieldDisabled
                ? 'fw-normal text-muted'
                : isNhoodSelected
                ? 'text-primary fw-bold'
                : selectedCity
                ? 'text-dark fw-bold'
                : 'fw-normal text-muted'
            }`}
          >
            <img
              className={`${lang === 'ar' ? 'ms-1' : 'me-1'}`}
              width={24}
              height={24}
              src={location?.citiesWithNHoods?.icon}
            />
            {location?.citiesWithNHoods?.neighborhoodLabel}
          </div>
          <div
            className={`fs-3 mx-9 mt-2 ${isNhoodSelected ? 'text-dark' : 'text-muted'}`}
            style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}
          >
            {isNhoodSelected && !isNhoodFieldDisabled
              ? selectedFields[location?.citiesWithNHoods?.items[0]?.neighborhoods?.fieldId]
                  ?.map((neigh: any) => {
                    return neighborhoods.find((nhood) => nhood.value === neigh.value)?.label
                  })
                  .join(', ')
              : ''}
          </div>
        </div>
        <span className={`${isNhoodFieldDisabled ? 'text-muted' : 'text-dark'} text-end w-25 fs-4`}>
          {intl.formatMessage({id: 'mylistings.singleselect.mobile.select'})}
          <i
            className={`px-2 ${
              isNhoodFieldDisabled ? 'text-muted' : 'text-primary'
            } fa fa-chevron-${lang === 'ar' ? 'left' : 'right'}`}
          ></i>
        </span>
      </div>
      {isNeighborhoodModalOpen && (
        <div className='modal show d-block' style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
          <div className='modal-dialog modal-fullscreen'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title fs-1'>
                  {location?.citiesWithNHoods?.neighborhoodLabel}
                </h5>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => setIsNeighborhoodModalOpen(false)}
                ></button>
              </div>
              <div className='modal-body d-flex flex-column gap-2'>
                <input
                  type='text'
                  className='form-control p-3'
                  placeholder={intl.formatMessage({id: 'mylistings.filters.search'})}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {isLoading ? (
                  <div>
                    {Array.from({length: 10}).map((_, index) => (
                      <Skeleton key={index} className='my-5' width={330} height={50} />
                    ))}
                  </div>
                ) : (
                  filteredNeighborhoods.map((neighborhood, index) => (
                    <div
                      key={neighborhood.value + ',' + index}
                      className={`card p-5 py-7 fs-2 fw-bold mb-3 shadow-sm d-flex flex-row justify-content-between align-items-center hoverPointer ${
                        selectedFields[selectedCity?.neighborhoods?.fieldId]?.find(
                          (selected: any) => selected.value === neighborhood.value
                        )
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() => handleNeighborhoodSelect(neighborhood)}
                    >
                      <span>{neighborhood.label}</span>
                      <i
                        className={`fs-1 fa fa-regular ${
                          selectedFields[selectedCity?.neighborhoods?.fieldId]?.find(
                            (selected: any) => selected.value === neighborhood.value
                          )
                            ? 'fa-check-square text-primary'
                            : 'fa-square text-muted'
                        }`}
                      ></i>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default NeighborhoodFilter
