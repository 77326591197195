import {useState, useEffect} from 'react'
import {AddFieldType, RemoveFieldType, TextFieldI} from '../../../../interfaces/MyListings'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import useFiltersStore from '../../my-listings/FilterStore'
import { useDebounce } from '../../../../helpers'


const SearchField = ({
  searchField,
  addField,
  removeField,
  reset,
}: {
  searchField: TextFieldI
  addField: AddFieldType
  removeField: RemoveFieldType
  reset?: boolean
}) => {
  const {label, placeHolder, fieldId, type} = searchField
  const [search, setSearch] = useState<string>('')
  const lang = useLang()
  const direction = lang === 'ar' ? 'rtl' : 'ltr'
  const {globalFiltersDisable} = useFiltersStore()

  const debouncedSearch = useDebounce(search, 200) // 200ms debounce delay

  const searchHandler = (e: any) => {
    let text = e.target.value
    if (type != 'textField') {
      text = text.replace(/[^0-9]/g, '')
    }
    setSearch(text)
  }

  useEffect(() => {
    if (debouncedSearch !== '') {
      addField(fieldId, {value: debouncedSearch}, 'SINGLE')
    } else {
      removeField(fieldId, {value: debouncedSearch}, 'SINGLE')
    }
  }, [debouncedSearch, fieldId])

  useEffect(() => {
    if (reset) {
      setSearch('')
    }
  }, [reset])
  return (
    <div
      data-id={fieldId}
      className={`position-relative desktop-filter-search-field-container ${direction} ${globalFiltersDisable ? 'hoverNotAllowed' : ''}`}
      style={{textAlign: direction === 'rtl' ? 'right' : 'left'}}
    >
      <i
        className='fa-solid fa-magnifying-glass fs-2'
        style={{
          position: 'absolute',
          right: direction === 'rtl' ? '10px' : 'auto',
          left: direction === 'rtl' ? 'auto' : '10px',
          top: '50%',
          transform: 'translateY(-50%)',
        }}
      />
      <input
        data-id={`${fieldId}-input`}
        type='text'
        value={search}
        onChange={searchHandler}
        className={`form-control fs-6 ps-12 pt-4  desktop-filter-search-field ${globalFiltersDisable ? 'hoverNotAllowed text-muted' : ''}`}
        readOnly={globalFiltersDisable}
        style={{padding: '10px', direction: direction}}
        placeholder={placeHolder}
      />
    </div>
  )
}

export default SearchField
