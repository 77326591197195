import { useRef, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from "react-intl"
import Swal from 'sweetalert2'
import { KTSVG } from '../../../helpers'
import { getCookies, logout } from '../../../helpers/commonFunctions'
import { useLang } from '../../../i18n/OpenSooqi18n'
import { IframeLoader } from '../../../modules/common/IframeLoader'

type Props = {
  show: boolean
  handleClose: () => void
  draftId : string
}

const DraftModal = ({show,handleClose,draftId}: Props) => {
    const iframeContent = useRef<null | HTMLIFrameElement>(null);
    const Intl = useIntl()
    const lang = useLang()
    const [loader,setLoader] = useState <boolean>(true)
    window.addEventListener("message", event => {
        if(event.data === 401){
            logout()
        }else if(event.data == 200){
            setLoader(false);
            if(iframeContent.current){
                iframeContent.current.classList.remove('d-none')
            }
        }
    }, false);


    const confirm = () => {
        const text = Intl.formatMessage({id : "cancelAddingListConfirmation"})
        Swal.fire({
            icon: "warning",
            html:
            `<div class="pe-10 ps-10 fw-bolder">${text}</div>`,
            buttonsStyling: false,
            showCancelButton: true,
            cancelButtonText : Intl.formatMessage({id : 'no'}),
            showLoaderOnConfirm: true,
            confirmButtonText: Intl.formatMessage({id : 'yes'}),
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-light-danger"
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                handleClose()
            }
        })
    }

    return <>
            <Modal id='iframe'tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered modal-fullscreen' style={{height : "100svh"}}  show={show} onHide={confirm}  >
                <div className='modal-header p-5'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                <div className='modal-body py-lg-10 px-lg-10' style={{overflowY: "hidden"}}>
                    {loader && <IframeLoader/>}
                    <iframe src={`${process.env.REACT_APP_ADD_POST}?v=2&source=${getCookies().source}&lang=${lang}&flow=draft&draft_id=${draftId}&from=my-opensooq`} className="d-none w-100" style={{height : "100%",overflowY:"hidden"}} ref={iframeContent}></iframe>
                </div>
            </Modal>
        </>
}

export { DraftModal }
