import { PopupChat, useChatFunctions } from 'opensooq-chat';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import uuid from 'react-uuid';
import Swal from 'sweetalert2';
import addToFavoriteApi from '../../../../api/listing/addToFavorite.api';
import unFavoriteAdApi from '../../../../api/listing/unFavoriteAd.api';
import updateApplicantStatus from '../../../../api/listing/updateApplicantStatus';
import { hexToRGB } from '../../../assets/ts/_utils';
import { showError, showSuccess } from '../../../helper/SystemAlert';
import { KTSVG, toAbsoluteUrl } from '../../../helpers';
import { cvLabelAligner, getApplicantImage, getCookies, isDeviceMobile } from '../../../helpers/commonFunctions';
import { useLang } from '../../../i18n/OpenSooqi18n';
import { CustomModal } from '../../../modales/CustomModal';
import { ShareCv } from './ShareCv';
import ApplicantView from '../../../../api/NodeApis/Jobs/ApplicantView'
import {Applicant} from '../../../interfaces/JobsInterfaces/ApplicantView'
import {DEFAULT_AVATAR} from '../../../helper/ImagePaths'


const ApplicantCVFullView = () => {
  const DEFAULT_ROOM = undefined
  const lang = useLang();
  const Intl = useIntl();
  const [room, setRoom] = useState<any>(DEFAULT_ROOM)
  const {createRoom} = useChatFunctions();
  const memberInfo = getCookies().userInfo
  const [fullApplicantInfo, setFullApplicantInfo] = useState<Applicant>(undefined);
  const [imageCdn, setImageCdn] = useState<string>();
  const [showCustomModal, setShowCustomModal] = useState<boolean>(false);
  const [sectionsRenderedItems, setSectionsRenderedItems] = useState<any[]>([]);
  const [phone, setPhone] = useState<string>('');
  const {id: applicationID} =  useParams<{id: string}>()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    ApplicantView(applicationID).then((res) => {
      let response = res.data;
      let sectionsArray : any[] = [];
      for (let i = 0; i < response.applicant?.sections.length; i++) {
          const section = {module: response?.applicant?.sections[i]?.module || '', items: response?.applicant?.sections[i]?.module == "general_info" ? response?.applicant?.sections[i].items : (response?.applicant?.sections[i]?.items?.slice(0, 2) || []), label: response?.applicant?.sections[i]?.label || ''};
          if(section)sectionsArray.push(section)                
      }
      setSectionsRenderedItems(sectionsArray);
      setFullApplicantInfo(response.applicant);
      setImageCdn(response.config.images_cdn)
    })
  }

  const createRoomHandler = () => {
  const roomData = {
      postId: fullApplicantInfo.card.post_id,
      ownerId: fullApplicantInfo.card.member_id,
      userId: memberInfo.id,
      post_title: fullApplicantInfo.sub_category_reporting_name+fullApplicantInfo.role,
      room: {
          avatar: fullApplicantInfo.card.avatar,
          name: fullApplicantInfo.card.name,
      }
  }
      createRoom(roomData as any).then((room) => {
          setRoom(room)
      })
  }

  const showPhone = () => {
    setPhone(fullApplicantInfo.international_number);
      window.location.href = `tel:${fullApplicantInfo.international_number}`;
  }

  const handleShowMore = (index) => {
    let items = sectionsRenderedItems;
    if(items[index].items.length == 2){
      items[index].items = fullApplicantInfo.sections[index].items;
    }else{
      items[index].items = fullApplicantInfo.sections[index].items.slice(0,2);
    }
    setSectionsRenderedItems([...items]);
  }

  const handleDownloadCV = (CVLink: string, name: string) => {
    fetch(CVLink).then((response) => response.blob())
    .then((blobresp) => {
      let blob = new Blob([blobresp], {type: "octet/stream"});
      let url = window.URL.createObjectURL(blob);
      let link = document.createElement("a");
      let fileName = name.replace(" ", "_") +'_cv.pdf';
      link.download = fileName;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }).catch(() => {
      showError(Intl,lang)
    });
  }

  const favoriteAd = (postId : number) => {        
    addToFavoriteApi(postId,{}).then((response : any) => {
      if(response.status == 200 || response.status == 201){
        setFullApplicantInfo({...fullApplicantInfo, card: {...fullApplicantInfo.card, bookmarkStatus: true}})
        showSuccess(Intl,lang, Intl.formatMessage({id : "myListingView.AddToFavorite.Description"}), Intl.formatMessage({id : "myListingView.AddToFavorite"}));
      }
    }).catch(() => {
      showError(Intl,lang);
    });
  };

  const unFavorite = (postId : number) => {
    unFavoriteAdApi(postId,{}).then((response : any) => {
      if(response.status == 202){
        setFullApplicantInfo({...fullApplicantInfo, card: {...fullApplicantInfo.card, bookmarkStatus: false}})
        showSuccess(Intl,lang, " ", Intl.formatMessage({id : "myListingView.RemoveFromFavorite"}));
      }else{
        showError(Intl,lang);
      }
    }).catch(() => {
      showError(Intl,lang);
    });
  };

  const showModal = () => {
    const postId : number = fullApplicantInfo.card.post_id
    const bookmarked : boolean = fullApplicantInfo.card.bookmarkStatus
    const content = (!bookmarked) ? Intl.formatMessage({id : 'posts.favorite.add'}) : Intl.formatMessage({id : 'posts.favorite.remove'})
    if(!bookmarked){
      favoriteAd(postId)
      return;
    }
    Swal.fire({
      icon: "warning",
      html:
      `<div class="pe-10 ps-10 fw-bolder">${content}</div`,
      buttonsStyling: false,
      showCancelButton: true,
      cancelButtonText : Intl.formatMessage({id : 'no'}),
      confirmButtonText: Intl.formatMessage({id : 'yes'}),
      customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-light-danger"
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if(result.isConfirmed){
        unFavorite(postId)
      }
    })
  };
  const closeModalHandler = () => {
    setRoom(DEFAULT_ROOM)
  }

   // will return the info message style based on the status
  const infoMessageStyle = (color: string) => {
    let backgroundRGB = hexToRGB(color);
    return {border: `1px dashed ${color}`, backgroundColor: `rgba(${backgroundRGB.join(',')}, 0.05)`}
  }

  const handleCVStatusAction = (cta: any) => {
    if(!cta.enabled) return
  //   const content = `Are you sure you want to ${cta} this applicant?`
  //   Swal.fire({
  //     icon: "warning",
  //     html:
  //     `<div class="pe-10 ps-10 fw-bolder">${content}</div`,
  //     buttonsStyling: false,
  //     showCancelButton: true,
  //     cancelButtonText : Intl.formatMessage({id : 'no'}),
  //     confirmButtonText: Intl.formatMessage({id : 'yes'}),
  //     customClass: {
  //         confirmButton: "btn btn-primary",
  //         cancelButton: "btn btn-light-danger"
  //     },
  //     allowOutsideClick: () => !Swal.isLoading(),
  // }).then((result) => {
  //     if(result.isConfirmed){
          return updateApplicantStatus(applicationID, cta.cta).then((res) => {
            setFullApplicantInfo(prevInfo => ({
              ...prevInfo,
              applicant_status: res.data.result.data.applicant_status,
              card: {
                ...prevInfo.card,
                cv_actions: {
                  ...prevInfo.card.cv_actions,
                  ctas: [
                    ...prevInfo.card.cv_actions.ctas.slice(0, -2), // keep all items except the last two
                    ...res.data.result.data.card.cv_actions.ctas.slice(-2) // add the new last two items
                  ]
                }
              }
            }));
        //   })
        // }
    }).catch(() => {
      showError(Intl,lang);
    });
  }

  const CTAButton = (props) : JSX.Element => {
    let svgPath, btnClass, btnDisabled = props.disabled;
    if (props.selected && props.buttonType != "decline") {
        svgPath = props.iconPath
    } else {
        svgPath = props.iconPath.replace(".svg", "-black.svg")
    }

    switch (props.buttonType) {
      case "decline":
        btnClass = "btn-light";
        break;
      case "shortlist":
        if ((!props.selected && props.disabled) || (!props.selected && !props.disabled)) {
          btnClass = "btn-light";
        } else if (props.selected && props.disabled) {
          btnClass = "btn-warning";
          btnDisabled = false;
        }
        break;
      default:
        if (props.selected) {
          btnClass = "btn-primary";
        } else {
          btnClass = "btn-light";
        }
        break;
    }
    
    return (    <>
   {!isDeviceMobile() && <button disabled={btnDisabled} className={`btn ${btnClass} ${props.className} fs-8  fw-bold`} style={{width: "160px"}} onClick={props.action}>
        <KTSVG className={`${props.iconClass}`} path={svgPath}/>
      { props.text || props.label }
    </button>}
    {isDeviceMobile() && <div className='d-flex flex-column align-items-center fw-bold gap-3 mt-5'><button disabled={btnDisabled} className={`btn ${btnClass} ${props.className} w-25 h-25 px-7 rounded-circle d-flex justify-content-center`} onClick={props.action}>
        <KTSVG className={`svg-icon-1 mx-1`} path={svgPath}/>
    </button> <span>{props.label}</span> </div>}
     </>)
    }

  const ctaActionsHandler = (cta: any) => {
    switch (cta.cta) {
      case "call":
        return showPhone()
      case "chat":
        return createRoomHandler()
      case "shortlist":
        return handleCVStatusAction(cta)
      case "decline":
        return handleCVStatusAction(cta)
      default:
        break;
    }
  }
    // will return the cta buttons based on the cta type
  const ctaButtonsHandler = (cta): JSX.Element => {
    let disabled = !cta.enabled;
        switch (cta.cta) {
            case "call":
                return <>{fullApplicantInfo?.international_number ? <CTAButton buttonType={"call"} action={()=>ctaActionsHandler(cta)} label={cta.label} text={phone} iconPath='/media/svg/general/phone.svg' disabled={disabled} selected={cta.selected} iconClass='svg-icon-7'/>: <></>}</>
            case "chat":
                return <CTAButton buttonType={"chat"} action={()=>ctaActionsHandler(cta)}  label={cta.label} iconPath='/media/svg/general/chat.svg' disabled={disabled} selected={cta.selected} iconClass='svg-icon-4'/>
            case "shortlist":
                return <CTAButton buttonType={"shortlist"} action={()=>ctaActionsHandler(cta)}  label={cta.label} iconPath='/media/svg/general/star-vector.svg' disabled={disabled} selected={cta.selected} iconClass='svg-icon-5'/>
            case "decline":
                return <CTAButton buttonType={"decline"} action={()=>ctaActionsHandler(cta)}  label={cta.label} iconPath='/media/svg/general/cross-vector.svg' disabled={disabled} selected={cta.selected} iconClass='svg-icon-5'/>
            default:
                break;
        }
    }

  const CardHeader = () :  JSX.Element => {
      return( <div className='row'>
      {fullApplicantInfo.card && <div className='d-flex gap-2 col-md-7 col-sm-12'>
          <img className='w-50px h-50px radius-8' src={fullApplicantInfo.card?.avatar ? (imageCdn +'/'+ fullApplicantInfo.card.avatar).replace("{size}","1024x0") : DEFAULT_AVATAR} alt="user_avatar" />
          <div className='d-flex flex-column'>
            <div className='d-flex justify-content-between'>
              {fullApplicantInfo.card.sector && <><p className='fs-3 fw-normal mb-1'>{fullApplicantInfo.card.sector}</p>
              {fullApplicantInfo.card.services && <div>
              {fullApplicantInfo.card.services && fullApplicantInfo.card.services?.map((service, index) => {
                  return <img key={uuid()} className="bigContainer me-1" width="25" height="25" src={toAbsoluteUrl(`/media/custom/${service}-service.svg`)} alt={service[index]}/>
              })}
              </div>}
              <span className='d-block'></span>
              <span className='d-block'></span></>}
            </div>
            {fullApplicantInfo.card.name && <p className='fs-2 fw-bold mb-1'>{fullApplicantInfo.card.name}</p>}
            {fullApplicantInfo.card.location && <p className='fs-5 fw-normal mb-1 '>{fullApplicantInfo.card.location}</p>}
            {(fullApplicantInfo.card.currentWork || fullApplicantInfo.role) && <p className='fs-6 fw-light mb-2'>{fullApplicantInfo.card.currentWork ?? fullApplicantInfo.role}</p>}
            {fullApplicantInfo.card.tags && <div className='mb-9 d-flex flex-wrap'>
              <div className='d-flex flex-wrap gap-1 w-100' key={uuid()} style={{overflow: "hidden", maxHeight: `${isDeviceMobile() ? "42.33px" : "63.5px"}`, maxWidth: "100%"}}>
                  {fullApplicantInfo.card.tags.map((tag) => {
                      return (<React.Fragment key={uuid()}>
                          {tag && <span style={{height: "19px"}} className="badge bg-secondary text-black me-3 fw-normal fs-5 pt-2">{tag}</span>}
                      </React.Fragment>)
                  })}
              </div>
            </div>}
          </div>
      </div>}
      <div className={`col-md-5 col-sm-12`}>
      <div className={`d-flex flex-wrap mb-5  ${ isDeviceMobile() ? "flex-column gap-2" : "flex-row-reverse gap-3 "}`}  style={{margin: "0px 50px"}}>
          <button className='btn btn-outline-secondary border border-dark fw-bold px-3 fs-6' style={{width: `${isDeviceMobile() ? "100%": "166px"}`}} onClick={()=>setShowCustomModal(true)} ><span className="svg-icon svg-icon-2 me-2">
              <KTSVG path="/media/custom/ctas/share.svg" className="svg-icon svg-icon-2 me-2" />
          </span>{Intl.formatMessage({id: "cv.action.share.cv"})}</button>
          <button className='btn btn-outline-secondary border border-dark fw-bold px-3 fs-6' style={{width: `${isDeviceMobile() ? "100%": "166px"}`}} onClick={showModal}>
              {(fullApplicantInfo.card.bookmarkStatus) ? <KTSVG path="/media/svg/general/is-fav.svg" className="svg-icon svg-icon-2 me-2" /> :
                <KTSVG path="/media/svg/general/add-to-fav.svg" className="svg-icon svg-icon-2 me-2" />}{Intl.formatMessage({id: "cv.action.save.cv"})}</button> 
        </div>
      </div>
    </div>
  )}

  const sectionsGenerator = (section: any, idx: number) => {
    switch (section.module) {
      case "general_info":
        return (
          <React.Fragment key={section.label || uuid()}>
            {section.items && section.items[0].view_type == "mask" &&
              <div>
                <h2 className='my-6'>{section.items[0].label}</h2>
                <p className='fs-4 mb-7'>{section.items[0].values}</p>
              </div>}
            {section.label && <div><h3 className='my-6'>{section.label}</h3></div>}
            {section.items.map((item) => {
                return (
                  <div className='d-flex gap-12 mb-6' key={uuid()}>
                    {(item.view_type == "single" || item.view_type == "multi") &&   <span className='w-25 text-black-50'>{item.label}</span>}
                    {item.view_type == "single" && <span className='w-75 fw-bold'>{item.values[0]}</span>}
                    { item.view_type == "multi" && <span className='w-75 fw-bold'>{item.values.map((value) => {
                        return (<span className="badge bg-secondary text-black me-3 fw-normal fs-7 pt-2 mb-1" key={uuid()}>{value}</span>)})}</span>} 
                    {item.view_type == "view_cv" && <><span className='w-25 text-black-50'></span><button className='btn btn-primary fw-bold' onClick={()=> handleDownloadCV(item?.values[0], fullApplicantInfo.card.name)} style={{marginLeft: "-1%"}}>Download And Share <KTSVG className='svg-icon-1' path='/media/svg/general/download-box.svg'/></button></>}
                  </div>)
                })}
            </React.Fragment>)
    case "experiences":
          return (<>
              {section?.items?.length > 0 && <React.Fragment key={section.label || uuid()}>
                {section.label && <h3 className='mt-12 mb-6'>{section.label}</h3>}
                <div className='d-flex flex-column align-items-start justify-content-start'>
                    {section.items.map((item) => {
                    return(
                      <div key={uuid()} className="p-5 border radius-8 mt-6 w-100 d-flex flex-column bg-light">
                        <p>{item.date}</p>
                        <h2>{item.job_title}</h2>
                        <p>{item.company_name}</p>
                        <p>{item.job_description}</p>
                      </div>)
                    })}
                    {fullApplicantInfo.sections[idx]?.items?.length > 2 && <button className='whiteBtn p-2 w-75 block m-auto mt-5' onClick={()=> handleShowMore(idx)} >{section.items?.length == 2 ? "Show More": "Show Less"}</button>}
                </div>
              </React.Fragment>}
            </>)           
      case "courses":
          return ( <>
          {section?.items?.length > 0 && <React.Fragment key={section.label || uuid()} >
              {section.label &&  <h3 className='mt-12 mb-6'>{section.label}</h3>}
              <div className='d-flex flex-column align-items-start justify-content-start'>
                  {section?.items.map((item) => {
                  return(
                  <div key={uuid()} className="p-5 border radius-8 mt-6 w-100 d-flex flex-column bg-light">
                    <p>{item.date}</p>
                    <h2>{item.training_name}</h2>
                    <p>{item.company_name}</p>
                  </div>)
                  })}
                  {fullApplicantInfo.sections[idx]?.items?.length > 2 && <button className='whiteBtn p-2 w-75 block m-auto mt-5' onClick={()=> handleShowMore(idx)} >{section.items?.length == 2 ? "Show More": "Show Less"}</button>}
              </div>
            </React.Fragment>}
          </>)
      default:
          break;
      }
  }
  
  return ( <>
          <div className="card py-4 pt-7">
            <div className="card-header d-flex flex-column flex-wrap ">
             {fullApplicantInfo && (<CardHeader />)}
              <div className='w-100 border-top dashed h-1'></div>
             {fullApplicantInfo && <div className='d-flex flex-column mt-7' style={{margin: `${!isDeviceMobile()?"0px 50px":""}`}}>
                {fullApplicantInfo.card && fullApplicantInfo.card.cv_match && <span className="d-block badge align-self-start text-white me-3 fw-bold fs-5 pt-2 mb-2" style={{backgroundColor: fullApplicantInfo.card.cv_match.color}}>{cvLabelAligner(fullApplicantInfo.card.cv_match.label)}</span>}
                <p className='fw-bold mb-2'>{fullApplicantInfo.card.cv_actions.title}</p>
                <div className={`d-flex flex-start gap-4 flex-wrap mb-5 ${isDeviceMobile() ? "justify-content-around" : ""}`}>
                    {fullApplicantInfo.card && fullApplicantInfo.card.cv_actions && fullApplicantInfo.card.cv_actions.ctas.map((cta) => {
                        return (<React.Fragment key={uuid()}>
                            {cta.cta && ctaButtonsHandler(cta)}
                        </React.Fragment>
                        )
                    })}
                </div>
              </div>}
            </div>
            <div className="card-body mt-n2" >
              {fullApplicantInfo && <div style={{margin: `${!isDeviceMobile()?"0px 50px":""}`}}>
                {fullApplicantInfo.applicant_status && <div className={`rounded min-w-125px py-3 px-4 mb-3 ${isDeviceMobile()?'mt-4':'me-6'}`} style={infoMessageStyle(fullApplicantInfo.applicant_status.color)}><p className='d-inline'>{fullApplicantInfo.applicant_status.title}, <span className='text fw-bold d-inline'>{fullApplicantInfo.applicant_status.subTitle}</span></p></div>}
                {fullApplicantInfo.sections && fullApplicantInfo.sections.length && sectionsRenderedItems.map((section, idx) => sectionsGenerator(section, idx))}
              </div>}
            </div>
            <Modal id='iframe' tabIndex={-1} aria-hidden='true'
                   dialogClassName='modal-dialog modal-dialog-centered modal-fullscreen' show={!!room}>
                <div className='modal-header p-5'>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeModalHandler}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg'/>
                    </div>
                </div>
                <div className='modal-body popup' style={{overflowY: "hidden"}}>
                    {room && <PopupChat newChat={room} onClear={closeModalHandler}/> }
                </div>
            </Modal>
            {fullApplicantInfo && <CustomModal show={showCustomModal} handleClose={() => {setShowCustomModal(false)}} children={<ShareCv opened={showCustomModal} comeFrom={'applicants'} listingUrl={fullApplicantInfo.share_deeplink} listing={fullApplicantInfo.card}/>}/>}
          </div>
        </>
  )
}

export { ApplicantCVFullView };
