import { InfoHeaderDataType } from "../types/InfoHeaderType"

const InfoHeader = ({data} : {data: InfoHeaderDataType}) => {
  return (
    <div id='sub_header' className='w-100 mt-1'>
        <span className={`fs-4 ${data?.style?.bold && 'fw-bold'}`} style={{color: data?.textColor}}>
            {data?.text}
        </span>
    </div>
    )
}

export default InfoHeader