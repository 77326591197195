import React, { useEffect, useState, useRef } from 'react';
import { NumberFieldMobileI, TextFieldMobileI } from '../../../../interfaces/MyListingsMobile';
import { AddFieldType, RemoveFieldType } from '../../../../interfaces/MyListings';

interface SearchFilterMobileProps {
  field: TextFieldMobileI | NumberFieldMobileI;
  addField: AddFieldType;
  removeField: RemoveFieldType;
  value: string;
}

const SearchFilterMobile: React.FC<SearchFilterMobileProps> = ({ field, addField, removeField, value }) => {
  const [searchText, setSearchText] = useState(value);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

  const handleInputChange = (value: string) => {
    let text = value;

    if (field.type !== 'textField') {
      text = text.replace(/[^0-9]/g, '');
    }

    setSearchText(text);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (text.trim() !== '') {
        addField(field.fieldId, { label: field.label, value: text }, 'SINGLE');
      } else {
        removeField(field.fieldId, { value: text }, 'SINGLE');
      }
    }, 200);
  };

  useEffect(() => {
    handleInputChange(value);

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    };
  }, [value]);

  return (
    <div className='container-fluid p-0 m-0 user-select-none shadow' key={field?.fieldId}>
      <div className='card w-100 rounded'>
        <div className='card-body p-3 mx-2'>
          <h5 className='card-title my-3 fs-3 fw-bold' style={{ fontSize: '1.5rem' }}>
            {field.label}
          </h5>
          <div
            className='input-group mb-3'
            style={{
              backgroundColor: '#fff',
              borderRadius: '0.75rem',
              border: '0.5px solid rgba(0, 0, 0, 0.1)',
            }}
          >
            <span
              className='input-group-text'
              style={{ backgroundColor: 'transparent', border: 'none' }}
            >
              <i className='fa fa-search fs-3'></i>
            </span>
            <input
              type={'text'}
              className='form-control fs-3'
              placeholder={field.placeHolder}
              value={searchText}
              onChange={(e) => handleInputChange(e?.target?.value)}
              style={{ boxShadow: 'none', border: 'none' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFilterMobile;
