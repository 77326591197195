import {useChatFunctions} from "opensooq-chat"
import {useEffect, useState} from "react"
import {useIntl} from "react-intl"
import getAdStatsApi from "../../../api/getAdStats.api"
import getListingApi from "../../../api/listing/get.listing.api"
import {toAbsoluteUrl} from "../../../app/helpers"
import {ListingStatsInterface} from "../../../app/interfaces/ListingStatsInterface"
import {Chart} from "../../../app/layout/components/opensooq/Chart"
import {
    logOrderStartClick,
    logScreenLoad
} from "../../../logging/helpers/commonLoggingFunctions"
import DynamicObject from "../../../models/dynamic-object"
import {LISTING_STATISTICS, PROMOTE} from "../../helper/Ctas"
import {PointOfSales} from "../../helper/PointOfSales"
import {MyListingViewInterface} from "../../interfaces/MyListingViewInterface"
import {ListingInfo} from "../common/ListingInfo"
import {ListingStatistics} from "../common/ListingStatistics"
import {MemberShipModal} from "../payment/MemberShipModal"
import {updateScreenHistory} from "../../../logging/helpers/updateScreenHistory";
import {LoggingScreenNames} from "../../../logging/types/LoggingTypes";

const ListingStats = () => {
    const listingId: any = new URLSearchParams(window.location.search).get('id') ?? 0;
    const [favoriteCountTotal, setFavoriteCountTotal] = useState<number>(0)
    const [favorite, setFavorite] = useState<ListingStatsInterface>()
    const [viewsCountTotal, setViewsCountTotal] = useState<number>(0)
    const [listing, setListing] = useState<MyListingViewInterface>();
    const [callCountTotal, setCallCountTotal] = useState<number>(0)
    const [roomCountTotal, setCvCountTotal] = useState<number>(0)
    const [calls, setCalls] = useState<ListingStatsInterface>()
    const [views, setViews] = useState<ListingStatsInterface>()
    const [chat, setChat] = useState<ListingStatsInterface>()
    const [isLoading, setISLoading] = useState<boolean>(true)
    const [showBundle, setShowBundle] = useState<boolean>(false)
    const [statsLoader, setStatsLoader] = useState(true)
    const [count, setCount] = useState(0)
    const {getUnreadPostRooms} = useChatFunctions()
    const Intl = useIntl();
    const pathName = window.location.pathname

    useEffect(() => {
        getListingApi(listingId).then((response) => {
            setListing(response.data);
        })

        getUnreadPostRooms(listingId).then((value: number) => {
            setCount(value)
        })

        getAdStatsApi(listingId).then(response => {
            setViews(response.data.views);
            setCalls(response.data.calls);
            setChat(response.data.rooms);
            setFavorite(response.data.bookmarks);
            setFavoriteCountTotal(response.data.bookmarks.total)
            setViewsCountTotal(response.data.views.total);
            setCallCountTotal(response.data.calls.total);
            setCvCountTotal(response.data.rooms.total);
            setStatsLoader(false);
            setISLoading(false)
        });

        setTimeout((() => {
            updateScreenHistory(LoggingScreenNames['listing-stats']);
            logScreenLoad();
        }), 1000);

    }, []);

    const promoteListingTop = () => {
        logOrderStartClick()
        setShowBundle(true)
    }

    return (
        <>
            <ListingInfo applicantsView={false} count={count} refresh={() => {
            }} listing={listing} comeFrom="stats"/>
            {!isLoading && <>
                {listing && Object.values(listing.ctas).filter((cta) => {
                    return cta.cta == PROMOTE
                })[0] && <div className="card mt-4 mb-5">
                    <div
                        className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
                        style={{
                            backgroundPosition: '100% 50%',
                            backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
                        }}>
                        <div className='mb-10'>
                            <div className='fs-2 fw-bold text-gray-800 text-center mb-10'>
                                <span className='me-2'>
                                    {Intl.formatMessage({id: 'viewsAndStats.title1'})}
                                    <br/>
                                    {Intl.formatMessage({id: 'viewsAndStats.title2'})}
                                </span>
                            </div>
                            <div className='text-center'>
                                <button onClick={() => {
                                    promoteListingTop()
                                }}
                                        className="btn h-100 btn-primary">{Intl.formatMessage({id: "myListingView.text38"})}</button>
                            </div>
                        </div>
                    </div>
                </div>}
                <div className="row">
                    {views && <div className="col-12">
                        <Chart className='mb-5 mt-5' total={views.total}
                               buttonText={Intl.formatMessage({id: 'adViews.text3'})}
                               description={Intl.formatMessage({id: 'adViews.text2'})}
                               title={Intl.formatMessage({id: 'adViews.text1'})} chartType={views} target={'views'}
                               adId={listingId} extraText={Intl.formatMessage({id: "listing.stats.viewsDesc"})}
                               chartName={Intl.formatMessage({id: 'adViews.text1'})}/>
                    </div>}
                    {calls && <div className="col-12">
                        <Chart className='mb-5 mt-5' total={calls.total}
                               buttonText={Intl.formatMessage({id: 'adViews.text6'})}
                               description={Intl.formatMessage({id: 'adViews.text5'})}
                               title={Intl.formatMessage({id: 'adViews.text4'})} chartType={calls} target={'calls'}
                               adId={listingId} chartName={Intl.formatMessage({id: 'adViews.text4'})}/>
                    </div>}
                    {chat && <div className="col-12">
                        <Chart className='mb-5 mt-5' total={chat.total}
                               buttonText={Intl.formatMessage({id: 'adViews.text12'})}
                               description={Intl.formatMessage({id: 'adViews.text8'})}
                               title={Intl.formatMessage({id: 'adViews.text7'})} chartType={chat} target={'msgs'}
                               adId={listingId} chartName={Intl.formatMessage({id: 'adViews.text7'})}/>
                    </div>}
                    {favorite && <div className="col-12">
                        <Chart className='mb-5 mt-5' total={favorite.total}
                               buttonText={Intl.formatMessage({id: 'adViews.text11'})}
                               description={Intl.formatMessage({id: 'adViews.text10'})}
                               title={Intl.formatMessage({id: 'adViews.text9'})} chartType={favorite}
                               target={'bookmarks'} adId={listingId}
                               chartName={Intl.formatMessage({id: 'adViews.text9'})}/>
                    </div>}
                </div>
                <div className="row">
                    {listing && (listing.ctas.find((cta: DynamicObject) => {
                            return cta.cta == PROMOTE
                        }) || listing.ctas.find((cta: DynamicObject) => {
                            return cta.cta == LISTING_STATISTICS
                        }) && pathName != '/listings/my-listings/listing-stats') &&
                        <ListingStatistics showStatsButton={false} comeFrom={"stats"}
                                           showPromote={listing && listing.ctas.find((cta: DynamicObject) => {
                                               return cta.cta == PROMOTE
                                           })} stats={{
                            views: viewsCountTotal,
                            favorite: favoriteCountTotal,
                            calls: callCountTotal,
                            rooms: roomCountTotal
                        }} loading={statsLoader}/>}
                </div>
                <MemberShipModal show={showBundle} type={['Turbo', 'Premium', 'Bumpup']} handleClose={() => {
                    setShowBundle(false)
                }} id={listingId} pos={PointOfSales.MY_LISTING_VIEW_POS_STATISTICS_TOP_PROMOTE}/>
            </>}
        </>
    )
}

export {ListingStats}
