import React, {useEffect, useState} from 'react'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import {
  AddFieldType,
  PerPageFieldI,
  RemoveFieldType,
  SelectedFieldsType,
  SortFieldI,
} from '../../../../interfaces/MyListings'
import fieldOptionsApi from '../../../../../api/newMyListings/fieldOptions.api'
import {
  PerPageFieldMobileI,
  PerPageItemMobileI,
  SortFieldMobileI,
  SortItemMobileI,
} from '../../../../interfaces/MyListingsMobile'
import {useLocation} from 'react-router-dom'
import {useIntl} from 'react-intl'
import Skeleton from 'react-loading-skeleton'

type ItemTypes = PerPageItemMobileI | SortItemMobileI

const SingleSelectMobile = ({
  field,
  addField,
  removeField,
  subCatReportingName,
  categoryReportingName,
  selectedFields,
}: {
  field: SortFieldMobileI | PerPageFieldMobileI
  removeField: RemoveFieldType
  addField: AddFieldType
  subCatReportingName: string
  categoryReportingName: string
  selectedFields: SelectedFieldsType
}) => {
  const {fieldId, label, type, items} = field
  const [selectedOption, setSelectedOption] = useState<ItemTypes>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState('')
  const [options, setOptions] = useState(field?.items ? [...field?.items] : [])
  const [isLoading, setIsLoading] = useState(items ? false : true)
  const location = useLocation()
  const intl = useIntl()

  useEffect(() => {
    if (!selectedFields[fieldId] && options && options.length > 0) {
      selectOption(options[0], true)
    }
  }, [selectedFields])

  useEffect(() => {
    if (!fieldId) return
    const searchParams = new URLSearchParams(location.search)
    // const selectedOptionFromUrl = searchParams.get(fieldId)
    const selectedOptionFromUrl = selectedFields[fieldId]?.value
    if (!items?.length && fieldId) {
      fieldOptionsApi(fieldId ?? null, subCatReportingName || categoryReportingName || null).then((res) => {
        setIsLoading(false)
        setOptions([...res?.data])
        let ops = [...res?.data]
        if (selectedOption) return
        const selectedOp = ops.find((op) => op?.value.toString() === selectedOptionFromUrl?.toString())
        if (!selectedOp) {
          selectOption(ops[0], true)
          return
        }
        selectOption(selectedOp)
      })
      return
    }
    setIsLoading(false)
    const selectedOp = options.find((op) => op?.value.toString() === selectedOptionFromUrl?.toString())
    if (!selectedOp) {
      selectOption(options[0], true)
      return
    }
    selectOption(selectedOp)
  }, [field, subCatReportingName, categoryReportingName])

  const selectOption = (option: ItemTypes, isDefaultValue: boolean = false) => {
    if (selectedOption) removeField(fieldId, {value: selectedOption?.value}, 'SINGLE')
    if (selectedOption?.value?.toString() === option.value.toString()) {
      setSelectedOption(null)
    } else {
      setSelectedOption(option)
      addField(fieldId, {value: option.value, label: label, parentid: '0'}, 'SINGLE', true, isDefaultValue)
    }
    setIsModalOpen(false)
  }

  const lang = useLang()

  const handleSelectOption = (option: ItemTypes) => {
    selectOption(option)
    setIsModalOpen(false)
  }

  const filteredOptions = options.filter((option) => {
    return option.label.includes(searchTerm)
  })

  const optionsLoadingCells = () => {
    const loaders = []
    for (let index = 0; index < 10; index++) {
      const element = <Skeleton className='my-5' width={325} height={50} />
      loaders.push(element)
    }
    return loaders
  }

  return (
    <div style={{direction: lang === 'ar' ? 'rtl' : 'ltr'}} key={fieldId}>
      <div
        onClick={() => setIsModalOpen(true)}
        className='card w-100 p-3 d-flex align-items-center justify-content-between flex-row p-5 shadow-sm'
        style={{borderRadius: '0.75rem'}}
      >
        <div>
          <div className={`fw-bold fs-3 ${selectedOption ? '' : 'my-3'}`}>{label}</div>
          {selectedOption && <div className='fs-5'>{selectedOption?.label ?? ''}</div>}
        </div>
        <span className='text-dark fs-4' style={{cursor: 'pointer'}}>
          {intl.formatMessage({id: 'mylistings.singleselect.mobile.select'})}{' '}
          <i className={`mx-1 fa text-primary fa-chevron-${lang === 'ar' ? 'left' : 'right'}`}></i>
        </span>
      </div>

      {isModalOpen && (
        <div className='modal show d-block' style={{backgroundColor: 'rgba(0,0,0,0.5)'}}>
          <div className='modal-dialog modal-fullscreen'>
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title fs-1'>{label}</h5>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => setIsModalOpen(false)}
                ></button>
              </div>
              <div className='modal-body d-flex flex-column gap-2'>
                {!isLoading ? (
                  filteredOptions.map((option, index) => (
                    <div
                      key={index}
                      className='card p-5 py-7 fs-2 fw-bold mb-3 shadow-sm d-flex flex-row justify-content-between align-items-center'
                      style={{borderRadius: '0.5rem', cursor: 'pointer'}}
                      onClick={() => handleSelectOption(option)}
                    >
                      <span>{option?.label}</span>
                      <i
                        className={`fa text-dark fw-bold fa-chevron-${
                          lang === 'ar' ? 'left' : 'right'
                        }`}
                      ></i>
                    </div>
                  ))
                ) : (
                  <div>{optionsLoadingCells()}</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SingleSelectMobile
