import { useEffect, useMemo, useState } from "react";

import {Modal} from 'react-bootstrap'
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { useLang } from "../../../i18n/OpenSooqi18n";
import Skeleton from "react-loading-skeleton";
import walletTopUpApi from "../../../../api/buyNow/walletTopUp.api";
import { TopUpInterface } from "../../../interfaces/TopUpInterface";
import { getGeneralImage } from "../../../helpers/commonFunctions";

type Props = {
  show: boolean
  handleClose: () => void
  setFlow: (flow: number) => void
}

const TopUpModal = ({show,handleClose,setFlow}: Props) => {
    const lang = useLang()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [topUp, setTopUp] = useState<TopUpInterface>();

    useEffect(()=>{
        if(!show) return
        setIsLoading(true)
        getTopUps();
    },[show]);

    const getTopUps = ()=>{
        walletTopUpApi().then((response) => {
            setTopUp(response.data);
            setIsLoading(false);
        })
    }

    const closeHandler = () => {
        handleClose()
    }

    const skeletonRows = useMemo(()=>{
        let rows = []
        for (let i = 0; i < 6; i++) {
            rows.push(<Skeleton className="m-5 h-35px w-400px" key={i}/>)
        }
        return rows;
    }, []);


    return  <>
        <Modal id='iframe'tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered' show={show} onHide={closeHandler}  >
            <div className='modal-header p-5'>
                <span className="h1">{isLoading ? <Skeleton className="h-30px w-300px"/> : topUp.title} </span>
                <div className='btn btn-sm btn-icon btn-active-color-primary' data-id="add-credit-popup-closeBtn" onClick={closeHandler}>
                    <KTSVG className='svg-icon-1 fa-5x' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className={`modal-body py-5 overflow-scroll h-500px'}`} >
                <div className={`row`}>
                    {isLoading && skeletonRows}
                    {!isLoading &&   <div className="w-100 h-100">{
                            topUp.items.map((item, index) => {
                                return <div className = {`w-100 ${ (index != topUp.items.length - 1 ) ? 'border-bottom' : ''} border-solid hoverPointer d-flex flex-row justify-content-between  py-5`} onClick={()=>{setFlow(item.flow)}} data-id={`add-credit-popup-${item.flow}`}>
                                    <div className="text-start">
                                        <img width={'30px'} height={'30px'} className="me-2" src={getGeneralImage(toAbsoluteUrl(item.icon))} alt="" />
                                        <span className="text-gray-800 fs-3">{item.title}</span>
                                    </div>
                                    <div className="text-end">
                                        <i className={`fas fs-2 ${lang === 'en' ? 'fa-chevron-right' : 'fa-chevron-left'}`} />
                                    </div>
                                </div>                                        
                            })
                        }
                    </div>}
                </div>
            </div>
        </Modal>
    </>
}

export {TopUpModal}