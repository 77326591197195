import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { getCookies, redirectOut } from "../../helpers/commonFunctions";
import { useLang } from "../../i18n/OpenSooqi18n";

const Products = () => {
    const Intl = useIntl();
    const lang = useLang();
    let navigate = useNavigate();
    function navigateTo(url : string) {
        navigate(url);
    }

    return  <div className="card card-xxl-stretch mb-5 mb-xxl-10 h-lg-100">
        <div className="card-header border-0 mt-2">
            <div className="fs-3 card-title align-items-start flex-column mt-2">
                <span className="card-label fw-bold text-dark">
                {Intl.formatMessage({id : "myOpenSooq.products"})}
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">{Intl.formatMessage({id : "myOpenSooq.products.text1"})}</span>
            </div>
        </div>
        <div className="card-body pt-0">
            <div onClick={() => {navigateTo('/services/carfax-report')}} className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" data-id="home-products-carHistoryReport">
                <div className="d-flex align-items-center h-30px hoverPointer">
                    <div className="flex-grow-1">
                        <span   className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "myOpenSooq.products.text2"})}</span>
                    </div>
                </div>
            </div>
            <div onClick={() => {navigateTo('/products')}} className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer" data-id="home-products-hideAds">
                <div className="d-flex align-items-center h-30px hoverPointer">
                    <div className="flex-grow-1">
                        <span  className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "myOpenSooq.products.text3"})}</span>
                    </div>
                </div>
            </div>
            <div className="border border-dashed border-gray-300  grayHoverBg w-100 rounded my-3 p-2 me-2 hoverPointer"  onClick={() => {redirectOut((lang === 'ar') ? `https://${getCookies().ecountry}.opensooq.com/ar/الخدمات-الاعلانية` : `https://${getCookies().ecountry}.opensooq.com/en/advertising-products`)}} data-id="home-products-moreViews">
                <div className="d-flex align-items-center h-30px hoverPointer">
                    <div className="flex-grow-1">
                        <span className="text-gray-800 text-hover-primary fw-bold fs-6">{Intl.formatMessage({id : "myOpenSooq.products.text4"})}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Products