import { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { PageLink, PageTitle } from '../../app/layout/core';
import { AccountStats } from '../modules/accounts/AccountStats';
import { Followers } from '../modules/accounts/Followers';
import { Following } from '../modules/accounts/Following';
import { HandleCv } from '../modules/accounts/HandleCv';
import { Settings } from '../modules/accounts/Settings';
import { CvStats } from '../modules/accounts/cv/CvStats';
import { NewCv } from '../modules/accounts/cv/NewCv';
import { ManageAccount } from '../modules/accounts/manageAccount/ManageAccount';
import { MyWallet } from '../modules/accounts/wallet/MyWallet';
import BundleUsage from '../modules/accounts/wallet/BundleUsage';
const history:string[] = [];

const AccountRoutes: FC = () => {
    const intl = useIntl();
    const location = useLocation()
    const account: Array<PageLink> = [
        {
          title: intl.formatMessage({id: 'account'}),
          path: '',
          isSeparator: false,
          isActive: false,
        }
    ]
    const wallet: Array<PageLink> = [
      {
        title: intl.formatMessage({id: 'account'}),
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: intl.formatMessage({id: 'myWallet'}),
        path: 'account/my-wallet',
        isSeparator: false,
        isActive: false,
      }
  ]

    useEffect(()=>{
      if(history.length == 2)
        history.shift();
      history.push(location.pathname)
    }, [location]);

    return (
        <Routes>
            <Route path='/*' element={<Navigate to='/error/404' state= {{ 'currentPath': window.location.pathname, 'previousPath' : history.length < 1 ? '' : history[history.length - 1]}}/>} />
            <Route path='my-account' element={ <> <PageTitle breadcrumbs={account}>{intl.formatMessage({id : "manageAccount"})}</PageTitle> <ManageAccount /> </> } />
            {/* <Route path='my-account' element={ <> <PageTitle breadcrumbs={account}>{intl.formatMessage({id : "myAccount.title"})}</PageTitle> <MyAccount /> </> } /> */}
            <Route path='my-account/account-stats' element={ <> <PageTitle breadcrumbs={account}>{intl.formatMessage({id : "myOpenSooq.accountStatistics"})}</PageTitle> <AccountStats /> </> } />
            <Route path='my-account/edit-profile' element={ <> <PageTitle breadcrumbs={account}>{intl.formatMessage({id:"manageAccount"})}</PageTitle> <ManageAccount /> </> } />
            <Route path='followers' key="followers" element={ <> <PageTitle breadcrumbs={account}>{intl.formatMessage({id: 'menu.account.followers'})}</PageTitle> <Followers  /> </> } />
            <Route path='following' key="following" element={ <> <PageTitle breadcrumbs={account}>{intl.formatMessage({id: 'menu.account.followings'})}</PageTitle> <Following /> </> } />
            <Route path='my-rating' key="rating" element={ <> <PageTitle breadcrumbs={account}>{intl.formatMessage({id: 'manageAccount'})}</PageTitle> <ManageAccount /> </> } />
            <Route path='my-wallet' key="my-wallet" element={<><PageTitle breadcrumbs={account}>{intl.formatMessage({id: 'myWallet'})}</PageTitle> <MyWallet /> </>} />
            <Route path='my-wallet/bundle-usage/:id' key="bundle-usage-wallet" element={<><PageTitle breadcrumbs={wallet}>{intl.formatMessage({id: 'myWallet.bundleUsage'})}</PageTitle> <BundleUsage /> </>} />
            <Route path='settings' element={ <> <PageTitle breadcrumbs={account}>{intl.formatMessage({id: 'menu.account.settings'})}</PageTitle> <Settings /> </> } />
            <Route path='cv/view' element={ <> <PageTitle breadcrumbs={account}>{intl.formatMessage({id : "profileHeader.myCv"})}</PageTitle><HandleCv /> </> } />
            <Route path='cv/edit' element={ <> <PageTitle breadcrumbs={account}>{intl.formatMessage({id : "profileHeader.myCv"})}</PageTitle> <NewCv/> </>}/>
            <Route path='cv/cv-stats' element={ <> <PageTitle breadcrumbs={account}>{intl.formatMessage({id: 'adViewsAndStats'})}</PageTitle>  <CvStats /></> } />
        </Routes>
    )
}

export default AccountRoutes
