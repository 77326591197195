import React from "react";

const IconDownArrowHead = ({
  width = "22",
  height = "22",
  className = "",
  fill = "#000",
  dataFlipTop = false,
  onClick = () => {},
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 22 22"
      className={className ? className : null}
      onClick={onClick}
      data-name="iconDownArrowHead"
      data-flip-top={dataFlipTop}
    >
      <path
        fill={fill}
        d="M10.2433 14.6444L5.31521 9.07046C5.21531 8.9581 5.13604 8.8245 5.08194 8.67734C5.02785 8.53019 5 8.3724 5 8.21303C5 8.05366 5.02785 7.89586 5.08194 7.74871C5.13604 7.60156 5.21531 7.46796 5.31521 7.35559C5.41468 7.24287 5.53282 7.15344 5.66287 7.09242C5.79292 7.03141 5.93233 7 6.07312 7C6.21391 7 6.35332 7.03141 6.48337 7.09242C6.61342 7.15344 6.73157 7.24287 6.83104 7.35559L11.0012 12.0721L15.1702 7.35559C15.2698 7.24299 15.3879 7.15367 15.518 7.09273C15.648 7.03179 15.7874 7.00043 15.9281 7.00043C16.0689 7.00043 16.2083 7.03179 16.3383 7.09273C16.4684 7.15367 16.5865 7.24299 16.6861 7.35559C16.7856 7.46819 16.8645 7.60187 16.9184 7.74898C16.9723 7.8961 17 8.05379 17 8.21303C17 8.37227 16.9723 8.52995 16.9184 8.67707C16.8645 8.82419 16.7856 8.95786 16.6861 9.07046L11.7591 14.6444C11.6596 14.7571 11.5415 14.8466 11.4114 14.9076C11.2814 14.9686 11.142 15 11.0012 15C10.8604 15 10.721 14.9686 10.5909 14.9076C10.4609 14.8466 10.3427 14.7571 10.2433 14.6444Z"
      />
    </svg>
  );
};

export default IconDownArrowHead;
