import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import Skeleton from "react-loading-skeleton";
import CreateCartApi from "../../../../api/CreateCart.api";
import getListingCreditsApi from "../../../../api/getListingCredits.api";
import { getGeneralImage } from "../../../helpers/commonFunctions";
import { useLang } from "../../../i18n/OpenSooqi18n";
import { ListingCreditInterface } from "../../../interfaces/ListingCreditsInterface";
import { LearnMore } from "./LearnMore";

interface Iprops {
    subCatReportingName: string
    changeHeaderHandler: (header:string) => void
    changeSubHeaderHandler: (subHeader:string) => void
    learnMore: boolean
    toggleLearnMore: () => void
    pos ?: string
    redirectTo ?: string
}
const BundleListingCredits = ({subCatReportingName, changeHeaderHandler, changeSubHeaderHandler, learnMore, toggleLearnMore,pos,redirectTo} : Iprops) => {
    const lang = useLang();
    const Intl = useIntl();
    const [listingCreditDetails, setListingCreditDetails] = useState<ListingCreditInterface>();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const action = params.get('action') ?? '';

    useEffect(()=>{
        getListingCreditsApi(subCatReportingName).then((response) => {
            setListingCreditDetails(response.data);
        })
    }, [subCatReportingName])

    useEffect(()=>{
        if(!listingCreditDetails) return;
        changeHeaderHandler(listingCreditDetails.header);
        changeSubHeaderHandler('');
    }, [listingCreditDetails, learnMore])

    const skeletonRows = useMemo(()=>{
        let rows = []
        for (let i = 0; i < 3; i++) {
            rows.push(
                <div className="d-flex flex-column col-xl-4 col-lg-6 col-sm-10" >
                <div  className={`fs-2 text-center `}></div>
                    <Skeleton className="card shadow w-100 h-200px my-4"/>
                </div>
            )
        }
        return rows;
    }, []);

    const handleTextSplit = (text: string) => {
        const textArray = text.split('*');
        
        return <span className="fs-7">{textArray[0]}<strong>{textArray[1]}</strong></span>;
    }

    const handleCreateCart = (qty : number, productAssignId : number) => {
        let redirectToUrl =  window.location.href;
        if(action != '' && action == 'openWalletBundles'){
            redirectToUrl =  window.location.origin + window.location.pathname;;
        }
        const formData = { 
            Payment: {
                isBundle: true,
                qty: qty,
                productAssignId: productAssignId,
                modelId : -1,
                posSource : pos,
                absoluteUrl : redirectTo ? redirectTo : redirectToUrl
            }
        }
        CreateCartApi(formData).then((res)=>{
            window.location.href = res.data.redirect;
        })
    }


    return (<> {learnMore && <div className="d-flex flex-column justify-content-start align-items-center gap-6"><LearnMore changeSubHeaderHandler={changeSubHeaderHandler} changeHeaderHandler={changeHeaderHandler}/></div>}
        {!learnMore && <div className="d-flex flex-column justify-content-start align-items-center gap-6">
            <div className="d-flex justify-content-between container w-100">
                {!listingCreditDetails && <Skeleton className="h-10px w-250px"/>}
                {listingCreditDetails && <p onClick={toggleLearnMore} className="text-primary text-decoration-underline fs-4 hoverPointer">{listingCreditDetails.blueUnderLineText}</p>}
            </div>
            <div className="container">
            <div className="row justify-content-center">
            {listingCreditDetails && listingCreditDetails.items.map((item) => { return(
            <div className="d-flex flex-column col-xl-4 col-lg-6 col-sm-10" key={item.productAssignId}>
            {item.isBestOffer && <div style={{width: "40%", height: "65px", backgroundColor: item.savingTag.backgroundColor, color:  item.savingTag.textColor}} className={`fs-2 text-center rounded-3 mb-n10 text-end ${lang === 'en' ?'align-self-end':'align-self-start'} fw-bold d-block`}>{Intl.formatMessage({id: "listing.topSelling"})}</div>}
            {!item.isBestOffer && <div style={{width: "40%", height: "65px"}} className={`fs-2 text-center rounded-3 mb-n10 text-end ${lang === 'en' ?'align-self-end':'align-self-start'} fw-bold d-block`}></div>}
                <div className="card shadow w-100" style={{zIndex: "900"}}>
                    <div className="card-body d-flex flex-column gap-3">
                        <div className="card-title">
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex flex-column fw-bold"><span className="align-self-center " style={{fontSize: "35px"}}>{item.qty}</span><span className="fs-3 text-muted">{Intl.formatMessage({id: "ads"})}</span></div>
                                <div className="fs mt-3">{item.description.map((desc, idx)=> {return (
                                <div className="d-flex align-self-center" key={idx}>
                                    <img src={getGeneralImage(desc.icon)} className="w-15px h-15px" alt="" />
                                    <p>{handleTextSplit(desc.text)}</p>
                                    </div> )})}
                            </div>
                            <div></div>
                        </div>
                    </div>
                    <hr style={{borderTop: "10px dark dotted"}} />
                        <div className="d-flex justify-content-between">
                            <span>
                            <span className="fs-3"><strong>{item.unitPrice}</strong></span>
                            <span> {item.subtitle}</span>
                            </span>
                            {item.savingTag && <span style={{backgroundColor: item.savingTag.backgroundColor, color:  item.savingTag.textColor }} className="badge me-3 fw-bold fs-5 pt-2">{item.savingTag.text}</span>}
                        </div>
                        <button onClick={() => {handleCreateCart(item.qty,item.productAssignId)}} className="btn mt-12 fw-bold" style={{backgroundColor: item.button.backgroundColor, color:  item.button.textColor }}>{item.button.text}</button>
                    </div>
                </div>
            </div>)
            })}
            {!listingCreditDetails && skeletonRows}
            </div>
            </div>
        </div>}
    </> )
}

export { BundleListingCredits };
