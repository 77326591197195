import React, {useEffect, useState} from 'react'
import {useLang} from '../../../i18n/OpenSooqi18n'
import CategoryFilterMobile from './mobileFilters/CategoryFilterMobile'
import SearchFilterMobile from './mobileFilters/SearchFilterMobile'
import RangeTextFilterMobile from './mobileFilters/rangeWithUnits/RangeTextFilterWithUnitMobile'
import {
  CategoryFieldItemMobileI,
  CategoryMobileI,
  DynamicFilterMobileI,
  FilterFieldMobileI,
  StatusFieldItemMobileI,
  StatusFieldMobileI,
} from '../../../interfaces/MyListingsMobile'
import {
  AddFieldType,
  RemoveFieldType,
  SelectedFieldsType,
  ValueType,
} from '../../../interfaces/MyListings'
import FiltersMobileMapper from './mobileFilters/FiltersMobileMapper'
import {useIntl} from 'react-intl'
import CategorySingleSelect from './CategorySingleSelect'
import StatusesMultiSelect from './StatusesMultiSelect'
import useFiltersStore from '../my-listings/FilterStore'
import RangeDateFilterMobile from './mobileFilters/RangeDateFilterMobile'

interface ListingFiltersMobileProps {
  filterWidget: FilterFieldMobileI
  dynamicFields: DynamicFilterMobileI[]
  addField: AddFieldType
  removeField: RemoveFieldType
  fetchDynamicFields: (reportingName: string) => void
  resetDynamicFields: () => void
  resetAllFilters: () => void
  selectedFields: SelectedFieldsType
  statusCount: number
  isPillAllEnabled?: boolean
  togglePillAllHandler?: () => void
  counts: any
}

const ListingFiltersMobile: React.FC<ListingFiltersMobileProps> = ({
  filterWidget,
  dynamicFields,
  addField,
  removeField,
  fetchDynamicFields,
  resetDynamicFields,
  resetAllFilters,
  selectedFields,
  statusCount,
  isPillAllEnabled,
  togglePillAllHandler,
  counts,
}: ListingFiltersMobileProps) => {
  const {category, statuses} = filterWidget
  const [isOpen, setIsOpen] = useState(false)
  const lang = useLang()
  const direction = lang === 'ar' ? 'rtl' : 'ltr'
  const [selectedCategory, setSelectedCategory] = useState<CategoryFieldItemMobileI | null>(
    selectedFields[filterWidget?.category?.items[0]?.fieldId ?? null]
  )
  const [selectedStatus, setSelectedStatus] = useState<StatusFieldItemMobileI | null>(null)
  const {globalFiltersDisable} = useFiltersStore()
  const intl = useIntl()
  //breadcrums logic prep
  const selectedCategoryFromFields = selectedFields['ref_cat1_id']
  const selectedSubCategory = selectedFields['ref_cat2_id'] as ValueType
  const cats: CategoryFieldItemMobileI[] | [] =
    filterWidget?.filter?.items?.categoryField.items ?? []
  const selectedCat: CategoryFieldItemMobileI | null = cats.find(
    (cat) => cat.value === selectedCategoryFromFields?.value
  )
  const selectedSubCat =
    selectedCat && selectedCat?.items && selectedSubCategory
      ? selectedCat?.items?.find((subCat) => subCat?.value === selectedSubCategory?.value)
      : null
  //
  // const selectedCategory = selectedFields['ref_cat1_id'] as ValueType
  const selectedCategoryReportingName = selectedCategoryFromFields
    ? cats.find((cat) => cat.value === selectedCategoryFromFields?.value)?.reporting
    : null

  useEffect(() => {
    const selectedCatFromFields = category?.items?.find((item) => {
      const selectedCat = selectedFields[category?.items[0].fieldId] as ValueType
      return item?.value === selectedCat?.value
    })
    setSelectedCategory(selectedCatFromFields ?? null)
  }, [selectedFields])

  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
    return () => {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  const removeSubCategory = () => {
    if (!selectedSubCat) return
    removeField('ref_cat2_id', {value: selectedSubCat.value}, 'SINGLE')
    resetDynamicFields()
  }

  const removeCategory = () => {
    if (!selectedCat) return
    removeSubCategory()
    removeField('ref_cat1_id', {value: selectedCat.value}, 'SINGLE')
  }

  const backButtonHandler = () => {
    if (selectedSubCategory) {
      removeSubCategory()
      return
    }
    if (selectedCat) {
      removeCategory()
      return
    }
    closeModal()
  }

  const resetFilters = () => {
    resetAllFilters()
    setSelectedCategory(null)
  }

  const categorySelectorHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = category?.items?.find((item) => item.value.toString() === e.target.value)
    if (!selectedOption) return
    if (selectedCategory) {
      removeField(selectedCategory.fieldId, {value: selectedCategory.value}, 'SINGLE')
      removeSubCategory()
    }
    addField(
      selectedOption.fieldId,
      {
        value: selectedOption.value,
        parentid: selectedOption.parentId?.toString(),
        label: selectedOption.label,
      },
      'SINGLE'
    )
    setSelectedCategory(selectedOption)
  }

  const statusSelectorHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = statuses?.items?.find(
      (item) => item.fieldId.toString() === e.target.value
    )
    if (!selectedOption) return
    if (selectedStatus) {
      removeField(selectedStatus.fieldId, {value: selectedStatus.value}, 'SINGLE')
    }
    addField(
      selectedOption.fieldId,
      {value: selectedOption.value, label: selectedOption.label},
      'SINGLE'
    )
    setSelectedStatus(selectedOption)
  }

  const breadcrums = () => {
    return (
      <div className='fs-6 text-dark bg-transparent'>
        <span className={`${selectedCat ? 'text-primary' : 'text-muted'}`} onClick={removeCategory}>
          {intl.formatMessage({id: 'mylistings.breadcrumb.all'})}
        </span>
        {selectedCat && (
          <>
            <span
              className={`px-2 fs-6 fa fa-chevron-${lang === 'ar' ? 'left' : 'right'}`}
              style={{color: '#757575'}}
            ></span>
            <span
              className={`${selectedSubCat ? 'text-primary' : 'text-muted'}`}
              onClick={removeSubCategory}
            >
              {selectedCat.label}
            </span>
          </>
        )}
        {selectedSubCat && (
          <>
            <span
              className={`px-2 fs-6 fa fa-chevron-${lang === 'ar' ? 'left' : 'right'}`}
              style={{color: '#757575'}}
            ></span>
            <span className='text-muted'>{selectedSubCat.label}</span>
          </>
        )}
      </div>
    )
  }

  return (
    <div className=' px-2'>
      <div className='row'>
        <div
          onClick={() => {
            if (!globalFiltersDisable) openModal()
          }}
          className={`col-4 ${globalFiltersDisable ? 'hoverNotAllowed' : ''}`}
        >
          <div className='card d-flex p-2 px-3 flex-row align-items-center justify-content-center gap-3  w-100 '>
            <img
              className={`mb-1 d-inline`}
              src={filterWidget?.filter?.icon}
              width={22}
              height={24}
              alt=''
            />
            <div
              className={`text-16 text-truncate py-3 ${globalFiltersDisable ? 'text-muted' : ''}`}
            >
              {filterWidget?.filter?.label}
            </div>
          </div>
        </div>

        <CategorySingleSelect
          field={category}
          selectedFields={selectedFields}
          addField={addField}
          removeField={removeField}
        />
        <StatusesMultiSelect
          fields={statuses}
          selectedFields={selectedFields}
          addField={addField}
          removeField={removeField}
          isPillAllEnabled={isPillAllEnabled}
          togglePillAllHandler={togglePillAllHandler}
          counts={counts}
        />
      </div>

      {isOpen && (
        <div className='modal fade show' role='dialog' style={{display: 'block'}}>
          <div className='modal-dialog modal-fullscreen' role='document'>
            <div className='modal-content' style={{backgroundColor: '#f8f9fa'}}>
              <div
                className='modal-header d-flex flex-column align-items-start justify-content-space-between'
                style={{
                  borderBottom: 'none',
                }}
              >
                <div className='d-flex align-items-center justify-content-between w-100'>
                  <span
                    className={`fs-2 fa fa-chevron-${direction === 'ltr' ? 'left' : 'right'}`}
                    style={{
                      color: 'black',
                      fontSize: '2rem',
                      opacity: 1,
                    }}
                    aria-label='Close'
                    onMouseOver={(e) => {
                      const target = e.target as HTMLElement
                      target.style.opacity = '0.8'
                    }}
                    onMouseOut={(e) => {
                      const target = e.target as HTMLElement
                      target.style.opacity = '1'
                    }}
                    onClick={backButtonHandler}
                  ></span>
                  <button
                    type='button'
                    className='fs-2 btn btn-link fs-3'
                    style={{
                      color: '#0179FF',
                      fontSize: '1rem',
                      marginLeft: direction === 'ltr' ? '0.5rem' : '0',
                      marginRight: direction === 'rtl' ? '0.5rem' : '0',
                    }}
                    onClick={resetFilters}
                  >
                    {intl.formatMessage({id: 'mylistings.filters.mobile.reset'})}
                  </button>
                </div>
                <span className='fw-bold mt-5' style={{fontSize: '24px'}}>
                  {filterWidget?.filter?.label}
                </span>
              </div>
              <div className='px-8 pb-1'>{breadcrums()}</div>
              <div className='modal-body d-flex flex-column gap-5'>
                {filterWidget?.filter?.items?.categoryField && (
                  <FiltersMobileMapper
                    addField={addField}
                    removeField={removeField}
                    filter={filterWidget?.filter?.items?.categoryField}
                    fetchDynamicFields={fetchDynamicFields}
                    resetDynamicFields={resetDynamicFields}
                    selectedFields={selectedFields}
                  />
                )}
                {filterWidget?.filter?.items?.locationSection && (
                  <FiltersMobileMapper
                    addField={addField}
                    removeField={removeField}
                    filter={filterWidget?.filter?.items?.locationSection}
                    resetDynamicFields={resetDynamicFields}
                    fetchDynamicFields={fetchDynamicFields}
                    selectedFields={selectedFields}
                    isNhoodDisabled={selectedCategory?.isNhoodDisabled ?? false}
                  />
                )}
                {filterWidget?.filter?.items?.listingIdSearchField && (
                  <FiltersMobileMapper
                    addField={addField}
                    removeField={removeField}
                    filter={filterWidget?.filter?.items?.listingIdSearchField}
                    resetDynamicFields={resetDynamicFields}
                    fetchDynamicFields={fetchDynamicFields}
                    selectedFields={selectedFields}
                  />
                )}
                {filterWidget?.filter?.items?.titleSearchField && (
                  <FiltersMobileMapper
                    addField={addField}
                    removeField={removeField}
                    filter={filterWidget?.filter?.items?.titleSearchField}
                    resetDynamicFields={resetDynamicFields}
                    fetchDynamicFields={fetchDynamicFields}
                    selectedFields={selectedFields}
                  />
                )}
                {filterWidget?.filter?.items?.price && (
                  <FiltersMobileMapper
                    addField={addField}
                    removeField={removeField}
                    filter={filterWidget?.filter?.items?.price}
                    resetDynamicFields={resetDynamicFields}
                    fetchDynamicFields={fetchDynamicFields}
                    selectedFields={selectedFields}
                  />
                )}
                {dynamicFields?.map((dynamicField) => {
                  return (
                    <FiltersMobileMapper
                      addField={addField}
                      removeField={removeField}
                      filter={dynamicField}
                      resetDynamicFields={resetDynamicFields}
                      fetchDynamicFields={fetchDynamicFields}
                      selectedFields={selectedFields}
                    />
                  )
                })}
                {filterWidget?.filter?.items?.sortField && (
                  <FiltersMobileMapper
                    addField={addField}
                    removeField={removeField}
                    filter={filterWidget?.filter?.items?.sortField}
                    resetDynamicFields={resetDynamicFields}
                    fetchDynamicFields={fetchDynamicFields}
                    selectedFields={selectedFields}
                    categoryReportingName={selectedCategoryReportingName ?? null}
                    subCatReportingName={selectedSubCat?.reporting ?? null}
                  />
                )}
                {filterWidget?.filter?.items?.perPage && (
                  <FiltersMobileMapper
                    addField={addField}
                    removeField={removeField}
                    filter={filterWidget?.filter?.items?.perPage}
                    resetDynamicFields={resetDynamicFields}
                    fetchDynamicFields={fetchDynamicFields}
                    selectedFields={selectedFields}
                  />
                )}
                {filterWidget?.filter?.items?.otherOptionSection && (
                  <FiltersMobileMapper
                    addField={addField}
                    removeField={removeField}
                    filter={filterWidget?.filter?.items?.otherOptionSection}
                    resetDynamicFields={resetDynamicFields}
                    fetchDynamicFields={fetchDynamicFields}
                    selectedFields={selectedFields}
                  />
                )}
                {filterWidget?.filter?.items?.date && (
                  <RangeDateFilterMobile
                    rangeField={filterWidget?.filter?.items?.date}
                    addField={addField}
                    removeField={removeField}
                    selectedFields={selectedFields}
                    // reset={reset}
                  />
                )}
              </div>
              <div className='modal-footer d-flex justify-content-center p-5 my-2'>
                <button
                  className='btn btn-lg btn-primary w-100 h-100 fw-bold fs-2'
                  onClick={closeModal}
                >{`${intl.formatMessage({id: 'mylisting.filter'})} (${statusCount ?? 0})`}</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ListingFiltersMobile
