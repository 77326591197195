/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, { ApexOptions } from 'apexcharts'
import Moment from 'moment'
import React, { useEffect, useRef } from 'react'
import { useIntl } from "react-intl"
import { Link } from 'react-router-dom'
import { ReactTooltipContainer } from '../../../../app/modules/common/ReactTooltipContainer'
import { getCSS, getCSSVariableValue } from '../../../assets/ts/_utils'
import { ListingStatsInterface } from '../../../interfaces/ListingStatsInterface'
import { useThemeMode } from '../../../partials'
import { generateAccountStatsDateRangeObject, generateDateRangeObject, isDeviceMobile } from '../../../helpers/commonFunctions'

type Props = {
  adId        : string
  className   : string
  chartType   : ListingStatsInterface
  title       : string
  description : string
  buttonText  ?: string
  total       : number,
  target      : string 
  extraText   ?: string
  chartName : string
}

const AccountChart: React.FC<Props> = ({className,chartType,title,description,buttonText,total,target,adId,extraText,chartName}) => {
    const minTimestamp = Number(chartType.min.toFixed()); 
    const minDate = new Date(minTimestamp);
    let min  : any= minDate.getTime();
    min = new Date(min * 1000)
    const maxTimestamp = Number(chartType.max.toFixed());
    const maxDate = new Date(maxTimestamp);
    let max  : any= maxDate.getTime();
    max = new Date(max * 1000)
    const chartRef = useRef<HTMLDivElement | null>(null)
    const {mode} = useThemeMode()
    const Intl = useIntl();

    useEffect(() => {
        const chart = refreshChart()
        return () => {
            if (chart) {
              chart.destroy()
            }
        }
    }, [chartRef, mode])

    const refreshChart = () => {
        if (!chartRef.current) {
            return
        }

        const free = chartType.free
        const premium = chartType.premium
        
        let combined = {};

        for (let key in premium) {
            if (free.hasOwnProperty(key)) {
                combined[key] = premium[key] + free[key];
            } else {
                combined[key] = premium[key];
            }
        }

        for (let key in free) {
            if (!combined.hasOwnProperty(key)) {
                combined[key] = free[key];
            }
        }

        const height = parseInt(getCSS(chartRef.current, 'height'))
        let dateRangeArray = generateAccountStatsDateRangeObject(min,max,combined);
        const chart = new ApexCharts(chartRef.current, getChartOptions(Intl,height,dateRangeArray,min,max))
        if (chart) {
            chart.render()
        }
        return chart
    }

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 '>
        <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
            <span className='text-muted fw-semibold fs-7'>{description}</span>
            {extraText && <span className='text-muted fw-semibold fs-7'>{extraText}</span>}
        </h3>
        <div className='card-toolbar'>
            <div className="d-flex flex-column mt-2">
                <div className="bd-highlight fs-3 fw-bolder">{total.toLocaleString()}</div>
                {/* <div className="bd-highlight fs-3 mt-1">
                    <span className='text-success fw-bolder'>+100% </span>{Intl.formatMessage({id: 'sinceLastWeek'})}
                </div> */}
                {buttonText && <div className="bd-highlight mt-1 fs-3">
                    <Link to={`/listings/my-listings/listing-lead?type=${target}&id=${adId}`} className='btn btn-primary btn-sm'>{buttonText}</Link>
                </div>}
            </div>
        </div>
      </div>

      <div className='card-body'>
        {total == 0 && <div className='p-15 text-center'><h1>{chartName}</h1>  </div>}
        {total > 0 && <div ref={chartRef} id='kt_charts_widget_1_chart' style={{height: '350px'}} />}
      </div>
      <ReactTooltipContainer/>
    </div>

  )
}

export { AccountChart }

function getChartOptions(Intl : any,height: number, series,min : Date,max : Date): ApexOptions {
  const minTimestamp = Number(min); 
  const minDate = new Date(minTimestamp);
  let newmin  : any= minDate.getTime();
  newmin = new Date(newmin * 1000)
  const minyyyy = minDate.getFullYear();
  const minmm = (minDate.getMonth() + 1).toString().padStart(2, '0');
  const mindd = minDate.getDate().toString().padStart(2, '0');
  const minformattedDate = `${minyyyy}-${minmm}-${mindd}`;

  const maxTimestamp = Number(max); 
  const maxDate = new Date(maxTimestamp);
  let newmax  : any= maxDate.getTime();
  newmax = new Date(newmax * 1000)
  const yyyy = maxDate.getFullYear();
  const mm = (maxDate.getMonth() + 1).toString().padStart(2, '0');
  const dd = maxDate.getDate().toString().padStart(2, '0');
  const maxformattedDate = `${yyyy}-${mm}-${dd}`;

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: height,
      toolbar: {
        show: false,
      },
    },
    series: [
      {
        name: Intl.formatMessage({id: "adViews.text1"}),
        data: series.stats,
      },
    ],
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: isDeviceMobile() ? 12: 30,
      colors: ['transparent'],
    },
    colors: ['#0179FF'],
    xaxis: {
      type: 'datetime',
      min: new Date(minformattedDate).getTime(),
      max: new Date(maxformattedDate).getTime(),
      categories: series.cat,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '100%',
        borderRadius: 0,
      },
    },
    dataLabels: {
      enabled: false,
      dropShadow: {
        enabled: true
      }
    },
  }
}
