import {Button, Modal} from 'react-bootstrap'
import { KTSVG } from "../../../helpers";
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLang } from '../../../i18n/OpenSooqi18n';
import createSalesLeadApi from '../../../../api/createSalesLead.api';
import getSalesLeadApi from '../../../../api/getSalesLead.api';
import { SalesLeadInterFace } from '../../../interfaces/SalesLead';
import { getCookies, redirectionInterceptor } from '../../../helpers/commonFunctions';
import Skeleton from 'react-loading-skeleton';
import { showError, showSuccess } from '../../../helper/SystemAlert';
import { LearnMore } from './LearnMore';

type Props = {
  show: boolean
  handleClose: () => void
}

type UserInfo = {
  full_name: string
  phone_number: string
  email: string
  city_id: string
  picker: number
  type: string
  landing_page_title: string
}

const SliderModal = ({show,handleClose}: Props) => {
  const [learnMore, setLearnMore] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [header, setHeader] = useState<string>('')

  const [fieldErrors, setFieldErrors] = useState({
    full_name: '',
    phone_number: '',
    email: '',
   })

  const changeHeaderHandler = (header : string) =>  {
    setHeader(header);
  }

  const changeSubHeaderHandler = (subHeader : string) =>  {
  }

  const closeHandler = () => {
    handleClose()
    setLearnMore(false)
    setTimeout(()=>{
      setUserInfo({
        full_name: '',
        phone_number: '',
        email: '',
        city_id: '',
        picker: undefined,
        type: 'slider',
        landing_page_title : "Payment_Slider"
      })
    }, 200)
  }

  const [userInfo, setUserInfo] = useState<UserInfo>({
    full_name: '',
    phone_number: '',
    email: '',
    city_id: '',
    picker: undefined,
    type: 'slider',
    landing_page_title : "Payment_Slider"
  })
  
  const [salesLead, setSalesLead] = useState<SalesLeadInterFace>(undefined)
  const Intl = useIntl()
  const lang = useLang()
  const country = getCookies().ecountry;
  const openSooqUrl =  (process.env.REACT_APP_OPENSOOQ_BASE ?? '').replace("{country}", country ?? '').replace("{lang}", lang ?? '');

    useEffect(() => {
        getSalesLeadApi("slider").then((response) => {
          setSalesLead(response.data)
        })
    }, [show])

    useEffect(()=>{
      if(!salesLead)
        return;
      setUserInfo({...userInfo, full_name: salesLead.content.inputs.full_name.value, phone_number: salesLead.content.inputs.phone_number.value, email: salesLead.content.inputs.email.value})
      changeHeaderHandler(salesLead.headerContent.header)
    }, [salesLead])

    const whatsappForward = (phone: string) => {
      if(!phone)
       return;
      window.open(`https://wa.me/${phone}`, '_self');
    }
  const selectCityHandler = (e :  React.ChangeEvent<HTMLSelectElement>)=>{
    const cityCode = e.target.value;
    setUserInfo({...userInfo, city_id: cityCode})
  }

  const createSliderHandler = () => {
    setFieldErrors({
      full_name: '',
      phone_number: '',
      email: '',
    })
    if(!userInfo.full_name || !userInfo.phone_number || !userInfo.picker || !userInfo.city_id || userInfo.city_id == "-1")
      return;
    setIsDisabled(true)
    createSalesLeadApi(userInfo).then((response) => {
      showSuccess(Intl,lang)
      setIsDisabled(false)
      closeHandler()
    }).catch((error) => {
      setIsDisabled(false)
        const errorMessages = error?.response?.data;
        let errorLimit = ''
        if(errorMessages && errorMessages.length > 0) {
          for (let i = 0; i < errorMessages.length; i++) {
            if(errorMessages[i].field == 'uuid') {
              errorLimit = errorMessages[i].message
            }
            const element = errorMessages[i];
            setFieldErrors((prevState) => ({
              ...prevState,
              [element.field]: element.message,
            }))
          }
        }
        if(errorLimit){
          showError(Intl,lang, {message: errorLimit})
        }
    })
  }
  function backActionHandler(): void {
    setLearnMore(false);
    changeHeaderHandler(salesLead.headerContent.header)
  }

  const handleFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldErrors({...fieldErrors, full_name: ''})
    setUserInfo({...userInfo, full_name: e.target.value})
  }

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldErrors({...fieldErrors, phone_number: ''})
    setUserInfo({...userInfo, phone_number: e.target.value})
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldErrors({...fieldErrors, email: ''})
    setUserInfo({...userInfo, email: e.target.value})
  }


    return  <>
        <Modal id='iframe'tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered' show={show} onHide={closeHandler}  >
              <div className={`modal-header p-5 ${!salesLead ? 'justify-content-start' : ''}`}>
                    <div className="h1">
                        {learnMore && <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={backActionHandler}>
                            <div style={{display:"flex"}} className="align-self-center p-2">{lang === 'ar' ? <><i className="fas fs-2 fa-arrow-right"></i></> : <><i className="fas fs-2 fa-arrow-left"></i></>}</div>
                        </div>}
                        <span className='w-100 h-30px w-300px'>{header}</span>
                    </div>
                    {!salesLead || !header && <span className="w-100"><Skeleton className="h-30px w-300px justify-self-start"/></span>}
                    <div className='btn btn-sm btn-icon btn-active-color-primary justify-content-end justify-self-end' onClick={closeHandler}>
                        <KTSVG className='svg-icon-1 fa-5x' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
                {!learnMore &&<div className='d-flex flex-column align-items-start justify-content-center gap-5 modal-body'>
                {salesLead && <p onClick={() => {setLearnMore(true); setHeader('')}} className='text-decoration-underline fs-4 hoverPointer text-primary'>{salesLead.headerContent.link}</p>}
                {!salesLead && <Skeleton className="h-20px w-150px"/>}
                {salesLead && <span className="h1">{Intl.formatMessage({id: "advertising.contactUs"})}</span>}
                {!salesLead && <Skeleton className="h-45px w-100px"/>}
                {salesLead &&  <div className="w-100">
                  <input
                    type='text'
                    name='fullname'
                    value={userInfo.full_name}
                    className='form-control mb-2 w-100'
                    placeholder={salesLead.content.inputs.full_name.label}
                    onChange={(e) => {
                      handleFullNameChange(e)
                    }}
                    required
                    />
                        {fieldErrors.full_name && <span className='fv-plugins-message-container m-0 p-0 invalid-feedback'>
                        {fieldErrors.full_name}
                      </span>}
                    </div>}

                {!salesLead && <Skeleton className="h-45px w-350px"/>}
                {salesLead && <div className="w-100">
                 <input
                    type='text'
                    name='phoneNumber'
                    value={userInfo.phone_number}
                    className='form-control mb-2 w-100'
                    placeholder={salesLead.content.inputs.phone_number.label}
                    onChange={(e) => {
                      handlePhoneNumberChange(e)
                    }}
                    required    
                    />
                      {fieldErrors.phone_number && <div className='fv-plugins-message-container invalid-feedback'>
                      {fieldErrors.phone_number}
                    </div>}
                </div>}
                  {!salesLead && <Skeleton className="h-45px w-350px"/>}
                  {salesLead && <div className="w-100"> 
                  <input
                      type='text'
                      name='email'
                      value={userInfo.email}
                      className='form-control mb-2 w-100'
                      placeholder={salesLead.content.inputs.email.label}
                      onChange={(e) => {
                        handleEmailChange(e)
                      }}
                      />
                    {fieldErrors.email && <div className='fv-plugins-message-container invalid-feedback'>
                    {fieldErrors.email}
                    </div>}
                  </div>}
                    {!salesLead && <Skeleton className="h-45px w-350px"/>}
                    {salesLead && <select
                      value={userInfo.city_id || "-1"}
                      placeholder={salesLead.content.select_cities}
                      name="city"
                      className='form-select mb-2'
                      onChange={selectCityHandler}
                    > 
                      <option value="-1" disabled>{salesLead.content.select_cities}</option>
                      {salesLead && salesLead.cities &&
                        salesLead.cities.map((c, index) => {
                          return (
                            <option key={index} value={c.id}>
                              {c[lang]}
                            </option>
                          )
                        })}
                    </select>}
                    {!salesLead && <Skeleton className="h-45px w-350px"/>}
                      <div className='d-flex justify-content-between align-items-center w-100 gap-7' >
                      {salesLead?.content?.inputs?.picker?.options?.map((option, idx)=> {
                        return( 
                        <button key={idx}  className="btn d-flex  justify-content-start border px-10 py-3 w-50 gap-2" onClick={()=> setUserInfo({...userInfo, picker: option.value})}>
                          <input className="mt-1" type="radio" checked={userInfo.picker == option.value}/>
                          <span className=''>{option.label}</span>
                        </button>)
                      })} 
                      {!salesLead && <Skeleton className="h-45px w-100px mx-3"/>}
                      {!salesLead && <Skeleton className="h-45px w-100px"/>}
                      </div>
                          {salesLead && <Button disabled={!userInfo.full_name || !userInfo.phone_number|| !userInfo.picker || !userInfo.city_id || isDisabled} className='w-100 fw-bold' onClick={createSliderHandler}>{salesLead.content.submit_your_request} {isDisabled && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}</Button>}
                          {!salesLead && <Skeleton className="h-45px w-350px"/>}
                          {salesLead && <span className='h3 lined-text line-before-text line-after-text fw-normal w-100'>{salesLead.content.or}</span>}
                          {!salesLead && <Skeleton className="h-20px w-50px"/>}
                          {salesLead && <span className='h3 fw-bold'>{salesLead.content.have_questions}</span>}
                          {!salesLead && <Skeleton className="h-20px w-350px"/>}
                      <div className='d-flex gap-5 w-100'>
                          {salesLead && <a onClick={redirectionInterceptor} type="button" href={`${openSooqUrl}/sales-representatives`} target="_blank" className='btn btn-outline-secondary border border-primary text-primary fw-bold w-50' style={{WebkitAppearance: 'none', appearance: 'none', MozAppearance: 'none'}}><KTSVG path="/media/custom/person-blue.svg" className="svg-icon svg-icon-2 me-2" />{salesLead.contacts.sales_team.text}</a>}
                          {!salesLead && <Skeleton className="h-45px w-150px"/>}
                          {salesLead && <button type="button" onClick={()=>(whatsappForward(salesLead.contacts.whatsapp.phone))}  className='btn btn-success border border-success text-white fw-bold w-50'><KTSVG path="/media/svg/social-logos/whatsapp-white.svg" className="svg-icon svg-icon-2 me-2 text-hover-dark" />{salesLead.contacts.whatsapp.text}</button>}
                          {!salesLead && <Skeleton className="h-45px w-150px"/>}
                      </div>
                </div>}
                {learnMore && <LearnMore changeSubHeaderHandler={changeSubHeaderHandler} changeHeaderHandler={changeHeaderHandler} />}
        </Modal>
    </>
}

export {SliderModal}