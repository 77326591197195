import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from "react-intl"
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { ListingActionsIntegration } from '../../../../app/modules/listing/ListingActionsIntegration'
import { KTSVG } from '../../../helpers'

type Props = {
  show: boolean
  handleClose: () => void
  id ?: number | 0
  type?:string | ''
}

const IframeModal = ({show,handleClose,id,type}: Props) => {
    const Intl = useIntl();
    const [showDialog,setShowDialog] = useState(true);
    const [onLastPage,setOnLastPage] = useState(false);
    const navigate = useNavigate();
    const confirm = () => {
        if(showDialog){
            confirmPopup()
        }else{
            handleClose()
            if(onLastPage){
                const search = window.location.search;
                const params = new URLSearchParams(search);
                if(params.get('action') === 'edit'){
                    const listing_id = params.get('id')
                    if(listing_id != null){
                        window.location.replace(`/listings/my-listings/listing-view?id=${listing_id}`)
                    }else{ 
                        navigate('/error/404')
                    }
                }else{
                    window.location.reload();
                }
            }
        }
    }
    
    const confirmPopup = () => {
        const text = type == '' || !type ? Intl.formatMessage({id : "cancelAddingListConfirmation"}) : Intl.formatMessage({id : "cancelEditListConfirmation"})
        Swal.fire({
            icon: "warning",
            html:
            `<div class="pe-10 ps-10 fw-bolder">${text}</div>`,
            buttonsStyling: false,
            showCancelButton: true,
            cancelButtonText : Intl.formatMessage({id : 'no'}),
            showLoaderOnConfirm: true,
            confirmButtonText: Intl.formatMessage({id : 'yes'}),
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn btn-light-danger"
            },
            allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
            if(result.isConfirmed){
                if(type == '' || !type){
                    if(onLastPage){
                        const search = window.location.search;
                        const params = new URLSearchParams(search);
                        if(params.get('action') === 'edit'){
                            const listing_id = params.get('id')
                            if(listing_id != null){
                                window.location.replace(`/listings/my-listings/listing-view?id=${listing_id}`)
                            }else{ 
                                navigate('/error/404')
                            }
                        }else{
                            window.location.reload();
                        }
                    }
                }
                handleClose()
            }
        })
    }

    window.addEventListener("message", event => {
        if(event.data === 'with_confirm_dialog'){
            setShowDialog(true)
        }else if(event.data == 'without_confirm_dialog'){
            setShowDialog(true)
        }else if(event.data == 'refresh_page'){
            setShowDialog(false)
            setOnLastPage(true)
        }
    }, false);

    return <>
        <Modal id='iframe'tabIndex={-1} aria-hidden='true' dialogClassName={`modal-dialog modal-dialog-centered modal-fullscreen`} style={{height : "100%"}} show={show} onHide={confirm}  >
            <div className={`modal-header p-5 justify-content-end`}>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={confirm}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='h-100' style={{paddingBottom: "env(safe-area-inset-bottom)"}}>
                <ListingActionsIntegration id={id} type={type}/>
            </div>
        </Modal>
    </>
}

export { IframeModal }
