import { useIntl } from "react-intl"
import { useNavigate } from "react-router-dom"
import DynamicObject from "../../../../models/dynamic-object"
import { isDeviceMobile } from "../../../helpers/commonFunctions"

type Props = {
    cv : DynamicObject
}

const CvFooter = ({cv} : Props) => {
    const Intl = useIntl()
    const navigate = useNavigate();
    const editCvCtaName = cv.completion_score < 50 ? Intl.formatMessage({id : "cv.action.complete.cv"}) : Intl.formatMessage({id : "cv.action.edit.cv"})
    return <> <h2>{Intl.formatMessage({id : "cv.qsWidget.text1"})}</h2>
        <div className="d-flex flex-wrap mb-4 fs-5">
            <ul className="">
                <li className="fs-6 fw-semibold text-gray-700 text-muted">{Intl.formatMessage({id : "cv.qsWidget.text2"})}</li>
                <li className="fs-6 fw-semibold text-gray-700 text-muted">{Intl.formatMessage({id : "cv.qsWidget.text3"})}</li>
            </ul>
        </div>
        <div className="notice rounded border border-dashed p-6">
            <div className="d-flex flex-column mt-3 pb-5">
                <div className="py-1">
                    <span className="text-dark fs-1 fw-bold me-2">{cv.completion_score}%</span>
                    <span className="fw-semibold text-gray-800 fs-4">{Intl.formatMessage({id : "cv.qsWidget.text4"})} </span>
                </div>
                <div className="h-5pxw-100 bg-light mb-3">
                    <div className="bg-success rounded h-5px"  style={{width: `${cv.completion_score}%`}} ></div>
                </div>
            </div>
        </div>
        {cv && isDeviceMobile() && <div className="d-flex mt-5">
            <button className="btn btn-primary ltrDir" onClick={() => {navigate(`/account/cv/edit`)}}>{editCvCtaName}</button>
        </div>}
    </>
}

export default CvFooter