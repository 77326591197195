import { useIntl } from "react-intl"
import Skeleton from "react-loading-skeleton"
import { useNavigate } from "react-router-dom"
import DynamicObject from "../../../../models/dynamic-object"
import { PROMOTE } from "../../../helper/Ctas"
import { PointOfSales } from "../../../helper/PointOfSales"
import { WantMoreViews } from "../../common/WantMoreViews"

type Props = {
    cv : DynamicObject
}

const CvStatistics = ({cv} : Props) => {
    const Intl = useIntl()
    const navigate = useNavigate();
    return <><div className="card card-flush py-4 ">
        <div className="card-header">
            <div className="card-title align-items-start flex-column">
                <h2>{Intl.formatMessage({id : "myListingView.text41"})}</h2>
                <div className="d-flex flex-wrap fw-semibold mb-4 fs-5 text-gray-400">{Intl.formatMessage({id : "myListingView.text28"})}</div>
            </div>
        </div>
        <div className="card-body pt-0">
            <div className="row p-0 mb-5 px-9">
                <div className="col " >
                    <div  className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                        <span onClick={() => {navigate(`/account/cv/cv-stats?id=${cv.equivalent_post_id}`)}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted" >{!cv && <Skeleton className="w-50px h-20px"/>} {cv && cv.total_views}</span>
                        <span id="listingStatisticsView" className="fs-4 fw-semibold text-muted d-block">{Intl.formatMessage({id : "myListingView.text29"})} </span>
                    </div>
                </div>
                <div className="col ">
                    <div className="border border-dashed border-gray-300 text-center min-w-125px rounded pt-4 pb-2 my-3">
                        <span onClick={() => {navigate('/listings/demand/job-applications')}} className="hoverPointer fs-2hx fw-bold text-gray-900 counted" >{!cv && <Skeleton className="w-50px h-20px"/>} {cv && cv.number_of_job_applications}</span>
                        <span id="listingStatisticsCalls" className="fs-4 fw-semibold text-muted d-block">{Intl.formatMessage({id : "menu.listing.jobApplications"})} </span>
                    </div>
                </div>
            </div>
            {cv && cv.ctas && cv.ctas.find((cta :DynamicObject) => {return cta.cta == PROMOTE}) && <div className="card-footer">
                <WantMoreViews pointOfSale={PointOfSales.PROMOTE_CV_STATISTICS_CV_WIDGET_3} listingId={parseInt(cv.equivalent_post_id)} showPromote={cv && cv.ctas && cv.ctas.find((cta :DynamicObject) => {return cta.cta == PROMOTE})} extraButton={false ? Intl.formatMessage({id : "myListingView.text37"}) : ''}/>
            </div>}
        </div>
    </div>
    </>
}

export default CvStatistics

