import { AxiosResponse} from "axios";
import Api from '../../api'

export default (postId: number, filter?: string, sort?: string): Promise<AxiosResponse> => {
  const body = {
    "post_id": postId,
    "filter": filter,
    "sort": sort
  }
  const path = '/jobs/poster/applicants'
  return Api.getInstance(false,false,process.env.REACT_APP_API_SUB_URL).request.post(`${path}`,body, path)
}