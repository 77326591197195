import {useState, useEffect, useRef} from 'react'
import Datetime from 'react-datetime'
import {useIntl} from 'react-intl'
import moment from 'moment'
import 'react-datetime/css/react-datetime.css'
import {AddFieldType, RangeFieldI, RemoveFieldType} from '../../../../interfaces/MyListings'
import useFiltersStore from '../../my-listings/FilterStore'

const RangeDateFilter = ({
  rangeField,
  addField,
  removeField,
  reset,
}: {
  rangeField: RangeFieldI
  addField: AddFieldType
  removeField: RemoveFieldType
  reset: boolean
}) => {
  const {fieldId, label, type} = rangeField
  const intl = useIntl()
  const [showFilter, setShowFilter] = useState(false)
  const [fromValue, setFromValue] = useState<string | null>(null)
  const [toValue, setToValue] = useState<string | null>(null)
  const containerRef = useRef(null)
  const {globalFiltersDisable} = useFiltersStore()

  useEffect(() => {
    if (reset) {
      setFromValue(null)
      setToValue(null)
    }
  }, [reset])

  const toggleFilterOn = () => {
    setShowFilter((old) => !old)
  }

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowFilter(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const fromHandler = (e: moment.Moment) => {
    const startOfDay = e.startOf('day') // Set to the start of the day
    const dateValue = startOfDay.format('DD-MM-YYYY')
    const dateTimestamp = Math.floor(startOfDay.valueOf() / 1000)

    setFromValue(dateValue)

    if (!dateValue) {
      removeField(rangeField.fieldId, {value: dateTimestamp, keyName: 'from'}, 'RANGE')
    } else {
      addField(rangeField.fieldId, {value: dateTimestamp, keyName: 'from'}, 'RANGE')
    }
  }

  const toHandler = (e: moment.Moment) => {
    const endOfDay = e.endOf('day') // Set to the end of the day
    const dateValue = endOfDay.format('DD-MM-YYYY')
    const dateTimestamp = Math.ceil(endOfDay.valueOf() / 1000)

    setToValue(dateValue)

    if (!dateValue) {
      removeField(rangeField.fieldId, {value: dateTimestamp, keyName: 'to'}, 'RANGE')
    } else {
      addField(rangeField.fieldId, {value: dateTimestamp, keyName: 'to'}, 'RANGE')
    }
  }

  const getDisplayText = () => {
    return `${fromValue} ${toValue ? '/' : ''} ${toValue ? toValue : ''}`
  }

  const isValidDateFrom = (current: moment.Moment) => {
    const threeYearsAgo = moment().subtract(3, 'years')
    const today = moment().endOf('day')
    return (
      current.isAfter(threeYearsAgo) &&
      current.isSameOrBefore(toValue ? moment(toValue, 'DD-MM-YYYY') : today)
    )
  }

  const isValidDateTo = (current: moment.Moment) => {
    const threeYearsAgo = moment().subtract(3, 'years')
    const fromDate = fromValue ? moment(fromValue, 'DD-MM-YYYY') : threeYearsAgo
    return current.isSameOrAfter(fromDate) && current.isBefore(moment().endOf('day'))
  }

  const isRangePicked = toValue || fromValue

  return (
    <>
      <div
        data-id={fieldId}
        ref={containerRef}
        className={`form-control user-select-none d-flex flex-row justify-content-between align-items-center desktop-filter-field ${
          !showFilter && 'hoverPointer'
        } ${globalFiltersDisable ? 'hoverNotAllowed' : ''}`}
        onClick={() => {
          if (!globalFiltersDisable) toggleFilterOn()
        }}
      >
        <div
          className={`d-flex text-primary fs-7 `}
          style={{position: 'absolute', top: '2px', width: '100%'}}
        >
          {isRangePicked && label}
        </div>
        <div
          className={`d-flex flex-nowrap overflow-hidden fs-6 text-dark ${
            isRangePicked ? 'fw-bold' : 'fw-normal'
          }  ${globalFiltersDisable ? 'text-muted' : ''}`}
          style={{
            top: `${isRangePicked ? '5px' : '0px'}`,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '80%',
            position: 'relative',
          }}
        >
          {isRangePicked ? getDisplayText() : label}
        </div>
        <i className='fa fa-solid fa-angle-down fs-2' />
        {showFilter && (
          <div
            onClick={stopPropagation}
            style={{
              position: 'absolute',
              top: '100%',
              left: '0',
              border: '1px solid #ccc',
              backgroundColor: 'white',
              padding: '8px',
              borderRadius: '10px',
              marginTop: '4px',
              zIndex: 1000,
            }}
            className='d-flex flex-row justify-content-between'
          >
            <div className='px-2 py-5 d-flex flex-column'>
              <div className='fs-2 text-dark mb-3 fw-bold'>{label}</div>
              {/* From Date */}
              <div className='d-flex'>
                <div className='d-flex flex-column p-2' data-id={`${fieldId}-from`}>
                  <label className='fs-5 text-dark mb-2'>{rangeField?.minLabel}</label>
                  <Datetime
                    onChange={fromHandler}
                    value={fromValue ?? ''}
                    initialValue=''
                    closeOnSelect={true}
                    dateFormat={'DD-MM-YYYY'}
                    timeFormat={false}
                    isValidDate={isValidDateFrom}
                    inputProps={{
                      readOnly: true,
                      autoComplete: 'disabled',
                      name: 'input',
                      placeholder: intl.formatMessage({id: 'mylistings.date.placeholder'}),
                      disabled: false,
                      className: 'form-control fs-6 form-control-solid bg-white border-2 rounded-3',
                    }}
                  />
                </div>
                {/* Middle Point */}
                <div className='mt-12 fs-1 fw-normal text-dark'>-</div>
                {/* To Date */}
                <div className='d-flex flex-column p-2' data-id={`${fieldId}-to`}>
                  <label className='fs-5 text-dark mb-2'>{rangeField?.maxLabel}</label>
                  <Datetime
                    onChange={toHandler}
                    value={toValue ?? ''}
                    initialValue=''
                    closeOnSelect={true}
                    dateFormat={'DD-MM-YYYY'}
                    timeFormat={false}
                    timeConstraints={{}}
                    isValidDate={isValidDateTo}
                    inputProps={{
                      readOnly: true,
                      autoComplete: 'disabled',
                      name: 'input',
                      placeholder: intl.formatMessage({id: 'mylistings.date.placeholder'}),
                      disabled: false,
                      className: 'form-control fs-6 form-control-solid bg-white border-2 rounded-3',
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default RangeDateFilter
