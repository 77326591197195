import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import rechargeCardApi from '../../../../api/rechargeCard.api';
import { showSuccess } from '../../../helper/SystemAlert';
import { KTSVG } from "../../../helpers";
import { useLang } from '../../../i18n/OpenSooqi18n';
import { deepLinkHandler, deepLinks } from '../../../helpers/commonFunctions';

type Props = {
  show: boolean
  handleClose: () => void
}

const RechargeVoucherModal = ({show,handleClose}: Props) => {
    const closeHandler = () => {
        handleClose()
    }
    const [voucher, setVoucher] = useState<string>('')
    const [error, setError] = useState<boolean>(false)
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const Intl = useIntl();
    const lang = useLang();

    const recharge = () => {
        setError(false)
        setIsDisabled(true);
        const rechargeCodeString = voucher;
        let data = {
            "VoucherRedeemForm" : {
                "code" : rechargeCodeString, 
            }
        };

        rechargeCardApi(data).then((response) => {
            if(response.status == 200){
                showSuccess(Intl,lang)
                setIsDisabled(false);
            }else{
                setIsDisabled(false);
                setError(true)
            }
        }).catch(err => {
            setIsDisabled(false);
            setError(true)
        });
    };

    useEffect(() => {
        setVoucher('')
        setError(false)
        setIsDisabled(false)
    },[show])


    return  <>
        <Modal id='iframe'tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered' show={show} onHide={closeHandler}  >
                <div className='d-flex flex-column align-items-center justify-content-center gap-5 modal-body'>
                <div className='d-flex justify-content-between mt-2 w-100'>
                    <span className="h1">{Intl.formatMessage({id: "rechargeVoucher.title"})}</span>
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={closeHandler}>
                        <KTSVG className='svg-icon-1 fa-5x' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                </div>
               <p className='align-self-start'>{Intl.formatMessage({id: "rechargeVoucher.desc"})}</p>
               <p onClick={()=>deepLinkHandler(deepLinks.help[lang])} className='mb-n3 mt-n7 align-self-end text-decoration-underline fs-4 hoverPointer text-primary'>{Intl.formatMessage({id: "footer.help"})}{lang=="en"?'?':"؟"}</p>
               <input
                    type='text'
                    name='voucher'
                    className='form-control mb-2 w-100'
                    placeholder={Intl.formatMessage({id: "myWallet.text11"})}
                    onChange={(e) => {
                        setVoucher(e.target.value)
                    }}
                    />
                       {error && <div className="d-flex flex-column  text-danger fv-row fv-plugins-icon-container">
                    {Intl.formatMessage({id: 'myWallet.text13'})}
                </div>}
                        <Button className='w-100 fw-bold' disabled={voucher.length < 16 || isDisabled} onClick={recharge}>{Intl.formatMessage({id: "apply"})} {isDisabled && <span className="spinner-border spinner-border-sm align-middle ms-2"></span>}</Button>
                        <p className='align-self-start'>{Intl.formatMessage({id: "rechargeVoucher.terms1"})}<a className='text-decoration-underline' target='_blank' href={`https://jo.opensooq.com/${lang}/termOfUse`}>{Intl.formatMessage({id: "rechargeVoucher.terms2"})}</a></p>
                </div>
        </Modal>
    </>
}

export { RechargeVoucherModal };
