import React, {useState, useEffect, useRef} from 'react'
import Select, {SingleValue} from 'react-select'
import {useIntl} from 'react-intl'
import {AddFieldType, RangeFieldI, RemoveFieldType} from '../../../../interfaces/MyListings'
import {keepOnlyNumbers} from '../../../../helpers/commonFunctions'
import useFiltersStore from '../../my-listings/FilterStore'

interface Option {
  label: string
  value: number
}

const RangeFilter = ({
  data,
  addField,
  removeField,
  reset,
}: {
  data: RangeFieldI
  addField: AddFieldType
  removeField: RemoveFieldType
  reset: boolean
}) => {
  const {label, type, config} = data
  const intl = useIntl()
  const [showFilter, setShowFilter] = useState(false)
  const [fromValue, setFromValue] = useState<SingleValue<Option>>(null)
  const [toValue, setToValue] = useState<SingleValue<Option>>(null)
  const [toValues, setToValues] = useState<Option[]>([])
  const [fromValues, setFromValues] = useState<Option[]>([])
  const containerRef = useRef(null)
  const {globalFiltersDisable} = useFiltersStore()

  const generateOptions = (min: number, max: number, minPlaceHolder: string) => {
    const optionsMin = []
    const optionsMax = []
    for (let i = min; i <= max; i++) {
      optionsMin.unshift({label: i === min ? minPlaceHolder : i.toString(), value: i})
      optionsMax.unshift({label: i.toString(), value: i})
    }
    return {optionsMin, optionsMax}
  }

  useEffect(() => {
    if (!config || !config.min || !config.max) return
    const {optionsMin, optionsMax} = generateOptions(config.min, config.max, config.minPlaceHolder)
    setFromValues(optionsMin)
    setToValues(optionsMax)
  }, [config.min, config.max, config.minPlaceHolder])

  useEffect(() => {
    if (reset) {
      setFromValue(null)
      setToValue(null)
    }
  }, [reset])

  const toggleFilterOn = () => {
    setShowFilter((old) => !old)
  }

  const stopPropagation = (e) => {
    e.stopPropagation()
  }

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setShowFilter(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const fromHandler = (value: SingleValue<Option>) => {
    if (fromValue?.value === value.value) {
      setFromValue(null)
      return
    }
    setFromValue(value)
    addField(data.fieldId, {label: value.label, value: value?.value, keyName: 'from'}, 'RANGE')
  }

  const toHandler = (value: SingleValue<Option>) => {
    if (toValue?.value === value.value) {
      setToValue(null)
      return
    }
    setToValue(value)
    addField(data.fieldId, {label: value.label, value: value?.value, keyName: 'to'}, 'RANGE')
  }

  useEffect(() => {
    if (!fromValue) {
      setToValues(generateOptions(config.min, config.max, config.minPlaceHolder)?.optionsMax)
      return
    }
    setToValues(generateOptions(fromValue.value, config.max, config.minPlaceHolder)?.optionsMax)
  }, [fromValue, config.min, config.max, config.minPlaceHolder])

  useEffect(() => {
    if (!toValue) {
      setFromValues(generateOptions(config.min, config.max, config.minPlaceHolder)?.optionsMin)
      return
    }
    setFromValues(generateOptions(config.min, toValue.value, config.minPlaceHolder)?.optionsMin)
  }, [toValue, config.min, config.max, config.minPlaceHolder])

  const getDisplayText = () => {
    return `${fromValue?.label || ''} - ${toValue?.label || ''}`
  }

  const isRangePicked = toValue && fromValue

  return (
    <>
      <div
        ref={containerRef}
        className={`form-control user-select-none d-flex flex-row justify-content-between align-items-center desktop-filter-field ${
          !showFilter && 'hoverPointer'
        } ${globalFiltersDisable ? 'hoverNotAllowed' : ''}`}
        onClick={() => {
          if (!globalFiltersDisable) toggleFilterOn()
        }}
        data-id={data.fieldId}
      >
        <div
          className='d-flex text-primary fs-7'
          style={{position: 'absolute', top: '0px', width: '100%'}}
        >
          {isRangePicked && label}
        </div>
        <div
          className={`d-flex flex-nowrap overflow-hidden fs-6 text-dark ${
            isRangePicked ? 'fw-bold' : 'fw-normal'
          }  ${globalFiltersDisable ? 'text-muted' : ''}`}
          style={{
            top: `${isRangePicked ? '5px' : '0px'}`,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '80%',
            position: 'relative',
          }}
        >
          {isRangePicked ? getDisplayText() : label}
        </div>
        <i className='fa fa-solid fa-angle-down fs-2' />
        {showFilter && (
          <div
            onClick={stopPropagation}
            style={{
              width: '300px',
            }}
            className='d-flex flex-column range-filter-dropdown justify-content-between px-3 py-5'
          >
            {/* From Date */}
            <div className='fs-2 text-dark mb-3 fw-bold'>{label}</div>
            <div className='d-flex'>
              <div
                className='d-flex flex-column p-2'
                style={{width: '150px'}}
                data-id={`${data.fieldId}-from`}
              >
                <label className='fs-5 text-dark mb-2'>{data?.minLabel}</label>
                <Select
                  placeholder={intl.formatMessage({id: 'myListings.date.selectDate'})}
                  value={fromValue}
                  onChange={fromHandler}
                  options={fromValues}
                  className='mb-2'
                />
              </div>
              {/* Middle Point */}
              <div className='mt-10 fs-1 fw-normal text-dark'>-</div>
              {/* To Date */}
              <div
                className='d-flex flex-column p-2'
                style={{width: '150px'}}
                data-id={`${data.fieldId}-to`}
              >
                <label className='fs-5 text-dark mb-2'>{data?.maxLabel}</label>
                <Select
                  placeholder={intl.formatMessage({id: 'myListings.date.selectDate'})}
                  value={toValue}
                  onChange={toHandler}
                  options={toValues}
                  className='mb-2'
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default RangeFilter
