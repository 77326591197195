import { IntlShape } from "react-intl";
import { showError } from "../../helper/SystemAlert";

const handleError = (error : any, lang:string, Intl:IntlShape) =>{
    if(error && error.response && error.response.data){
      showError(Intl, lang, error.response.data);
    }
    else{
      showError(Intl, lang)
    }
  }

  export{handleError}