import Skeleton from 'react-loading-skeleton'

export default function DesktopListSkeleton() {
  return (
    <div className='d-flex flex-column gap-2 my-12'>
      <div className='row'>
        <div id='listing-info' className='col col-2-8 d-flex gap-2'>
          <div>
            <Skeleton width={80} height={80} className='rounded-3' />
          </div>
          <div className='d-flex flex-column gap-1 w-75'>
            <Skeleton width='100%' height={14} />
            <Skeleton width='100%' height={14} />
            <Skeleton width='100%' height={14} />
            <Skeleton width='25%' height={14} />
          </div>
        </div>
        <div id='listing-category' className='d-flex flex-column col col-1'>
          <Skeleton width='100%' height={20} />
          <Skeleton width='100%' height={20} />
        </div>
        <div id='listing-location' className='col col-1'>
          <Skeleton width='100%' height={20} />
          <Skeleton width='75%' height={20} />
        </div>
        <div id='listing-created' className='col col-1'>
          <Skeleton width='100%' height={20} />
          <Skeleton width='50%' height={20} />
        </div>
        <div id='listing-expires' className='col col-1'>
          <Skeleton width='100%' height={20} />
          <Skeleton width='50%' height={20} />
        </div>
        <div id='listing-quality' className='col col-0-7'>
          <Skeleton width='50%' height={20} />
        </div>
        <div className='row col col-1-6'>
          <div id='listing-views' className='col col-6'>
            <Skeleton width='50%' height={20} />
          </div>
          <div id='listing-leads' className='col col-6'>
            <Skeleton width='50%' height={20} />
          </div>
        </div>
        <div id='listing-leads-actions' className='col col-1-1 d-flex flex-column gap-5'>
          {Array(3)
            .fill(1)
            .map((_, index) => (
              <span key={index} className='d-flex gap-2'>
                <Skeleton width={18} height={18} />
                <Skeleton width={75} height={18} />
              </span>
            ))}
        </div>
        <div id='listing-actions' className='col col-1-25 d-flex flex-column gap-2 ms-5'>
          {Array(5)
            .fill(1)
            .map((_, index) => (
              <span key={index} className='d-flex gap-2'>
                <Skeleton width={18} height={18} />
                <Skeleton width={75} height={20} />
              </span>
            ))}
        </div>
      </div>
      <div className='d-flex gap-5 align-items-center py-2'>
        <div className='d-flex gap-3'>
          <Skeleton width={50} height={20} />
          <Skeleton width={75} height={20} />
        </div>
        <Skeleton width={115} height={20} />
        <div className='d-flex gap-2'>
          {Array(3)
            .fill(1)
            .map((_, index) => (
              <span key={index} className='d-flex'>
                <span>
                  <Skeleton width={15} height={20} className='mx-2' />
                </span>
                <Skeleton width={115} height={20} />
              </span>
            ))}
        </div>
      </div>
    </div>
  )
}
