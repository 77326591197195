import { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { toAbsoluteUrl } from "../../../app/helpers";
import { useLang } from "../../../app/i18n/OpenSooqi18n";
import { Button } from "../../../app/layout/components/opensooq/Button";
import { logOrderStartClick, logScreenLoad } from "../../../logging/helpers/commonLoggingFunctions";
import { generateOpenSooqRedirectUrl, getCookies, getSiteCustomContent, isDeviceMobile, redirectionInterceptor } from "../../helpers/commonFunctions";
import { MemberShipModal } from "../payment/MemberShipModal";
import getEnabledServicesApi from "../../../api/servicrs/get.enabledServices.api";
import { handleError } from "../common/HandleError";
import Skeleton from "react-loading-skeleton";

export function Product() {
    let navigate = useNavigate(); 
    const lang = useLang();
    const Intl = useIntl();
    document.title = Intl.formatMessage({id : "menu.products"})
    const [showBundle,setShowBundle] = useState (false);
    const [enabledServices, setEnabledServices] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const cookiesData = getCookies();
    const memberInfo = cookiesData.userInfo
    const adv = (lang === 'ar') ? 'الخدمات-الاعلانية' : 'advertising-products'
    const openSooqUrl =  generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE,cookiesData.ecountry,lang)
    const serviceUrl = (lang === 'ar') ? 'تقارير-المركبات' : 'car-reports'
    const buyCarReport =  openSooqUrl + "/"+ serviceUrl;

    useEffect(()=>{
        logScreenLoad()
        getEnabledServicesApi().then((response)=>{            
            setEnabledServices(response.data);            
        }).catch((err)=>{
            handleError(err, lang,Intl)
        }).finally(()=>{
            setIsLoading(false)
        })
    }, [])

    const servicesFormatter = (service) => {
        return service?.map((service) => {
            return service?.title
        }).join(", ")
    }

    const content = getSiteCustomContent(Intl);

    const showBundleModalHandler = useCallback(()=>{
        //logging
        logOrderStartClick()
        //
        setShowBundle(true)
    }, [])
    
    return (
        <div className="card ">
            <div className="card-header py-4">
                <div className="card-title align-items-start flex-column">
                    <h2>{Intl.formatMessage({id : "products.title"})}</h2>
                    <div className="d-flex flex-wrap fw-semibold  fs-5 text-gray-400">
                    {Intl.formatMessage({id : "products.description"})}
                    </div>
                </div>
            </div>
            <div className="card-body p-lg-12">
                <div className="row mb-5">
                    <div className="card card shadow-lg radius-8" data-theme="light" >
                        <div className="card-body d-flex ps-xl-8">
                            <div className="m-0">
                                <div className="position-relative fs-2x z-index-2   mb-7">
                                    <span className="position-relative d-inline-block text-dark">
                                        <span className=" opacity-75-hover fw-bold">{Intl.formatMessage({id : "products.carReports"})}</span>
                                    </span>
                                    <br/>
                                    {!isLoading ? <span className="fs-3 text-gray-400">{enabledServices.length ? servicesFormatter(enabledServices): ""}</span> : <Skeleton/>}
                                </div>
                                <div className="mb-3">
                                    <a href={buyCarReport} className="btn btn-primary fw-semibold me-2 border border-primary border-1" data-id="products-carReports-buyNowBtn">
                                        {Intl.formatMessage({id : "common.buyNow"})}
                                    </a>
                                    <Button action={() => { navigate(`/services/car-reports`)}} text={Intl.formatMessage({id : "common.viewPurchasedReports"})} className="btn btn-warning" setData="products-carReports-viewReportsBtn"/>
                                </div>
                            </div>
                            <img src={toAbsoluteUrl('/media/product/car-reports.png')} className={`position-absolute end-0 bottom-0 ${isMobile ? 'w-25' : 'w-200px'}`} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="card shadow-lg radius-8">
                        <div className="card-body d-flex">
                            <div className="m-0">
                                <div className="position-relative fs-2x z-index-2   mb-7">
                                    <span className="position-relative d-inline-block text-dark">
                                        <span className=" opacity-75-hover fw-bold">{Intl.formatMessage({id : "products.openSooqBuyer.section1"})}</span>
                                    </span>
                                    <br/>
                                    <span className="fs-3 text-gray-400">{content.sitePlusDesc}</span>
                                </div>
                                <div className="mb-3">
                                    <button onClick={showBundleModalHandler} className="btn btn-primary fw-semibold me-2" data-id="products-hidePromotionalListings-buyNowBtn">{Intl.formatMessage({id : "common.buyNow"})}</button>
                                </div>
                            </div>
                            <img src={toAbsoluteUrl('/media/product/no-ads.png')}  className={`position-absolute bottom-0 end-0 ${isMobile ? 'w-25' : 'w-200px'}`} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="card shadow-lg" >
                        <div className="card-body d-flex radius-8">
                            <div className="m-0">
                                <div className="position-relative fs-2x z-index-2 mb-7">
                                    <span className="position-relative d-inline-block ">
                                        <a onClick={redirectionInterceptor} href={`${openSooqUrl}/${adv}`}  className="fw-bold text-dark" data-id="products-opensooqVAS-titleAction">{content.serviceValue}</a>
                                    </span>
                                    <br/>
                                    <span className="me-2 text-gray-400 fs-3">{Intl.formatMessage({id : "products.ValueService.section1"})}</span>
                                    <br/>
                                    <span className="text-gray-400 fs-3">{Intl.formatMessage({id : "products.ValueService.section2"})}</span>
                                </div>
                                <div className="mb-3">
                                    <a onClick={redirectionInterceptor} href={`${openSooqUrl}/${adv}`} className="btn btn-primary fw-semibold me-2" data-id="products-opensooqVAS-viewNowBtn">{Intl.formatMessage({id : "common.viewNow"})}</a>
                                </div>
                            </div>
                            <img src={toAbsoluteUrl('/media/product/service-value.png')} className={`position-absolute end-0 ${isDeviceMobile() ? 'w-25 bottom-0' : 'w-200px'}`} alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <MemberShipModal show={showBundle} type={['ProBuyer']} handleClose={() => {setShowBundle(false)}} id={memberInfo.id} pos={'products'} isProBuyer={true}/>
        </div>
    )
}