export interface DocsInterface {
    id          :   number
    media_type  :   number 
    member_id   :   number 
    mime_type   :   string
    uri         :   string 
}


export interface DocTypeInterface{
    key: number, 
    label: string, 
    expiry_date_enabled: boolean
}

export interface DocTabInterface extends DocsInterface {
        status: number,
        expire_date: string,
        document_type: number
}

export enum DocStatus {
    DISABLE = 0,
    BUILDIN = 3,
    ACTIVE  = 1,
    DELETE  = 2,
    PENDING = 4,
    NEW    = 5,
    EXPIRED = 6,
    BLOCKED = 7
}
