import { useCallback, useContext, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { MemberShipModal } from "../../payment/MemberShipModal";
import Skeleton from "react-loading-skeleton";
import { useLang } from "../../../i18n/OpenSooqi18n";
import { toAbsoluteUrl } from "../../../helpers";
import getAccountSummaryApi from "../../../../api/account/getAccountSummary.api";
import { AccountSummaryInterface  } from "../../../interfaces/AccountSummary";
import { PointOfSales } from "../../../helper/PointOfSales";
import { AuthContext } from "../../../i18n/AuthProvider";
import { MemberInfoInterface } from "../../../interfaces/MemberInfoInterface";

interface Props{
    accountFeatures : MemberInfoInterface
}

export const MEMBER_TYPE_ENUM = {
    REGULAR : 0,
    SHOP : 1
}


export function AccountSummary({accountFeatures}: Props) {
    const [showBundle,setShowBundle] = useState (false);
    const Intl = useIntl();
    const lang = useLang();
    const paidTypes = ['silver', 'gold', 'shop'];
    const isFreeMemberShip = accountFeatures && accountFeatures.limit_account_report && (paidTypes.find((type) => type === accountFeatures.limit_account_report.membership_type.toLowerCase() ) === undefined);
    const isRegularMembership = accountFeatures && accountFeatures.member_basic && accountFeatures.member_basic.member_type ? accountFeatures.member_basic.member_type === MEMBER_TYPE_ENUM.REGULAR : true;
    const [accountSummary, setAccountSmmary] = useState<AccountSummaryInterface>();
    const {userIdentity} = useContext(AuthContext);

    useEffect(()=>{
        getAccountSummaryApi().then((response)=> {
            setAccountSmmary(response.data);
        }).catch((err)=>{
            console.error(err);
        })
    }, [])

    const showBundleModalHandler = useCallback(()=>{
        setShowBundle(true)
    }, [])
    
    return (<div className="mb-10">{ (!isRegularMembership && (accountFeatures && accountFeatures.limit_account_report && accountFeatures.limit_account_report.near_expire) ) && <div className="card mb-5 mb-xl-8">
            <div className="card-header p-3 d-flex flex-row justify-content-between hoverPointer" onClick={showBundleModalHandler}>
                <div className="d-flex flex-row">
                        <img className="px-4 py-3" src={toAbsoluteUrl(`/media/custom/account_summary_shop.svg`)} alt=""></img>
                    <div className="d-flex flex-column justify-content-center">
                        {!accountFeatures ? <div className="w-100 "><Skeleton className="w-100 h-5px w-100 bg-light rounded h-5px" /></div> : <div className="fs-4 text-black">{Intl.formatMessage({id: 'accountSummary.membership'})}</div>}
                        {!accountFeatures ? <div className="w-100 "><Skeleton className="w-100 h-5px w-100 bg-light rounded h-5px" /></div> : <div className="fs-6 text-gray-400">{`${accountFeatures.limit_account_report.membership_label} ${accountFeatures.limit_account_report.listing_limit} ${Intl.formatMessage({id: 'accountSummary.for'})} ${accountFeatures.limit_account_report.duration} ${Intl.formatMessage({id: 'accountSummary.days'})}`}</div>}
                        {!accountFeatures ? <div className="w-100 "><Skeleton className="w-100 h-5px w-100 bg-light rounded h-5px" /></div> : <div className="fs-6" style={{color: 'red'}}>{`${Intl.formatMessage({id: 'accountSummary.expires'})} ${accountFeatures.limit_account_report.expiration_date}`}</div>}    
                    </div>
                </div>
                <button className="btn btn-sm m-0 px-1" >
                {lang === 'ar' ? <i className="fa-solid fa-chevron-left fs-3"></i> :  <i className="fa-solid fa-chevron-right fs-3"></i> }
                </button>
            </div>
        </div>}   
        <div className="card mb-xl-8">
            <div className="card-header">
                <h3 className="card-title align-items-start flex-column">
                    <span  className="card-label fw-bold text-dark">{Intl.formatMessage({id: 'accountSummary.accountSummary'})}</span> 
                </h3>
            </div>
            <div className="card-body" data-id="account-summary-block">
                <div className="d-flex align-items-center justify-content-center flex-column">
                    <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                        <span className="fw-semibold fs-6 text-gray-400">{Intl.formatMessage({id: 'accountSummary.accountCompleteness'})}</span>
                        {accountFeatures && accountFeatures.member_completion_rate && <span  className="fw-bold fs-6">{accountFeatures.member_completion_rate.toFixed(1)}%</span>}
                        { (!accountFeatures || !accountFeatures.member_completion_rate) && <span  className="fw-bold fs-6"> <Skeleton className="w-100px" /></span>} 
                    </div>
                    {accountFeatures && accountFeatures.member_completion_rate && <div className="h-5 mx-3 w-100 bg-light mb-3">
                        <div className="bg-success rounded h-5px fw-bold" style={{width: `${accountFeatures.member_completion_rate.toFixed(1)}%`}}></div>
                    </div>}
                    { (!accountFeatures || !accountFeatures.member_completion_rate) && <div className="w-100 fw-bold"><Skeleton className="w-100 h-5px w-100 bg-light rounded h-5px" /></div>}
                </div>
                <table className="table fs-5 dataTable"  id="kt_table_widget_4_table">
                    <tbody className="text-gray-600">
                        <tr className="odd w-100 border-bottom border-dashed">
                            <td className="text-start">
                                <span className="text-gray-800">{Intl.formatMessage({id: 'accountSummary.liveListings'})}</span>
                            </td>
                            <td className="text-end">
                                <span className="text-gray-800 fw-bold"> {accountFeatures && accountFeatures.limit_account_report ? accountFeatures.limit_account_report.listing_live_count : <Skeleton className="w-100"/>}</span>
                            </td>
                        </tr>
                        <tr className="odd border-bottom border-dashed">
                            <td>
                                <span className="text-gray-800">{Intl.formatMessage({id: 'accountSummary.remainaingListings'})}</span>
                            </td>
                            <td className="text-end">
                                <span className="text-gray-800 fw-bold"> {accountFeatures && accountFeatures.limit_account_report ? accountFeatures.limit_account_report.listing_remaining_count : <Skeleton className="w-100"/>}</span>
                            </td>
                        </tr>
                        <tr className="odd">
                            <td>
                                <span className="text-gray-800 ">{Intl.formatMessage({id: 'accountSummary.paidListings'})}</span>
                            </td>
                            <td className="text-end">
                                <span className="text-gray-800 fw-bold"> {accountFeatures && accountFeatures.limit_account_report ? accountFeatures.limit_account_report.listing_paid_count : <Skeleton className="w-100"/>}</span>
                            </td>
                        </tr>
                        <tr className="even">
                            <td>
                            </td>
                        </tr>
                        {   !isFreeMemberShip &&<>
                            <tr className="even">
                                <td>
                                    <div className="fw-bold text-gray-800 ">{Intl.formatMessage({id: 'accountSummary.membership'})}</div>
                                    <a data-id='account-summary-membershipBenefitsBtn' className="fw-bold text-decoration-underline" onClick={showBundleModalHandler}>{Intl.formatMessage({id: 'accountSummary.benefits'})}</a>
                                </td>
                            </tr> 
                            <tr className="even border-bottom border-dashed">
                                <td>
                                    <span className="text-gray-800 ">{Intl.formatMessage({id: 'accountSummary.type'})}</span>
                                </td>
                                <td className="text-end">
                                    <span className="text-gray-800 fw-bold"> {accountFeatures && accountFeatures.limit_account_report ? accountFeatures.limit_account_report.membership_label : <Skeleton className="w-100"/>}</span>
                                </td >
                            </tr>
                            <tr className="odd border-bottom border-dashed">
                                <td>
                                    <span className="text-gray-800 ">{Intl.formatMessage({id: 'accountSummary.numberOfListings'})}</span>
                                </td>
                                <td className="text-end">
                                    <span className="text-gray-800 fw-bold"> {accountFeatures && accountFeatures.limit_account_report ? accountFeatures.limit_account_report.listing_limit : <Skeleton className="w-100"/>}</span>
                                </td>
                            </tr>
                            <tr className="odd border-bottom border-dashed">
                                <td>
                                    <span className="text-gray-800 ">{Intl.formatMessage({id: 'accountSummary.startDate'})}</span>
                                </td>
                                <td className="text-end">
                                    <span className="text-gray-800 fw-bold">{accountFeatures && accountFeatures.limit_account_report ? accountFeatures.limit_account_report.start_date : <Skeleton className="w-50"/>}</span>
                                </td>
                            </tr>
                            <tr className="odd border-bottom border-dashed">
                                <td>
                                    <span className="text-gray-800 ">{Intl.formatMessage({id: 'accountSummary.endDate'})}</span>
                                </td>
                                <td className="text-end">
                                    <span className="text-gray-800 fw-bold">{accountFeatures && accountFeatures.limit_account_report ? accountFeatures.limit_account_report.expiration_date : <Skeleton className="w-50"/>}</span>
                                </td>
                            </tr>
                        </>
                        }
                    </tbody>
                </table>
                {accountFeatures && <div className="mt-0">
                <button onClick={showBundleModalHandler} className="btn btn-primary btn-lg" data-id="account-summary-getMoreListingsBtn" >
                    <i className="fa-solid fs-3 fa-store"></i>
                    <span className="fw-bold">{Intl.formatMessage({id: "notFound.shop.button"})}</span>
                </button>
            </div>}
            </div>
            {accountFeatures && accountSummary && <MemberShipModal  show={showBundle}  sku={accountSummary.membership_tier ?? ''} type={['MemberShip']} handleClose={() => {setShowBundle(false)}} id={accountFeatures.member_basic.id} pos={PointOfSales.MYACCOUNT_ACCOUNTSUMMARY_WIDGET_UPGRADE_MEMBERSHIP_BUTTON} widgetName="account_overview"/>}
        </div>
    </div>)
}