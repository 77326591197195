import {create} from 'zustand'

type AccountOwnerI = {
  opensooq_member_id: number
  profile_picture: string
  company_mobile: string
  account_owner: string
} | null

type State = {
  accountOwner: AccountOwnerI
}

type Actions = {
  setAccountOwner: (accountOwner: AccountOwnerI) => void
}

const useAccountOwnerStore = create<State & Actions>((set, get, state) => ({
  accountOwner: null,
  setAccountOwner: (accountOwner) => set({accountOwner}),
}))

export default useAccountOwnerStore
