import DynamicObject from "../../models/dynamic-object";
import { AxiosResponse} from "axios";
import Api from "../api";

export default (url: string, type : string,config?: DynamicObject): Promise<AxiosResponse> => {
    let newUrl = url.replace('%2C',",")
    const fullUrl = newUrl.replace('http:',"https:")
    const path = 'car-report';
    const apiUrl : string = (url === '') ? `${path}?page=1&per-page=30&providers=${type}` : fullUrl
    return Api.getInstance().request.get(apiUrl, path)
}