import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useEffect, useRef } from 'react';
import { useLang } from '../../../i18n/OpenSooqi18n';
import { ILocation } from '../../../interfaces/ProfileInterface';


type MarkerProps = {
    lat? : number,
    lng? : number
    onLocationPick: (location : ILocation) => void 
}

const Place = (marker:MarkerProps)=>{
    const {lat, lng, onLocationPick} = marker;
    return (
        <Marker
          key={`${lat * lng}`}
          position={{
            lat: lat,
            lng: lng
          }}
          draggable={true}
          onDragEnd={(e)=>{
            const {lat, lng} = e.latLng.toJSON()
            onLocationPick({lat : lat, lng : lng});
          }}
        />
    )
}

type MapWidgetProps = {
  onLocationPick : (location : ILocation) => void 
  location : ILocation
}
const MapWidget = ({onLocationPick, location}: MapWidgetProps) => {
    const mapRef = useRef();
    const lang = useLang();
    
    const defaultProps = {
        center: {
          lat: location && location.lat ? location.lat : 0,
          lng: location && location.lng ? location.lng : 0,
        },
        zoom: 15
    };


    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_PUBLIC_MAP_KEY,
        language: lang,
    });

    const onMapLoad = map => {
        mapRef.current = map;
    }


    const mapContainerStyle = {
        height: "50vh",
        width: "100%",
        border: 'solid rounded',
        borderRadius: '5px'
      };
      const scrollTo = new URLSearchParams(document.location.search)?.get('scrollTo') ?? '';
      const elementRef = useRef(null);
      useEffect(() => {
        if(elementRef?.current &&  scrollTo == "shopLocation"){
            elementRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }, [isLoaded])
    
    return <>
        {!isLoaded &&  <></>}
        { isLoaded &&
          <div tabIndex={-1} aria-hidden='true' style={{overflowY:"scroll"}} ref={elementRef} >
            <GoogleMap
            id='map'
            center={defaultProps.center}
            zoom={defaultProps.zoom}
            mapContainerStyle={mapContainerStyle}
            onLoad={onMapLoad}
            options={{
                draggable: true,
                scrollwheel: false,
                keyboardShortcuts: false,
                mapTypeControl: false, 
                fullscreenControl: false,
            }}
            >
                <Place lat={defaultProps.center.lat} lng={defaultProps.center.lng} onLocationPick={onLocationPick}/>
            </GoogleMap>
          </div>
        }
      </>
}

export { MapWidget };
