import Skeleton from 'react-loading-skeleton'
import {isDeviceMobile} from '../../../../../helpers/commonFunctions'

const AmountDueSkeleton = ({handleClose}) => {
  const isMobile = isDeviceMobile()
  return (
    <>
      {isMobile ? (
        <>
          <div className='d-flex flex-column gap-5'>
            <div className='w-100 d-flex justify-content-between'>
              <Skeleton height={32} width={135} />
              <span
                className='btn btn-sm btn-icon btn-active-color-primary text-end'
                onClick={handleClose}
              >
                <span className='fa fa-close fs-1'></span>
              </span>
            </div>
            <Skeleton height={25} />
            <Skeleton height={36} className='px-3' />
            <Skeleton height={61} />
            <Skeleton height={61} />
            <Skeleton height={61} />
            <Skeleton height={58} />
            <Skeleton height={58} />
            <Skeleton height={58} />
            <Skeleton height={58} />
            <Skeleton height={58} />
          </div>
          <div className='mt-auto'>
            <Skeleton height={67} />
          </div>
        </>
      ) : (
        <div className='d-flex flex-column'>
          <div className='w-100 d-flex justify-content-between'>
            <Skeleton height={35} width={135} />
            <span
              className='btn btn-sm btn-icon btn-active-color-primary text-end'
              onClick={handleClose}
            >
              <span className='fa fa-close fs-1'></span>
            </span>
          </div>
          <Skeleton height={26} width={289} className='mt-5' />
          <Skeleton height={38} width={435} className='mt-5' />
          <Skeleton height={61} width={435} className='mt-5' />
          <Skeleton height={61} width={435} className='mt-5' />
          <Skeleton height={61} width={435} className='mt-5' />
          <Skeleton height={58} width={435} className='mt-5' />
        </div>
      )}
    </>
  )
}

export default AmountDueSkeleton
