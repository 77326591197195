import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CreditCellSkeleton = ({width, idx}: {width: number; idx: number}) => {
  return (
    <div
      key={idx*Math.random()}
      className='border-2 m-2 border px-3 py-2'
      style={{width: `${width}%`, borderRadius: '8px', height: '70px'}}
    >
      <div className='fs-3 fw-bold'>
        <Skeleton width={155} height={20} />
      </div>
      <div className='fs-4'>
        <Skeleton width={155} height={20} />
      </div>
    </div>
  )
}

export default CreditCellSkeleton
