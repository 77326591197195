import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { DeactivateAccountConfirmationPopup } from "../../layout/components/modals/DeactivateAccountConfirmationPopup";
import validateAccountPasswordApi from "../../../api/account/validateAccountPassword.api";
import { handleError } from "../common/HandleError";
import { useLang } from "../../i18n/OpenSooqi18n";
import { Loader } from "../../layout/components/opensooq/Loader";

const DeactivateAccount = () => {
    const [showPasswordPopup,setShowPasswordPopup] = useState(false);
    const [showConfirmationPopup,setShowConfirmationPopup] = useState(false);
    const [invalidPassword,setInvalidPassword] = useState<boolean>(false);
    const [isNextButtonDisabled,setIsNextButtonDisabled] = useState<boolean>(false);
    const [showPasswordDiv,setShowPasswordDiv] = useState<boolean>(false);
    const [loading,setLoading] = useState<boolean>(false);
    const [password, setPassword] = useState<string>('')
    const [passwordErrorMsg, setPasswordErrorMsg] = useState<string>('')
    const [actionStep, setActionStep] = useState<number>(0)
    const Intl = useIntl();
    const Lang = useLang();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newPassword = e.target.value
        setPassword(newPassword)
        checkIfPasswordIsValidPass(newPassword)
    }

    const checkIfPasswordIsValidPass = (password: string) => {
        if (password.length >= 1) {
            setIsNextButtonDisabled(false)
            setInvalidPassword(false)
        } else {
            setIsNextButtonDisabled(true)
            setInvalidPassword(true)
            setPasswordErrorMsg(Intl.formatMessage({id : "account.settings.passwordInput.empty"}))
        }
    }

    const showInvalidPassword = (message : string) => {
        setInvalidPassword(true)
        setPasswordErrorMsg(message)
    }

    const closePopup = () => {
        setShowConfirmationPopup(false)
        setInvalidPassword(false)
        setPasswordErrorMsg('')
        setPassword('')
        setIsNextButtonDisabled(true)
    }

    const handleAction = () => {
        if(actionStep == 0){
            setShowPasswordDiv(true);
            setIsNextButtonDisabled(true);
            setActionStep(1);
        }else{
            setShowConfirmationPopup(true)
        }
    }

    return <><div className="card">
            <div className="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_deactivate" aria-expanded="true" aria-controls="kt_account_deactivate">
                <div className="card-title m-0">
                    <h3 className="fw-bold m-0">{Intl.formatMessage({id:"account.settings.deactivateAccount.header"})}</h3>
                </div>
            </div>
            <div id="kt_account_settings_deactivate" className="collapse show">
                <div className="card-body border-top">
                    <div className="d-flex flex-stack flex-grow-1">
                        <div className="fw-semibold">
                            <div className="fs-4">{Intl.formatMessage({id:"account.settings.deactivateAccount.body"})}</div> 
                        </div>
                    </div>
                    {showPasswordDiv && <div className="mt-10">
                        <label  className="form-label">{Intl.formatMessage({id : "account.settings.passwordPopup.input.label"})}</label>
                        <input type="password" value={password} onChange={handleChange} className="form-control w-50 bg-light" placeholder={Intl.formatMessage({id: 'account.settings.passwordPopup.input.text'})}/>
                        {invalidPassword && <div id="passwordHelp" className="form-text text-danger">{passwordErrorMsg}</div>}
                    </div>}
                </div>
                <div className="card-footer d-flex justify-content-start py-6 px-9">
                    {!loading && <button id="kt_account_deactivate_account_submit" type="button" disabled={isNextButtonDisabled} onClick={handleAction} className="btn btn-danger fw-semibold">{Intl.formatMessage({id:"account.settings.deactivateAccount.button"})}</button>}
                    {loading && <Loader/>}
                </div>
            </div>
        </div>
        <DeactivateAccountConfirmationPopup password={password} show={showConfirmationPopup} handleClose={closePopup} showInvalidPassword={showInvalidPassword} handleCloseOnApi={() => {setShowConfirmationPopup(false)}}/>
    </>
}

export default DeactivateAccount;