import React, {useState, useEffect, useRef, useCallback} from 'react'
import {
  AddFieldType,
  CityFieldI,
  CityItemI,
  PerPageFieldI,
  PerPageItemI,
  RemoveFieldType,
  SortFieldI,
  SortItemI,
} from '../../../../interfaces/MyListings'
import fieldOptionsApi from '../../../../../api/newMyListings/fieldOptions.api'
import Skeleton from 'react-loading-skeleton'
import {useIntl} from 'react-intl'
import {useLang} from '../../../../i18n/OpenSooqi18n'
import {useLocation} from 'react-router-dom'
import useFiltersStore from '../../my-listings/FilterStore'

type FieldTypes = CityFieldI | PerPageFieldI | SortFieldI
type ItemTypes = CityItemI | PerPageItemI | SortItemI

type SingleSelectProps = {
  fields: FieldTypes
  enableChildField?: (option: ItemTypes | undefined) => void
  addField: AddFieldType
  removeField: RemoveFieldType
  catReportingName?: string
  subCatReportingName?: string
  reset: boolean
}

const SingleSelect = ({
  fields,
  enableChildField,
  addField,
  removeField,
  catReportingName,
  subCatReportingName,
  reset,
}: SingleSelectProps) => {
  const {fieldId, label, items, type, icon} = fields
  const [options, setOptions] = useState<ItemTypes[]>(items || []) // Explicitly define options as ItemTypes array
  const [showOptions, setShowOptions] = useState(false)
  const [selectedOption, setSelectedOption] = useState<ItemTypes | null>(null)
  const [hoveredOption, setHoveredOption] = useState<ItemTypes | null>(null)
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [filteredOptions, setFilteredOptions] = useState<ItemTypes[]>(options)
  const containerRef = useRef<HTMLDivElement>(null)
  const displayTextRef = useRef<HTMLSpanElement>(null)
  const intl = useIntl()
  const lang = useLang()
  const direction = lang === 'ar' ? 'rtl' : 'ltr' // Adjust this as per your locale detection
  const location = useLocation()
  const {globalFiltersDisable} = useFiltersStore()

  // useEffect(()=>{
  //   if(!(type === 'perPage' || type === 'cityField') && !subCatReportingName)
  //     return;
  //   if(!fields)
  //     return;
  //   const searchParams = new URLSearchParams(location.search);
  //   const selectedOptionFromUrl = searchParams.get(fieldId);
  //   if(selectedOptionFromUrl)
  //   {
  //     if(!options.length){
  //       fieldOptionsApi(fieldId ?? null, subCatReportingName?? null).then((res) => {
  //         let ops = [ ...res?.data];
  //         const selectedOp = ops.find((op) => op?.value.toString() === selectedOptionFromUrl);
  //         if(!selectedOp)
  //           return;
  //         selectOption(selectedOp);
  //       });
  //       return;
  //     }
  //     const selectedOp = options.find((op) => op?.value.toString() === selectedOptionFromUrl);
  //     selectOption(selectedOp);
  //   }
  // }, [fields, subCatReportingName])

  useEffect(() => {
    if (reset) {
      setSelectedOption(null)
    } else if (!reset) {
      initiateFilter()
    }
  }, [reset])

  useEffect(() => {
    if (!enableChildField || !options) return
    if (selectedOption !== null) {
      const selected = options.find(
        (option: ItemTypes) => option?.value.toString() === selectedOption.value.toString()
      )
      enableChildField(selected)
    } else {
      enableChildField(undefined)
    }
  }, [selectedOption, enableChildField, options])

  const toggleOptions = () => {
    setShowOptions((old) => {
      if (!old && !items) {
        setOptions([])
        setFilteredOptions([])
        fieldOptionsApi(fieldId, subCatReportingName || catReportingName || '').then((res) => {
          setOptions(res.data)
          setFilteredOptions(res.data)
        })
      } else {
        setOptions(items || [])
        setFilteredOptions(items || [])
      }
      return !old
    })
  }

  const initiateFilter = () => {
    // const searchParams = new URLSearchParams(location.search);
    // const selectedOptionFromUrl = searchParams.get(fieldId);
    // if(selectedOptionFromUrl)
    //   return;
    const isDynamicOptions = type === 'sortField' && !items && !selectedOption
    if (isDynamicOptions) {
      fieldOptionsApi(fieldId || null).then((res) => {
        selectOption(res?.data[0], true)
      })
    } else if (type === 'perPage' && items) {
      selectOption(items[0], true)
    }
  }

  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation()
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
      setShowOptions(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const selectOption = (option: ItemTypes, isDefaultValue: boolean = false) => {
    if (selectedOption && type === 'cityField')
      removeField(fieldId, {value: selectedOption.value}, 'SINGLE')
    if (selectedOption?.value?.toString() === option.value.toString()) {
      if (type === 'sortField' || type === 'perPage') return
      setSelectedOption(null)
    } else {
      setSelectedOption(option)
      addField(fieldId, {value: option.value, label: label, parentid: '0'}, 'SINGLE', true, isDefaultValue)
    }
    setShowOptions(false)
  }

  const handleCategoryHover = (e: React.MouseEvent, option: ItemTypes) => {
    setHoveredOption(option) // Convert option.value to number
  }

  const handleCategoryLeaveHover = (e: React.MouseEvent) => {
    setHoveredOption(null)
  }

  const getDisplayText = () => {
    if (selectedOption === null) {
      return label
    }
    return selectedOption.label.toString() // Ensure the display text is a string
  }

  const loader = useCallback(() => {
    const skeletons: JSX.Element[] = []
    for (let index = 0; index < 6; index++) {
      const element = <Skeleton width={100} height={20} className='m-3' />
      skeletons.push(element)
    }
    return skeletons
  }, [])

  useEffect(() => {
    if (searchQuery) {
      setFilteredOptions(
        options.filter((option: CityItemI) =>
          option.autoComplete.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    } else {
      setFilteredOptions(options)
    }
  }, [searchQuery, options])

  return (
    <div
      key={fieldId}
      data-id={fieldId}
      ref={containerRef}
      className={`form-control user-select-none desktop-filter-field  ${
        !showOptions && 'hoverPointer'
      } ${globalFiltersDisable ? 'hoverNotAllowed' : ''}`}
      onClick={() => {
        if (!globalFiltersDisable) toggleOptions()
      }}
    >
      <div className='d-flex align-items-center pt-1' style={{position: 'relative', width: '100%'}}>
        <div
          className='d-flex text-primary fs-7'
          style={{position: 'absolute', top: '-8px', width: '100%'}}
        >
          {selectedOption !== null && label}
        </div>
        <div
          className={`d-flex flex-nowrap overflow-hidden fs-6 text-dark pt-1 ${
            selectedOption !== null ? 'fw-bold' : 'fw-normal'
          }  ${globalFiltersDisable ? 'text-muted fw-normal' : ''}`}
          style={{
            top: `${selectedOption !== null ? '5px' : '0px'}`,
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '80%',
            position: 'relative',
          }}
        >
          {selectedOption !== null ? getDisplayText() : label}
        </div>
        <span
          ref={displayTextRef}
          style={{position: 'absolute', visibility: 'hidden', whiteSpace: 'nowrap'}}
        ></span>
        <i
          className='fa fa-solid fa-angle-down fs-2'
          style={{
            position: 'absolute',
            right: direction === 'rtl' ? 'unset' : '0px',
            left: direction === 'rtl' ? '0px' : 'unset',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      </div>
      {showOptions && (
        <div
          onClick={stopPropagation}
          style={{
            position: 'absolute',
            top: '100%',
            left: '0',
            border: '1px solid #ccc',
            backgroundColor: 'white',
            padding: '8px',
            borderRadius: '10px',
            marginTop: '4px',
            zIndex: 1000,
            width: '100%',
          }}
        >
          {options && options[0] && (options[0] as CityItemI)?.autoComplete && (
            <input
              type='text'
              className='form-control mb-2'
              placeholder={intl.formatMessage({id: 'mylistings.filters.search'})}
              value={searchQuery}
              data-id={`search-${fieldId}`}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          )}
          <div className='`' style={{maxHeight: '250px', overflowY: 'auto'}}>
            {filteredOptions &&
              filteredOptions.map((option, index) => {
                const isHovered = hoveredOption?.value.toString() === option.value.toString()
                const isSelected = selectedOption?.value.toString() === option.value.toString()
                const bgColor =
                  (isSelected && !hoveredOption) || isHovered ? '#0179FF12' : 'transparent'
                return (
                  <div
                    key={index}
                    data-id={option.value}
                    className='d-flex align-items-center p-2 my-2'
                    style={{cursor: 'pointer', borderRadius: '15px', backgroundColor: bgColor}}
                    onClick={() => selectOption(option)}
                    onMouseEnter={(e) => {
                      handleCategoryHover(e, option)
                    }}
                    onMouseLeave={(e) => handleCategoryLeaveHover(e)}
                  >
                    <div className='d-flex align-items-center hoverPointer text-center fs-4 text-dark user-select-none'>
                      <span
                        className='pt-1'
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          maxWidth: '100%',
                        }}
                      >
                        {option.label}
                      </span>
                    </div>
                  </div>
                )
              })}
            {(!filteredOptions || filteredOptions.length === 0) && loader()}
          </div>
        </div>
      )}
    </div>
  )
}

export default SingleSelect
