import Skeleton from 'react-loading-skeleton'
import {CellLoader} from '../../../layout/components/opensooq/CellLoader'
import CreditCell from './CreditCell'
import CreditCellSkeleton from './CreditCellSkeleton'
import {useIntl} from 'react-intl'
import {useEffect, useState} from 'react'
import getWalletDetailsApi from '../../../../api/getWalletDetails.api'
import {WalletDetailsInterface} from '../../../interfaces/WalletDetailsInterface'
import {TopUpModal} from '../../accounts/wallet/TopUpModal'
import {FlowEnum} from '../../../interfaces/BuyNowServiceInterface'
import AddCreditModal from '../../accounts/wallet/AddCreditModal'
import {BuyNowModal} from '../../accounts/wallet/BuyNowModal'
import {RechargeVoucherModal} from '../../accounts/wallet/RechargeVoucherModal'
import {SliderModal} from '../../accounts/wallet/SliderModal'
import {
  generateOpenSooqRedirectUrl,
  getCookies,
  isDeviceMobile,
  deepLinkHandler,
  deepLinks,
} from '../../../helpers/commonFunctions'
import {useLang} from '../../../i18n/OpenSooqi18n'
import { logOrderStartClick } from '../../../../logging/helpers/commonLoggingFunctions'
import { EnumUxTypes } from '../../../../logging/types/LoggingTypes'

const MyListingsCredit = ({setOpenLearnMore, setLearnMoreLink}) => {
  const Intl = useIntl()
  const lang = useLang()
  const cookies = getCookies()
  const [isLoading, setIsLoading] = useState(true)
  const [walletDetails, setWalletDetails] = useState<WalletDetailsInterface>()
  const [showTopUpModal, setShowTopUpModal] = useState<boolean>(false)
  const [flow, setFlow] = useState<FlowEnum>(undefined)
  const [showAddCreditModal, setShowAddCreditModal] = useState<boolean>(false)
  const [showBuyNowModal, setShowBuyNowModal] = useState<boolean>(false)
  const [showRechargeVoucherModal, setShowRechargeVoucherModal] = useState<boolean>(false)
  const [showSlidingModal, setShowSlidingModal] = useState<boolean>(false)
  const learnMoreLink = `${generateOpenSooqRedirectUrl(
    process.env.REACT_APP_OPENSOOQ_BASE,
    cookies.ecountry,
    lang
  )}/upselling/credits?webview=1`

  const handleOpenLearnMore = (link) => {
    setOpenLearnMore(true)
    setLearnMoreLink(link)
  }
  const getWalletDetails = () => {
    getWalletDetailsApi()
      .then((response) => {
        setWalletDetails(response.data)
        setIsLoading(false)
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }

  const setFlowHandler = (flow: number) => {
    setFlow(flow)
  }

  const showTopUpModalHandler = () => {
    logOrderStartClick("wallet_cell", EnumUxTypes.BUTTON)
    setShowTopUpModal(true)
  }

  const hideTopUpModal = () => {
    setShowTopUpModal(false)
    setFlow(undefined)
  }

  const hideAddCreditModal = () => {
    setShowAddCreditModal(false)
    setFlow(undefined)
  }

  const hideBuyNowModalHandler = () => {
    setFlow(undefined)
    setShowBuyNowModal(false)
  }

  const hideRechargeVoucherModal = () => {
    setShowRechargeVoucherModal(false)
    setFlow(undefined)
  }
  const hideSlidingModal = () => {
    setShowSlidingModal(false)
    setFlow(undefined)
  }

  useEffect(() => {
    getWalletDetails()
  }, [])

  useEffect(() => {
    setShowTopUpModal(false)
    switch (flow) {
      case FlowEnum.ADD_CREDIT:
        setShowAddCreditModal(true)
        break
      case FlowEnum.ADD_LISTING:
      case FlowEnum.ADD_PROMOTION:
      case FlowEnum.ADD_REPOST:
        setShowBuyNowModal(true)
        break
      case FlowEnum.RECHARGE:
        setShowRechargeVoucherModal(true)
        break
      case FlowEnum.SLIDER:
        deepLinkHandler(deepLinks.contactUs[lang] +  "/15/99")
        hideTopUpModal();
        break
    }
  }, [flow])

  const LoadingCells = () => {
    const cellLoader = []
    for (let cellIndex = 0; cellIndex < 5; cellIndex++) {
      cellLoader.push(<CreditCellSkeleton key={cellIndex} idx={cellIndex} width={31} />)
    }
    return cellLoader
  }
  return (
    <>
      {isDeviceMobile() ? (
        <div className='card w-100 mb-2 mt-5 p-5'>
          <div className='d-flex justify-content-between align-items-center'>
            <div className='d-flex flex-column gap-1 fs-3'>
              <span
                className='text-primary text-decoration-underline'
                onClick={() => handleOpenLearnMore(learnMoreLink)}
              >
                {Intl.formatMessage({id: 'mylistings.listingcredits.mobile'})}
              </span>
              <span className='fw-bold'>{walletDetails?.wallet?.balance ?? 0}</span>
            </div>
            <button
              className='btn btn-md btn-primary p-3 fw-bold fs-4'
              data-id="credits-buy-credits"
              style={{borderRadius: '12px'}}
              onClick={showTopUpModalHandler}
              disabled={isLoading}
            >
              {Intl.formatMessage({id: 'mylistings.listingcredits.buy'})}
            </button>
          </div>
        </div>
      ) : (
        <div className='d-flex flex-column card p-5 justify-content-start custom-listing-card-width'>
          <div className='d-flex flex-row justify-content-between'>
            <div>
              <div className='fw-bold fs-2'>
                {Intl.formatMessage({id: 'mylistings.listingcredits'})}
                <i
                  className='mx-1 fs-5 fa fa-5x fa-circle-info'
                  style={{color: 'grey'}}
                  onClick={() => handleOpenLearnMore(learnMoreLink)}
                ></i>
              </div>
              <div className='fs-5'>
                {!isLoading &&
                  (walletDetails?.wallet?.latestExpiry ||
                    +walletDetails?.wallet?.latestExpiry == 0) &&
                  Intl.formatMessage({id: 'mylistings.listingcredits.expire'})}
                <span className='mx-1 fw-bold'>
                  {isLoading ? (
                    <Skeleton width={50} />
                  ) : walletDetails?.wallet?.latestExpiry ||
                    +walletDetails?.wallet?.latestExpiry == 0 ? (
                    `${walletDetails?.wallet?.latestExpiry ?? 0} ${Intl.formatMessage({
                      id: 'mylistings.listingcredits.days',
                    })}`
                  ) : (
                    ''
                  )}
                </span>
              </div>
            </div>
            <button
              className='btn btn-md btn-primary p-3 fw-bold fs-4'
              style={{borderRadius: '12px'}}
              onClick={showTopUpModalHandler}
              disabled={isLoading}
            >
              {Intl.formatMessage({id: 'mylistings.listingcredits.buy'})}
            </button>
          </div>
          <div className='mt-10 d-flex flex-row flex-wrap'>
            {isLoading && <>{LoadingCells()}</>}
            {!isLoading && walletDetails && (
              <>
                <CreditCell
                  dataId='elas'
                  width={190}
                  title={walletDetails?.wallet?.elasHint?.title}
                  value={walletDetails.wallet?.bundlesSummary?.Elas.toString()}
                />
                <CreditCell
                  dataId='bumpup'
                  width={190}
                  title={walletDetails?.wallet?.bumpupHint?.title}
                  value={walletDetails?.wallet?.bundlesSummary?.Bumpup.toString()}
                />
                <CreditCell
                  dataId='promoted'
                  width={190}
                  title={walletDetails?.wallet?.promotedHint?.title}
                  value={walletDetails?.wallet?.bundlesSummary?.Promoted.toString()}
                />
                <CreditCell
                  dataId='turbo'
                  width={190}
                  title={Intl.formatMessage({id: 'mylistings.listingcredits.turbo'})}
                  value={walletDetails?.wallet?.bundlesSummary?.Turbo?.toString()}
                />
                <CreditCell
                  dataId='vip'
                  width={190}
                  title={Intl.formatMessage({id: 'mylistings.listingcredits.vip'})}
                  value={walletDetails?.wallet?.bundlesSummary?.VIP?.toString()}
                />
                <CreditCell
                  dataId='balance'
                  width={190}
                  title={walletDetails?.wallet?.currency}
                  value={walletDetails?.wallet?.balance?.toString()}
                />
              </>
            )}
          </div>
        </div>
      )}
      <TopUpModal show={showTopUpModal} handleClose={hideTopUpModal} setFlow={setFlowHandler} />
      {showAddCreditModal && (
        <AddCreditModal show={showAddCreditModal} handleClose={hideAddCreditModal} />
      )}
      <BuyNowModal
        show={showBuyNowModal}
        handleClose={hideBuyNowModalHandler}
        children={<></>}
        flow={flow}
      ></BuyNowModal>
      <RechargeVoucherModal
        show={showRechargeVoucherModal}
        handleClose={hideRechargeVoucherModal}
      />
      <SliderModal show={showSlidingModal} handleClose={hideSlidingModal} />
    </>
  )
}

export default MyListingsCredit
