import { useIntl } from 'react-intl';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

export function CvReactTooltipContainer() {
    const Intl = useIntl();
    return (
        <>
            <ReactTooltip clickable={true} anchorId="PromotedBumpup"  className='w-200px'    place="bottom" content={Intl.formatMessage({id : "cv.tooltip.PromotedBumpup"})} />
            <ReactTooltip clickable={true} anchorId="PromotedPremium" className='w-200px'   place="bottom" content={Intl.formatMessage({id : "cv.tooltip.PromotedPremium"})}/>
            <ReactTooltip clickable={true} anchorId="PromotedTurbo"   className='w-200px'   place="bottom" content={Intl.formatMessage({id : "cv.tooltip.PromotedTurbo"})}  />
            <ReactTooltip clickable={true} anchorId="PromotedVip"     className='w-200px'   place="bottom" content={Intl.formatMessage({id : "cv.tooltip.PromotedVip"})}    />
        </>
    )
}