import Moment from 'moment';
import React, { useEffect, useState } from "react";
import { useIntl } from 'react-intl';
import draftsApi from "../../../api/listing/drafts.api";
import { Button } from "../../../app/layout/components/opensooq/Button";
import { SearchComponent } from "../../../app/layout/components/opensooq/SearchComponent";
import { TableLoader } from "../../../app/layout/components/opensooq/TableLoader";
import { logScreenLoad } from "../../../logging/helpers/commonLoggingFunctions";
import { IMAGE_SM } from "../../helper/ImageSize";
import { editListing, getNoImageLogo } from "../../helpers/commonFunctions";
import { useLang } from "../../i18n/OpenSooqi18n";
import { DraftModal } from "../../layout/components/modals/DraftModal";
import AddNewListing from "../common/AddNewListing";

export interface DraftsInterface {
    description             :  string
    draft_id                :   string
    inserted_at             :   string
    mainImage               :   string
    next_step_identifier    :   string
    subTitle                :   string
    title                   :   string
    workflow_id             :   number
}

const Drafts = () => {
    const Intl = useIntl();
    const [loader,serLoader] = useState <boolean>(true)
    const [drafts,setDrafts] = useState <DraftsInterface[]>([]); 
    const [searchString, setSearchString] = useState("")
    const [searchData,setSearchData] = useState <DraftsInterface[]>([]); 
    const lang = useLang()
    const noImage = getNoImageLogo(lang)
    const [showModal,setShowModal] = useState(false)

    useEffect(() => {
        getDrafts()
    },[]);

    useEffect(()=>{
        logScreenLoad()
    }, [])

    const getDrafts = () => {
        draftsApi('').then(response => {
            const items = response.data.result.data.items;
            setDrafts(items);
            serLoader(false)
            setSearchData(items);
        }).catch(() => {  
            const items : any = [];  
            setDrafts(items);
            serLoader(false)
            setSearchData(items);
        });
    }

    const setSearch = (e: any) => {
        setSearchString(e.target.value);
    };

    useEffect(() => {
        const newList = drafts.filter(draft => {
            if(draft.title.toLowerCase().includes(searchString.toLowerCase())){
                return true;
            }

            if(draft.subTitle.toLowerCase().includes(searchString.toLowerCase())){
                return true
            }

        })
        setSearchData(newList);
    },[searchString]);


    const closeModal = () => {
        setShowModal(false)
        getDrafts()
    }
    const AdsList:React.FC<{searchData:DraftsInterface[]}> = ({searchData}) => {
        return <table className="table table-hover align-middle table-row-dashed fs-6 gy-3 dataTable no-footer" id="kt_table_widget_4_table">
            <thead>
                <tr className="text-start text-gray-900 fw-bold fs-7  gs-0">
                    <th className="text-start min-w-100px sorting_disabled " rowSpan={1} colSpan={1} > {Intl.formatMessage({id: 'listingDrafts'})}</th>
                    <th className="text-end min-w-125px sorting_disabled hideOnMobile" rowSpan={1} colSpan={1}>{Intl.formatMessage({id: 'draftDate'})}</th>
                    {/* <th className="text-end min-w-100px sorting_disabled hideOnMobile" rowSpan={1} colSpan={1}>{Intl.formatMessage({id: 'action'})}</th> */}
                    <th className="text-start sorting_disabled" rowSpan={1} colSpan={1} ></th>
                </tr>
            </thead>
            <tbody className="">
                {searchData.map((draft  : DraftsInterface,index) => {
                return <React.Fragment key={index}>
                        <tr key={index} className={(index%2 === 0) ? "even": "odd" } data-kt-table-widget-4="expand_row"  data-bs-toggle="collapse" data-bs-target={`#id-${draft.draft_id}`}>
                            <td className="">
                                <div className="d-flex align-items-center">
                                    <div className="symbol symbol-50px me-5">
                                        <img src={(draft.mainImage != '' ) ? `${process.env.REACT_APP_ARBOK_URL}${IMAGE_SM}/${draft.mainImage}.jpg` : noImage} alt=""/>
                                    </div>
                                    <div className="d-flex justify-content-start flex-column">
                                        <a href="#" className="text-dark fw-bold text-hover-primary fs-6">{draft.title}</a>
                                        <span className="text-dark fw-semibold  d-block fs-7">{draft.subTitle}</span>
                                    </div>
                                </div>
                            </td>
                            <td className="text-end hideOnMobile">
                                <span className="text-dark text-hover-primary">{Moment(draft.inserted_at).format('DD-MM-YYYY')}</span>
                            </td>
                            <td className="text-end hideOnMobile">
                                <span className="text-dark text-hover-primary d-block fs-6">
                                    <Button action={() => {editListing(draft,'draft')}} text={Intl.formatMessage({id: 'continuePostingYourListing'})} className={'btn-primary btn-sm'}/>
                                </span>
                            </td>
                            <td className="text-end hideOnDesktop">
                                <button type="button" className="btn btn-sm btn-icon btn-light btn-active-light-primary toggle h-25px w-25px" data-kt-table-widget-4="expand_row"  data-bs-toggle="collapse" data-bs-target={`#id-${draft.draft_id}`}>
                                    <span className="svg-icon svg-icon-3 m-0 toggle-off">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect opacity="0.5" x="11" y="18" width="12" height="2" rx="1"
                                                transform="rotate(-90 11 18)" fill="currentColor"></rect>
                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                    <span className="svg-icon svg-icon-3 m-0 toggle-on">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect x="6" y="11" width="12" height="2" rx="1" fill="currentColor"></rect>
                                        </svg>
                                    </span>
                                </button>
                            </td>
                        </tr>
                        <tr id={`id-${draft.draft_id}`} className="hideOnDesktop collapse" data-bs-parent="#kt_accordion_tr-1"  >
                            <td colSpan={6} className="shadow1">
                                <dl className="row mb-0 ">
                                    <dt className="col-4 text-gray-500 fw-bolder">{Intl.formatMessage({id: 'draftDate'})}</dt>
                                    <dd className="col-8">{Moment(draft.inserted_at).format('DD-MM-YYYY')}</dd>
                                </dl>
                                <dl className="row mb-0">
                                    <dd className="col-12">
                                        <Button action={() => {editListing(draft,'draft')}} text={Intl.formatMessage({id: 'continuePostingYourListing'})} className={'btn-primary'}/>
                                    </dd>
                                </dl>
                            </td>
                        </tr>
                    </React.Fragment>
                })}
            </tbody>
        </table>
    };


    return (
        <>
            <div className="row g-5">
                <div className='col-12'>
                    <div className='card '>   
                        <div className="card-header ">
                            <SearchComponent action={setSearch}  placeholder={`${Intl.formatMessage({id: 'searchIn'})} ${Intl.formatMessage({id : "menu.listing.drafts"})}`}/>
                            <div className="card-toolbar flex-row-fluid justify-content-end gap-5 mt-3">
                                {/* <Button text={Intl.formatMessage({id: 'postAd'})} className={'btn-primary btn-sm'}/> */}
                            </div>       
                        </div> 
                        <div className="card-body">
                            {loader  &&  <TableLoader/>  }
                            {searchData && !loader && <> 
                                {searchData.length == 0 &&  <div className="container my-15">
                                        <h1 className="mb-10">{Intl.formatMessage({id:"ads.noListings"})}</h1>
                                        <AddNewListing source={"default"} withBorder={true} widgetName="add_listing_card"/>
                                     </div>}
                                {searchData.length > 0 && <> <AdsList searchData={searchData}/> </>  }
                            </>
                            }
                        </div> 
                    </div>
                </div>
            </div>
            <DraftModal show={showModal} draftId='' handleClose={closeModal} />
        </>
    )}
    
    export { Drafts };
    