import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useIntl } from "react-intl"
import { KTSVG } from '../../../helpers'
import validateAccountPasswordApi from '../../../../api/account/validateAccountPassword.api'
import { handleError } from '../../../modules/common/HandleError'
import { useLang } from '../../../i18n/OpenSooqi18n'
import { Loader } from '../opensooq/Loader'
import { getCookies, logout } from '../../../helpers/commonFunctions'

type Props = {
  show: boolean
  handleClose: () => void
  showInvalidPassword: (message) => void
  handleCloseOnApi : () => void
  password: string
}

const DeactivateAccountConfirmationPopup = ({show, handleClose,showInvalidPassword,password,handleCloseOnApi}: Props) => {
  const Intl = useIntl()
  const lang = useLang();
  const [loading,setLoading] = useState<boolean>(false);

  const deactivateAccount = () => {
    setLoading(true)
    validateAccountPasswordApi({password: password}).then((response => {
        const country = getCookies().ecountry;
        const lang = getCookies().NEXT_LOCALE;
        logout();
        const url = process.env.REACT_APP_OPENSOOQ_BASE?.replace("{country}",country ?? 'jo').replace("{lang}",lang) ?? '' ;
        window.location.href = url + '?source=deactivateAccount';
    })).catch((error) => {
        if(error.response.data[0]){
            showInvalidPassword(error.response.data[0].message)
            setLoading(false)
            handleCloseOnApi();
        }else{
            handleError(error.response,lang,Intl)
            setLoading(false)
        }
    })
  }

  const handleClosePopup = () => {
    setLoading(false)
    handleClose();
    showInvalidPassword('')
  }

  return <>
        <Modal id='kt_modal_create_app' tabIndex={-1} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered ' show={show} onHide={handleClosePopup}  >
            <div className='modal-header p-5'>
                <h2>{Intl.formatMessage({id : "account.settings.confirmation.header"})}</h2>
                <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
                    <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                </div>
            </div>
            <div className='model-body'>
                <div className="pt-5 ps-5 pe-5 pb-10">
                    <span className='fs-5 fw-bolder'> {Intl.formatMessage({id: 'account.settings.confirmation.body.header'})}</span>
                    <br/>
                    <span className='fs-6'> {Intl.formatMessage({id: 'account.settings.confirmation.body.body'})}</span>
                </div>
            </div>
            {!loading && <div className='model-footer p-5'>
                <button onClick={deactivateAccount} className="btn btn-primary w-100" >{Intl.formatMessage({id: 'account.settings.confirmation.button.confirm'})}</button>
                <button onClick={handleClosePopup} className="btn btn-light w-100 mt-2" >{Intl.formatMessage({id: 'account.settings.confirmation.button.cancel'})}</button>
            </div>}
            {loading && <div className='model-footer p-5'>
                <Loader/>
            </div>}
        </Modal>
    </>
}

export { DeactivateAccountConfirmationPopup }
