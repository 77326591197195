import React, {useState, useEffect, useCallback, useRef} from 'react'
import MultiRangeSlider from '../MultiRangeSlider'
import {AddFieldType, RemoveFieldType} from '../../../../../interfaces/MyListings'
import {RangeFieldMobileI} from '../../../../../interfaces/MyListingsMobile'
import Select from 'react-select'
import {customUnitsSelectStyles} from '../../../my-listings/helper'

// Debounce function
const debounce = (func, wait) => {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(this, args), wait)
  }
}

type MultiSelectPillsProps = {
  data: RangeFieldMobileI
  addField: AddFieldType
  removeField: RemoveFieldType
  selectedFields: any
}

const RangeTextFilterMobile = ({
  addField,
  removeField,
  data,
  selectedFields,
}: MultiSelectPillsProps) => {
  const [step, setStep] = useState(1)
  const [minValue, setMinValue] = useState(null)
  const [maxValue, setMaxValue] = useState(null)
  const [initialMinValue, setInitialMinValue] = useState(null)
  const [initialMaxValue, setInitialMaxValue] = useState(null)
  const [tempMinValue, setTempMinValue] = useState(null)
  const [tempMaxValue, setTempMaxValue] = useState(null)
  const [minValueError, setMinValueError] = useState(false)
  const [maxValueError, setMaxValueError] = useState(false)
  const timeoutRef = useRef(null)

  const hideMinRange = minValue == maxValue && minValue == initialMinValue
  const hideMaxRange = maxValue == minValue && maxValue == initialMaxValue

  useEffect(() => {
    if (minValueError || minValue === '')
      removeField(data.fieldId, {value: minValue, keyName: 'from'}, 'RANGE')
    if (maxValueError || maxValue === '')
      removeField(data.fieldId, {value: maxValue, keyName: 'to'}, 'RANGE')
    if (!minValueError && !isNaN(parseInt(maxValue)))
      addField(data.fieldId, {value: maxValue, keyName: 'to'}, 'RANGE')
    if (!maxValueError && !isNaN(parseInt(minValue)))
      addField(data.fieldId, {value: minValue, keyName: 'from'}, 'RANGE')
  }, [minValueError, maxValueError, minValue, maxValue])

  useEffect(() => {
    if (data?.config) {
      const selected = selectedFields[data.fieldId]
      const from = selected?.from || data.config?.min
      const to = selected?.to || data.config?.max
      setInitialMinValue(data.config?.min)
      setInitialMaxValue(data.config?.max)
      setMinValue(from)
      setMaxValue(to)
      // handle step later
      // setStep(data.config?.step)
      setTempMinValue(from)
      setTempMaxValue(to)
    }
  }, [])

  const handleSliderChange = ({min, max}) => {
    let maxVal = max
    let minVal = min
    if (maxVal + step > initialMaxValue) {
      maxVal = initialMaxValue
    }
    if (minVal + step > initialMaxValue) {
      minVal = initialMaxValue
    }
    if (minVal - step < initialMinValue) {
      minVal = initialMinValue
    }
    if (maxVal - step < initialMinValue) {
      maxVal = initialMinValue
    }

    setTempMinValue(minVal)
    setTempMaxValue(maxVal)

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      validateAndSetValue('min', minVal)
      validateAndSetValue('max', maxVal)
    }, 500)
  }

  const validateAndSetValue = (type, value) => {
    const intValue = Number(value)
    const isValid =
      /^\d+$/.test(value) && intValue >= initialMinValue && intValue <= initialMaxValue

    if (type === 'min') {
      if (isValid && intValue <= maxValue) {
        setTempMinValue(intValue)
        setMinValue(intValue)
        setMinValueError(false)
      } else {
        setMinValueError(true)
      }
    } else {
      if (isValid && intValue >= minValue) {
        setTempMaxValue(intValue)
        setMaxValue(intValue)
        setMaxValueError(false)
      } else {
        setMaxValueError(true)
      }
    }
  }

  const handleInputChange = useCallback(
    debounce((type, value) => {
      if (isNaN(+value)) return
      validateAndSetValue(type, value)
    }, 0),
    [maxValue, minValue]
  )

  const handleMinValueChange = (e) => {
    const value = e.target.value
    if (isNaN(+value)) return
    setTempMinValue(value)
  }

  const handleMaxValueChange = (e) => {
    const value = e.target.value
    if (isNaN(+value)) return
    setTempMaxValue(value)
  }

  return (
    <div className='card p-4 gap-5 shadow' key={data.fieldId}>
      <div className='fw-bold mb-4 fs-1 px-2'>{data.label}</div>
      <div className='d-flex justify-content-between mb-4'>
        <div className='form-control-container'>
          <span className='form-control-container__time fs-3'>{data.minLabel}</span>
          <input
            type='text'
            className={`form-control-container__time__input range-input range-filter-input ${
              minValueError ? 'border-danger' : ''
            }`}
            value={
              tempMinValue == initialMinValue
                ? data.config?.minPlaceHolder || tempMinValue
                : tempMinValue
            }
            // onChange={handleMinValueChange} // Update temp state immediately
            readOnly={true}
            onBlur={(e) => handleInputChange('min', e.target.value)} // Apply debounce validation on blur
          />
        </div>
        <div className='form-control-container'>
          <span className='form-control-container__time fs-3'>{data.maxLabel}</span>
          <input
            type='text'
            className={`form-control-container__time__input range-input range-filter-input ${
              maxValueError ? 'border-danger' : ''
            }`}
            value={
              tempMaxValue == initialMinValue
                ? data.config?.minPlaceHolder || tempMaxValue
                : tempMaxValue
            }
            // onChange={handleMaxValueChange} // Update temp state immediately
            readOnly={true}
            onBlur={(e) => handleInputChange('max', e.target.value)} // Apply debounce validation on blur
          />
        </div>
      </div>
      <MultiRangeSlider
        min={initialMinValue}
        max={initialMaxValue}
        hideMinRange={hideMinRange}
        hideMaxRange={hideMaxRange}
        minVal={minValue}
        maxVal={maxValue}
        onChange={handleSliderChange}
        step={1}
      />
    </div>
  )
}

export default RangeTextFilterMobile
