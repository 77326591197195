import React from 'react'
import { DynamicFiltersMapperProps, SelectedFieldsType, ValueType } from '../../../interfaces/MyListings'
import MultiSelect from './filters/MultiSelect';
import SearchField from './filters/SearchField';
import RangeTextFilter from './filters/RangeTextFilter';
import RangeFilter from './filters/RangeFilter';

const DynamicFiltersMapper = ({filters, addField, removeField, selectedFields} : DynamicFiltersMapperProps) => {
    const getParentIdValue = (parentId: number) => {
        if (!parentId || parentId === 0) {
          return null;
        }
        const parentField = selectedFields[parentId];
        if(!parentField)
            return null;
        if (typeof parentField === 'object' && ('to' in parentField || 'from' in parentField)) {
          return null;
        }
        if (!Array.isArray(parentField) && 'value' in parentField) {
          return (parentField as ValueType).value;
        }
        if (Array.isArray(parentField) && parentField.length === 1) {
          return parentField[0].value;
        }
        return null;
      };

    return <>
    {    
        filters.map((filter, _) => {
            const {type, parentId} = filter;
            const isDisabled = ((parentId !== 0 && !selectedFields[parentId]) || (selectedFields[parentId] as ValueType[])?.length > 1);
            const parentIdValue = getParentIdValue(parentId);
            switch (type) {
            case 'dropdownlist':
                return <MultiSelect addField={addField} removeField={removeField} disabled={isDisabled} label={filter.label} parentid={parentId.toString()} fieldId={filter.fieldId.toString()} parentidValue={parentIdValue}/>
            case 'rangeFieldWithUnits':
                return <RangeTextFilter addField={addField} removeField={removeField} rangeTextLabel={filter} reset={false} selectedFields={selectedFields}/>
            case 'rangeField':
                return <RangeFilter addField={addField} removeField={removeField} data={filter} reset={false}/>
            case 'text':
                return <SearchField addField={addField} removeField={removeField} searchField={{label: filter.label, fieldId: filter.fieldId.toString(), type: type, placeHolder: filter.placeHolder}}/>
            default:
                return <></>
            }
        })}
    </>
}

export default DynamicFiltersMapper