export const trackEvent = (
    eventAction : number| string,
    eventErrorCategory : string,
    eventLabel : string,
    eventUrl? : string,
) => {
    window.dataLayer = window.dataLayer ?? [];
    window.dataLayer.push({
        event: "UserInteraction",
        eventAction: eventAction,
        eventCategory: eventErrorCategory,
        eventLabel: eventLabel,
        fullUrl:eventUrl ?? "",
    });
};