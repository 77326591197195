import {useIntl} from 'react-intl'
import { useLang } from '../../../../i18n/OpenSooqi18n'
import { generateOpenSooqRedirectUrl, getCookies, getSiteCustomContent, redirectionInterceptor } from '../../../../helpers/commonFunctions'

export function MenuInner() {
  const intl = useIntl()
  const lang = useLang()
  const openSooqUrl = generateOpenSooqRedirectUrl(process.env.REACT_APP_OPENSOOQ_BASE ?? '',getCookies().ecountry,lang);
  return (
    <>
     <div className='menu-item me-lg-1'>
      <a onClick={redirectionInterceptor} href={`${openSooqUrl}`}  target="_blank" className={'btn btn-light text-dark unsetOnHover'}>{getSiteCustomContent(intl).backToSite}</a>
     </div>
    </>
  )
}
